import { useState, useEffect, useContext } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import {Link, useParams, useNavigate} from "react-router-dom";
import placeholderImage from '../img/image_placeholder.png'
import { Placeholder } from "react-bootstrap";
import UserContext from './UserContext';


function EditCustomer(){
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const [customer, setCustomer] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const navigate = useNavigate();
    const [customer_photo, setCustomer_photo] = useState("");
    const userContext = useContext(UserContext);

    const handleInput = (e) => {
          setCustomer({ ...customer, [e.target.name]: e.target.value });
     
        }
   

      const handleFileChange = (event) => {
        setCustomer_photo(event.target.files[0]);
       setCustomer({ ...customer, customer_photo: event.target.files[0] });
      };

      
      const handleImageLoad = () => {
        setIsImageLoading(false);
    };



    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/edit-customer/`+id, ).then(res=>{
           if(res.data.status === 200){
              setCustomer(res.data.customer); 
           }
           else if(res.data.status === 404){
          Swal("Error",res.data.error,"error");
             //navigate.push('/view-inventory');
             //console.log(res.data.error)
           }
      
           setIsLoading(false);
        });
       }, []);

       async function update(event)
       {
        event.preventDefault();
        setLoading(true); 

         const data = customer;
  
   
            await axios.post(`${API_URL}/update-customer/${id}`, data).then(res =>{
          Swal.fire({
               icon:"success",
               text:res.data.message
             }).then(function(){
                 setErrorList([]);
                 navigate('/view-customers');
            
             
           });
           }).catch(function(error) {
              // if(error.response.status===422){         
              setErrorList(error.response.data.validate_err);
              // }
          //    else{
               Swal.fire({
              
                 text:error.response.data.message,
                 icon:"error"
               })
               setLoading(false); 
          //    }
           })





        
           }

    return(
<div>
<SideNav/>
<TopBar/>
{isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

        
<div class="right_panel position create_inventory h-100" id="right_panel">
  
  <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
      <h1>Edit Customer</h1>
  </div>

  <div class="content_wrapper pt-0">
    
      <div id="inventory_section1">
          <div class="col" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
              <div class="vm_content_box_1" style={{padding:'20px 200px 20px 200px'}}>
                  <div class="title">
                      <h2><i class="bi bi-person"></i> Personal Information</h2>
                  </div>
                  <div class="content pt-3">
                      <div class="row">
                          
                        
                     
                          <div class="col-md-6 form-group">
                              <label for="firstname">First Name</label>
                              <input type="text" class="form-control" name="firstname" id="firstname" placeholder="Enter Your First Name" value={customer.firstname} 
                              onChange={handleInput }/>
                               <span class="text-danger">{error_list.firstname}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="lastname">Last Name</label>
                              <input type="text" class="form-control" name="lastname" id="lastname" placeholder="Enter Your Last Name" value={customer.lastname}
                              onChange={handleInput }/>
                                  <span class="text-danger">{error_list.lastname}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="phone">Phone Number</label>
                              <input type="text" class="form-control" name="phone" id="phone" placeholder="Enter Your Phone Number" value={customer.phone}
                              onChange={handleInput }/>
                                 <span class="text-danger">{error_list.phone}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="email">Email Address</label>
                              <input type="text" class="form-control" name="email" id="email" value={customer.email} placeholder="Enter Your Email Address"
                               onChange={handleInput }/>
                                  <span class="text-danger">{error_list.email}</span>
                          </div>
                       
                          {/* <div class="col-md-6 form-group">
                              <label for="country">Country</label>
                              <select class="form-select" name="country" id="country"  value={customer.country} onChange={handleInput }>
                              <option value="">Select Country</option>
                                  <option value="Canada">Canada</option>
                                  <option value="United States">United States</option>
                              </select>
                              <span class="text-danger">{error_list.country}</span>
                          </div>
                          <div class="col-md-12 form-group">
                              <label for="address">Address</label>
                              <input type="text" class="form-control" name="address" id="address" value={customer.address} placeholder="Address"
                              onChange={handleInput }/>
                                   <span class="text-danger">{error_list.address}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="apart_or_suite">Apt/Suite/Other</label>
                              <input type="text" class="form-control" name="apart_or_suite" id="apart_or_suite" value={customer.apart_or_suite}  placeholder="Apt/Suite/Other"
                              onChange={handleInput }/>
                                   <span class="text-danger">{error_list.apart_or_suite}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="city">City</label>
                              <input type="text" class="form-control" name="city" id="city" value={customer.city}  placeholder="Enter Your City" 
                              onChange={handleInput }/>
                               <span class="text-danger">{error_list.city}</span>
                          </div>
                          <div class="col-md-6 form-group">
                          <label for="province">State</label>
                              <input type="text" class="form-control" name="province" value={customer.province} id="province" placeholder="State"
                               onChange={handleInput }/>
                              <span class="text-danger">{error_list.province}</span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="postalcode">Postal Code</label>
                              <input type="text" class="form-control" name="postal_code" id="postal_code"  value={customer.postal_code} placeholder="Postal Code"
                               onChange={handleInput }/>
                              <span class="text-danger">{error_list.postal_code}</span>
                          </div> */}
                      </div>
                  </div>

                  <div class="content pt-1 pb-4">
                     
                     <button class="btn btn-sm btn_secondary"  onClick={update}>{loading?"Updating":"Update"}</button>
            
             </div>
              </div>
          </div>

          {/* <div class="col ms-lg-4 pt-4 pt-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
              <div class="vm_content_box_1 h-100">
                  <div class="title">
                      <h2><i class="bi bi-person"></i> Employer Information</h2>
                  </div>
                 
              </div>
          </div> */}


      </div>

  </div>
</div>


</div>



    );
}
export default  EditCustomer;