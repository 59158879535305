

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2';


function ViewCalender() {

    const API_URL = process.env.REACT_APP_API_URL;
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const [kids_count, setKids_count] = useState(0);
    const [adults_count, setAdults_count] = useState(0);
    const [game_title, setGame_title] = useState('');
    const [game_description, setGame_description] = useState('');
  const [available_space, setAvailable_space] = useState('');
  const [bookedTimes, setBookedTimes] = useState([]);
  const [game_location, setGame_location] = useState('stouffville');
  const [game_type, setGame_type] = useState('');
  const [groupedBookedTimes, setGroupedBookedTimes] = useState({});
  const [bookingErrorList, setBookingErrorList] = useState([]);
  const [booking_date, setBooking_date] = useState('');
  const [booking_time, setBooking_time] = useState('');
  const [timeSlotById, setTimeSlotById] = useState([]);
  const [bookingAvailableTimeSlots, setBookingAvailableTimeSlots] = useState([]);

  const showToast = (message) => {
    Swal.fire({
      icon: 'success',
      title: message,
      color:"white",
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
          popup: 'sweetalert-popup',
          title: 'sweetalert-title',
          icon: 'sweetalert-icon',
        },
        background: '#333',
        showCloseButton: true,
        padding: '4px', 
    });
  };


  
    useEffect(() => {
        setIsLoading(true);
        getTimeSlotList();
        fetchEvents();
    }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount


    const getTimeSlotList = async (bookedTimes) => {
        try {
            const res = await axios.get(`${API_URL}/view-time-slot`);
            if (res.data.status === 200) {
                const timeSlot = res.data.timeslot[0];
                setTimeSlotById(timeSlot);
                setGame_title(res.data.timeslot[0].game_title);
                setGame_description(res.data.timeslot[0].game_description);
    
                const { start_time, end_time, time_interval, reserved_slot } = timeSlot;
                const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_interval, 10));
                const formattedTimeSlots = formatTimeSlots(timeSlots); // Format time slots
    
                setBookingAvailableTimeSlots(formattedTimeSlots);
    
                // const initialAvailableSpace = {};
                // formattedTimeSlots.forEach(slot => {
                //     initialAvailableSpace[slot] = reserved_slot - (groupedBookedTimes[slot] || 0);
                // });
                // setAvailable_space(initialAvailableSpace);
           


                const availableTimes = generateBookingTimeSlots(bookedTimes, formattedTimeSlots);
                setBookingAvailableTimeSlots(availableTimes);
                // setAvailable_space(reserved_slot);
                console.log('space',availableTimes)


            } else if (res.data.status === 404) {
                setBookingAvailableTimeSlots([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching time slots:', error);
        }
    };
    
    // Function to format time slots into ranges
    const formatTimeSlots = (timeSlots) => {
        const formattedSlots = [];
        
        for (let i = 0; i < timeSlots.length - 1; i++) {
            const startTime = timeSlots[i];
            const endTime = timeSlots[i + 1];
            
            // Convert to 12-hour format
            const startTime12h = convertTo12HourFormat(startTime);
            const endTime12h = convertTo12HourFormat(endTime);
            
            // Format the range
            const formattedRange = `${startTime12h} - ${endTime12h}`;
            formattedSlots.push(formattedRange);
        }
        
        return formattedSlots;
    };
    
    // Function to convert 24-hour time to 12-hour format
    const convertTo12HourFormat = (time24h) => {
        const [hours, minutes] = time24h.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours, 10);
    
        if (hours12 === 0) {
            hours12 = 12;
        } else if (hours12 === 12) {
            period = 'PM';
        } else if (hours12 > 12) {
            hours12 -= 12;
            period = 'PM';
        }
    
        return `${hours12.toString().padStart(2, '0')}:${minutes} ${period}`;
    };

        
        
        useEffect(() => {
            if (!isLoading && booking_time && timeSlotById) {
                let totalPrice = 0;
        
                // Assuming timeSlotById has the following properties: 
                // first_game_kids_price, first_game_adults_price
                // second_game_kids_price, second_game_adults_price
                // third_game_kids_price, third_game_adults_price
        
                if (booking_time.length === 1) {
                    totalPrice = timeSlotById.kids_price * kids_count + 
                                 timeSlotById.adults_price * adults_count;
                } else if (booking_time.length === 2) {
                    totalPrice = (timeSlotById.kids_price * kids_count + 
                                  timeSlotById.adults_price * adults_count) +
                                 (timeSlotById.second_kids_price * kids_count + 
                                  timeSlotById.second_adults_price * adults_count);
                } else if (booking_time.length >= 3) {
                    const additionalSlots = booking_time.length - 2;
                    totalPrice = (timeSlotById.kids_price * kids_count + 
                                  timeSlotById.adults_price * adults_count) +
                                 (timeSlotById.second_kids_price * kids_count + 
                                  timeSlotById.second_adults_price * adults_count) +
                                 (additionalSlots * (timeSlotById.third_kids_price * kids_count + 
                                                     timeSlotById.third_adults_price * adults_count));
                }
              
              
                setTimeSlotById((prev) => ({ ...prev, price: totalPrice }));
        
                const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
                const bookedPeople = groupedBookedTimes[booking_time] || 0;
                const remainingSpace = timeSlotById.reserved_slot - bookedPeople;
                setAvailable_space(Math.max(0, remainingSpace - totalPeople));
                if (totalPeople <= 30 && Math.max(0, remainingSpace - totalPeople) === 0) {
                    alert('Cannot exceed 30 people and available space is 0.');
                    // You can reset counts or take other actions as needed
                    // setKids_count(0);
                    // setAdults_count(0);
                }
            }
        }, [kids_count, adults_count, isLoading, booking_time]);   
    
        function fetchEvents() {
            setIsLoading(true);
            if(game_location == 'stouffville'){
                axios.get(`${API_URL}/view-booking`).then(res=>{
                  if (res.data.status === 200) {
    const formattedEvents = res.data.booking.map(booking => {
        // Ensure booking.booking_time is an array of times
        const bookingTimes = Array.isArray(booking.booking_time) ? booking.booking_time : JSON.parse(booking.booking_time);

        // Extract start and end times from bookingTimes
        const startTime = new Date(`${booking.booking_date} ${bookingTimes[0].split(' - ')[0]}`);
        const endTime = new Date(`${booking.booking_date} ${bookingTimes[bookingTimes.length - 1].split(' - ')[1]}`);

        // Create formatted event object
        const formattedEvent = {
            title: `${booking.game_location} - Adults: ${booking.adults_count}, Kids: ${booking.kids_count}`,
            start: startTime.toISOString(), // Convert to ISO 8601 format
            end: endTime.toISOString(), // Convert to ISO 8601 format
            description: `${booking.game_title} - ${booking.game_description}`,
            price: booking.total_price
        };

        return formattedEvent;
    });

    // Log each event separately for better readability
    formattedEvents.forEach(event => {
        console.log('Event:');
        console.log(`Title: ${event.title}`);
        console.log(`Start: ${event.start}`);
        console.log(`End: ${event.end}`);
        console.log(`Description: ${event.description}`);
        console.log(`Price: ${event.price}`);
        console.log(''); // Separate events with a blank line
    });



                        setEvents(formattedEvents);
            
                        console.log(res.data.booking.booking_date);
                    }
            else if(res.data.status === 404){
                setEvents([]);    
                }
                setIsLoading(false);
                });
            }else if(game_location == 'scarborough'){

                axios.get(`${API_URL}/view-booking-scarborough`).then(res=>{
                    if(res.data.status === 200){
                        const formattedEvents =  res.data.bookingScarborough.map(bookingScarborough => ({
                            title: bookingScarborough.game_location,
                            start: bookingScarborough.booking_date + 'T' + bookingScarborough.booking_time // Combine date and time into a single string
                        }));
                        setEvents(formattedEvents);
        
                    }
            else if(res.data.status === 404){
                setEvents([]);    
                }
                setIsLoading(false);
                });
            }
        
        
        

    }


    const calculateTimeSlots = (startTime, endTime, interval) => {
        const timeSlots = [];
        const [startHour, startMinute, startSecond] = startTime.split(':').map(Number);
        const [endHour, endMinute, endSecond] = endTime.split(':').map(Number);

        let current = new Date();
        current.setHours(startHour, startMinute, startSecond, 0);

        const end = new Date();
        end.setHours(endHour, endMinute, endSecond, 0);

        while (current < end) {
        const timeSlot = current.toTimeString().split(' ')[0]; // 'HH:mm:ss' format
        timeSlots.push(timeSlot);
        current.setMinutes(current.getMinutes() + interval);
        }

        return timeSlots;
    };

    const generateBookingTimeSlots = (bookedTimes, allTimeSlots) => {
        const availableTimeSlots = allTimeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
        return availableTimeSlots;
    };


    const handleBookingDateChange = (selectedDate) => {
        setIsLoading(true);
    resetBooking();

        setBooking_date(selectedDate);

        if(game_location == 'stouffville'){

            axios.get(`${API_URL}/booked-appointments-game`, { params: { selectedDate } })
            .then(response => {
                const bookedTimes = response.data;
                setBookedTimes(bookedTimes.filteredTimeSlots); // Save booked times to state
                getTimeSlotList(bookedTimes.filteredTimeSlots);// Pass only the time keys to getTimeSlotList
                setGroupedBookedTimes(bookedTimes.groupedBookedTimes);
                console.log(response.data.groupedBookedTimes); 
            })
            
            .catch(error => {
                console.error('Error fetching available date-time pairs:', error);
            });
        }else if (game_location == 'scarborough'){

            axios.get(`${API_URL}/booked-appointments-scarborough`, { params: { selectedDate } })
            .then(response => {
                const bookedTimes = response.data;
                setBookedTimes(bookedTimes.filteredTimeSlots); // Save booked times to state
                getTimeSlotList(bookedTimes.filteredTimeSlots);// Pass only the time keys to getTimeSlotList
                setGroupedBookedTimes(bookedTimes.groupedBookedTimes);
                console.log(response.data.groupedBookedTimes); 
            })
            
            .catch(error => {
                console.error('Error fetching available date-time pairs:', error);
            });
        }

  
    };


    // const handleTimeSlotChange = (e) => {
    //     const selectedSlot = e.target.value;
    //     setBooking_time(selectedSlot);

    //     const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
    //     const bookedPeople = groupedBookedTimes[selectedSlot] || 0;
    //     const remainingSpace = timeSlotById.reserved_slot - bookedPeople;
    //     setAvailable_space(remainingSpace - totalPeople);
    // };




    const handleTimeSlotChange = (e) => {
        const { value } = e.target;

        console.log(value)
        let updatedBookingTime;
        
        if (booking_time.includes(value)) {
            updatedBookingTime = booking_time.filter((time) => time !== value);
        } else {
            updatedBookingTime = [...booking_time, value];
        }
        
        setBooking_time(updatedBookingTime);
        console.log(updatedBookingTime);
        const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
        const bookedPeople = groupedBookedTimes[updatedBookingTime] || 0;
        const remainingSpace = timeSlotById.reserved_slot - bookedPeople;
        setAvailable_space(remainingSpace - totalPeople);
       console.log(remainingSpace - totalPeople)
    };





    // const handleTimeSlotChange = (e) => {
    //     const selectedSlot = e.target.value;
    //     let updatedBookingTime = [...booking_time];
    //     const slotIndex = booking_time.findIndex(slot => slot.timeSlot === selectedSlot);
    
    //     if (e.target.checked) {
    //         if (slotIndex === -1) {
    //             updatedBookingTime.push({
    //                 timeSlot: selectedSlot,
    //                 kids_count: 0,
    //                 adults_count: 0,
    //                 price: 0,
    //             });
    //         }
    //     } else {
    //         updatedBookingTime = updatedBookingTime.filter(slot => slot.timeSlot !== selectedSlot);
    //     }
    
    //     setBooking_time(updatedBookingTime);
    //     calculateAvailableSpace(updatedBookingTime);
    // };
    
    // const handleKidsCountChange = (timeSlot, value) => {
    //     const updatedKidsCount = parseInt(value, 10);
        
    //     // Update booking_time with the new kids_count
    //     const updatedBookingTime = booking_time.map(slot =>
    //         slot.timeSlot === timeSlot ? { ...slot, kids_count: updatedKidsCount } : slot
    //     );
        
    //     // Calculate price for the updated slot
    //     const updatedBookingTimeWithPrice = updatedBookingTime.map(slot => {
    //         if (slot.timeSlot === timeSlot) {
    //             const price = (updatedKidsCount * timeSlotById.kids_price) + (slot.adults_count * timeSlotById.adults_price);
    //             return { ...slot, price: price };
    //         }
    //         return slot;
    //     });
    
    //     // Update state with the new booking_time including updated prices
    //     setBooking_time(updatedBookingTimeWithPrice);
    
    //     // Recalculate available space based on the updated booking times
    //     calculateAvailableSpace(updatedBookingTimeWithPrice);
    // };
    
    // const handleAdultsCountChange = (timeSlot, value) => {
    //     const updatedAdultsCount = parseInt(value, 10);
        
    //     // Update booking_time with the new adults_count
    //     const updatedBookingTime = booking_time.map(slot =>
    //         slot.timeSlot === timeSlot ? { ...slot, adults_count: updatedAdultsCount } : slot
    //     );
        
    //     // Calculate price for the updated slot
    //     const updatedBookingTimeWithPrice = updatedBookingTime.map(slot => {
    //         if (slot.timeSlot === timeSlot) {
    //             const price = (slot.kids_count * timeSlotById.kids_price) + (updatedAdultsCount * timeSlotById.adults_price);
    //             return { ...slot, price: price };
    //         }
    //         return slot;
    //     });
    
    //     // Update state with the new booking_time including updated prices
    //     setBooking_time(updatedBookingTimeWithPrice);
    
    //     // Recalculate available space based on the updated booking times
    //     calculateAvailableSpace(updatedBookingTimeWithPrice);
    // };
    
    
    
 
    // const calculateAvailableSpace = (updatedBookingTime) => {
    //     const spaceUpdate = {};
    //     bookingAvailableTimeSlots.forEach(timeSlot => {
    //         const bookedSlot = updatedBookingTime.find(slot => slot.timeSlot === timeSlot);
    //         const bookedKids = bookedSlot ? bookedSlot.kids_count : 0;
    //         const bookedAdults = bookedSlot ? bookedSlot.adults_count : 0;
    //         const totalBooked = bookedKids + bookedAdults;
    //         spaceUpdate[timeSlot] = timeSlotById.reserved_slot - (groupedBookedTimes[timeSlot] || 0) - totalBooked;
    //     });
    //     setAvailable_space(spaceUpdate);
    // };
    
    // const convertTo12HourFormat = (time) => {
    //     const [hour, minute] = time.split(':');
    //     const hour12 = (parseInt(hour) % 12) || 12;
    //     const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
    //     return `${hour12}:${minute} ${ampm}`;
    // };

    const getNextTimeSlot = (index) => {
        return bookingAvailableTimeSlots[index + 1];
    };


    const handleGameType  = (event) => {
        setGame_type(event.target.value);
        };

        
    const handleGameLocation  = (event) => {
        setIsLoading(true);
    setGame_location(event.target.value);
        if(event.target.value == 'stouffville'){
            axios.get(`${API_URL}/view-booking`).then(res=>{
                if(res.data.status === 200){
                    const formattedEvents =  res.data.booking.map(booking => ({
                        title: booking.game_location,
                        start: booking.booking_date + 'T' + booking.booking_time // Combine date and time into a single string
                    }));
                    setEvents(formattedEvents);

                }
        else if(res.data.status === 404){
            setEvents([]);    
            }
            setIsLoading(false);
            });  
        }
        else if(event.target.value == 'scarborough'){
            axios.get(`${API_URL}/view-booking-scarborough`).then(res=>{
                if(res.data.status === 200){
                    const formattedEvents =  res.data.bookingScarborough.map(bookingScarborough => ({
                        title: bookingScarborough.game_location,
                        start: bookingScarborough.booking_date + 'T' + bookingScarborough.booking_time // Combine date and time into a single string
                    }));
                    setEvents(formattedEvents);

                }
        else if(res.data.status === 404){
            setEvents([]);    
            }
            setIsLoading(false);
            });  
        }
        };


    const handleDateChange = (e) => {
        
        const selectedDate = e.target.value;
        setDate(selectedDate);
        axios.get(`${API_URL}/booked-appointments`, { params: { selectedDate } })
        .then(response => {
            const bookedTimes = response.data.map(time => {
                const [hour, minute] = time.split(':');
                const hour12 = (parseInt(hour) % 12) || 12;
                const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
                return `${hour12}:${minute} ${ampm}`;
            });

            const availableTimes = generateTimeSlots(bookedTimes);
            // console.log(bookedTimes);
        })
        .catch(error => {
            console.error('Error fetching available date-time pairs:', error);
        });
    };

    const generateTimeSlots = (bookedTimes) => {
        // Generate time slots from 9 AM to 4 PM
        const startTime = 9;
        const endTime = 16;
        const timeSlots = [];

        for (let hour = startTime; hour <= endTime; hour++) {
        const formattedHour = hour % 12 || 12;
        const ampm = hour < 12 ? 'AM' : 'PM';
        const timeSlot = `${formattedHour}:00 ${ampm}`;
        timeSlots.push(timeSlot);
        }


    // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

        const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
    setAvailableTimeSlot(availableTimeSlots);
        return availableTimeSlots;
    };




        const handleSubmit = async (e) => {
            e.preventDefault();
            setLoading(true);
        

                await axios.post(`${API_URL}/save-meet-template`, {
                    title,
                    start_datetime: startDatetime,
                    end_datetime: endDatetime
                }).then(res =>{
            
                    Swal.fire({
                        icon:"success",
                        text:res.data.message
                    }).then(function(){ 
                        setLoading(false);        
                        resetData();
                        setErrorList([]); 
                        fetchEvents();
                    });
                    }).catch(function(error) {
                        setLoading(false);
                        setErrorList(error.response.data.validate_err);
                        Swal.fire({
                        text:error.response.data.message,
                        icon:"error"
                        })
                    })

            }


        const resetData = () => {
            setTitle('');
            setStartDatetime('');
            setEndDatetime('');
        };


        const bookGame = (e) => {
            e.preventDefault();
            setLoading(true);
        
        

            const formData = new FormData();
            // formData.append("customer_id", id);
            formData.append("game_title", game_title);
            formData.append("game_description", game_description);
            formData.append("game_location", game_location);
            formData.append("game_type", game_type);
            formData.append("booking_date", booking_date);
            formData.append("booking_time", JSON.stringify(booking_time)); 
            formData.append("kids_count", kids_count);
            formData.append("adults_count", adults_count);
            formData.append("total_price", timeSlotById.price);

        console.log(game_location);
        
        if(game_location === 'stouffville'){
                axios.post(`${API_URL}/save-booking`, formData)
                .then(() => {
                    showToast("Booked successfully!"); 
                    setLoading(false);
                    // setTemplate_details([]);
                    resetBooking();
                    fetchEvents();
                // getBookingbyId(id);
                    setBookingErrorList([]);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setBookingErrorList(error.response.data.validate_err);
            Swal.fire({
                        text:error.response.data.message,
                        icon:"error"
                    })
                });
            }
            else if(game_location === 'scarborough'){
                axios.post(`${API_URL}/save-booking-scarborough`, formData)
                .then(() => {
                    showToast("Booked successfully!"); 
                    setLoading(false);
                    // setTemplate_details([]);
                    resetBooking();
                    //fetchEvents();
                // getBookingbyId(id);
                    setBookingErrorList([]);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setBookingErrorList(error.response.data.validate_err);
            Swal.fire({
                        text:error.response.data.message,
                        icon:"error"
                    })
                });
            }
                
        };


        const resetBooking = () => {
            setBooking_date("");
            setGame_type("");
            setBooking_time([]);
            setKids_count(0);
            setAdults_count(0);
            setTimeSlotById((prev) => ({ ...prev, price: 0 }));
            };

          
    

            const handleDateClick = (arg) => {
           
                // Log the clicked date
                resetBooking();
                console.log('Clicked date:', arg.dateStr);
        
                // Prompt the user for an event title
                // const eventTitle = prompt('Please enter the title for the event:');
                
                // Custom logic: If eventTitle is null or an empty string, we "prevent" the default action
                // if (!eventTitle) {
                //     console.log('Event creation was cancelled or title was empty.');
                //     return; // Early exit to prevent adding an event
                // }
                setBooking_date(arg.dateStr);
                handleBookingDateChange(arg.dateStr);


            
            // If we have a valid title, proceed with adding the event
            // const newEvent = {
            //     title: eventTitle,
            //     start: arg.date,
            //     allDay: true
            // };
    
            // // Update events state with the new event
            // setEvents([...events, newEvent]);
    
    };

  
    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Calender</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Booking details</h2>
                        </div>
                       
<div class="content col-md-2">
                            <div class="form-group pt-3 pb-2">
                          
                            <select class="form-select" name="game_location" id="game_location" value={game_location} onChange={handleGameLocation}>
                                        <option value="">Select Location</option>
                                        <option value="stouffville">Stouffville</option>
                                        <option value="scarborough">Scarborough</option>
                                    </select>
                            </div>
                        </div>
<div className='row'>


    <div className='col'>
    <div className="content pt-3 pb-5 mb-5" id="calender"  style={{ boxShadow: "0px 0px 21px 2px rgba(0, 0, 0, 0.18)",backgroundColor:"white", marginTop:"20px", width:'100%'}}  >
                        

                            <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    events={events}
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                    }}
                                    themeSystem="bootstrap-premium"
                                    height="auto"
                                    contentHeight="auto"
                                    eventColor="#03a9f3" // Set event color
                                    eventTextColor="#ffffff" // Set event text color
                                    dateClick={handleDateClick} 
                                />

                            </div>
    </div>
    {booking_date && (
    <div className='col-md-4'>
    
    <div class="py-3 mt-4 border-top bg-light">
    <h3 class="vm_font_semi_bold border-bottom mb-3 px-3 pb-2">
                           Booking Form
                        </h3>
    <div className="content px-3">
    <div className="row">

    <div className="col-md-6">
                                  <div className="form-group ">
                                      <label htmlFor="date"> Date</label>
                                      <div class="d-flex input_wrapper">
                                          <div class="icon_wrapper">
                                              <i class="bi bi-calendar2-plus icon"></i>
                                          </div>
                                   
                        <input type="date" id="booking_date" value={booking_date} className="form-control" onChange={(e)=>handleBookingDateChange(e.target.value)} required />


                                      </div>
                                      <span class="text-danger">{bookingErrorList.booking_date}</span>
                                  </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                    <label for="game_type">Select Game Type</label>
                                    <select class="form-select" name="game_type" id="game_type" value={game_type} onChange={handleGameType}>
                                        <option value="">Select Game Type</option>
                                        <option value="Public">Public</option>
                                        <option value="Private">Private</option>
                                    </select>
                                    <span class="text-danger">{error_list.game_type}</span>
                                </div>
                            </div>

                              <div className="col-md-12">
                                  <div className="form-group">
                                          <label htmlFor="game_title">Game Title</label>
                                      <input type="text" className="form-control" id="game_title" value={game_title} onChange={(e) => setGame_title(e.target.value)} placeholder="Enter game title" />
                                      <span class="text-danger">{bookingErrorList.game_title}</span>
                                  </div>
                              </div>

                              <div className="col-md-12">
                                  <div className="form-group">
                                          <label htmlFor="game_description">Game Description</label>
                                      <input type="text" className="form-control" id="game_description" value={game_description} onChange={(e) => setGame_description(e.target.value)} placeholder="Enter game description" />
                                      <span class="text-danger">{bookingErrorList.game_description}</span>
                                  </div>
                              </div>

                              {/* <div class="col-md-6">
                                <div class="form-group">
                                    <label for="game_location">Select Location</label>
                                    <select class="form-select" name="game_location" id="game_location" value={game_location} onChange={handleGameLocation}>
                                        <option value="">Select Location</option>
                                        <option value="Stoufville">Stoufville</option>
                                        <option value="Scarborough">Scarborough</option>
                                    </select>
                                    <span class="text-danger">{error_list.game_location}</span>
                                </div>
                            </div> */}

                            

                              
                             

                            <div className="col-md-12">
    <div className="form-group">
    <label htmlFor="booking_time">Select Time:</label>
    <div id="booking_time" className="form-control py-3">
    {bookingAvailableTimeSlots.map((timeSlot, index) => (
                        <div className="form-group-checkbox d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                            <div key={index} > 
                                <input style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                                    type="checkbox"
                                    className="checkbox"
                                    id={`timeslot_${index}`}
                                    value={timeSlot}
                                    checked={booking_time.includes(timeSlot)}
                                    onChange={handleTimeSlotChange}
                                />
                                <label className={`timeslot_${index}`} style={{marginLeft:"5px"}}>
                                    {timeSlot}
                                </label>
                            </div>
                            </div>
                        </div>
                    ))}
    <span className="text-danger">{bookingErrorList.booking_time}</span>
</div>
    </div>
</div>
  
<div className="col-md-4">
<div className="form-group py-2">
          <label htmlFor="kids_count">Kids</label>
          <input
            type="number"
            id="kids_count"
            name="kids_count"
            value={kids_count}
            className="form-control"
            onChange={(e) => {setKids_count(e.target.value);}}
            min="0"
            required
          />
        </div>
</div>   <div className="col-md-4">
<div className="form-group py-2">
          <label htmlFor="kids_count">Adults</label>
          <input
            type="number"
            id="adults_count"
            name="adults_count"
            value={adults_count}
            className="form-control"
            onChange={(e) => {setAdults_count(e.target.value);}}
            required
               min="0"
          />
        </div>
</div>   


<div className="col-md-4">
                              <div className="form-group py-2">
                              <label htmlFor="price">Price</label>
                              <input type="number" class="form-control" name="price" id="price" value={timeSlotById.price} placeholder="Price" readOnly
                                    min="0"/>
                                            <span class="text-danger">{bookingErrorList.price}</span>

</div></div>
{/* {booking_time && (
<div className="col-md-10">
<div className="form-group py-2 pt-6">
<label htmlFor="available_space">Available Space : {available_space}</label>

</div>
</div> 
)} */}

<div class="d-flex justify-content-end ms-auto py-3">


        <button onClick={bookGame} class="btn btn-sm btn_secondary text-white "><i class="bi bi-send"></i>  {loading ? "Booking..." : "Book"} </button>
</div>    </div>
                          </div>

</div>
</div> )}

</div>


                       

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewCalender;
