import React,{ useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";


function EditSettingGameMainPackage(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const { id } = useParams();
    const navigate = useNavigate();
    const [game_main_package, setGame_main_package] = useState([]);
    const [game_main_package_by_id, setGame_main_package_by_id] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const scrollToRef = useRef(null);

    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
   

    const filteredResult = game_main_package.filter((item) => {
        return item.package_name.toLowerCase().includes(searchQuery.toLowerCase())
      });
      


   
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
    

   
      const handleInput = (e) =>{
          setGame_main_package_by_id({...game_main_package_by_id, [e.target.name] : e.target.value})
        }

    useEffect(() => {
        
       
        setIsLoading(true);
         axios.get(`${API_URL}/edit-game-main-package/`+id, ).then(res=>{
           
            if(res.data.status === 200){
                setGame_main_package_by_id(res.data.gameMainPackage);    
                setSelectedGame(res.data.gameMainPackage.game_id);
            }
            else if(res.data.status === 404){
                
             // console.log(res.data.error)
            }      
            getGameMainPackageList(); 
            fetchGames();
          
         });
         

        }, []);

        const fetchGames = async () => {
            try {
              const response = await axios.get(`${API_URL}/view-game`);
              setGames(response.data.game);
            } catch (error) {
              console.error("Error fetching games:", error);
            }
          };

        const handleGameChange = (e) => {
            const selectedGameId = e.target.value;
            setSelectedGame(selectedGameId);
          setGame_main_package_by_id({...game_main_package_by_id, game_id : selectedGameId})
            // You can also update other related state if needed
          };

          

        function getGameMainPackageList() {
            axios.get(`${API_URL}/view-game-main-package`).then(res => {
                if (res.data.status === 200) {
                    setGame_main_package(res.data.gameMainPackage);
                  
                } else if (res.data.status === 404) {
                    setGame_main_package([]);
                 
                }
                setIsLoading(false);
            }).catch(error => {
                console.error("Error fetching game main package", error);
                setIsLoading(false);
            });
        }

      
          async function update(event)
          {
           event.preventDefault(); 
           setLoading(true);

       
        const data = game_main_package_by_id;
     


     await axios.put(`${API_URL}/update-game-main-package/` +id ,data).then(res =>{  
    
        Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                    setErrorList([]);
                    navigate('/setting-game-main-package');
              });
              }).catch(function(error) {        
                 setErrorList(error.response.data.validate_err);
              
                  Swal.fire({
                 
                    text:error.response.data.message,
                    icon:"error"
                  })
              })
              setLoading(false);
           
              }

              const deleteGameMainPackage = (e,id) =>{
                e.preventDefault();
                var buttonClicked = window.confirm("Are you sure you want to delete Game Main Package record ?");
                if(buttonClicked == true){
                    axios.delete(`${API_URL}/delete-game-main-package/`+id).then(res => {
                     
                  Swal.fire({
                          icon:"success",
                          text:res.data.message
                        }).then(function(){
                      });
        
                      getGameMainPackageList();
                    })
                }
                else
                {
                    getGameMainPackageList();
                }
               
               }

     

     function editGameMainPackage(id){
        setIsLoading(true);
        setErrorList([]);
        axios.get(`${API_URL}/edit-game-main-package/`+id, ).then(res=>{
           
        if(res.data.status === 200){
            setGame_main_package_by_id(res.data.gameMainPackage);
                // scrollToRef.current.scrollIntoView({ behavior: "smooth" });
                
        }
        else if(res.data.status === 404){
       Swal("Error",res.data.error,"error");
          //console.log(res.data.error)
        }
   
      
     });
  
     getGameMainPackageList();
    
  
    }

  
    
    return (
        <div>
<SideNav/>
<TopBar/>
{isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

<div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

        <div class="main_title d-flex aling-items-center justify-content-start">
            <i class="bi bi-sticky "></i>
            <h1>Game Main Packages</h1>
        </div>

        <div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Edit Game Main Package</h2>
                </div>
                <div class="content pt-3">
                    <div class="row">


                    <div className="col-md-3">
                                <div class="form-group">
                            <label for="game">Select Game:</label>
        <select  class="form-select" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
          <option value="">Select a game</option>
          {games.map((game) => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
                                       <span class="text-danger">{error_list.game_id}</span>
                                </div>
                                    </div>
                                  


                                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="package_name">Package Name</label>
                        <input type="text" class="form-control" name="package_name" id="package_name" value={game_main_package_by_id.package_name} placeholder="Enter Package Name"
                        onChange={handleInput}/>
                            <span class="text-danger">{error_list.package_name}</span>
                    </div>
                                    </div>


                                    <div className="col-md-3">
                                    <div class="form-group">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" name="description" id="description" value={game_main_package_by_id.description} placeholder="Enter  description"
                        onChange={handleInput}/>
                            <span class="text-danger">{error_list.description}</span>
                    </div>
                                    </div>
                                    </div>
                   
                    <div class="d-md-flex align-items-center justify-content-between">
                      
                      
                        <div>

                            <button onClick={update} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Updating":"Update"}</button>
                        </div>                     
                    </div>
                
                                        
                </div>
            </div>

            <div class="vm_content_box_1 mt-4">
                <div class="title">
                    <h2>Manage Game Main Packages</h2>
                </div>

                <div class="content">
              
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search package name" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                            <th>Game</th>
                            <th>Package Name</th>
                            <th>Description</th>
                          
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -1000px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => 
                                           
                                           {
                                            const game = games.find(game => game.id === item.game_id) || {};
                                         
                                            return (      
                                           <React.Fragment key={index}>
                                        <tr >
                                           <td>
                                           <p>{game ? game.name : 'Unknown'}</p>
        </td>
        <td>
        <p>{item.package_name}</p>
  
        </td>
      
 <td>
            <p>{item.description}</p>
        </td>
        
      
      

                                       
        <td class="d-flex justify-content-end">

        <Link to={`/edit-game-main-package/${item.id}`} onClick={(e) => editGameMainPackage(item.id)} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> Edit </Link>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGameMainPackage(e, item.id)}><i class="bi bi-trash3"></i> Delete </button>
                                                </td>
   
            
        </tr>


</React.Fragment>
      );
    })}
                        </tbody>
                        <tfoot>
                            <tr>
                                  <th>Game</th>
                            <th>Package Name</th>
                            <th>Description</th>
                           
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                </>
                </div>


                <div className="content pt-3 pb-5 mb-5">


                </div>


            </div>

        </div>
        

</div>

        </div>
    )
}
export default EditSettingGameMainPackage;