import React, { useState, useEffect } from 'react';

function KidConsentForm({ formData, onChange, onSubmit, noOfKids,totalSubmittedKids }) {
  const [children, setChildren] = useState(formData.children || [{ name: '', dob: '' }]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (noOfKids !== children.length) {
  //     const updatedChildren = Array.from({ length: noOfKids }, (_, index) => ({
  //       name: children[index] ? children[index].name : '',
  //       dob: children[index] ? children[index].dob : ''
  //     }));
  //     setChildren(updatedChildren);
  //     onChange({ ...formData, children: updatedChildren });
  //   }
  // }, [noOfKids]);

   useEffect(() => {
    console.log(totalSubmittedKids);
    if (noOfKids !== children.length) {
      const updatedChildren = Array.from({ length: 1 }, (_, index) => ({
        name: children[index] ? children[index].name : '',
        dob: children[index] ? children[index].dob : ''
      }));
      setChildren(updatedChildren);
      onChange({ ...formData, children: updatedChildren });
    }
  }, [noOfKids]);


  const handleChildChange = (index, key, value) => {
    const updatedChildren = children.map((child, idx) =>
      idx === index ? { ...child, [key]: value } : child
    );
    setChildren(updatedChildren);
    onChange({ ...formData, children: updatedChildren });
  };

  // const addChild = () => {
  //   if (totalSubmittedKids <= noOfKids) {
  //     setChildren([...children, { name: '', dob: '' }]);
  //   } else {
  //     // Optionally, display a message or alert if the limit is reached
  //     alert(`You cannot add more children. The maximum number of kids allowed is ${noOfKids}.`);
  //   }
  // };

  const addChild = () => {
    // Calculate the current number of children rows
    const currentChildrenCount = children.length;
  
    // Check if the current number of children rows is less than the allowed count (noOfKids - totalSubmittedKids)
    if (currentChildrenCount < (noOfKids - totalSubmittedKids)) {
      // Add a new row only if the limit is not exceeded
      setChildren([...children, { name: '', dob: '' }]);
    } else {
      // Display a message or alert if the limit is reached
      alert(`You cannot add more children. The maximum number of kids allowed is ${noOfKids}.`);
    }
  };

  const removeChild = (index) => {
    if (children.length > 1) {
      const updatedChildren = children.filter((_, idx) => idx !== index);
      setChildren(updatedChildren);
      onChange({ ...formData, children: updatedChildren });
    }
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(); // Ensure onSubmit is an async function
  } catch (error) {
      console.error("Submission failed:", error);
      // Optionally handle the error here
  }
  setLoading(false);
  };

  return (

    <><div class="right_panel position contact_section vh-200" id="right_panel" style={{padding:'0px',overflow: 'hidden'}}>

    {/* <div class="main_title d-flex align-items-center">
        <h1><i class="bi bi-sticky"></i>Fill Consent Forms for Players</h1>
    </div> */}

<div class="content_wrapper " style={{padding:'10px 2px 10px 2px'}}>
        <div class="vm_content_box_1">
            <div class="title">
                <h2> Kids Consent Form</h2>
            </div>
            <div class="content ">

                {/* <div class="content pt-4 pb-4"> */}
                    <div class="row">
                        
    <form onSubmit={handleSubmit} className="form">
      <div className="row">
        <div className="col-md-4 form-group">
          <label htmlFor="guardian_name">Parent/Guardian Name</label>
          <input
            type="text"
            className="form-control"
            name="guardian_name"
            id="guardian_name"
            value={formData.guardian_name}
            placeholder="Parent/Guardian Name"
            onChange={(e) => onChange({ ...formData, guardian_name: e.target.value })}
            required
          />
        </div>
      </div>

      {children.map((child, index) => (
        <div key={index} className="row align-items-center">
          <div className="col-md-4 form-group">
            <label htmlFor={`child_name_${index}`}>Child Name</label>
            <input
              type="text"
              className="form-control"
              name={`child_name_${index}`}
              id={`child_name_${index}`}
              value={child.name}
              placeholder="Child Name"
              onChange={(e) => handleChildChange(index, 'name', e.target.value)}
              required
            />
          </div>
          <div className="col-md-4 form-group">
            <label htmlFor={`child_dob_${index}`}>Date of Birth</label>
            <input
              type="date"
              className="form-control"
              name={`child_dob_${index}`}
              id={`child_dob_${index}`}
              value={child.dob}
              placeholder="DOB"
              onChange={(e) => handleChildChange(index, 'dob', e.target.value)}
              required
            />
          </div>
          {index > 0 && (
            <div className="col-md-2 pt-3">
              <button type="button"  class="btn btn-sm btn-danger"  onClick={() => removeChild(index)}>
              Remove
              </button>
            </div>
          )}
        </div>
      ))}

      <button type="button" onClick={addChild} class="btn btn-sm btn_secondary text-white">
      
      Add Child
      </button>


      <div className="agreement-container">

      <ol className="agreement-list">
      <h3>Agreement, Release, and Discharge of Liability and Hold Harmless - Laser Combat</h3>
      <li>
        This Agreement is accepted, on behalf of myself and any child that accompanies me to a Laser Combat facility, in
        favor of Laser Combat, and any corporation or entity that operates any Laser Combat Games, and all their
        shareholders, affiliates, subsidiaries and affiliated businesses and locations, together with their administrators,
        successors, assigns, servants, agents, officers, directors, employees and insurers (hereinafter collectively referred
        to as "Laser Combat"), and I hereby agree as follows:
        </li>
        <li>
         1. I understand that the games at Laser Combat are physical, strenuous and vigorous and consequently involve
          potential risks of injury. I, therefore, understand and agree to accept the risks associated with the games and
          activities at Laser Combat including the possibility of physical and emotional injury, illness, paralysis, disability,
          death as well as property damage. More specifically, the games and activities at Laser Combat include the risk of
          cuts and bruises, muscle and joint sprains and strains, broken bones, concussions, and equipment failure. I
          understand this applies to all games, activities, and equipment within a Laser Combat facility. I understand such
          risks have not been eliminated since that would jeopardize the essential and enjoyable qualities of the games and
          activities.
        </li>
        <li>
          2. I understand various viruses, bacteria, and diseases such as the novel coronavirus, Covid-19, (“hereinafter
          “Diseases”) are extremely contagious and are believed to spread from person-to-person contact, touching surfaces,
          and airborne. As a result, federal, provincial, and local governments and health agencies recommend social
          distancing and have, in many instances, prohibited the congregation of groups of people. Laser Combat has put in
          place some preventative measures to reduce the spread of Diseases; however, Laser Combat cannot guarantee that
          I, or any child that accompanies me to a Laser Combat facility, will not become infected with a Disease. By signing
          this agreement, I acknowledge the contagious nature of Diseases and voluntarily assume the risk that I, and any
          child that accompanies me to any Laser Combat facility, may be exposed to or infected by Diseases by attending
          any Laser Combat facility and that such exposure or infection may result in physical or emotional injury, illness,
          paralysis, disability, death as well as property damage. I understand the risk of becoming exposed to or infected by
          Diseases at a Laser Combat facility may result from the actions, omissions, or negligence or gross negligence of
          Laser Combat, myself, or others.
        </li>
        <li>
          3. I understand a Laser Combat facility contains strobe lights, lasers, fog machines, darkness, and/or small spaces and,
          therefore, may not be suitable for people who are pregnant, suffer from heart conditions, hypertension,
          claustrophobia, epilepsy, or any other conditions. I agree with my decision to participate despite this
          recommendation is my sole responsibility.
        </li>
        <li>
          4. I agree all game and activity details of Laser Combat are protected by intellectual property laws. No use, copy,
          distribution, public display, or creation of any game or activity details, other information, or derivative work of Laser
          Combat, is permitted.
        </li>
        <li>
          5. I agree Laser Combat has the right to terminate my games and activities and remove a participant from any Laser
          Combat facility without a refund at any time if, at the sole discretion of Laser Combat, it is felt that a participant is
          being unsafe, are under the influence of alcohol or drugs, are causing damage to any Laser Combat facility or are
          not following Laser Combat rules.
        </li>
        <li>
         6. I agree lockers will be provided for personal belongings, but I understand Laser Combat is not responsible for the
          loss or damage of any personal belongings stemming from the use of these lockers or while personal belongings are
          in these lockers.
        </li>
        <li>
          7. I agree not to discriminate against, harass, bully, or show disrespect or violence towards anyone else in a Laser
          Combat facility including other participants, and that doing so will result in the termination of my game and activities
          and my removal from a Laser Combat facility without refund.
        </li>
        <li>
          8. I agree Laser Combat will record and store by video surveillance the games and activities of all persons at a Laser
          Combat facility to help ensure the safety and security of all persons.
        </li>
        <li>
          9. I voluntarily accept and assume, on behalf of myself and any child that accompanies me to any Laser Combat facility,
          all of the risks associated with the games, activities, and equipment at any Laser Combat facility and being in any
          Laser Combat facility and choose to participate in the games and activities and use the equipment despite these
          risks. I, on behalf of myself and any child that accompanies me to any Laser Combat facility, hereby release and
          forever discharge and hold harmless Laser Combat from any claims, demands, actions, or causes of action arising
          out of or in consequence of any loss, injury, damage or inconvenience to person or property incurred while or by
          attending any Laser Combat facility or participating in the Laser Combat games and activities, notwithstanding any
          such loss, injury, damage or inconvenience may have arisen because of the actions, omissions, or negligence or
          gross negligence of Laser Combat, myself or any others.
        </li>
        <li>
          10. I also agree this Agreement shall be governed by and construed per the laws of the Province of Ontario, without
          reference to its choice of law rules. Any proceeding against Laser Combat must be heard by the courts of the
          Province of Ontario or the federal courts of Canada, as appropriate and I, on behalf of myself and any child that
          accompanies me to a Laser Combat facility, each attorn to the jurisdiction of such court in all matters related to this
          Agreement and Laser Combat.
        </li>
        <li>
          11. I agree this Agreement is intended to be as broad and inclusive as permitted by the laws of the Province of Ontario. I
          agree that if any clause or provision of this Agreement shall be held to be invalid by any court of competent
          jurisdiction, the invalidity of such a clause or provision shall not otherwise affect the remaining provisions of this
          Agreement that shall continue to be enforceable.
        </li>
        <li>
          12. I confirm that I have read and have had sufficient opportunity to read this entire Agreement, have understood the
          terms of this Agreement, and AGREE TO BE BOUND by the terms of this Agreement.
        </li>
      </ol>
    </div>
    <div className="col-md-4 form-group">
        <div className="form-group-checkbox d-flex align-items-center justify-content-between">
            <div  className="d-flex align-items-center">
                <input
                    type="checkbox"
                   
                    name="consent"
                    id="consent"
                    checked={formData.consent}
                    onChange={(e) => onChange({ ...formData, consent: e.target.checked })}
                    style={{
                        height:'15px', width:'15px',
                        border:' solid' ,
                        boxShadow: "2px 2px #059618", transition: "all .5s ease"// Optional: Add cursor pointer for better UX
                    }}
                    required
                />
                <label  htmlFor="consent" style={{ marginTop: '10px' }}>
                I have read and agree to the terms and conditions.
                </label>
            </div>
         
            </div>
        </div>


      <button type="submit" class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i>
      {loading ? "Submitting..." : "Submit"}
      </button>
    </form>

    </div>
                      {/* </div> */}

                  </div>
              </div>

            
          </div>

      </div>
    </>
  );
}

export default KidConsentForm;
