import React,{ useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";


function EditSettingGametype(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const { id } = useParams();
    const navigate = useNavigate();
    const [game_type, setGame_type] = useState([]);
    const [updated_slot, setUpdated_slot] = useState([]);
    const [game_type_by_id, setGame_type_by_id] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const scrollToRef = useRef(null);

    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const [timeslots, setTimeSlots] = useState([]);
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedGameLocation, setSelectedGameLocation] = useState('');
    const [locations, setLocations] = useState([]);
    const [game_available_location, setGame_available_location] = useState([]);
    const [game_main_packages, setGame_main_packages] = useState([]);
   

    const filteredResult = game_type.filter((item) => {
        return item.game_type_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });
      

      const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setDate(selectedDate);
        getGameTypeList();
    };

    const getGameTypeLabel = (type) => {
        // Ensure type is converted to string for comparison
        switch (String(type)) {
          case '1':
            return 'Public';
          case '2':
            return 'Private';
          default:
            return 'Unknown';
        }
      };

    // useEffect(() => {
    //     getGameTypeList();
    // }, [date]); 
      
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
    

      const getDayName = (dateString) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(dateString);
        const dayName = days[date.getDay()];
        return dayName;
    };

      const handleInput = (e) =>{
          setGame_type_by_id({...game_type_by_id, [e.target.name] : e.target.value})
        }

    useEffect(() => {
        
       
        setIsLoading(true);
         axios.get(`${API_URL}/edit-game-type/`+id, ).then(res=>{
           
            if(res.data.status === 200){
                setGame_type_by_id(res.data.gameType);    
                setSelectedGame(res.data.gameType.game_id);
                fetchGameAvailableLocation(res.data.gameType.game_id);
                setSelectedGameLocation(res.data.gameType.location_id);
            }
            else if(res.data.status === 404){
                
             // console.log(res.data.error)
            }      
            getGameTypeList(); 
            fetchGames();
            fetchLocations();
         });
         

        }, []);

        const fetchGames = async () => {
            try {
              const response = await axios.get(`${API_URL}/view-game`);
              setGames(response.data.game);
            } catch (error) {
              console.error("Error fetching games:", error);
            }
          };


          const fetchLocations = async () => {
            try {
             
              const response = await axios.get(`${API_URL}/view-game-location`);
              setLocations(response.data.gameLocation);
            } catch (error) {
              console.error("Error fetching locations:", error);
            }
          };
      

          const fetchGameAvailableLocation = async (gameId) => {
            try {
        
            const res = await axios.get(`${API_URL}/view-available-game-location-by-gameId/`+gameId);
                if(res.data.locations){
                    setGame_available_location(res.data.locations);
                }
                else
                {
                    setGame_available_location([]);
                }
            } catch (error) {
            console.error("Error fetching games:", error);
            }
    };

        const handleGameChange = (e) => {
            const selectedGameId = e.target.value;
            setSelectedGame(selectedGameId);
            fetchGameAvailableLocation(selectedGameId);
          setGame_type_by_id({...game_type_by_id, game_id : selectedGameId})
            // You can also update other related state if needed
          };

          const handleGameAvailableLocationChange = (e) => {
            const selectedLocationId = e.target.value;
            setSelectedGameLocation(selectedLocationId);
            setGame_type_by_id({...game_type_by_id, location_id : selectedLocationId})
          };

        function getGameTypeList() {
            axios.get(`${API_URL}/view-game-type`).then(res => {
                if (res.data.status === 200) {
                    setGame_type(res.data.gameType);
                    // res.data.gameType.forEach(slot => {
                    //     const { start_time, end_time, time_interval, kids_price, adults_price, 
                    //         second_kids_price, second_adults_price, third_kids_price, third_adults_price, updated_slots } = slot;
                    //     const parsedInterval = parseInt(time_interval, 10); // Ensure correct parsing
                    //     const timeSlots = calculateTimeSlots(start_time, end_time, kids_price, adults_price,  second_kids_price, second_adults_price, third_kids_price, third_adults_price, parsedInterval,updated_slots);
                    //     setTimeSlots(timeSlots);
                    //     console.log(timeSlots);
                    // });
                } else if (res.data.status === 404) {
                    setGame_type([]);
                    // Handle case when no time slots are found
                }
                setIsLoading(false);
            }).catch(error => {
                console.error("Error fetching time slots:", error);
                setIsLoading(false);
            });
        }

      const calculateTimeSlots = (startTime, endTime, kidsPrice, adultsPrice, secondKidsPrice, secondAdultsPrice, thirdKidsPrice, thirdAdultsPrice, interval, updatedSlots) => {
        const timeSlots = [];
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);
    
        let current = new Date();
        current.setHours(startHour, startMinute, 0, 0);
    
        const end = new Date();
        end.setHours(endHour, endMinute, 0, 0);
    
        while (current.getTime() + interval * 60000 <= end.getTime()) {
            const formattedStartTime = formatTime(current.getHours(), current.getMinutes());
            current.setMinutes(current.getMinutes() + interval);
            const formattedEndTime = formatTime(current.getHours(), current.getMinutes());
    
            const formattedSlot = `${formattedStartTime} - ${formattedEndTime}`;
            
            const updatedSlot = JSON.parse(updatedSlots).find(upSlot => upSlot.date === date && upSlot.slot === formattedSlot);

            console.log(updatedSlot)
            if (updatedSlot) {
                // Use updated prices if found
                timeSlots.push({
                    slot: formattedSlot,
                    kids_price: updatedSlot.kids_price,
                    adults_price: updatedSlot.adults_price,
                    second_kids_price: updatedSlot.second_kids_price,
                    second_adults_price: updatedSlot.second_adults_price,
                    third_kids_price: updatedSlot.third_kids_price,
                    third_adults_price: updatedSlot.third_adults_price,
                });
            } else {
                // Use original prices if no updated prices found
                timeSlots.push({
                    slot: formattedSlot,
                    kids_price: kidsPrice,
                    adults_price: adultsPrice,
                    second_kids_price: secondKidsPrice,
                    second_adults_price: secondAdultsPrice,
                    third_kids_price: thirdKidsPrice,
                    third_adults_price: thirdAdultsPrice,
                });
            }
        }
           
         
       
          
        return timeSlots;
    };
    
    // Helper function to format time as HH:mm am/pm
    const formatTime = (hours, minutes) => {
        let formattedHours = hours % 12 || 12; // Adjust for 12-hour format
        const ampm = hours < 12 ? 'am' : 'pm';
        formattedHours = formattedHours < 10 ? '0' + formattedHours : formattedHours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };


          async function update(event)
          {
           event.preventDefault(); 
           setLoading(true);


        
          const formData = new FormData();

        //   formData.append("game_id", game_type_by_id.game_id);
        //   formData.append("game_type_name", game_type_by_id.game_type_name);

        //   formData.append("common_title", game_type_by_id.common_title);
        //   formData.append("common_description", game_type_by_id.common_description);
        //   formData.append("start_time", game_type_by_id.start_time);
        //   formData.append("end_time", game_type_by_id.end_time);
        //   formData.append("time_slot_interval", game_type_by_id.time_slot_interval);
        //   formData.append("min_players", game_type_by_id.min_players);
        //   formData.append("max_players", game_type_by_id.max_players);
        //   formData.append("max_players_in_a_slot", game_type_by_id.max_players_in_a_slot);
        //   formData.append("kids_first_price", game_type_by_id.kids_first_price);
        //   formData.append("adults_first_price", game_type_by_id.adults_first_price);
        //   formData.append("kids_second_price", game_type_by_id.kids_second_price);
        //   formData.append("adults_second_price", game_type_by_id.adults_second_price);
        //   formData.append("kids_third_price", game_type_by_id.kids_third_price);
        //   formData.append("adults_third_price", game_type_by_id.adults_third_price);

    
        const data = game_type_by_id;
       
        // const updatedSlots = timeslots.filter(slot => {
        //     return slot.kids_price !== time_slot_by_id.kids_price || slot.adults_price !== time_slot_by_id.adults_price;
        // }).map(slot => ({
        //     date: date,
        //     slot: slot.slot,
        //     kids_price: slot.kids_price,
        //     adults_price: slot.adults_price,
        //     second_kids_price: slot.second_kids_price,
        //     second_adults_price: slot.second_adults_price,
        //     third_kids_price: slot.third_kids_price,
        //     third_adults_price: slot.third_adults_price
        // }));


    
        // // Append modified slots data to formData if needed
        // updatedSlots.forEach((slot, index) => {
        //     formData.append(`slots[${index}][date]`, slot.date);
        //     formData.append(`slots[${index}][slot]`, slot.slot);
        //     formData.append(`slots[${index}][kids_price]`, slot.kids_price);
        //     formData.append(`slots[${index}][adults_price]`, slot.adults_price);
        //     formData.append(`slots[${index}][second_kids_price]`, slot.second_kids_price);
        //     formData.append(`slots[${index}][second_adults_price]`, slot.second_adults_price);
        //     formData.append(`slots[${index}][third_kids_price]`, slot.third_kids_price);
        //     formData.append(`slots[${index}][third_adults_price]`, slot.third_adults_price);
        // });
         
     await axios.put(`${API_URL}/update-game-type/` +id ,data).then(res =>{  
    
        Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                    setErrorList([]);
                    navigate('/setting-game-type');
              });
              }).catch(function(error) {        
                 setErrorList(error.response.data.validate_err);
              
                  Swal.fire({
                 
                    text:error.response.data.message,
                    icon:"error"
                  })
              })
              setLoading(false);
           
              }

              const deleteGameType = (e,id) =>{
                e.preventDefault();
                var buttonClicked = window.confirm("Are you sure you want to delete Game Type record ?");
                if(buttonClicked == true){
                    axios.delete(`${API_URL}/delete-game-type/`+id).then(res => {
                     
                  Swal.fire({
                          icon:"success",
                          text:res.data.message
                        }).then(function(){
                      });
        
                      getGameTypeList();
                    })
                }
                else
                {
                    getGameTypeList();
                }
               
               }

     

     function editGameType(id){
        setIsLoading(true);
        setErrorList([]);
        axios.get(`${API_URL}/edit-game-type/`+id, ).then(res=>{
           
        if(res.data.status === 200){
            setGame_type_by_id(res.data.gameType);
            setSelectedGame(res.data.gameType.game_id);
            fetchGameAvailableLocation(res.data.gameType.game_id);
            setSelectedGameLocation(res.data.gameType.location_id);
                // scrollToRef.current.scrollIntoView({ behavior: "smooth" });
                
        }
        else if(res.data.status === 404){
       Swal("Error",res.data.error,"error");
          //console.log(res.data.error)
        }
   
      
     });
  
     getGameTypeList();
    
  
    }

    const handlePrice = (e, index, type) => {
        const { value } = e.target;
        // Update timeslots state immutably
        setTimeSlots(prevSlots => {
            const updatedSlots = prevSlots.map((slot, idx) => {
                if (idx === index) {
                    return {
                        ...slot,
                        [type]: value
                    };
                }
                return slot;
            });
    
            console.log("Updated Slots:", updatedSlots); // Log updated slots for verification
            return updatedSlots;
        });
    };

    
    return (
        <div>
<SideNav/>
<TopBar/>
{isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

<div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

        <div class="main_title d-flex aling-items-center justify-content-start">
            <i class="bi bi-sticky "></i>
            <h1>Game Type</h1>
        </div>

        <div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Edit game type</h2>
                </div>
                <div class="content pt-3">
                    <div class="row">


                    <div className="col-md-3">
                                <div class="form-group">
                            <label for="game">Select Game:</label>
        <select  class="form-select" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
          <option value="">Select a game</option>
          {games.map((game) => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
                                       <span class="text-danger">{error_list.game_id}</span>
                                </div>
                                    </div>


                                    <div class=" col-md-3">
                            <div class="form-group">
                            <label for="game">Select Game Location:</label>
                            <select  class="form-select" id="game_available_location" name="game_available_location" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                            <option value="">Select game location</option>
                        {game_available_location.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.location_name}
                            </option>
                        ))}
        </select>
        <span class="text-danger">{error_list.location_id}</span>
                            </div>
                        </div>





                                    <div className="col-md-3">
                                    <div className="form-group">
                                            <label htmlFor="game_type_name">Game Type Name</label>
                                            
                        <input
                            className="form-control" name="game_type_name" placeholder="Enter game type name"
                            id="game_type_name"
                            value={game_type_by_id.game_type_name}
                            onChange={handleInput}
                       />
                            
                          

                    
                                            <span class="text-danger">{error_list.game_type_name}</span>
                                        </div>
                                        </div>



                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="common_title"> Title</label>
                        <input type="text" class="form-control" name="common_title" id="common_title" value={game_type_by_id.common_title} placeholder="Enter title"
                         onChange={handleInput }/>
                            <span class="text-danger">{error_list.common_title}</span>
                    </div>
                                    </div>

                                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="common_description"> Description</label>
                        <input type="text" class="form-control" name="common_description" id="common_description" value={game_type_by_id.common_description} placeholder="Enter description"
                          onChange={handleInput }/>
                            <span class="text-danger">{error_list.common_description}</span>
                    </div>
                                    </div>

                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="start_time">Start Time</label>
                                            <input type="time" className="form-control" id="start_time" name="start_time" value={game_type_by_id.start_time} onChange={handleInput} placeholder="select start time" />
                                            <span class="text-danger">{error_list.start_time}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="end_time">End Time</label>
                                            <input type="time" className="form-control" id="end_time" name="end_time" value={game_type_by_id.end_time} onChange={handleInput} placeholder="select end time" />
                                            <span class="text-danger">{error_list.end_time}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="time_slot_interval">Time Interval</label>
                                            
                        <select
                            className="form-control"
                            id="time_slot_interval" name="time_slot_interval"
                            value={game_type_by_id.time_slot_interval}
                            onChange={handleInput}
                        >
                            <option value="">Select Time Interval</option>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="60">1 hour</option>
                            <option value="120">2 Hours</option>
                            <option value="180">3 hours</option>
                            <option value="240">4 hours</option>
                            <option value="300">5 hours</option>

                        </select>
                                            <span class="text-danger">{error_list.time_slot_interval}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="min_players">Min players</label>
                                            <input type="number" class="form-control" name="min_players" id="min_players" value={game_type_by_id.min_players} placeholder="Enter min players"
                                      onChange={handleInput} min="0"/>
                                            <span class="text-danger">{error_list.min_players}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="max_players">Max players</label>
                                            <input type="number" class="form-control" name="max_players" id="max_players" value={game_type_by_id.max_players} placeholder="Enter max players"
                                      onChange={handleInput} min="0"/>
                                            <span class="text-danger">{error_list.max_players}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="max_players_in_a_slot">Max players in a slot</label>
                                            <input type="number" class="form-control" name="max_players_in_a_slot" id="max_players_in_a_slot" value={game_type_by_id.max_players_in_a_slot} placeholder="Enter max players in a slot"
                                      onChange={handleInput}  min="0"/>
                                            <span class="text-danger">{error_list.max_players_in_a_slot}</span>
                                        </div>
                                    </div>
                 
              

              


                                    

                                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                <div style={{ background: '#059618', color: '#fff',width:'125px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>First Booking Price</div>
                                <div className="form-group py-1">
                                <label htmlFor="kids_first_price">Kids Price</label>
                                            <input type="number" class="form-control" name="kids_first_price" id="kids_first_price" value={game_type_by_id.kids_first_price} placeholder="Kids price"
                                      onChange={handleInput} min="0"/>
                                            <span class="text-danger">{error_list.kids_first_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                    <label htmlFor="adults_first_price">Adults Price</label>
                                            <input type="number" class="form-control" name="adults_first_price" id="adults_first_price" value={game_type_by_id.adults_first_price} placeholder="Adults price"
                                      onChange={handleInput} min="0"/>
                                            <span class="text-danger">{error_list.adults_first_price}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                    <div style={{ background: '#059618', color: '#fff',width:'143px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>Second Booking Price</div>
                                    <div className="form-group py-1">
                                    <label htmlFor="kids_second_price">Kids Price</label>
                                            <input type="number" class="form-control" name="kids_second_price" id="kids_second_price" value={game_type_by_id.kids_second_price} placeholder="Kids price"
                                      onChange={handleInput} min="0"/>
                                            <span class="text-danger">{error_list.kids_second_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="adults_second_price">Adults price</label>
                                        <input type="number" className="form-control" name="adults_second_price" id="adults_second_price" value={game_type_by_id.adults_second_price} placeholder="adults price"
                                           onChange={handleInput} min="0" />
                                        <span className="text-danger">{error_list.adults_second_price}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                <div style={{ background: '#059618', color: '#fff', width:'130px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>Third Booking Price</div>
                                <div className="form-group py-1">
                                        <label htmlFor="kids_third_price">Kids price</label>
                                        <input type="number" className="form-control" name="kids_third_price" id="kids_third_price" value={game_type_by_id.kids_third_price} placeholder="kids price"
                                             onChange={handleInput}  min="0" />
                                        <span className="text-danger">{error_list.kids_third_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="adults_third_price">Adults price</label>
                                        <input type="number" className="form-control" name="adults_third_price" id="adults_third_price" value={game_type_by_id.adults_third_price} placeholder="adults price"
                                          onChange={handleInput}  min="0" />
                                        <span className="text-danger">{error_list.adults_third_price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="date">Select Date</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                         
          <input type="date" id="date" value={date} className="form-control" onChange={handleDateChange} required />
     

                                            </div>
                                          
                                        </div>
                                    </div> */}

{/* {date && (
 <div className="bg-light vm_content_box_1 py-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
 <div className="day-name bg-light py-2 px-3">
<h5 className="text-center mt-0">{getDayName(date)}</h5>
</div>




                                        {isLoading ? (
                                            <div className="loader-container">
                                               
                                            </div>
                                        ) : timeslots.length > 0 ? (
                                            timeslots.map((slot, index) => {
                                                const { slot: timeRange, kids_price, adults_price } = slot;
                                                const [startTime, endTime] = timeRange.split(' - ');

                                                return (
                                                    <div className="bg-light vm_content_box_1 py-2 px-3" key={index}>
                                                        <div className="row">
                                                            <p>Time Slot {index + 1}</p>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>From</label>
                                                                    <input type="text" className="form-control" value={startTime} readOnly />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>To</label>
                                                                    <input type="text" className="form-control" value={endTime} readOnly />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>First Kids Price</label>
                                                                    <input type="number" className="form-control" value={slot.kids_price}
                                                                        name={`kids_price_${index}`} placeholder="Kids price"
                                                                        onChange={(e) => handlePrice(e, index, 'kids_price')} min="0" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>First Adults Price</label>
                                                                    <input type="number" className="form-control" value={slot.adults_price}
                                                                        name={`adults_price_${index}`} placeholder="Adults price"
                                                                        onChange={(e) => handlePrice(e, index, 'adults_price')} min="0" />
                                                                </div>
                                                            </div>


                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Second Kids Price</label>
                                                                    <input type="number" className="form-control" value={slot.second_kids_price}
                                                                        name={`second_kids_price_${index}`} placeholder="Second Kids price"
                                                                        onChange={(e) => handlePrice(e, index, 'second_kids_price')} min="0" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Second Adults Price</label>
                                                                    <input type="number" className="form-control" value={slot.second_adults_price}
                                                                        name={`second_adults_price_${index}`} placeholder=" Second Adults price"
                                                                        onChange={(e) => handlePrice(e, index, 'second_adults_price')} min="0" />
                                                                </div>
                                                            </div>


                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Third Kids Price</label>
                                                                    <input type="number" className="form-control" value={slot.third_kids_price}
                                                                        name={`third_kids_price_${index}`} placeholder="Third Kids price"
                                                                        onChange={(e) => handlePrice(e, index, 'third_kids_price')} min="0" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Third Adults Price</label>
                                                                    <input type="number" className="form-control" value={slot.third_adults_price}
                                                                        name={`third_adults_price_${index}`} placeholder=" Third Adults price"
                                                                        onChange={(e) => handlePrice(e, index, 'third_adults_price')} min="0" />
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="no-time-slots">No time slots available for selected date.</div>
                                        )}
                                    </div>

                                )} */}





                                    </div>
                   
                    <div class="d-md-flex align-items-center justify-content-between">
                      
                      
                        <div>

                            <button onClick={update} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Updating":"Update"}</button>
                        </div>                     
                    </div>
                
                                        
                </div>
            </div>

            <div class="vm_content_box_1 mt-4">
                <div class="title">
                    <h2>Manage Game Type</h2>
                </div>

                <div class="content">
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Game type" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                            <th>Game</th>
                            <th>Location</th>
                            <th>Game Type</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Start time</th>
                                <th>End time</th>
                                <th>Time Interval</th>
                                <th>Min Players</th>
                                <th>Max Players</th>
                                <th>Max players in a slot</th>
                                <th>Kids Price1</th>
                                <th>Adults Price1</th>
                                <th>Kids Price2</th>
                                <th>Adults Price2</th>
                                <th>Kids Price3</th>
                                <th>Adults Price3</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -1000px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => 
                                           
                                           {
                                            const game = games.find(game => game.id === item.game_id) || {};
                                            const gameLocation = locations.find(location => location.id === item.location_id) || {};
                                            return (      
                                           <React.Fragment key={index}>
                                        <tr >
                                           <td>
                                           <p>{game ? game.name : 'Unknown'}</p>
        </td>
        <td>
        <p>{gameLocation ? gameLocation.location_name: 'Unknown'}</p>
  
        </td>
        <td>
        <p>{item.game_type_name}</p>
  
        </td>
        
        <td>
            <p>{item.common_title}</p>
        </td>
        <td>
            <p>{item.common_description}</p>
        </td>
<td>
            <p>{item.start_time}</p>
        </td>
        <td>
            <p>{item.end_time}</p>
        </td>
        <td>
            <p>{item.time_slot_interval}</p>
        </td>
        <td>
            <p>{item.min_players}</p>
        </td>
        <td>
            <p>{item.max_players}</p>
        </td>
        <td>
            <p>{item.max_players_in_a_slot}</p>
        </td>
        <td>
            <p>{item.kids_first_price}</p>
        </td>
        <td>
            <p>{item.adults_first_price}</p>
        </td>
        <td>
            <p>{item.kids_second_price}</p>
        </td>
        <td>
            <p>{item.adults_second_price}</p>
        </td>
        <td>
            <p>{item.kids_third_price}</p>
        </td>
        <td>
            <p>{item.adults_third_price}</p>
        </td>
      

                                       
        <td class="d-flex justify-content-end">

        <Link to={`/edit-game-type/${item.id}`} onClick={(e) => editGameType(item.id)} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> </Link>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGameType(e, item.id)}><i class="bi bi-trash3"></i> </button>
                                                </td>
   
            
        </tr>

{/* {item.updated_slots && item.updated_slots.length > 0 && (
    <tr>
        <td colSpan="13">
        <div class="title">
                    <h2>Updated Price History</h2>
                </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                    <th>S.no</th>
                        <th>Date</th>
                        <th>Slot</th>
                        <th>First Kids Price</th>
                        <th>First Adults Price</th>
                        <th>Second Kids Price</th>
                        <th>Second Adults Price</th>
                        <th>Third Kids Price</th>
                        <th>Third Adults Price</th>
                    </tr>
                </thead>
                <tbody>
                    {JSON.parse(item.updated_slots).map((slot, idx) => (
                        <tr key={idx}>
                                   <td>{idx +1 }</td>
                            <td>{slot.date}</td>
                            <td>{slot.slot}</td>
                            <td>{slot.kids_price}</td>
                            <td>{slot.adults_price}</td>
                            <td>{slot.second_kids_price}</td>
                            <td>{slot.second_adults_price}</td>
                            <td>{slot.third_kids_price}</td>
                            <td>{slot.third_adults_price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </td>
    </tr>
)} */}
</React.Fragment>
      );
    })}
                        </tbody>
                        <tfoot>
                            <tr>
                                  <th>Game</th>
                                  <th>Location</th>
                            <th>Game Type</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Start time</th>
                                <th>End time</th>
                                <th>Time Interval</th>
                                <th>Min Players</th>
                                <th>Max Players</th>
                                <th>Max players in a slot</th>
                                <th>Kids Price1</th>
                                <th>Adults Price1</th>
                                <th>Kids Price2</th>
                                <th>Adults Price2</th>
                                <th>Kids Price3</th>
                                <th>Adults Price3</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table>

                    <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                  
                </>
                </div>


                <div className="content pt-3 pb-5 mb-5">


                </div>


            </div>

        </div>
        

</div>

        </div>
    )
}
export default EditSettingGametype;