

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";
import UserContext from './UserContext';

import Swal from 'sweetalert2';

function SettingGameException() {

    const API_URL = process.env.REACT_APP_API_URL;
    const [events, setEvents] = useState([]);
    const [common_title, setCommon_title] = useState('');
    const [common_description, setCommon_description] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const userContext = useContext(UserContext);

    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [time_slot_interval, setTime_slot_interval] = useState('');
    const [kids_first_price, setKids_first_price] = useState('');
    const [adults_first_price, setAdults_first_price] = useState('');
    const [max_players_in_a_slot, setMax_players_in_a_slot] = useState('');
    // const scrollToRef = useRef(null);
    const [game_exception, setGame_exception] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [kids_second_price, setKids_second_price] = useState('');
    const [adults_second_price, setAdults_second_price] = useState('');
    const [kids_third_price, setKids_third_price] = useState('');
    const [adults_third_price, setAdults_third_price] = useState('');

    const [games, setGames] = useState([]);
    const [game_types, setGame_types] = useState([]);
    const [game_types_array, setGame_types_array] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedGameType, setSelectedGameType] = useState('');
    const [min_players, setMin_players] = useState('');
    const [max_players, setMax_players] = useState('');
    const [specific_date, setSpecific_date] = useState('');
    const [specific_from_time, setSpecific_from_time] = useState('');
    const [specific_to_time, setSpecific_to_time] = useState('');
    const [selectedGameLocation, setSelectedGameLocation] = useState('');
      const [game_available_location, setGame_available_location] = useState([]);
      const [locations, setLocations] = useState([]);

    const getGameTypeLabel = (type) => {
        // Ensure type is converted to string for comparison
        switch (String(type)) {
          case '1':
            return 'Public';
          case '2':
            return 'Private';
          default:
            return 'Unknown';
        }
      };


    const filteredResult = game_exception.filter((item) => {
    
            const gameType = game_types_array.find(gameType => 
                parseInt(gameType.id) === parseInt(item.game_type_id) && 
                parseInt(gameType.game_id) === parseInt(item.game_id)
            );
        
            // Check if gameType and gameType.game_type_name are defined before using toLowerCase()
            if (gameType && gameType.game_type_name) {
                return gameType.game_type_name.toLowerCase().includes(searchQuery.toLowerCase());
            }
        
            // If gameType or game_type_name is not defined, exclude the item from the filter result
            return false;
        });
      

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };



    useEffect(() => {
         setIsLoading(true);
         fetchGames();
         fetchLocations();
         getGameExceptionList();
         fetchGameTypesArray();
    }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

    function getGameExceptionList()
      {
        
        axios.get(`${API_URL}/view-game-exception`).then(res=>{
            if(res.data.status === 200){
                setGame_exception(res.data.gameException);    
                console.log(res.data.gameException)
                //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
                setGame_exception([]);  
                }
                setIsLoading(false);
         });  
       
      }
   
      const handleGameAvailableLocationChange = (e) => {
        const selectedGameTypeLocationId = e.target.value;
        setSelectedGameLocation(selectedGameTypeLocationId);
    
  
      };




      const fetchGames = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game`);
          setGames(response.data.game);
        } catch (error) {
          console.error("Error fetching games:", error);
        }
      };


      const fetchGameTypesArray = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game-type`);
    
            setGame_types_array(response.data.gameType);
   
          
         

        } catch (error) {
          console.error("Error fetching games:", error);
     
        }
      };
      
            
            const fetchGameTypes = async (gameId, locationId) => {
                 
                try {
                const response = await axios.get(`${API_URL}/view-game-type-by-gameId/${gameId}/${locationId}`);
                if(response.data.gameType){
                    setGame_types(response.data.gameType);

                    console.log(response.data.gameType);
                }
                else
                {
            setGame_types([]);
          }
          
         

        } catch (error) {
          console.error("Error fetching games:", error);
     
        }
      };
      



      useEffect(() => {
        if (selectedGame && selectedGameLocation) {
            fetchGameTypes(selectedGame, selectedGameLocation);
        }
    }, [selectedGame, selectedGameLocation]);
      const fetchLocations = async () => {
        try {
         
          const response = await axios.get(`${API_URL}/view-game-location`);
          setLocations(response.data.gameLocation);
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      };

      const fetchGameAvailableLocation = async (gameId) => {
        try {
    
        const res = await axios.get(`${API_URL}/view-available-game-location-by-gameId/`+gameId);
            if(res.data.locations){
                setGame_available_location(res.data.locations);
            }
            else
            {
                setGame_available_location([]);
            }
        } catch (error) {
        console.error("Error fetching games:", error);
        }
};

      const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
        setSelectedGameType(''); // Reset game type when game changes
        fetchGameAvailableLocation(selectedGameId);
      
      
      
        // You can also update other related state if needed
      };

      const handleGameTypeChange = (e) => {
        const selectedGameTypeId = e.target.value;
        setSelectedGameType(selectedGameTypeId);
      
        // You can also update other related state if needed
      };


      const handleSpecificDateChange = (e) => {
        setSpecific_date(e.target.value);
    };

  const generateTimeSlots = (bookedTimes) => {
    // Generate time slots from 9 AM to 4 PM
    const startTime = 9;
    const endTime = 16;
    const timeSlots = [];

    for (let hour = startTime; hour <= endTime; hour++) {
      const formattedHour = hour % 12 || 12;
      const ampm = hour < 12 ? 'AM' : 'PM';
      const timeSlot = `${formattedHour}:00 ${ampm}`;
      timeSlots.push(timeSlot);
    }


   // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

    const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
setAvailableTimeSlot(availableTimeSlots);
    return availableTimeSlots;
  };



  async function save(e){
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("game_id", selectedGame);
    formData.append("location_id", selectedGameLocation);
    formData.append("game_type_id", selectedGameType);
    formData.append("specific_date", specific_date);
    formData.append("specific_from_time", specific_from_time);
    formData.append("specific_to_time", specific_to_time);
    formData.append("common_title", common_title);
    formData.append("common_description", common_description);
    formData.append("start_time", start_time);
    formData.append("end_time", end_time);
    formData.append("time_slot_interval", time_slot_interval);
    formData.append("min_players", min_players); 
    formData.append("max_players", max_players);
    formData.append("max_players_in_a_slot", max_players_in_a_slot);
    formData.append("kids_first_price", kids_first_price);
    formData.append("adults_first_price", adults_first_price);
    formData.append("kids_second_price", kids_second_price);
    formData.append("adults_second_price", adults_second_price);
    formData.append("kids_third_price", kids_third_price);
    formData.append("adults_third_price", adults_third_price);
    formData.append('created_by',  userContext.userDetails.id);
   
        
      await axios.post(`${API_URL}/save-game-exception`, formData).then(res =>{
       
        Swal.fire({
            icon:"success",
            text:res.data.message
          }).then(function(){ 
            setLoading(false);        
            resetData();
            setErrorList([]);                           
            getGameExceptionList();
               
        });
        }).catch(function(error) {
            setLoading(false);
            setErrorList(error.response.data.validate_err);
            Swal.fire({
              text:error.response.data.message,
              icon:"error"
            })
        })
   }

   const resetData = () => {
    setSelectedGame("");
    setSelectedGameLocation("");
    setSelectedGameType("");
    setSpecific_date("");
    setSpecific_from_time("");
    setSpecific_to_time("");
    setCommon_title("");
    setCommon_description("");
    setStart_time("");
    setEnd_time("");
    setTime_slot_interval("");
    setMin_players("");
    setMax_players("");
    setMax_players_in_a_slot("");
    setKids_first_price("");
    setAdults_first_price("");
    setKids_second_price("");
    setAdults_second_price("");
    setKids_third_price("");
    setAdults_third_price("");
  

    };
   
    const deleteGameException = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Game exception record ?");
        if(buttonClicked == true){
            setIsLoading(true);
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-game-exception/`+id).then(res => {
                setIsLoading(false);
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getGameExceptionList();
            })
        }
        else
        {
            getGameExceptionList();
        }
       
       }

 

  
    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Game Exception</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Create Game Exception</h2>
                        </div>
                        <div className="content pt-3 ">
                          
                          
                                <div className="row">

                                <div className="col-md-3">
                                <div class="form-group">
                            <label for="game">Select Game:</label>
        <select  class="form-select" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
          <option value="">Select a game</option>
          {games.map((game) => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
                                       <span class="text-danger">{error_list.game_id}</span>
                                </div>
                                    </div>



                                    <div class=" col-md-3">
                            <div class="form-group">
                            <label for="game">Select Game Location:</label>
                            <select  class="form-select" id="game_available_location" name="game_available_location" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                            <option value="">Select game location</option>
                        {game_available_location.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.location_name}
                            </option>
                        ))}
        </select>
                            </div>
                        </div>



                                    <div className="col-md-3">
                                    <div className="form-group">
                                            <label htmlFor="game_type">Select Game Type</label>
                                            
                                            <select  class="form-select" id="game_type" name="game_type" value={selectedGameType} onChange={handleGameTypeChange}>
          <option value="">Select game type</option>
          {game_types.map((game_type) => (
            <option key={game_type.id} value={game_type.id}>
              {game_type.game_type_name}
            </option>
          ))}
        </select>


                                            <span class="text-danger">{error_list.game_type_id}</span>
                                        </div>
                                        </div>

                                <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="specific_date">Select Specific Date</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                         
                                    <input type="date" id="specific_date" value={specific_date} className="form-control" onChange={handleSpecificDateChange}  />
     

                                            </div>
                                          
                                        </div>
                                    </div>


                                        <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="specific_from_time">Specific From Time</label>
                                            <input type="time" className="form-control" id="specific_from_time" name="specific_from_time" value={specific_from_time} onChange={(e) => setSpecific_from_time(e.target.value)} placeholder="select start time" />
                                            <span class="text-danger">{error_list.specific_from_time}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="specific_to_time">Specific To Time</label>
                                            <input type="time" className="form-control" id="specific_to_time" name="specific_to_time" value={specific_to_time} onChange={(e) => setSpecific_to_time(e.target.value)} placeholder="select end time" />
                                            <span class="text-danger">{error_list.specific_to_time}</span>
                                        </div>
                                    </div>



                                <div className="col-md-3">
                                <div class="form-group">
                        <label for="common_title">Title</label>
                        <input type="text" class="form-control" name="common_title" id="common_title" value={common_title} placeholder="Enter title"
                        onChange={(event) =>{ setCommon_title(event.target.value); }}/>
                            <span class="text-danger">{error_list.commmon_title}</span>
                    </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="form-group">
                        <label for="common_description">Description</label>
                        <input type="text" class="form-control" name="common_description" id="common_description" value={common_description} placeholder="Enter  description"
                        onChange={(event) =>{ setCommon_description(event.target.value); }}/>
                            <span class="text-danger">{error_list.common_description}</span>
                    </div>
                                    </div>


                                <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="start_time">Start Time</label>
                                            <input type="time" className="form-control" id="start_time" name="start_time" value={start_time} onChange={(e) => setStart_time(e.target.value)} placeholder="select start time" />
                                            <span class="text-danger">{error_list.start_time}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="end_time">End Time</label>
                                            <input type="time" className="form-control" id="end_time" name="end_time" value={end_time} onChange={(e) => setEnd_time(e.target.value)} placeholder="select end time" />
                                            <span class="text-danger">{error_list.end_time}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="time_slot_interval">Time Slot Interval</label>
                                            
                        <select
                            className="form-control" name="time_slot_interval"
                            id="time_slot_interval"
                            value={time_slot_interval}
                            onChange={(e) => setTime_slot_interval(e.target.value)}
                        >
                            <option value="">Select Time Interval</option>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="60">1 hour</option>
                            <option value="120">2 Hours</option>
                            <option value="180">3 hours</option>
                            <option value="240">4 hours</option>
                            <option value="300">5 hours</option>

                        </select>
                                            <span class="text-danger">{error_list.time_slot_interval}</span>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="min_players">Min players</label>
                                            <input type="number" class="form-control" name="min_players" id="min_players" value={min_players} placeholder="Enter min players"
                                      onChange={(event) =>{ setMin_players(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.min_players}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="max_players">Max players</label>
                                            <input type="number" class="form-control" name="max_players" id="max_players" value={max_players} placeholder="Enter max players"
                                      onChange={(event) =>{ setMax_players(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.max_players}</span>
                                        </div>
                                    </div>




                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="max_players_in_a_slot">Max players in a slot</label>
                                            <input type="number" class="form-control" name="max_players_in_a_slot" id="max_players_in_a_slot" value={max_players_in_a_slot} placeholder="Enter max players in a slot"
                                      onChange={(event) =>{ setMax_players_in_a_slot(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.max_players_in_a_slot}</span>
                                        </div>
                                    </div>

                                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                <div style={{ background: '#059618', color: '#fff',width:'125px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>First Booking Price</div>
                                <div className="form-group py-1">
                                        <label htmlFor="kids_first_price">Kids price</label>
                                        <input type="number" className="form-control" name="kids_first_price" id="kids_first_price" value={kids_first_price} placeholder="kids price"
                                            onChange={(event) => setKids_first_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.kids_first_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="adults_first_price">Adults price</label>
                                        <input type="number" className="form-control" name="adults_first_price" id="adults_first_price" value={adults_first_price} placeholder="adults price"
                                            onChange={(event) => setAdults_first_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.adults_first_price}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                    <div style={{ background: '#059618', color: '#fff',width:'143px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>Second Booking Price</div>
                                    <div className="form-group py-1">
                                        <label htmlFor="kids_second_price">Kids price</label>
                                        <input type="number" className="form-control" name="kids_second_price" id="kids_second_price" value={kids_second_price} placeholder=" kids price"
                                            onChange={(event) => setKids_second_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.kids_second_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="adults_second_price">Adults price</label>
                                        <input type="number" className="form-control" name="adults_second_price" id="adults_second_price" value={adults_second_price} placeholder="adults price"
                                            onChange={(event) => setAdults_second_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.adults_second_price}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="highlightbox_1">
                                <div style={{ background: '#059618', color: '#fff', width:'130px', marginTop: '-29px', marginLeft: '-7px', fontSize: '13px', padding: '3px 8px' }}>Third Booking Price</div>
                                <div className="form-group py-1">
                                        <label htmlFor="kids_third_price">Kids price</label>
                                        <input type="number" className="form-control" name="kids_third_price" id="kids_third_price" value={kids_third_price} placeholder="kids price"
                                            onChange={(event) => setKids_third_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.kids_third_price}</span>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="adults_third_price">Adults price</label>
                                        <input type="number" className="form-control" name="adults_third_price" id="adults_third_price" value={adults_third_price} placeholder="adults price"
                                            onChange={(event) => setAdults_third_price(event.target.value)} min="0" />
                                        <span className="text-danger">{error_list.adults_third_price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                            
                                <div class="d-md-flex align-items-center justify-content-between">
                                    <div>
                                        <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i>{loading ? "Saving..." : "Save"} </button>
                                    </div>
                                </div>
                         
                        </div>
                        </div>

                        <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Game Exception</h2>
        </div>

        <div class="content">

        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Game type " type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>
<> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                            <th>Game</th>
                            <th>Location</th>
                            <th>Game Type</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Start time</th>
                                <th>End time</th>
                                <th>Time Interval</th>
                                <th>Min Players</th>
                                <th>Max Players</th>
                                <th>Max players in a slot</th>
                                <th>Kids Price1</th>
                                <th>Adults Price1</th>
                                <th>Kids Price2</th>
                                <th>Adults Price2</th>
                                <th>Kids Price3</th>
                                <th>Adults Price3</th>
                          
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -1000px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => 
                                    {
                                        const game = games.find(game => game.id === item.game_id) || {};
                                        const gameLocation = locations.find(location => location.id === item.location_id) || {};
                                        const gameType = game_types_array.find(gameType => 
                                            parseInt(gameType.id) === parseInt(item.game_type_id) && 
                                            parseInt(gameType.game_id) === parseInt(item.game_id)
                                          ) || {};
                                  
                                        return (      
                                         
                                         <React.Fragment key={index}>
                                           <tr >
                                           <td>
                                           <p>{game ? game.name : 'Unknown'}</p>
        </td>
        <td>
        <p>{gameLocation ? gameLocation.location_name: 'Unknown'}</p>
  
        </td>
        <td>
        <p>{gameType.game_type_name || 'Unknown'}</p>
  
        </td>
      
 <td>
            <p>{item.common_title}</p>
        </td>
        <td>
            <p>{item.common_description}</p>
        </td>
<td>
            <p>{item.start_time}</p>
        </td>
        <td>
            <p>{item.end_time}</p>
        </td>
        <td>
            <p>{item.time_slot_interval}</p>
        </td>
        <td>
            <p>{item.min_players}</p>
        </td>
        <td>
            <p>{item.max_players}</p>
        </td>
        <td>
            <p>{item.max_players_in_a_slot}</p>
        </td>
        <td>
            <p>{item.kids_first_price}</p>
        </td>
        <td>
            <p>{item.adults_first_price}</p>
        </td>
        <td>
            <p>{item.kids_second_price}</p>
        </td>
        <td>
            <p>{item.adults_second_price}</p>
        </td>
        <td>
            <p>{item.kids_third_price}</p>
        </td>
        <td>
            <p>{item.adults_third_price}</p>
        </td>
      


                                       
        <td class="d-flex justify-content-end">

        <Link to={`/edit-game-exception/${item.id}`}  class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i></Link>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGameException(e, item.id)}><i class="bi bi-trash3"></i></button>
                                                </td>
   
            
        </tr>

{/* {item.updated_slots && item.updated_slots.length > 0 && (
    <tr>
        <td colSpan="13">
        <div class="title">
                    <h2>Updated Price History</h2>
                </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                    <th>S.no</th>
                        <th>Date</th>
                        <th>Slot</th>
                        <th>First Kids Price</th>
                        <th>First Adults Price</th>
                        <th>Second Kids Price</th>
                        <th>Second Adults Price</th>
                        <th>Third Kids Price</th>
                        <th>Third Adults Price</th>
                    </tr>
                </thead>
                <tbody>
                    {JSON.parse(item.updated_slots).map((slot, idx) => (
                        <tr key={idx}>
                                   <td>{idx +1 }</td>
                            <td>{slot.date}</td>
                            <td>{slot.slot}</td>        
                            <td>{slot.kids_price}</td>
                            <td>{slot.adults_price}</td>
                            <td>{slot.second_kids_price}</td>
                            <td>{slot.second_adults_price}</td>
                            <td>{slot.third_kids_price}</td>
                            <td>{slot.third_adults_price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </td>
    </tr>
)} */}
</React.Fragment>
      );
    })}
                        </tbody>
                        <tfoot>
                            <tr>
                               <th>Game</th>
                               <th>Location</th>
                            <th>Game Type</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Start time</th>
                                <th>End time</th>
                                <th>Time Interval</th>
                                <th>Min Players</th>
                                <th>Max Players</th>
                                <th>Max players in a slot</th>
                                <th>Kids Price1</th>
                                <th>Adults Price1</th>
                                <th>Kids Price2</th>
                                <th>Adults Price2</th>
                                <th>Kids Price3</th>
                                <th>Adults Price3</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                </>
                </div>
    </div>





                   
                </div>
            </div>
        </div>
    );
}

export default SettingGameException;
