import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";

function SettingEmailTemplate(){


    const API_URL = process.env.REACT_APP_API_URL;
    const [template_name, setTemplate_name] = useState("");
    const [order_by, setOrder_by] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [images, setImages] = useState([]);
    // const [videos, setVideos] = useState([]);
    const [previews, setPreviews] = useState([]);
    // const [previewsVideo, setPreviewsVideo] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [email_template, setEmail_template] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const scrollToRef = useRef(null);

    const filteredResult = email_template.filter((item) => {
        return item.template_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

    const handleOderByChange  = (event) => {
        setOrder_by(event.target.value);
      };
  
      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      useEffect(() => {
        setIsLoading(true);
        getEmailTemplateList();
       
    }, []);

    function getEmailTemplateList()
      {
        
        axios.get(`${API_URL}/view-email-template`).then(res=>{
            if(res.data.status === 200){
                setEmail_template(res.data.emailTemplate);    
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
                setEmail_template([]);
              //  setMessage(res.data.message);     
                }
                setIsLoading(false);
         });  
       
      }

      const deleteEmailTemplate = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete email template ?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-email-template/`+id).then(res => {
             
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getEmailTemplateList();
            })
        }
        else
        {
            getEmailTemplateList();
        }
       
       }

    
      function onSelectImages(event){
        const selectedImages = Array.from(event.target.files);

        const validImages = selectedImages.filter(image => image.size <= 15 * 1024 * 1024); // 15MB 
        if (validImages.length !== selectedImages.length) {
          Swal.fire({
            text: "Image file size should be less than or equal to 15MB.",
            icon: "error"
          });
        }       
        setImages([...images, ...validImages]);         
         
       // const filePreviews = validImages.map((image) => URL.createObjectURL(image));

        const filePreviews = validImages.map(file => {
        if (file.type === 'application/pdf') {
            return { type: 'pdf', name: file.name , url: URL.createObjectURL(file)};
        } else if (file.type === 'video/mp4') {
          return { type: 'video', name: file.name, url: URL.createObjectURL(file) };
         } else {
            return { type: 'image', name: file.name ,url: URL.createObjectURL(file) };
        }
    });

        setPreviews([...previews, ...filePreviews]);
        
      }

      // function onSelectVideos(event){

      //   const selectedVideos = Array.from(event.target.files);

      //   // Check the file size for each selected video
      //   const validVideos = selectedVideos.filter(video => video.size <= 25 * 1024 * 1024); // 25MB
      
      //   if (validVideos.length !== selectedVideos.length) {
      //     // Show the validation error message
      //     Swal.fire({
      //       text: "Video file size should be less than or equal to 25MB.",
      //       icon: "error"
      //     });
      //   }
      
      //   setVideos(validVideos);
      
      //   const videoPreviews = validVideos.map(video => URL.createObjectURL(video));
      //   setPreviewsVideo(videoPreviews);
      // }

      
    
      function deleteImage(index){
        const updatedFiles = [...images];
      updatedFiles.splice(index, 1);
      setImages(updatedFiles);
      setPreviews(previews.filter((_, i) => i !== index));
     
     }

      
    //  function deleteVideo(index){
    //     const updatedFiles = [...videos];
    //   updatedFiles.splice(index, 1);
    //   setVideos(updatedFiles);
    //   setPreviewsVideo(previewsVideo.filter((_, i) => i !== index));
     
    //  }

     async function save(e){
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("template_name", template_name);
        // formData.append("order_by", order_by);
        formData.append("subject", subject);
        formData.append("message", message);
       
        if (images.length > 0) {
        images.forEach((image, index) => {
            formData.append(`images[${index}]`, image);
          });
        }
        
        // if (videos.length > 0) {
        //   videos.forEach((video, index) => {
        //     formData.append(`videos[${index}]`, video);
        //   });
        // }
    
          await axios.post(`${API_URL}/save-email-template`, formData).then(res =>{
           
            Swal.fire({
                icon:"success",
                text:res.data.message
              }).then(function(){ 
                setLoading(false);        
                resetData();
               // setErrorList([]);                           
                 getEmailTemplateList();
                   
            });
            }).catch(function(error) {
                setLoading(false);
                setErrorList(error.response.data.validate_err);
                Swal.fire({
                  text:error.response.data.message,
                  icon:"error"
                })
            })
       }

       const resetData = () => {
        setTemplate_name("");
        setOrder_by("");
        setSubject("");
        setMessage("");
        setImages([]);
        // setVideos([]);
        setPreviews([]);
       
        };
    
        const handleImageClick =  (index) => {
            Swal.fire({
              imageUrl: previews[index].url,
              imageAlt: `Preview ${index}`,
              showCloseButton: true,
              showConfirmButton: false,
              imageWidth: "100%",
              imageHeight: 'auto',

            });
          }

          const handlePdfClick = (index) => {
            const pdfUrl = previews[index].url;
            window.open(pdfUrl, '_blank');
        }

        const handleVideoClick = (index) => {
          const videoUrl = previews[index].url;
          
          Swal.fire({
            html: `<div style="overflow: hidden;">
                      <video width="100%" height="100%" controls style="margin-top: 15px;"> <!-- Adjust margin-right to accommodate scrollbar -->
                          <source src="${videoUrl}" type="video/mp4">
                          Your browser does not support the video tag.
                      </video>
                  </div>`,
            showCloseButton: true,
            showConfirmButton: false,
            scrollbarPadding: false 
          });
      };

        const showTooltip = (index) => {
    const tooltip = document.querySelector(`.file-name-tooltip-${index}`);
    if (tooltip) {
        tooltip.style.display = "block";
    }
};

const hideTooltip = (index) => {
    const tooltip = document.querySelector(`.file-name-tooltip-${index}`);
    if (tooltip) {
        tooltip.style.display = "none";
    }
};

    return(
<div>
    <SideNav/>
    <TopBar/>
    {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

 <div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

        <div class="main_title d-flex aling-items-center justify-content-start">
            <i class="bi bi-envelope"></i>
            <h1>Create New Email Template</h1>
        </div>

        <div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Create Template</h2>
                </div>
<div className="col-md-12">
<div className="row">
<div className="col-md-8">

<div class="content pt-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template_name">Template Name</label>
                                <input type="text" class="form-control" name="template_name"  id="template_name"  value={template_name} placeholder="Enter Template Name"
                                 onChange={(event) =>{ setTemplate_name(event.target.value); }}/>
                                      <span class="text-danger">{error_list.template_name}</span>
                            </div>
                        </div>
                        {/* <div class="col-md-4">
                            <div class="form-group">
                                <label for="order_by">Order By</label>
                                <select class="form-select" name="order_by" id="order_by" value={order_by} onChange={handleOderByChange} >
                                    <option value="">Select Order</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <span class="text-danger">{error_list.order_by}</span>
                            </div>
                        </div> */}
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="subject">Email Subject</label>
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Enter Email Subject" value={subject}
                            onChange={(event) =>{ setSubject(event.target.value); }}/>
                                 <span class="text-danger">{error_list.subject}</span>
                        </div>
                    </div>
                    {/* <div class="col-md-12">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="lotlocation">Generate AI</label>
                                <button class="btn_secondary text-white mb-1 py-0">Generate AI</button>
                            </div>
                            <textarea class="form-control h-100" name="generate_ai" id="generate_ai" cols="" rows="5" placeholder="Ex: Birthday Greeting..."></textarea>
                        </div>
                    </div> */}
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="lotlocation">Template Message</label>
                            <textarea class="form-control" name="message" id="message"  rows="8" value={message} placeholder="Template Message"
                            onChange={(event) =>{ setMessage(event.target.value); }} />
                                 <span class="text-danger">{error_list.message}</span>
                        </div>
                    </div>
                    <div class="d-md-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center pb-3 pb-md-0">
                            <div>
                                <label class="vm_cursor_pointer border border-2 d-flex align-items-center px-2" for="image" style={{width:"max-content"}}>
                                    <i class="bi bi-upload  h3 me-2"></i>
                                    Upload Attachments
                                </label>
                                <input class="d-none" type="file" name="image" id="image"  multiple onChange= {onSelectImages}/>
                            </div>


                            <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }}>
                                            {previews.map((preview, index, file) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                
                                                {preview.type === 'image' && (
                                                
            <img
                loading="lazy"
                onClick={() => handleImageClick(index)}
                // onMouseEnter={() => document.querySelector(`.file-name-tooltip-${index}`).style.display = "block"}
                // onMouseLeave={() => document.querySelector(`.file-name-tooltip-${index}`).style.display = "none"}
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                src={preview.url}
                alt={`Preview ${index}`}
            />
        
            
        )}
         {/* {preview.name && (
            <span className={`file-name-tooltip file-name-tooltip-${index}`}>
                {preview.name}
            </span>
        )} */}

{preview.type === 'pdf' && (
            
                <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                // onMouseEnter={() => document.querySelector(`.file-name-tooltip-${index}`).style.display = "block"}
                // onMouseLeave={() => document.querySelector(`.file-name-tooltip-${index}`).style.display = "none"}
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
            
         
        )}

{preview.type === 'video' && (
         
            <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
       
    )}
                                               
                                                </div>
                                            ))}
                                        </div>
                           

                            {/* <div class="ps-2">
                                <label class="vm_cursor_pointer border border-2 d-flex align-items-center px-2 border-start" for="video" style={{width:"max-content"}}>
                                    <i class="bi bi-film h5 me-2"></i>
                                    Upload Video
                                </label>
                                <input class="d-none" type="file" name="video" id="video"  multiple
                             onChange= {onSelectVideos}/>
                             <span>{error_list}</span>
                            </div>

                            <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex"}}>
                                            {previewsVideo.map((previewUrl, index, file) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteVideo(index)}>&times;</span>
                                                 <video loading="lazy"   style={{ width: "100%" , height: "100%", borderRadius: "5px" }}  src={previewUrl} alt={`Preview ${index}`} />  
                                              
                                                </div>
                                            ))}
                                        </div> */}

                        </div>
                      
                        <div>

                            <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                        </div>                     
                    </div>

                   

                                        
                </div>


</div>




<div className="col-md-4">

<div className="vm_content_box_1 mt-4 ">
      
      <div class="title">
          <h2>Email Placeholders</h2>
      </div>
<div className="content py-0 bg-white px-2 py-2  mb-3 w-100" >
        
        

<div className="row">
      <div className="col-md-12">
        <ul className="list-unstyled">
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Customer's full name</span>
            <span><strong>[customer_name]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Date of the event</span>
            <span><strong>[event_date]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Time of the event</span>
            <span><strong>[event_time]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Location of the event</span>
            <span><strong>[location]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Total number of players</span>
            <span><strong>[number_of_players]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Name of the game package</span>
            <span><strong>[package_name]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">Consent form Link</span>
            <span><strong>[consent_form_link]</strong></span>
          </li>
          <li className="d-flex justify-content-between mb-2 placeholder-row">
            <span className="placeholder-label">QR code image</span>
            <span><strong>[qr_code]</strong></span>
          </li>
        </ul>
      </div>



</div>

          
      </div>
  </div>



</div>
</div>
</div>
              



              
            </div>

            <div class="vm_content_box_1 mt-4">
                <div class="title">
                    <h2>Email Templates</h2>
                </div>

                <div class="content">
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Email Templates" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>




                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>Template Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -400px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

        <td>
            <p>{item.template_name}</p>
        </td>
        <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-email-template/${item.id}`} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteEmailTemplate(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
   
            
        </tr>
        ))}
                        </tbody>
                        <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                        {/* <tfoot>
                            <tr>
                                <th>Template Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot> */}
                    </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                    {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                </div><div class="col-md-9" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredResult.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div></>
                </div>
            </div>

        </div>
        

</div>
</div>
    );
}
export default SettingEmailTemplate;