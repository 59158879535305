import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserContext from './UserContext';

function SettingGame(){

      const API_URL = process.env.REACT_APP_API_URL;
      const [name, setName] = useState("");
      const [isLoading, setIsLoading] = useState(true);
      const [loading, setLoading] = useState(false);
      const userContext = useContext(UserContext);

      const [error_list, setErrorList] = useState([]);
      const [game, setGame] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
      const [currentPage, setCurrentPage] = useState(0);
      const [perPage, setPerPage] = useState(10);

      const filteredResult = game.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

   
      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      useEffect(() => {
        setIsLoading(true);
        getGameList();
       
    }, []);


       function getGameList()
      {
        
        axios.get(`${API_URL}/view-game`).then(res=>{
            if(res.data.status === 200){
                setGame(res.data.game); 
                
            }     
            else if(res.data.status === 404){
                setGame([]);
                }
                setIsLoading(false);
         });  
       
      }

      async function save(event)
      {
        
          event.preventDefault();
          setLoading(true);
          const formData = new FormData()
    
          formData.append('name', name);
          formData.append('created_by',  userContext.userDetails.id);
      
          await axios.post(`${API_URL}/save-game`, formData).then(res =>{
         
            Swal.fire({
              icon:"success",
              text:res.data.message
            }).then(function(){ 
                setLoading(false);        
                 resetData();
                 setErrorList([]);
                 getGameList();
                 
          }
        );
          }).catch(function(error) {
            setLoading(false);
            // if(error.response.status===422){         
            setErrorList(error.response.data.validate_err);
            // }
            // else{
            //   Swal.fire({
            //     text:error.response.data.message,
            //     icon:"error"
            //   })
            // }
          })
     }
    
    
     const resetData = () => {
        setName("");
        };


        const deleteGame= (e,id) =>{
            e.preventDefault();
            var buttonClicked = window.confirm("Are you sure you want to delete game?");
            if(buttonClicked == true){
               // const thisClicked = e.currentTarget;
               // thisClicked.innerText = "Deleting";
                axios.delete(`${API_URL}/delete-game/`+id).then(res => {
                 
              Swal.fire({
                      icon:"success",
                      text:res.data.message
                    }).then(function(){
                       // thisClicked.closest("tr").remove();  
                        
                  });
                   getGameList();
                })
            }
            else
            {
                getGameList();
            }
           
           }


 

    return(
        <div>
            <SideNav/>
            <TopBar/>

            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


   <div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Create Game</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Create Game</h2>
        </div>
        <div class="content pt-3">  
                    
                        <div class="content pt-4 pb-4">
                            <div class="row">
                            <div class="col-md-4 form-group">
                                    <input type="text" class="form-control" name="name" id="name" value={name} placeholder="Game Name"
                                     onChange={(event) =>{ setName(event.target.value); }}/>
                                       <span class="text-danger">{error_list.name}</span>
                                </div>
                                
                            </div>
                           
                           
                         
                    <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                            
                            </div>
            
        </div>
    </div>

    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Game</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Game name" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Game Name</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <p>{item.name}</p>
                                                </td>

                                               


                                                <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-game/${item.id}`} class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre">
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGame(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
                                            </tr>


                                        ))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                        <tr>
                                            <th>Game Name</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot> */}
                                </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                      

        </div>
    </div>
</div>

</div>



        </div>
    )
}

export default SettingGame;