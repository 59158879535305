import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import noImagePlaceholder from '../img/no_image_placeholder.png';
import placeholderImage from '../img/image_placeholder.png'

function ViewTeam(){
    const API_URL = process.env.REACT_APP_API_URL;
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [team, setTeam] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [locations, setLocations] = useState([]);

    const filteredResult = team.filter((item) => {
        const itemLocationIds = item.location_id.split(',').map(id => id.trim()); // Split and trim the location IDs
        const matchedLocations = locations.filter(location => itemLocationIds.includes(location.id.toString()));
        const locationNames = matchedLocations.map(location => location.location_name).join(', ');
        const lowerCaseLocationNames = locationNames ? locationNames.toLowerCase() : '';
        return ( 
        (item.user_role && item.user_role.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.first_name && item.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.last_name && item.last_name.toLowerCase().includes(searchQuery.toLowerCase()))||
        (item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase()) ) ||
        ( lowerCaseLocationNames.includes(searchQuery.toLowerCase()))
        );
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

    useEffect(() => { 
        setIsLoading(true);
          AOS.init({ duration: 1200 });
          fetchLocations();
          getTeamList();
}, []);


const fetchLocations = async () => {

    try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/view-game-location`);
        setLocations(response.data.gameLocation || []);
    } catch (error) {
        console.error("Error fetching locations:", error);
    }

};

const handleImageLoad = () => {
    setIsImageLoading(false);
};

  async function getTeamList(){
 
    axios.get(`${API_URL}/view-team-members`).then(res=>{
        if(res.data.status === 200){
          const team = res.data.team;
            setTeam(res.data.team);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }

    const deleteTeam = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Team Member record?");
        if(buttonClicked == true){
            setIsLoading(true);
           // const thisClicked = e.currentTarget;
          //  thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-team-member/`+id).then(res => {  
                setIsLoading(false);
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){  
                       
                         // thisClicked.closest("tr").remove();                           
              });
              getTeamList();
            })
        }
        else{
            getTeamList();
        }
 
       }

    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-people"></i> Team Members</h1>
           
                <button class="btn btn-sm btn_secondary">
                <Link style={{color:"white"}} to={'/create-team-member'}>Create Team Member </Link>
                  </button>
          
        </div>

        <div class="content_wrapper pt-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Team Member Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Location</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -700px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                            {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => {
                                           
                                           const itemLocationIds = item.location_id.split(',').map(id => id.trim()); // Split and trim the location IDs
                                          const matchedLocations = locations.filter(location => itemLocationIds.includes(location.id.toString()));
                                         const locationNames = matchedLocations.map(location => location.location_name).join(', ');
                                
                                           return (  
                                            
                               
                                   <tr>
                                  <td>
                                                   <div class="d-flex align-items-center"> {/* Change align-items-start to align-items-center */}


                                                       {(isImageLoading) ? <img className="circle_img bg-light" onLoad={handleImageLoad} src={placeholderImage} />
                                                           : <img className="circle_img bg-light" src={item.user_photo_path ? `${LARAVEL_API_URL}/${item.user_photo_path}` : noImagePlaceholder} />}

                                                       <div class="ps-md-2">
                                                           <p>{item.first_name} {item.last_name}</p>
                                                       </div>
                                                   </div>
                                               </td><td>
                                                       <div class="d-md-flex align-items-center" style={{ marginTop: "9px" }}>
                                                           <p>{item.email} </p>
                                                       </div>
                                                   </td><td>
                                                       <div class="d-md-flex align-items-center" style={{ marginTop: "9px" }}>
                                                           <p>{item.user_role} </p>
                                                       </div>
                                                   </td><td>
                                                       <div class="d-md-flex align-items-center" style={{ marginTop: "9px" }}>
                                                           <p>{locationNames} </p>
                                                       </div>
                                                   </td><td>
                                                       <div class="d-flex justify-content-md-end" style={{ margin: "7px 0px 0px 0px" }}>
                                                           <Link style={{ color: "white" }} to={`/edit-team-member/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createTeamMember"><i class="bi bi-pencil-square"></i></Link>
                                                           <button class="btn btn-sm btn_secondary" data-bs-target="#createTeamMember" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                                               onClick={(e) => deleteTeam(e, item.id)}><i class="bi bi-trash3"></i></button>
                                                       </div>
                                                   </td>
                                </tr>
                                
                                           );
                                        })}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                            {/* <tfoot>
                                <tr>
                                    <th>Team Member Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Location</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot> */}
                        </table>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   
    );
}
export default ViewTeam;
