

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";
import UserContext from './UserContext';

import Swal from 'sweetalert2';

function SettingGamePackage() {

    const API_URL = process.env.REACT_APP_API_URL;
  
    const [loading, setLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);

  
    // const scrollToRef = useRef(null);
    const [game_package, setGame_package] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);


    const [games, setGames] = useState([]);
    const [game_main_packages, setGame_main_packages] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedMainPackage, setSelectedMainPackage] = useState('');
    const [sub_package_name, setSub_package_name] = useState('');
    const [description, setDescription] = useState('');
    const [min_games, setMin_games] = useState('');
    const [basic_price, setBasic_price] = useState('');
    const [food_details, setFood_details] = useState('');
    const [drink_details, setDrink_details] = useState('');
    const [birthday_kid, setBirthday_kid] = useState('');
    const [price_per_game, setPrice_per_game] = useState('');
    const [deposit_required, setDeposit_required] = useState('');
    const [deposit_amount, setDeposit_amount] = useState('');
    const [game_main_package_by_id, setGame_main_package_by_id] = useState([]);


    const filteredResult = game_package.filter((item) => {
        return item.sub_package_name.toLowerCase().includes(searchQuery.toLowerCase())

      });
      

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };



    useEffect(() => {
         setIsLoading(true);
         fetchGames();
         fetchMainPackages();
         getGamePackageList();
    }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount


    const fetchGameAvailableMainPackage = async (gameId) => {
        try {
    
        const res = await axios.get(`${API_URL}/view-game-main-package-by-gameId/`+gameId);
            if(res.data.gameMainPackage){
                setGame_main_package_by_id(res.data.gameMainPackage);
            }
            else
            {
                setGame_main_package_by_id([]);
            }
        } catch (error) {
        console.error("Error fetching games:", error);
        }
};

    function getGamePackageList()
      {
        
        axios.get(`${API_URL}/view-game-package`).then(res=>{
            if(res.data.status === 200){
                setGame_package(res.data.gamePackages);    
                //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
                setGame_package([]);  
                }
                setIsLoading(false);
         });  
       
      }
   
    

      const fetchGames = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game`);
          setGames(response.data.game);
        } catch (error) {
          console.error("Error fetching games:", error);
        }
      };

      const fetchMainPackages = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game-main-package`);
          setGame_main_packages(response.data.gameMainPackage);
        } catch (error) {
          console.error("Error fetching games:", error);
        }
      };
      
      const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
        fetchGameAvailableMainPackage(selectedGameId);

      };
      const handleGameMainPackageChange = (e) => {
        const selectedMainPackageId = e.target.value;
        setSelectedMainPackage(selectedMainPackageId);
      };


  async function save(e){
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("game_id", selectedGame);
    formData.append("main_package_id", selectedMainPackage);
    formData.append("sub_package_name", sub_package_name);
    formData.append("description", description);
    formData.append("min_games", min_games);
    formData.append("basic_price", basic_price);
    formData.append("food_details", food_details);
    formData.append("drink_details", drink_details); 
    formData.append("birthday_kid", birthday_kid);
    formData.append("price_per_game", price_per_game);
    formData.append("deposit_required", deposit_required);
    formData.append("deposit_amount", deposit_amount);
    formData.append('created_by',  userContext.userDetails.id);
        
      await axios.post(`${API_URL}/save-game-package`, formData).then(res =>{
       
        Swal.fire({
            icon:"success",
            text:res.data.message
          }).then(function(){ 
            setLoading(false);        
            resetData();
            setErrorList([]);                           
             getGamePackageList();
               
        });
        }).catch(function(error) {
            setLoading(false);
            setErrorList(error.response.data.validate_err);
            Swal.fire({
              text:error.response.data.message,
              icon:"error"
            })
        })
   }

   const resetData = () => {
    setSelectedGame("");
    setSelectedMainPackage("");
    setSub_package_name("");
    setDescription("");
    setMin_games("");
    setBasic_price("");
    setFood_details("");
    setDrink_details("");
    setBirthday_kid("");
    setPrice_per_game("");
    setDeposit_required("");
    setDeposit_amount("");

    };
   
    const deleteGamePackage = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Game package record ?");
        if(buttonClicked == true){
            setIsLoading(true);
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-game-package/`+id).then(res => {
                setIsLoading(false);
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getGamePackageList();
            })
        }
        else
        {
            getGamePackageList();
        }
       
       }

 

  
    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Game Packages</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Create Game Package</h2>
                        </div>
                        <div className="content pt-4 pb-4 ">
                          
                          
                                <div className="row">

                                <div className="col-md-3">
                                <div class="form-group">
                            <label for="game">Select Game:</label>
        <select  class="form-select" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
          <option value="">Select a game</option>
          {games.map((game) => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
          <span class="text-danger">{error_list.game_id}</span>
                                </div>
                                    </div>
                                   



                                    <div class=" col-md-3">
                            <div class="form-group">
                            <label for="game_main_package_by_id">Select Main Package:</label>
                            <select  class="form-select" id="game_main_package_by_id" name="game_main_package_by_id" value={selectedMainPackage} onChange={handleGameMainPackageChange}>
                            <option value="">Select Main Package</option>
                        {game_main_package_by_id.map((mainPackage) => (
                            <option key={mainPackage.id} value={mainPackage.id}>
                                {mainPackage.package_name}
                            </option>
                        ))}
        </select>
                            </div>
                        </div>

                                <div className="col-md-3">
                                <div class="form-group">
                        <label for="sub_package_name">Sub Package Name</label>
                        <input type="text" class="form-control" name="sub_package_name" id="sub_package_name" value={sub_package_name} placeholder="Enter Sub Package Name"
                        onChange={(event) =>{ setSub_package_name(event.target.value); }}/>
                            <span class="text-danger">{error_list.sub_package_name}</span>
                    </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="form-group">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" name="description" id="description" value={description} placeholder="Enter  description"
                        onChange={(event) =>{ setDescription(event.target.value); }}/>
                            <span class="text-danger">{error_list.description}</span>
                    </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="min_games">Min Games</label>
                                            <input type="number" class="form-control" name="min_games" id="min_games" value={min_games} placeholder="Enter min games"
                                      onChange={(event) =>{ setMin_games(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.min_games}</span>
                                        </div>
                                    </div>

                           

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="basic_price">Basic Price</label>
                                            <input type="number" class="form-control" name="basic_price" id="basic_price" value={basic_price} placeholder="Enter basic price"
                                      onChange={(event) =>{ setBasic_price(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.basic_price}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="food_details">Food Details</label>
                        <input type="text" class="form-control" name="food_details" id="food_details" value={food_details} placeholder="Enter Food details"
                        onChange={(event) =>{ setFood_details(event.target.value); }}/>
                            <span class="text-danger">{error_list.food_details}</span>
                    </div>
                                    </div>

                                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="drink_details">Drink Details</label>
                        <input type="text" class="form-control" name="drink_details" id="drink_details" value={drink_details} placeholder="Enter Drink details"
                        onChange={(event) =>{ setDrink_details(event.target.value); }}/>
                            <span class="text-danger">{error_list.drink_details}</span>
                    </div>
                                    </div>

                                    <div className="col-md-3">
                                <div class="form-group">
                        <label for="birthday_kid">Birthday Kid</label>
                        <input type="text" class="form-control" name="birthday_kid" id="birthday_kid" value={birthday_kid} placeholder="Enter Birthday kid"
                        onChange={(event) =>{ setBirthday_kid(event.target.value); }}/>
                            <span class="text-danger">{error_list.birthday_kid}</span>
                    </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="price_per_game">Price per game</label>
                                            <input type="number" class="form-control" name="price_per_game" id="price_per_game" value={price_per_game} placeholder="Enter Price per game"
                                      onChange={(event) =>{ setPrice_per_game(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.price_per_game}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                <div class="form-group">
                            <label for="deposit_required">Deposit Required:</label>
        <select  class="form-select" id="deposit_required" name="deposit_required" value={deposit_required}  onChange={(event) =>{ setDeposit_required(event.target.value);} }>
          <option value="">Select deposit required</option>
        
            <option value={1}>Yes</option>
            <option value={0}>No</option>
         
        </select>
          <span class="text-danger">{error_list.deposit_required}</span>
                                </div>
                                    </div>


                             
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="deposit_amount">Deposit Amount</label>
                                            <input type="number" class="form-control" name="deposit_amount" id="deposit_amount" value={deposit_amount} placeholder="Enter deposit amount"
                                      onChange={(event) =>{ setDeposit_amount(event.target.value);} } min="0"/>
                                            <span class="text-danger">{error_list.deposit_amount}</span>
                                        </div>
                                    </div>       

                </div>
                        
                            
                                <div class="d-md-flex align-items-center mt-3 justify-content-between">
                                    <div>
                                        <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i>{loading ? "Saving..." : "Save"} </button>
                                    </div>
                                </div>
                         
                        </div>
                        </div>

                        <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Game Package</h2>
        </div>

        <div class="content">

        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search sub package" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>



            
<> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                            <th>Game</th>
                            <th>Package Name</th>
                            <th>Sub Package Name</th>
                            <th>Description</th>
                            <th>Min Games</th>
                                <th>Basic Price</th>
                                <th>Food details</th>
                                <th>Drink details</th>
                                <th>Birthday Kid</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                              
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -1000px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => 
                                    {
                                        const game = games.find(game => game.id === item.game_id) || {};
                                        const main_package = game_main_packages.find(mainPackage => mainPackage.id === item.main_package_id) || {};
                                        return (      
                                         
                                         <React.Fragment key={index}>
                                           <tr >
                                           <td>
                                           <p>{game ? game.name : 'Unknown'}</p>
        </td>

        <td>
        <p>{main_package.package_name}</p>
  
        </td>
        <td>
        <p>{item.sub_package_name}</p>
        </td>
        <td>
            <p>{item.description}</p>
        </td>
        <td>
            <p>{item.min_games}</p>
        </td>
<td>
            <p>{item.basic_price}</p>
        </td>
        <td>
            <p>{item.food_details}</p>
        </td>
        <td>
            <p>{item.drink_details}</p>
        </td>
        <td>
            <p>{item.birthday_kid}</p>
        </td>
        <td>
            <p>{item.price_per_game}</p>
        </td>
        <td>
       <p> {item.deposit_required === 1 ? 'Yes' : item.deposit_required === 0 ? 'No' : ''}</p>
  
        </td>
        <td>
            <p>{item.deposit_amount}</p>
        </td>
      
        
                                       
        <td class="d-flex justify-content-end">

        <Link to={`/edit-game-package/${item.id}`}  class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i></Link>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGamePackage(e, item.id)}><i class="bi bi-trash3"></i></button>
                                                </td>
   
            
        </tr>


</React.Fragment>
      );
    })}
                        </tbody>
                        <tfoot>
                            <tr>
                                 <th>Game</th>
                                 <th>Package Name</th>
                                 <th>Sub Package Name</th>
                            <th>Description</th>
                            <th>Min Games</th>
                                <th>Basic Price</th>
                                <th>Food details</th>
                                <th>Drink details</th>
                                <th>Birthday Kid</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table>


                    <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    
                </>
                </div>
    </div>





                   
                </div>
            </div>
        </div>
    );
}

export default SettingGamePackage;
