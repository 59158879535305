import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef, useContext } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import UserContext from './UserContext';
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';

function ViewCustomers(){
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [bookings, setBookings] = useState([]);
    const userContext = useContext(UserContext);

    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [error_list, setErrorList] = useState([]);


    const filteredResult = customer.filter((item) => {
      return `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
             item.booking_ref_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
             item.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
             item.email.toLowerCase().includes(searchQuery.toLowerCase()) || 
             item.location_name.toLowerCase().includes(searchQuery.toLowerCase());
    });

   

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

//     useEffect(() => { 
//         setIsLoading(true);
//           AOS.init({ duration: 1200 });
//           getCustomerList();
// }, []);



useEffect(() => {
  if (userContext.username) {
    setIsLoading(true);
    AOS.init({ duration: 1200 });
      getLocationByUser();
     // console.log(userContext.username)
  }
}, [userContext.username]); 

async function getLocationByUser() {

       axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.customers;

         //   setCustomer(res.data.customers);
          const bookings = res.data.bookings;


          const enrichedCustomers = customer.map((customer) => {
            // Find the matching booking for the customer
            const matchingBooking = bookings.find(
              (booking) => booking.id === customer.booking_id
            );
    
            // If a matching booking is found, return enriched customer data with booking_completed
            return {
              ...customer,
              booking_completed: matchingBooking
                ? matchingBooking.booking_completed
                : null, 
                status: matchingBooking
                ? matchingBooking.status
                : null,
            };
          });
    
          // Set the enriched customer data
          setCustomer(enrichedCustomers);
             // console.log(enrichedCustomers);

        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
   
}


  async function getCustomerList(){
 
    axios.get(`${API_URL}/view-customer`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.customers;

            setCustomer(res.data.customers);
            //  console.log(res.data.customers);

        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }


    
    const handleViewBookings = (bookingId) => {

      navigate('/booking-details/'+bookingId);      
    };

    const deleteCustomer = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Customer record?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
          //  thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-customer/`+id).then(res => {  
               
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){  
                  
                       
               // thisClicked.closest("tr").remove();                           
              });
            //  getCustomerList();
            })
        }
        else{
           // getCustomerList();
        }
 
       }

       const getStatusStyle = (status) => {
        switch (status) {
            case 'Completed':
                return { color: 'green', fontWeight: 'bold' };
            case 'Incomplete':
                return { color: 'red', fontWeight: 'bold' };
            case 'Cancelled':
                return { color: 'blue', fontWeight: 'bold' };
            default:
                return { color: 'black' }; // Default color for unknown statuses
        }
    };




    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-people"></i> Customers</h1>
           
                {/* <button class="btn btn-sm btn_secondary">
                <Link style={{color:"white"}} to={'/create-customer'}>Create New Customer </Link>
                  </button> */}
          
        </div>

        <div class="content_wrapper pt-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>phone</th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th>Booked On</th>
                                    <th>Status</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                          {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item) => (
                                 <tr key={item.id}>
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.phone}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.email}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td>
                                    <td style={{ verticalAlign: 'middle', ...getStatusStyle(item.status) }}>{item.status}</td>
                                    {/* <td style={{ 
      verticalAlign: 'middle',
      color: item.booking_completed === 1 ? 'green' : 'red'
    }}>
  {item.booking_completed === 1 ? 'Completed' : 'Incomplete'}</td> */}
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.booking_id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                        <Link style={{color:"white"}} to={`/edit-customer/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createNewCustomre"><i class="bi bi-pencil-square"></i></Link>
                                          
                                           {/*  <button class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                     onClick={(e) => deleteCustomer(e, item.id)}><i class="bi bi-trash3"></i></button> */}
                               </div>
                                    </td>
                                </tr>
))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                            {/* <tfoot>
                                <tr>
                                <th>Booking Id</th>
                                <th>Customer Name</th>
                                
                                    <th>Location</th>
                                    <th>Game</th>
                                    <th>Booked By</th>
                                    <th>Booked On</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot> */}
                        </table>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   
    );
}
export default ViewCustomers;
