import React, { useState, useEffect } from 'react';
import ConsentForm from './ConsentForm';
import KidConsentForm from './KidConsentForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function MultipleConsentForms({ bookingId, participantCount, noOfKids, onAllFormsSubmitted }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [formsData, setFormsData] = useState(
    Array(participantCount).fill().map(() => ({ name: '', dob: '', consent: false }))
  );
  const [kidFormData, setKidFormData] = useState({ guardian_name: '', children: [] });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isAdultForm, setIsAdultForm] = useState(true);
  const [showAdultForm, setShowAdultForm] = useState(false);
  const [showKidForm, setShowKidForm] = useState(false);
  const navigate = useNavigate();
  const [submittedAdultForms, setSubmittedAdultForms] = useState(0);
  const [submittedKidForms, setSubmittedKidForms] = useState(0);
  const [allFormsCompleted, setAllFormsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);





  const handleFormChange = (index, formData) => {
    const updatedForms = formsData.map((form, idx) => 
      idx === index ? formData : form
    );
    setFormsData(updatedForms);
  };

  useEffect(() => {
    const fetchSubmittedForms = () => {
      setIsLoading(true);
      axios.get(`${API_URL}/consent-forms?bookingId=${bookingId}`)
        .then(response => {
          const parsedConsentForms = JSON.parse(response.data.consent_form_details) || [];
          const parsedKidForms = JSON.parse(response.data.kids_form_details) || [];
  
          const filteredConsentForms = parsedConsentForms.filter(form => Number(form.is_deleted) !== 1);
          const filteredKidForms = parsedKidForms.filter(form => Number(form.is_deleted) !== 1);
  
          setSubmittedAdultForms(filteredConsentForms.length);
          const totalSubmittedKids = filteredKidForms.reduce((count, form) => count + form.children.length, 0);
          setSubmittedKidForms(totalSubmittedKids);
  
          const initialFormsData = Array(participantCount).fill({});
          filteredConsentForms.forEach((form, index) => {
            initialFormsData[index] = form;
          });
          setFormsData(initialFormsData);
          setCurrentFormIndex(filteredConsentForms.length);
  
          // Check if all forms are completed
          const formsCompleted = filteredConsentForms.length === participantCount &&
            totalSubmittedKids >= noOfKids;
  
          setAllFormsCompleted(formsCompleted);
     // Update the state
  
          if (formsCompleted) {
           
            //   navigate(`/thank-you-completed/${bookingId}`, {
            //   state: { allFormsCompleted: true },
            // });
            onAllFormsSubmitted(); // Trigger the callback directly
          }
        })
        .catch(error => {
          //setIsLoading(false);
          console.error('Error fetching consent forms:', error);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1500); 
        
        });
    };
  
    fetchSubmittedForms();
  }, [bookingId, participantCount, noOfKids]);

  const handleFormSubmit = async () => {
    setSubmitting(true);
    try {
      const formData = isAdultForm ? formsData[currentFormIndex] : kidFormData;

      await axios.post(`${API_URL}/save-consent-form`, {
        booking_ref_id: bookingId,
        form_data: formData,
      });

      // console.log(formData);

      if (isAdultForm) {
        setSubmittedAdultForms(prev => {
          const updatedAdultCount = prev + 1;

          // Navigate to thank you page after each adult form submission
          navigate(`/thank-you/${bookingId}`);

          // Check if all adult forms are submitted
          const allAdultsSubmitted = updatedAdultCount === participantCount;

          if (allAdultsSubmitted) {
            // If all adult forms are submitted, check if all kids' forms are completed
            if (submittedKidForms >= noOfKids) {
              setAllFormsCompleted(true);
              navigate(`/thank-you-completed/${bookingId}`);
              onAllFormsSubmitted();
            }
          }

          return updatedAdultCount;
        });

        // Move to the next form if there are more forms
        if (currentFormIndex < participantCount - 1) {
          setCurrentFormIndex(currentFormIndex + 1);
        }
      } else {
        const numberOfKidsInForm = formData.children.length;
        setSubmittedKidForms(prev => {
          const updatedKidCount = prev + numberOfKidsInForm;

          // Check if all kids' forms are completed
          const allKidsSubmitted = updatedKidCount >= noOfKids;
          const allAdultsSubmitted = submittedAdultForms === participantCount;

          if (allAdultsSubmitted && allKidsSubmitted) {
            setAllFormsCompleted(true);
            navigate(`/thank-you-completed/${bookingId}`);
            onAllFormsSubmitted();
          } else {
            navigate(`/thank-you/${bookingId}`);
          }

          return updatedKidCount;
        });
      }
    } catch (error) {
      console.error("Error saving consent form:", error);
    }
  
  };

  return (
    <div>


       {isLoading ?
                <div className="loader-container" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "9999" }}>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div> : null}


{/* {allFormsCompleted ? (
                <div className="alert alert-success" style={{ fontSize: '18px', textAlign: 'center', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
                  All forms have been submitted! Thank you.
                </div>
              ) : ( */}


      <div className="d-flex justify-content-left">
      {!showAdultForm && submittedAdultForms < participantCount && (
      <button
          className="btn btn-sm btn_secondary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            setIsAdultForm(true);
            setShowAdultForm(true);
            setShowKidForm(false);
          }}
        >
          Adults Consent Form
        </button>
        )}
          {!showKidForm && submittedKidForms < noOfKids && (
        <button
          className="btn btn-sm btn_secondary"
          onClick={() => {
            setIsAdultForm(false);
            setShowAdultForm(false);
            setShowKidForm(true);
          }}
        >
          Kids Consent Form
        </button>
)}
      </div>

{/* )} */}

      {showAdultForm && isAdultForm && (
        <ConsentForm
          formData={formsData[currentFormIndex]}
          onChange={(formData) => handleFormChange(currentFormIndex, formData)}
          onSubmit={handleFormSubmit}
        />
      )}

      {showKidForm && !isAdultForm && (
        <KidConsentForm
          formData={kidFormData}
          onChange={setKidFormData}
          onSubmit={handleFormSubmit}
          noOfKids = {noOfKids}
          totalSubmittedKids = {submittedKidForms}
        />
      )}
    </div>

    
  );
}

export default MultipleConsentForms;
