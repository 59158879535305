import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserContext from './UserContext';

function EditSettingGameAvailableLocation(){

    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const navigate = useNavigate();
    const [game_available_location, setGame_available_location] = useState([]);
    const [game_available_location_by_id, setGame_available_location_by_id] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userContext = useContext(UserContext);

   
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);

    const filteredResult = (game_available_location || []).filter((item) => {
        const game = games?.find(game => game.id === item.game_id) || {};
        // Find the locations based on comma-separated IDs
        const locationIds = item.location_id.split(',').map(id => parseInt(id.trim(), 10)); // Split string and convert to array of integers
        const foundLocations = locations.filter(location => locationIds.includes(location.id));
        
        // Extract location names and join them with a comma
        const locationNames = foundLocations.map(location => location.location_name).join(', ');
      
        // Extract game name and location names
        const gameName = game.name || '';
        const locationName = locationNames || '';
      
        // Check if either game name or location names include the search query
        return (
          gameName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          locationName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });


    const handlePageChange = (selectedPage) => {
      setCurrentPage(selectedPage.selected);
    };
  
    
      const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
      
        // You can also update other related state if needed
      };
      
      const handleLocationChange = (e) => {
        const value = parseInt(e.target.value, 10);
        let updatedLocations = selectedLocations; // Ensure it's an array
      
        if (e.target.checked) {
          updatedLocations = [...updatedLocations, value];
        } else {
          updatedLocations = updatedLocations.filter((id) => id !== value);
        }
      
        setSelectedLocations(updatedLocations); // Convert back to a comma-separated string
      };

     




    const fetchGames = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game`);
          setGames(response.data.game);
        } catch (error) {
          console.error("Error fetching games:", error);
        }
      };
    
      const fetchLocations = async () => {
        try {
         
          const response = await axios.get(`${API_URL}/view-game-location`);
          setLocations(response.data.gameLocation);
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      };
    



    useEffect(() => {
        
        // const inventory_id = props.match.params.id;
        setIsLoading(true);
    
         axios.get(`${API_URL}/edit-available-game-location/`+id, ).then(res=>{
           
            if(res.data.status === 200){
                setGame_available_location_by_id(res.data.gameAvailableLocation);
                setSelectedGame(res.data.gameAvailableLocation.game_id);
                setSelectedLocations(res.data.gameAvailableLocation.location_id.split(',').map(Number));
                
            }
            else if(res.data.status === 404){
          // Swal("Error",res.data.error,"error");
              //navigate.push('/view-inventory');
              console.log(res.data.error)
            }
            fetchGames();
            fetchLocations();
            getGameAvailableLocationList(); 
          
         });
         

        }, []);



function editGameAvailableLocation(game_available_location_id){
    setErrorList([]);
        axios.get(`${API_URL}/edit-available-game-location/`+game_available_location_id, ).then(res=>{
           
        if(res.data.status === 200){
            setGame_available_location_by_id(res.data.gameAvailableLocation);
            setSelectedGame(res.data.gameAvailableLocation.game_id);
            setSelectedLocations(res.data.gameAvailableLocation.location_id);
           
        }
        else if(res.data.status === 404){
       Swal("Error",res.data.error,"error");
          //navigate.push('/view-inventory');
          console.log(res.data.error)
        }
   
      
     });
     getGameAvailableLocationList();

    }


    
    
    function getGameAvailableLocationList()
    {
      
      axios.get(`${API_URL}/view-available-game-location`).then(res=>{
          if(res.data.status === 200){
              setGame_available_location(res.data.gameAvailableLocation); 
              
          }     
          else if(res.data.status === 404){
            setGame_available_location([]);
              }
              setIsLoading(false);
       });  
     
    }
    
          async function update(event)
          {
           event.preventDefault();
           setLoading(true);
              
           const formData = new FormData()
    
        //   const data = game_available_location_by_id;

        formData.append('game_id', selectedGame)
        formData.append('location_id', selectedLocations.join(','))
        formData.append('created_by', userContext.userDetails.id)
      
               await axios.post(`${API_URL}/update-available-game-location/` +id ,formData).then(res =>{
   
    
        Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                    setLoading(false);
                    setErrorList([]);
                    navigate('/setting-game-available-location');
                
              });
              }).catch(function(error) {
                setLoading(false);
                 // if(error.response.status===422){         
                 setErrorList(error.response.data.validate_err);
                 // }
             //    else{
                  Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })
             //    }
              })
           
              }

       

              const deleteGameAvailableLocation= (e,id) =>{
                e.preventDefault();
                var buttonClicked = window.confirm("Are you sure you want to delete available game location?");
                if(buttonClicked == true){
                   // const thisClicked = e.currentTarget;
                   // thisClicked.innerText = "Deleting";
                    axios.delete(`${API_URL}/delete-available-game-location/`+id).then(res => {
                     
                  Swal.fire({
                          icon:"success",
                          text:res.data.message
                        }).then(function(){
                           // thisClicked.closest("tr").remove();  
                            
                      });
                       getGameAvailableLocationList();
                    })
                }
                else
                {
                    getGameAvailableLocationList();
                }
               
               }
    
    return(
        <div>
 <SideNav/>
            <TopBar/>

            {isLoading?
      <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Available Game Location</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Edit Available Game Location</h2>
        </div>
        <div class="content pt-3">  
                      
                        <div class="content pt-4 pb-4">
                            <div class="row">
                            <div class="col-md-3">
                            <div class="form-group">
                            <label for="game">Select Game:</label>
        <select  class="form-select" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
          <option value="">Select a game</option>
          {games.map((game) => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
                                       <span class="text-danger">{error_list.game_name}</span>
                                </div>
                                </div>
                                
                                <div className="col-md-3">
                                <label>Select Locations:</label>
                                <div class="px-3 mb-4 bg-light mt-1 pb-4 pt-3">
        

                            <div class="row ">
        {locations.map((location) => (
            <div className="col-md-6 pb-2" key={location.id}>
      <div className="form-group-checkbox d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
        
            <input
             style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
              type="checkbox"
              id={`location-${location.id}`}
              value={location.id}
              checked={selectedLocations.includes(location.id)}
              onChange={handleLocationChange}
            />
            <label htmlFor={`location-${location.id}`}>{location.location_name}</label>
          </div>
          </div>
          </div>
                         
        ))}
                                       <span class="text-danger">{error_list.game_name}</span>
                             
                                   
                             
                                </div>
                                </div>
                                
                            </div>
                            </div>
                           
                           
                         
                                             
                            <button onClick={update} class="btn btn-sm btn_secondary text-white"> {loading?"Updating":"Update"}</button>
                            
               
            
        </div>

                           
                            
                    
            
        </div>
    </div>

    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Game Location</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search game location" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          

<><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
<thead>
    <tr>
    <th>Game Name</th>
    <th>Locations</th>
        <th class="text-end">Action</th>
    </tr>
</thead>
<tbody>
                                    {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            {/* <td colSpan="4" style={{textAlign: "center"}}>{message}</td> */}
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) =>                                                      
                                   {
                                    const game = games.find(game => game.id === item.game_id) || {};
                                    const locationIds = item.location_id.split(',').map(id => parseInt(id.trim(), 10)); // Split string and convert to array of integers
                                    const locationNames = locations
                                      .filter(location => locationIds.includes(location.id))
                                      .map(location => location.location_name)
                                      .join(', ');
                                    return (
                                 <tr key={index}>
                                  
<td>
                <p>{game ? game.name : 'Unknown'}</p>
              </td>
              <td>
                <p>{locationNames ? locationNames : 'Unknown'}</p>
              </td>
                                  

                                    <td class="d-flex justify-content-end" >

                            <button class="btn btn-sm btn_secondary" onClick={(e) => editGameAvailableLocation(item.id)}><i class="bi bi-pencil-square" ></i> Edit</button>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteGameAvailableLocation(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                        </td>
                                </tr>

);
})}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                              {/* <tfoot>
                                <tr>
                                <th>Game Name</th>
                                <th>Locations</th>
                                    <th class="text-end">Action</th>
                                </tr>
                            </tfoot>  */}
                        </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                          
          

        </div>
    </div>
</div>

</div>

        </div>
    )
}
export default EditSettingGameAvailableLocation;