import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';
import MultipleConsentForms from './MultipleConsentForms';
import { Modal, Button, Form } from 'react-bootstrap'; 

function ViewCustomerDetails(){

    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerById, setCustomerById] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [showConsentForms, setShowConsentForms] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email_error_list, setEmailErrorList] = useState({
        to: '',
        subject: '',
        message: ''
    });
    const [emailData, setEmailData] = useState({
        to: customerById.email,
        subject: '',
        message: ''
    });
     
    
    const showToast = (message) => {
        Swal.fire({
          icon: 'success',
          title: message,
          color:"white",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          customClass: {
              popup: 'sweetalert-popup',
              title: 'sweetalert-title',
              icon: 'sweetalert-icon',
            },
            background: '#333',
            showCloseButton: true,
            padding: '4px', 
        });
      };

      useEffect(() => { 
        setIsLoading(true);
        getCustomerByBookingId(id); 
     
 
       
       
         }, []);

         const filteredConsentFormDetails = customerById.consent_form_details && customerById.consent_form_details.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            moment(item.dob).format('D MMMM YYYY').toLowerCase().includes(searchQuery.toLowerCase())
        );


         function getCustomerByBookingId(id){
 
            axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
                if(res.data.status === 200){
                    const bookedSlot = res.data.bookedSlot;
                    setCustomerById(res.data.bookedSlot);
                    setEmailData(prevData => ({ ...prevData, to: res.data.bookedSlot.email }));
                 
        
                    console.log( res.data.bookedSlot);
                   const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);
          
          // Update state with formatted data
          setCustomerById({
            ...bookedSlot,
            booked_time: formattedBookedTime,
            consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : []
          });
                }
                else if(res.data.status === 404){
                    //setMessage(res.data.message);
                    console.log(res.data.status);
                    //setIsLoading(false);
                    }
                setIsLoading(false);
             });
            
            }

            const formatBookedTime = (bookedTime) => {
                try {
                  const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
                  if (Array.isArray(timeSlots) && timeSlots.length > 0) {
                    const startTime = timeSlots[0].split(' - ')[0];
                    const endTime = timeSlots[timeSlots.length - 1].split(' - ')[1];
                    // Convert to moment objects and format them
                    const formattedStartTime = moment(startTime, 'hh:mm A').format('h:mm A');
                    const formattedEndTime = moment(endTime, 'hh:mm A').format('h:mm A');
                    return `${formattedStartTime} - ${formattedEndTime}`;
                  }
                  return 'No time slots available';
                } catch (error) {
                  console.error("Error parsing booked time:", error);
                  return 'Invalid time format';
                }
              };


            //   const handleShowConsentForms = () => {
            //     setShowConsentForms(true);
            //   };


              const handleShowConsentForms = () => {
                   console.log(id);
                   navigate(`/consent-form-page/${customerById.booking_ref_id}`);
               // setShowConsentForms(true);
              };

            
              const handleAllFormsSubmitted = () => {
                setShowConsentForms(false);
                alert('All consent forms have been submitted successfully!');
              };

              const handlePageChange = (selectedPage) => {
                setCurrentPage(selectedPage.selected);
              };
              const handleShowModal = () => setShowModal(true);
              const handleCloseModal = () => setShowModal(false);

              const handleInputChange = (e) => {
                const { name, value } = e.target;
                setEmailData(prevData => ({ ...prevData, [name]: value }));
            };
        
            const handleSubmit = (e) => {
                e.preventDefault();
                setLoading(true);

                const formData = {
                    ...emailData,
                };
                // Implement email sending functionality here
                console.log('Email sent with data:', formData);
              
                  axios.post(`${API_URL}/send-email`, formData)
                  .then(() => {
              
                   setLoading(false);
                   showToast("Email sent successfully!");
                   handleCloseModal();
                 //  setEmailErrorList({ to: '', subject: '', message: '' });
                //    resetEmail();
                   
                  })
                  .catch((error) => {
                    setLoading(false);
                   // setEmailErrorList(error.response.data.validate_err);
                    
                   console.error('Error sending email:', error.response.data.message);
                  });



         

            };
        

    return(
        <div>
     <SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


          
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-people"></i> Customer Booking details</h1>
           

        </div>

        <div class="content_wrapper pt-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            
            <div>
       
                    <div class="content">

                  
                    <div class="content_wrapper pt-0">
                          

<div className="row">
                           <div className='col-md-6'>
        <div className="vm_content_box_1 mt-4 ">
      
        <div class="title">
            <h2>Booking Details</h2>
        </div>
<div className="content py-0 bg-white px-3 py-3  mb-3 w-100" >
            <div className="text-center">
                <h2 className="vm_font_bold mt-2">{customerById.firstname} {customerById.lastname}</h2>
                <div className="d-flex justify-content-center mb-3">
                    <a style={{ cursor: "pointer" }}     className="d-flex align-items-center pe-3 text-decoration-none text-dark">
                        <i className="bi bi-telephone-fill me-2 text-success"></i>
                        <p className="mb-0">{customerById.phone}</p>
                    </a>

                    <a style={{ cursor: "pointer" }}   onClick={handleShowModal}  className="d-flex align-items-center text-decoration-none text-dark">
                        <i className="bi bi-envelope-fill me-2 text-success"></i>
                        <p className="mb-0">{customerById.email}</p>
                    </a>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="emailTo" className="form-group mt-3 ">
                            <label>To</label>
                            <Form.Control 
                                type="email" 
                                name="to" 
                                value={emailData.to} 
                                onChange={handleInputChange} 
                                readOnly
                            />
                                  <span  className="text-danger">{email_error_list.to}</span>
                        </Form.Group>
                        <Form.Group controlId="emailSubject"  className="form-group mt-3 ">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="subject" 
                                value={emailData.subject} 
                                onChange={handleInputChange} 
                            />
                                <span  className="text-danger">{email_error_list.subject}</span>
                        </Form.Group>
                        <Form.Group controlId="emailMessage"  className="form-group mt-3 ">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                name="message" 
                                value={emailData.message} 
                                onChange={handleInputChange} 
                            />
                                <span  className="text-danger">{email_error_list.message}</span>
                        </Form.Group>
                        <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                           
                            <button class="btn btn-sm btn_secondary text-white ms-auto"    type="submit">
                       Send Email
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

<div className="row">
<div className="col-md-12">
<div className="row border-top pb-3">
                {[
                    { label: 'Booking Id', value: customerById.booking_ref_id },
                    { label: 'Game Name', value: customerById.game_name },
                    { label: 'Location', value: customerById.location_name },
                    { label: 'Game Package', value: customerById.game_type_name ? customerById.game_type_name : `${customerById.package_name} - ${customerById.game_package_name}` },
                    { label: 'Booked Date', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-calendar3 me-2 text-success" ></i>
                            <span>{moment(customerById.booked_date).format('D MMMM YYYY')}</span>
                        </div>
                    ) },
                    { label: 'Booked Time', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-clock-fill me-2 text-success" ></i>
                            <span>{customerById.booked_time}</span>
                           
                        </div>
                    ) },
                    { label: 'Adult Count', value: customerById.no_of_adults },
                    { label: 'Kids Count', value: customerById.no_of_children },
                    { label: 'Amount paid', value: customerById.amount_paid },
                  
                  
                    ...(customerById.package_name 
                        ? [
                          
                            { label: 'Birthday Kid Name', value: customerById.birthday_kid_name },
                            ...(customerById.food_details 
                                ? [{ label: 'Food details', value: (customerById.food_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                : []),
                                ...(customerById.drink_details 
                                    ? [{ label: 'Drink details', value: (customerById.drink_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                    : []),
                           

                        ]
                        : []),

                    
                       

                ].map((detail, index) => (
                    <div className="d-flex border-bottom py-2" key={index}>
                        <div className="col-5 p-1 border-end vm_font_bold text-secondary">{detail.label}</div>
                        <div className="col-7 p-1 ps-3">{detail.value}</div>
                    </div>
                ))}
            </div>


            <div className="px-3">
                           
                            {showConsentForms && (
                              <MultipleConsentForms
                                bookingId={customerById.booking_ref_id}
                                participantCount={customerById.no_of_adults}
                                onAllFormsSubmitted={handleAllFormsSubmitted}
                              />
                            )}
                          </div>
</div>

 






</div>

            
        </div>
    </div>
    </div>


<div className="col-md-6">

<div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Consent Form Details</h2>
                {filteredConsentFormDetails && filteredConsentFormDetails.length === customerById.no_of_adults  ? <p style={{color:'green'}}>Completed</p>:  (          
                
                       <a
        href={'/consent-form-page/' + customerById.booking_ref_id}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-sm btn_secondary"
        style={{ color: 'white', textDecoration: 'none' }}
    >
        Fill Out Consent Forms
    </a>
                      
                        
                    
                )}
            </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                           <th>S.No</th>
                                            <th>Name</th>
                                            <th>DOB</th>
                                            <th>Submitted on</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredConsentFormDetails && filteredConsentFormDetails.length === 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -350px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredConsentFormDetails && filteredConsentFormDetails.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>
                                                <td><p>{index + 1}</p> </td>
                                                <td><p>{item.name}</p> </td>
                                                <td><p> {moment(item.dob).format('D MMMM YYYY')}</p> </td>
                                                <td><p> {moment(item.created_at).format('MMM D, YYYY, h:mm A')}</p> </td>
                                            </tr>


                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>DOB</th>
                                        <th>Submitted on</th>
                                        </tr>
                                    </tfoot>
                                </table><div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                {filteredConsentFormDetails && filteredConsentFormDetails.length > 0 && `Showing 1 to ${Math.min(perPage, filteredConsentFormDetails.length)} of ${filteredConsentFormDetails.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredConsentFormDetails && filteredConsentFormDetails.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                      

        </div>
        </div>
   
</div>
</div>


                           </div>
                       
                       
                    </div>
             
            </div>
        </div>
        </div>
       
        </div>   
    );
}
export default ViewCustomerDetails;
