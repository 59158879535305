
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef,useContext } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import Swal from "sweetalert2";
import { elements } from "chart.js";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import placeholderImage from '../img/image_placeholder.png';
import noImagePlaceholder from '../img/no_image_placeholder.png';
// import UserContext from './UserContext';
import moment from 'moment';
import { Collapse } from 'react-bootstrap'; 
import { Modal, Button } from 'react-bootstrap';

function LeadsDetails(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    // const userContext = useContext(UserContext);  

    const [isLoading, setIsLoading] = useState(false);
    const [mergedArray, setMergedArray] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [customerType, setCustomerType] = useState([]);
    const [assign_to, setAssign_to] = useState([]);
    const [email_template, setEmail_template] = useState([]);
    const [sms_template, setSms_template] = useState([]);
    const [note_template, setNote_template] = useState([]);
    const [team, setTeam] = useState([]);
    const [upload_template, setUpload_template] = useState([]);
    const [booking, setBooking] = useState([]);
    const [bookingScarborough, setBookingScarborough] = useState([]);
    const [pin_template, setPin_template] = useState([]);
    const [customerById, setCustomerById] = useState([]);
    const [customerBookedHistory, setCustomerBookedHistory] = useState([]);
    const [emailLogById, setEmailLogById] = useState([]);
    const [smsLogById, setSmsLogById] = useState([]);
    const [meetLogById, setMeetLogById] = useState([]);
    const [noteLogById, setNoteLogById] = useState([]);
    const [uploadLogById, setUploadLogById] = useState([]);
    const [bookingById, setBookingById] = useState([]);
    const [timeSlotById, setTimeSlotById] = useState([]);
    const [inquiryById, setInquiryById] = useState([]);
    const { id } = useParams();

    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [minKilometer, setMinKilometer] = useState("");
    const [maxKilometer, setMaxKilometer] = useState("");
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [bodyStyleOptions, setBodyStyleOptions] = useState([]);
const [engineOptions, setEngineOptions] = useState([]);
const [exteriorColorOptions, setExteriorColorOptions] = useState([]);
const [transmissionOptions, setTransmissionOptions] = useState([]);
const [driveTypeOptions, setDriveTypeOptions] = useState([]);
const [doorsOptions, setDoorsOptions] = useState([]);
const [stockNoOptions, setStockNoOptions] = useState([]);
const [vehicle_name, setVehicle_name] = useState("");
    const [selectedMakes, setSelectedMakes] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedBodyStyles, setSelectedBodyStyles] = useState([]);
const [selectedEngines, setSelectedEngines] = useState([]);
const [selectedExteriorColors, setSelectedExteriorColors] = useState([]);
const [selectedTransmissions, setSelectedTransmissions] = useState([]);
const [selectedDriveType, setSelectedDriveType] = useState([]);
const [selectedDoors, setSelectedDoors] = useState([]);
const [selectedStockNo, setSelectedStockNo] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [selectedInventoryType, setSelectedInventoryType] = useState("false");
    const [disableMakeOptions, setDisableMakeOptions] = useState(true);
    const [loading, setLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    const [filteredInventory, setFilteredInventory] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [settingsVisible, setSettingsVisible] = useState(false);
    const [inqVisible, setInqVisible] = useState(false);
    const [callVisible, setCallVisible] = useState(false);
    const [emailVisible, setEmailVisible] = useState(false);
    const [textVisible, setTextVisible] = useState(false);
    const [pinVisible, setPinVisible] = useState(false);
    const [meetVisible, setMeetVisible] = useState(false);
    const [noteVisible, setNoteVisible] = useState(false);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [bookingVisible, setBookingVisible] = useState(false);
    const [inqueryVisible, setInqueryVisible] = useState(true);
    const [infoVisible, setInfoVisible] = useState(true);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [email_error_list, setEmailErrorList] = useState([]);
    const [meetingErrorList, setMeetingErrorList] = useState([]);
    const [noteErrorList, setNoteErrorList] = useState([]);
    const [paymentErrorList, setPaymentErrorList] = useState([]);
    const [uploadErrorList, setUploadErrorList] = useState([]);
    const [bookingErrorList, setBookingErrorList] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [description, setDescription] = useState('');

    const [template, setTemplate] = useState("");
    const [text_template, setText_template] = useState("");
    const [note_template_select, setNote_template_select] = useState("");
    const [team_select, setTeam_select] = useState([]);
    const [upload_template_select, setUpload_template_select] = useState("");
    const [pin_template_select, setPin_template_select] = useState("");
    const [template_details, setTemplate_details] = useState([]);
    const [text_template_details, setText_template_details] = useState([]);
    const [note_template_details, setNote_template_details] = useState([]);
    const [upload_template_details, setUpload_template_details] = useState([]);
    const [pin_template_details, setPin_template_details] = useState([]);
    const [subject, setSubject] = useState("");
    const [note_subject, setNote_subject] = useState("");
    const [upload_subject, setUpload_subject] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [previewsVideo, setPreviewsVideo] = useState([]);
    const selectAllCheckboxRef = useRef();
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [text_message, setText_message] = useState("");
    const [note_message, setNote_message] = useState("");
    const [upload_message, setUpload_message] = useState("");
    const [pin_message, setPin_message] = useState("");
    const [activeButton, setActiveButton] = useState(null);
    const [sortOrder, setSortOrder] = useState('newest'); 
    const [title, setTitle] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [booking_date, setBooking_date] = useState('');
    const [time, setTime] = useState('');
    const [booking_time, setBooking_time] = useState('');
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const [bookingAvailableTimeSlots, setBookingAvailableTimeSlots] = useState([]);
    const [makeError, setMakeError] = useState();
    const [modelError, setModelError] = useState();
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [isUploadImageLoading, setIsUploadImageLoading] = useState(true);
    const [isPhotoLoading, setIsPhotoLoading] = useState(true);
    const [user_type, setUser_type] = useState("");
    const [activeTab, setActiveTab] = useState('adults'); 
    const [kids_count, setKids_count] = useState(0);
    const [adults_count, setAdults_count] = useState(0);
    const [game_title, setGame_title] = useState('');
    const [game_description, setGame_description] = useState('');
  const [available_space, setAvailable_space] = useState(0);
  const [bookedTimes, setBookedTimes] = useState([]);
  const [game_location, setGame_location] = useState('');
  const [game_type, setGame_type] = useState('');
  const [groupedBookedTimes, setGroupedBookedTimes] = useState([]);
    const [isEmail, setIsEmail] = useState(false); 
    const [openEmailId, setOpenEmailId] = useState(null);
    const [openNoteId, setOpenNoteId] = useState(null);
    const [openActivityEmailId, setOpenActivityEmailId] = useState(null);
    const [openActivitySmsId, setOpenActivitySmsId] = useState(null);
    const [openActivityNoteId, setOpenActivityNoteId] = useState(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [payment_method, setPayment_method] = useState('');
  const [payment_amount, setPayment_amount] = useState('');
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isTemplate, setIsTemplate] = useState(false); 
  const [showModal, setShowModal] = useState(false); 
  const [availableSpaces, setAvailableSpaces] = useState({});
  const [timeSlotLoading, setTimeSlotLoading] = useState(false);
  const [disabledTimeSlots, setDisabledTimeSlots] = useState([]);
  const [timeSlotDetails, setTimeSlotDetails] = useState([]);
  const [game_packages, setGame_packages] = useState([]);
  const [game_types_array, setGame_types_array] = useState([]);
  const [game_count, setGame_count] = useState([]);
  const [game_types, setGame_types] = useState([]);
  const [minPlayers, setMinPlayers] = useState(0);
  const [maxPlayers, setMaxPlayers] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [sub_total, setSub_total] = useState(0);
  const [amount_paid, setAmount_paid] = useState(0);
  const [hst, setHst] = useState(0);
  const [cart_details, setCart_details] = useState('');
  const [deposit_amount, setDeposit_amount] = useState(0);
  const [deposit_details, setDeposit_details] = useState('');
  const [birthdayPrice, setBirthdayPrice] = useState([]);
  const [birthday_packages, setBirthday_packages] = useState([]);
  const [min_game, setMin_game] = useState(0);
  const [basic_price, setBasic_price] = useState(0);
  

  const handlePaymentMethodChange = (e) => {
    setPayment_method(e.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    setPayment_amount(e.target.value);
  };

  const handleShowPaymentModal = () => 
    setShowPaymentModal(true);
  const handleClosePaymentModal = () => setShowPaymentModal(false);
    
  
    const handleToggle = (emailId) => {
        setOpenEmailId(openEmailId === emailId ? null : emailId);
    };

    const handleToggleNote = (noteId) => {
        setOpenNoteId(openNoteId === noteId ? null : noteId);
    };

    const handleToggleEmailActivity = (emailId) => {
        setOpenActivityEmailId(openActivityEmailId === emailId ? null : emailId);
    };

    
    const handleToggleSmsActivity = (smsId) => {
        setOpenActivitySmsId(openActivitySmsId === smsId ? null : smsId);
    };

    const handleToggleNoteActivity = (noteId) => {
        setOpenActivityNoteId(openActivityNoteId === noteId ? null : noteId);
    };

      // Filter the team based on the search input
      const filteredTeam = team.filter((member) =>
        `${member.first_name} ${member.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
      );
    

      const filteredConsentFormDetails = customerById.consent_form_details && customerById.consent_form_details.filter(item =>
        Number(item.is_deleted) === 0 && 
        (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.dob && moment(item.dob).format('D MMMM YYYY').toLowerCase().includes(searchQuery.toLowerCase()))
    );



    const filteredKidsFormDetails = customerById.kids_form_details && customerById.kids_form_details.filter(item => 
        Number(item.is_deleted) === 0 && 
        (
            item.guardian_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.children.some(child => 
                child.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                moment(child.dob).format('D MMMM YYYY').toLowerCase().includes(searchQuery.toLowerCase())
            )
        )
    );

    const filteredBookedHistory =  customerBookedHistory.filter((item) => {
        return `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.booking_ref_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.game_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

      const generateBookingTimeSlots = (bookedTimes, allTimeSlots) => {
        const availableTimeSlots = allTimeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
        setBookingAvailableTimeSlots(availableTimeSlots);
        return availableTimeSlots;
      };

      const handleGameType  = (event) => {
        setGame_type(event.target.value);
        };

        
      const handleGameLocation  = (event) => {
        setGame_location(event.target.value);
        };
    
     

    const handleDateChange = (e) => {
       
        const selectedDate = e.target.value;
        setDate(selectedDate);
        axios.get(`${API_URL}/booked-appointments`, { params: { selectedDate } })
        .then(response => {
            const bookedTimes = response.data.map(time => {
                const [hour, minute] = time.split(':');
                const hour12 = (parseInt(hour) % 12) || 12;
                const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
                return `${hour12}:${minute} ${ampm}`;
            });
   
            const availableTimes = generateTimeSlots(bookedTimes);
       
        })
        .catch(error => {
            console.error('Error fetching available date-time pairs:', error);
        });
      };


      const handleEditBooking = () => {
        
        setShowModal(true);
        // if(customerById.game_type_id)
        // {
        //     recalculateCosts();
        // }
        // if(customerById.package_name)
        //     {
        // recalculateBirthdayCosts();
        //     }
      };
    
      // Function to close the modal
      const handleCloseModal = () => setShowModal(false);

      const handleBookingDateChange = (e) => {

if(game_location === 'stouffville'){

    const selectedDate = e.target.value;
    setBooking_date(selectedDate);

    axios.get(`${API_URL}/booked-appointments-game`, { params: { selectedDate } })
        .then(response => {
            const bookedTimes = response.data;
            setBookedTimes(bookedTimes.filteredTimeSlots); // Save booked times to state
            getTimeSlotList(bookedTimes.filteredTimeSlots);// Pass only the time keys to getTimeSlotList
            setGroupedBookedTimes(bookedTimes.groupedBookedTimes);
         
        })
         
        .catch(error => {
            console.error('Error fetching available date-time pairs:', error);
        });
}
else if (game_location === 'scarborough'){

    const selectedDate = e.target.value;
    setBooking_date(selectedDate);

    axios.get(`${API_URL}/booked-appointments-scarborough`, { params: { selectedDate } })
        .then(response => {
            const bookedTimes = response.data;
            setBookedTimes(bookedTimes.filteredTimeSlots); // Save booked times to state
            getTimeSlotList(bookedTimes.filteredTimeSlots);// Pass only the time keys to getTimeSlotList
            setGroupedBookedTimes(bookedTimes.groupedBookedTimes);
  
        })
         
        .catch(error => {
            console.error('Error fetching available date-time pairs:', error);
        });
}


       
    };


    const handleTimeSlotChange = (e) => {
        const selectedSlot = e.target.value;
        setBooking_time(selectedSlot);

        const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
        const bookedPeople = groupedBookedTimes[selectedSlot] || 0;
        const remainingSpace = timeSlotById.reserved_slot - bookedPeople;
        setAvailable_space(remainingSpace - totalPeople);
    };

    

      const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(':');
        const hour12 = (parseInt(hour) % 12) || 12;
        const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
        return `${hour12}:${minute} ${ampm}`;
    };
      const isImage = (fileName) => {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
        return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    };

    const isDocument = (fileName) => {
        const documentExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.txt']; // Add more extensions if needed
        const fileExtension = fileName.toLowerCase().split('.').pop(); // Get the file extension
        
        return documentExtensions.includes(`.${fileExtension}`);
    };
      
      const formatMeetDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };
    
    const formatMeetTime = (timeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString('en-US', options);
    };

    const formatBookingDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const formatBookingTime = (timeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString('en-US', options);
    };

      const generateTimeSlots = (bookedTimes) => {
        // Generate time slots from 9 AM to 4 PM
        const startTime = 9;
        const endTime = 16;
        const timeSlots = [];
    
        for (let hour = startTime; hour <= endTime; hour++) {
          const formattedHour = hour % 12 || 12;
          const ampm = hour < 12 ? 'AM' : 'PM';
          const timeSlot = `${formattedHour}:00 ${ampm}`;
          timeSlots.push(timeSlot);
        }   
    
  
       // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

        const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
setAvailableTimeSlot(availableTimeSlots);
        return availableTimeSlots;
      };

      const formattedDate = new Date(date);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDateString = formattedDate.toLocaleDateString('en-US', options);

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      

      const filteredEmailHistory = emailLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      
      const filteredMeetHistory = meetLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      const filteredNoteHistory = noteLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      const filteredUploadHistory = uploadLogById.filter((item) => {
        return item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });

      const filteredBookingHistory = bookingById.filter((item) => {
        return item.game_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())  
      });


       const uploadImageClick = (imageURL) => {
        Swal.fire({
          imageUrl: imageURL,
          imageAlt: 'Uploaded Image',
          showCloseButton: true,
          showConfirmButton: false,
        });
      };


       
      const sortedSmsHistory = [...smsLogById]; // Make a copy of the original data

      if (sortOrder === 'oldest') {
          sortedSmsHistory.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      } else {
          sortedSmsHistory.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }

      useEffect(() => {
    const filteredOptions = mergedArray.filter((item) => {
        return (
            (selectedInventoryType === 'all' || item.global_inventory === selectedInventoryType) &&
            (!selectedMakes.length || selectedMakes.includes(item.brand) || 
            (selectedMakes.includes(item.brand) && selectedModels.includes(item.model_name))) &&
            (!selectedExteriorColors.length || selectedExteriorColors.includes(item.exterior_color.toLowerCase())) &&
            (!selectedTransmissions.length || selectedTransmissions.includes(item.transmission.toLowerCase())) &&
            (!selectedBodyStyles.length || selectedBodyStyles.includes(item.body_type.toLowerCase())) &&
            (!selectedEngines.length || selectedEngines.includes(item.engine_type.toLowerCase())) &&
            (!selectedDriveType.length || selectedDriveType.includes(item.drive_type.toLowerCase())) &&
            (!selectedDoors.length || selectedDoors.includes(item.door)) &&
            (!selectedStockNo.length || selectedStockNo.includes(item.stock_no.toLowerCase())) &&
            (!minPrice || item.price.sale_price >= minPrice) &&
            (!maxPrice || item.price.sale_price <= maxPrice) &&
            (!minKilometer || item.odometer >= minKilometer) &&
            (!maxKilometer || item.odometer <= maxKilometer)
        );
    });

    const bodyStyles = filteredOptions.map(row => row.body_type.toLowerCase());
    setBodyStyleOptions([...new Set(bodyStyles)]);

    const engines = filteredOptions.map(row => row.engine_type.toLowerCase());
    setEngineOptions([...new Set(engines)]);

    const exteriorColor = filteredOptions.map(row => row.exterior_color.toLowerCase());
    setExteriorColorOptions([...new Set(exteriorColor)]);

    const transmissions = filteredOptions.map(row => row.transmission.toLowerCase());
    setTransmissionOptions([...new Set(transmissions)]);

    const driveTypes = filteredOptions.map(row => row.drive_type.toLowerCase());
    setDriveTypeOptions([...new Set(driveTypes)]);

    const doors = filteredOptions.map(row => row.door);
    setDoorsOptions([...new Set(doors)]);

    const stockNos = filteredOptions.map(row => row.stock_no.toLowerCase());
    setStockNoOptions([...new Set(stockNos)]);
}, [mergedArray, selectedInventoryType, selectedMakes, selectedModels, selectedExteriorColors, selectedTransmissions, selectedBodyStyles, selectedEngines, selectedDriveType, selectedDoors, selectedStockNo, minPrice, maxPrice, minKilometer, maxKilometer]);

    const handleImageLoad = () => {
    setIsImageLoading(false);
};

const handleUploadImageLoad = () => {
    setIsUploadImageLoading(false);
};

const handlePhotoLoad = () => {
    setIsPhotoLoading(false);
};

   const showToast = (message) => {
  Swal.fire({
    icon: 'success',
    title: message,
    color:"white",
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    customClass: {
        popup: 'sweetalert-popup',
        title: 'sweetalert-title',
        icon: 'sweetalert-icon',
      },
      background: '#333',
      showCloseButton: true,
      padding: '4px', 
  });
};

const showToastFail = (message) => {
    Swal.fire({
      icon: 'error',
      title: message,
      color:"white",
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
          popup: 'sweetalert-popup',
          title: 'sweetalert-title',
          icon: 'sweetalert-icon',
        },
        background: '#333',
        showCloseButton: true,
        padding: '4px', 
    });
  };

  const handleUserType = async (event, customerId) => {
    setIsLoading(true);
    const newUserType = event.target.value;
    setCustomer(prevCustomer => prevCustomer.map(c => c.id === customerId ? { ...c, user_type: newUserType } : c));
    setUser_type(newUserType); // Set the new user type in the state
    try {
        // Fetch the existing customer data
        const res = await axios.get(`${API_URL}/edit-customer/${customerId}`);
        if (res.data.status === 200) {
            const customerData = res.data.customer;
            setCustomerType(customerData);

            // Prepare form data for the update request
            const formData = new FormData();
            formData.append('customer_photo', customerData.customer_photo);
            formData.append('user_type', newUserType); // Use the new user type
            formData.append('first_name', customerData.first_name);
            formData.append('last_name', customerData.last_name);
            formData.append('phone_no', customerData.phone_no);
            formData.append('email', customerData.email);
            formData.append('purchased_on', customerData.purchased_on);
            formData.append('country', customerData.country);
            formData.append('address', customerData.address);
            formData.append('apart_or_suite', customerData.apart_or_suite);
            formData.append('city', customerData.city);
            formData.append('province', customerData.province);
            formData.append('postal_code', customerData.postal_code);
            formData.append('employer_name', customerData.employer_name);
            formData.append('employer_address', customerData.employer_address);
            formData.append('employer_country', customerData.employer_country);
            formData.append('employer_apart_or_suite', customerData.employer_apart_or_suite);
            formData.append('employer_city', customerData.employer_city);
            formData.append('employer_state', customerData.employer_state);
            formData.append('employer_postalcode', customerData.employer_postalcode);
            formData.append('job_title', customerData.job_title);
            formData.append('monthly_income', customerData.monthly_income);
            formData.append('vehicle_type', customerData.vehicle_type);
            formData.append('hear_about', customerData.hear_about);

            // Send the update request
            const updateRes = await axios.post(`${API_URL}/update-customer/${customerId}`, formData);
            if (updateRes.status === 200) {
                showToast("Usertype updated!");
            }
        }


    } catch (error) {
        Swal.fire({
            text: error.response?.data?.message || 'An error occurred',
            icon: "error"
        });
    }
    setIsLoading(false);
};


const saveAssign = () => {
    setLoading(true);

    // Update local state
    setCustomer(prevCustomer => 
        prevCustomer.map(c => c.id === customerById[0].id ? { ...c, assign_to: team_select } : c)
    );
    setTeam_select(team_select);

    // Fetch the existing customer data
    axios.get(`${API_URL}/edit-customer/${customerById[0].id}`)
        .then(res => {
            if (res.data.status === 200) {
                const customerData = res.data.customer;
                let assignToList = [];
                try {
                    assignToList = JSON.parse(customerData[0].assign_to);
                    setTeam_select(assignToList);
                } catch (e) {
                    console.error("Error parsing assign_to:", e);
                }
                // Prepare form data for the update request
                const formData = new FormData();
                formData.append('customer_photo', customerData.customer_photo);
                formData.append('user_type', customerData.user_type); // Use the new user type
                formData.append('first_name', customerData.first_name);
                formData.append('last_name', customerData.last_name);
                formData.append('phone_no', customerData.phone_no);
                formData.append('email', customerData.email);
                formData.append('purchased_on', customerData.purchased_on);
                formData.append('country', customerData.country);
                formData.append('address', customerData.address);
                formData.append('apart_or_suite', customerData.apart_or_suite);
                formData.append('city', customerData.city);
                formData.append('province', customerData.province);
                formData.append('postal_code', customerData.postal_code);
                formData.append('employer_name', customerData.employer_name);
                formData.append('employer_address', customerData.employer_address);
                formData.append('employer_country', customerData.employer_country);
                formData.append('employer_apart_or_suite', customerData.employer_apart_or_suite);
                formData.append('employer_city', customerData.employer_city);
                formData.append('employer_state', customerData.employer_state);
                formData.append('employer_postalcode', customerData.employer_postalcode);
                formData.append('job_title', customerData.job_title);
                formData.append('monthly_income', customerData.monthly_income);
                formData.append('vehicle_type', customerData.vehicle_type);
                formData.append('hear_about', customerData.hear_about);
                formData.append('assign_to', JSON.stringify(team_select)); // Ensure it's a string

                // Send the update request
                axios.post(`${API_URL}/update-customer/${customerById[0].id}`, formData)
                    .then(updateRes => {
                        if (updateRes.data.status === 200) {
                            showToast("Assigned successfully!");
                        } else {
                            throw new Error("Failed to update customer");
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        Swal.fire({
                            text: error.response?.data?.message || error.message || 'An error occurred',
                            icon: "error"
                        });
                        setLoading(false);
                    });
            } else {
                throw new Error("Failed to fetch customer data");
            }
        })
        .catch(error => {
            Swal.fire({
                text: error.response?.data?.message || error.message || 'An error occurred',
                icon: "error"
            });
            setLoading(false);
        });
};





    const toggleDiv = (divName) => {
       setActiveButton(activeButton === divName ? null : divName);
        switch (divName) {
            case 'info':
                if (infoVisible) return; 
               
                setInfoVisible(!infoVisible);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setNoteVisible(false);
                setHistoryVisible(false);
              

                break;
                if (settingsVisible) return; 
                resetData();
                setSettingsVisible(!settingsVisible);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
                setBookingVisible(false);
                setInqueryVisible(false);
                setInfoVisible(false);
                setIsSearch(false);
                break;
            case 'inq':
                if (inqVisible) return; 
                resetData();
                setInqVisible(!inqVisible);
                setSettingsVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
                setBookingVisible(false);
                setInqueryVisible(false);
                setInfoVisible(false);

                setIsSearch(false);
                break;
                case 'call':
                    if (callVisible) return; 
                    setCallVisible(!callVisible);
                    setSettingsVisible(false);
                    setInqVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setUploadVisible(false);
                setInqueryVisible(false);
                setInfoVisible(false);
                setBookingVisible(false);
                setIsSearch(false);
                setHistoryVisible(false);
                    break;
                    case 'email':
                        if (emailVisible) return; 
                        // setSearchQuery("");
                        setTemplate_details([]);
                        setEmailErrorList([]);
                        resetEmail();
                        setEmailVisible(!emailVisible);
                        // setSettingsVisible(false);
                        // setInqVisible(false);
                        setCallVisible(false);
                        setTextVisible(false);
                        // setPinVisible(false);
                        // setMeetVisible(false);
                        setNoteVisible(false);
                        // setUploadVisible(false);
                        // setBookingVisible(false);
                        // setInqueryVisible(false);
                        setInfoVisible(false);
                        setHistoryVisible(false);
                        // setIsSearch(false);
                        break;
                        case 'text':
                            if (textVisible) return; 
                            setTextVisible(!textVisible);
                            setSettingsVisible(false);
                            setInqVisible(false);
                            setCallVisible(false);
                            setEmailVisible(false);
                            setPinVisible(false);
                            setMeetVisible(false);
                            setNoteVisible(false);
                            setBookingVisible(false);
                            setUploadVisible(false);
                            setInqueryVisible(false);
                            setInfoVisible(false);
                            setHistoryVisible(false);
                            resetSms();
                            setErrorList([]);
                            setText_template_details([]);
                            setIsSearch(false);
                            break;

                            case 'history':
                                if (historyVisible) return; 
                                setHistoryVisible(!historyVisible);
                                setInfoVisible(false);
                                setCallVisible(false);
                                setEmailVisible(false);
                                setTextVisible(false);
                                setNoteVisible(false);
                
                                break;
          case 'pin':
            if (pinVisible) return; 
            setPinVisible(!pinVisible);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
            setBookingVisible(false);
            setInqueryVisible(false);
            setIsSearch(false);
            break;
          case 'meet':
            if (meetVisible) return; 
            setMeetVisible(!meetVisible);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
            setBookingVisible(false);
            setInqueryVisible(false);
            setMeetingErrorList([]);
            setIsSearch(false);
            break;
          case 'note':
            if (noteVisible) return; 
            setNoteVisible(!noteVisible);
            // setSettingsVisible(false);
            // setInqVisible(false);
            setInfoVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setHistoryVisible(false);
            // setPinVisible(false);
            // setMeetVisible(false);
            // setUploadVisible(false);
            // setBookingVisible(false);
            // setInqueryVisible(false);
            setNoteErrorList([]);
            setNote_template_details([]);
            setIsSearch(false);
            break;
            case 'upload':
                if (uploadVisible) return; 
                setUploadVisible(!uploadVisible);
                setSettingsVisible(false);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setBookingVisible(false);
                setInqueryVisible(false);
                setUploadErrorList([]);
                setUpload_template_details([]);
                setIsSearch(false);
                break;
            case 'booking':
                if (bookingVisible) return; 
                setBookingVisible(!bookingVisible);
                setSettingsVisible(false);
                setInqVisible(false);
                setCallVisible(false);
                setEmailVisible(false);
                setTextVisible(false);
                setPinVisible(false);
                setMeetVisible(false);
                setNoteVisible(false);
                setInqueryVisible(false);
                setUploadErrorList([]);
                setUpload_template_details([]);
                setIsSearch(false);
                resetBooking();
                break;
          default:
            break;
        }
      };

      function getDayName(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'short' };
        return date.toLocaleDateString('en-US', options);
    }


      function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    
    function formatTime(dateString) {
        const date = new Date(dateString);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }





    useEffect(() => { 
       
         //getCustomerList();
        // getTeamList();
          getCustomerById(id);
          fetchGameTypesArray();
        
           getEmailTemplateList();
        //   getSmsTemplateList();
           getNoteTemplateList();
          
        //   getUploadTemplateList();
        //   getPinTemplateList();
        //   getInventoryList(); 
        //   getBookingList();
        //   getScarboroughBookingList();
        //  getTimeSlotList();
}, [id]);



useEffect(() => { 
    fetchGameTypes();

}, [customerById]);


const fetchGamePackages = async (selectedPackageType) => {
  
    try {
        const response = await axios.get(`${API_URL}/view-game-package-by-package/${customerById.location_id}/${customerById.game_id}/${selectedPackageType}`);
 
        if (response.data.gamePackages) {
            setGame_packages(response.data.gamePackages);
   
        }
        else {
            setGame_packages([]);
        }
    } catch (error) {
        console.error("Error fetching package:", error);

    }
};

const fetchGameTypes = async () => {
   
    try {
        const response = await axios.get(`${API_URL}/view-game-type-by-gameId/${customerById.game_id}/${customerById.location_id}`);
        if (response.data.gameType) {
            setGame_types(response.data.gameType);

        }
        else {
            setGame_types([]);
        }



    } catch (error) {
        console.error("Error fetching games:", error);

    }
}; 

useEffect(() => {
     
  //  setBooking_time([]);
//     if(selectedGameType && (adults_count + kids_count)  > 1){
   
//   const totalPlayers = Number(kids_count) + Number(adults_count);
//   if (totalPlayers < minPlayers || totalPlayers > maxPlayers) {
//       setTotalPlayersErrorList(`The total number of players must be between ${minPlayers} and ${maxPlayers}.`);
//       setBookingAvailableTimeSlots([]);
//   } 
 
//      else {
     //   fetchTimeSlots();
    //     setTotalPlayersErrorList('');

    //  }
//}       

// if(selectedBirthdayPackage && game_count > 1 &&  (adults_count + kids_count)  > 1)
// {
//     // const filteredPackage = game_packages.filter(item => item.id === Number(selectedBirthdayPackage));
//     //         const max_players_in_a_slot = filteredPackage[0].max_players_in_a_slot;

//     const totalPlayers = Number(kids_count) + Number(adults_count);
//     if (selectedBirthdayPackage && totalPlayers > birthday_packages.max_players_in_a_slot) {
//         setTotalPlayersErrorList(`The total number of players for a birthday package must be between 1 and ${birthday_packages.max_players_in_a_slot}.`);
//     } 
    
//     else {
        // setTotalPlayersErrorList('');
       // fetchTimeSlotsBirthday();
//      }
// }

if(customerById.game_type_id)
{
    fetchTimeSlots();
  
}

if(customerById.package_name == "Birthday")
    {
        fetchGamePackages(1);
        fetchTimeSlotsBirthday();
  
    }



    }, [adults_count, kids_count, game_count]);

  async function getCustomerList(){
 
    axios.get(`${API_URL}/view-booked-slots`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.bookedSlots;
            setCustomer(customer);
     
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }



const getTimeSlotList = async (bookedTimes) => {
    try {
      const res = await axios.get(`${API_URL}/view-time-slot`);
      if (res.data.status === 200) {
        const timeSlot = res.data.timeslot[0];
        setTimeSlotById(timeSlot);
        setGame_title(res.data.timeslot[0].game_title);
        setGame_description(res.data.timeslot[0].game_description);

        const { start_time, end_time, time_interval, reserved_slot } = timeSlot;
        const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_interval, 10));
        
        const availableTimes = generateBookingTimeSlots(bookedTimes, timeSlots);
        setBookingAvailableTimeSlots(availableTimes);
        setAvailable_space(reserved_slot);
      } else if (res.data.status === 404) {
        setBookingAvailableTimeSlots([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching time slots:', error);
    }
  };

//   useEffect(() => {
//     if (!isLoading && booking_time) {
//         const totalPrice = (kids_count * timeSlotById.kids_price) + (adults_count * timeSlotById.adults_price);
//         setTimeSlotById((prev) => ({ ...prev, price: totalPrice }));

//         const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
//         const bookedPeople = groupedBookedTimes[booking_time] || 0;
//         const remainingSpace = timeSlotById.reserved_slot - bookedPeople;
//         setAvailable_space(Math.max(0, remainingSpace - totalPeople));
//         if (totalPeople <= 30 && Math.max(0, remainingSpace - totalPeople) === 0) {
//             alert('Cannot exceed 30 people and available space is 0.');
//             // You can reset counts or take other actions as needed
//             // setKids_count(0);
//             // setAdults_count(0);
//         }
//     }
// }, [kids_count, adults_count, isLoading, booking_time]);        



//   useEffect(() => {
//     if (booking_date) {
//       handleBookingDateChange({ target: { value: booking_date } });
//     }
//   }, [booking_date]);

  useEffect(() => {
    if(game_types.length)
    {

        recalculateCosts();
       
    }
  
}, [kids_count ,adults_count,booking_time]);

useEffect(() => {
    if(game_packages.length)
        {

    recalculateBirthdayCosts();
        }
}, [kids_count, adults_count, booking_time]);


const recalculateCosts = () => {

 
    if (!isLoading && booking_time && customerById.game_type_id) {


        const filteredGameType = game_types.filter(item => item.id === Number(customerById.game_type_id));
        const adultsFirstPrice = filteredGameType[0].adults_first_price;
        const adultsSecondPrice = filteredGameType[0].adults_second_price;
        const adultsThirdPrice = filteredGameType[0].adults_third_price;
        const depositMethod = filteredGameType[0].deposit_method;
        const depositAmountPerPerson = parseFloat(filteredGameType[0].deposit_amount_per_person).toFixed(2);
        const depositPercentage = parseFloat(filteredGameType[0].deposit_percentage).toFixed(2);
        const minPlayers = filteredGameType[0].min_players;
        const maxPlayers = filteredGameType[0].max_players;
      
      setMinPlayers(minPlayers);
      setMaxPlayers(maxPlayers);

        let totalPrice = 0;
        let slotDetails = {};
      const totalPlayers = Number(kids_count) + Number(adults_count);
     
        if (booking_time.length === 1) {
            totalPrice = adultsFirstPrice * kids_count +
            adultsFirstPrice * adults_count;
         
            slotDetails[booking_time[0]] = {
                time_slot: booking_time[0],
                price_for_slot: adultsFirstPrice.toFixed(2),
                total_players: totalPlayers,
                row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
            };


        } else if (booking_time.length === 2) {
            totalPrice = (adultsFirstPrice * kids_count +
                adultsFirstPrice * adults_count) +
                (adultsSecondPrice * kids_count +
                    adultsSecondPrice* adults_count);

                    
        slotDetails[booking_time[0]] = {
            time_slot: booking_time[0],
            price_for_slot: adultsFirstPrice.toFixed(2),
            total_players: totalPlayers,
            row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
        };

        slotDetails[booking_time[1]] = {
            time_slot: booking_time[1],
            price_for_slot: adultsSecondPrice.toFixed(2),
            total_players: totalPlayers,
            row_total: (adultsSecondPrice * totalPlayers).toFixed(2)
        };
                
        } else if (booking_time.length >= 3) {
            const additionalSlots = booking_time.length - 2;
            totalPrice = (adultsFirstPrice * kids_count +
                adultsFirstPrice * adults_count) +
                (adultsSecondPrice * kids_count +
                    adultsSecondPrice * adults_count) +
                (additionalSlots * (adultsThirdPrice * kids_count +
                    adultsThirdPrice * adults_count));


                    slotDetails[booking_time[0]] = {
                        time_slot: booking_time[0],
                        price_for_slot: adultsFirstPrice.toFixed(2),
                        total_players: totalPlayers,
                        row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
                    };
        
                    slotDetails[booking_time[1]] = {
                        time_slot: booking_time[1],
                        price_for_slot: adultsSecondPrice.toFixed(2),
                        total_players: totalPlayers,
                        row_total: (adultsSecondPrice * totalPlayers).toFixed(2)
                    };
        
                    for (let i = 2; i < booking_time.length; i++) {
                        const priceForSlot = adultsThirdPrice;
                        const rowTotal = priceForSlot * totalPlayers;
        
                        slotDetails[booking_time[i]] = {
                            time_slot: booking_time[i],
                            price_for_slot: priceForSlot.toFixed(2),
                            total_players: totalPlayers,
                            row_total: rowTotal.toFixed(2)
                        };
                    }
                }
        
                setCart_details(slotDetails);
  

        setTimeSlotDetails((prev) => ({ ...prev, price: totalPrice }));
        setSub_total(totalPrice);

        const hst = (totalPrice * 13) / 100;
         setHst(hst);

        // Calculate grand total
        const grandTotal = totalPrice + hst;
        const formattedGrandTotal = parseFloat(grandTotal.toFixed(2));

        setGrand_total(formattedGrandTotal);

            // Update customerById state with the new kids_count value
            setCustomerById(prevCustomer => ({
                ...prevCustomer,
                subtotal: totalPrice,
                grand_total: formattedGrandTotal,
            }));



          // Calculate the deposit amount
    let depositAmount = 0;
    if (depositMethod === 1) { // Amount per person
        depositAmount = depositAmountPerPerson * (Number(kids_count) + Number(adults_count))* booking_time.length ;
    } else if (depositMethod === 2) { // Percentage
        depositAmount = (totalPrice * depositPercentage) / 100;
    }
    setDeposit_amount(depositAmount);
    setAmount_paid(depositAmount);

   


    let depositDetails = '';
    if(depositMethod === 1)
    {
      depositDetails = 'Deposit amount per person : ' +depositAmountPerPerson;
      setDeposit_details(depositDetails);
    
    }
    else if (depositMethod === 2)
    {
       depositDetails = 'Deposit amount percentage : ' +depositPercentage;
       setDeposit_details(depositDetails);

    }


    }
    

}


const recalculateBirthdayCosts = () => {
    
    if(!isLoading && booking_time  && customerById.package_name)
    {
   
        const filteredPackage = game_packages.filter(item => item.id === Number(customerById.sub_package_id));
        const birthdayPrice = filteredPackage[0].price_per_game;
        const basicPrice= filteredPackage[0].basic_price;
        const depositAmt= filteredPackage[0].deposit_amount;

        let totalPrice = basicPrice;
        let slotDetails = {};
        const totalPlayers =  Number(kids_count) + Number(adults_count);

        const totalPlayersExcludingBirthdayKid = totalPlayers > 1 ? totalPlayers - 1 : 0; // Ensure the total doesn't go below 0
       
        const totalSlots = booking_time.length;
        const freeGames = 2; // Number of free games

        // Calculate the number of paid slots
        const paidSlots = totalSlots > freeGames ? totalSlots - freeGames : 0;
        const totalPriceForPaidSlots = birthdayPrice * totalPlayersExcludingBirthdayKid * paidSlots;

        totalPrice += totalPriceForPaidSlots; // Add the calculated price

        // Populate slot details
        for (let i = 0; i < totalSlots; i++) {
            const timeSlot = booking_time[i];
            slotDetails[timeSlot] = {
                time_slot: timeSlot,
                price_for_slot: i < freeGames ? 0 : birthdayPrice.toFixed(2),
                total_players: totalPlayersExcludingBirthdayKid,
                row_total: i < freeGames ? '0.00' : (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            };
        }

                    setCart_details(slotDetails);
        setBirthdayPrice((prev) => ({ ...prev, price: totalPrice }));
        setSub_total(totalPrice);

    const hst = (totalPrice * 13) / 100;
    setHst(hst);

    const grandTotal = totalPrice + hst;
    const formattedGrandTotal = parseFloat(grandTotal.toFixed(2));


    setGrand_total(formattedGrandTotal);

    // Update customerById state with the new kids_count value
    setCustomerById(prevCustomer => ({
        ...prevCustomer,
        subtotal: totalPrice,
        grand_total: formattedGrandTotal,
        game_count: paidSlots
    }));


    setDeposit_amount(depositAmt);


    setAmount_paid(depositAmt);
 
    setBasic_price(basicPrice);

        if(customerById.package_name && birthday_packages.min_games)  
    { 
        setMin_game(birthday_packages.min_games)
    }

    let depositDetails = '';
      depositDetails = 'Package basic price : ' +depositAmt;
      setDeposit_details(depositDetails);


    }
}


//   useEffect(() => {
//     // Function to fetch available booking times based on selected date
//     const fetchAvailableBookingTimes = async () => {
//         try {
//             const response = await axios.get(`${API_URL}/booked-appointments-game`, {
//                 params: { selectedDate: booking_date }
//             });
//             setAvailableBookingTimes(response.data);
//             console.log(response.data);
//         } catch (error) {
//             console.error('Error fetching available booking times:', error);
//         }
//     };

//     // Call the function when booking_date changes
//     if (booking_date) {
//         fetchAvailableBookingTimes();
//     }
// }, [booking_date]);









// function getTimeSlotList()
// {
  
//   axios.get(`${API_URL}/view-time-slot`).then(res=>{
//       if(res.data.status === 200){
//         setTimeSlotById(res.data.timeslot[0]);   
//         const totalPrice = (kids_count * res.data.timeslot[0].kids_price) + (adults_count * res.data.timeslot[0].adults_price);
//         setTimeSlotById({ ...timeSlotById, price: totalPrice }); 
//          const { start_time, end_time, time_interval } = res.data.timeslot[0];

  
//           setBookingAvailableTimeSlots(calculateTimeSlots(start_time, end_time, parseInt(time_interval, 10)));
        
//           //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
//       }     
//       else if(res.data.status === 404){
//        // setTime_slot([]);
//        setBookingAvailableTimeSlots([]);  
//           }
//           setIsLoading(false);
//    });  
 
// }
const handleEmailToggle = () => {
    setIsEmail(!isEmail); // Toggle the isEmail state
};



const calculateTimeSlots = (startTime, endTime, interval) => {
    const timeSlots = [];
    const [startHour, startMinute, startSecond] = startTime.split(':').map(Number);
    const [endHour, endMinute, endSecond] = endTime.split(':').map(Number);

    let current = new Date();
    current.setHours(startHour, startMinute, startSecond, 0);

    const end = new Date();
    end.setHours(endHour, endMinute, endSecond, 0);

    while (current <= end) {
      const timeSlot = current.toTimeString().split(' ')[0]; // 'HH:mm:ss' format
      timeSlots.push(timeSlot);
      current.setMinutes(current.getMinutes() + interval);
    }

    return timeSlots;
  };


// function getCustomerList() {
//     return axios.get(`${API_URL}/view-customer`)
//         .then(res => {
//             if (res.data.status === 200) {
//                 const customer = res.data.customer;
//                 setCustomer(res.data.customer); 
                
//                 const mergedArrayCustomer = res.data.customer.map(section => {
//                     const inquiry = res.data.inquiry.filter(item => item.customer_id === section.id);
//                     const emailLogs = res.data.emailLogs.filter(item => item.customer_id === section.id);
//                     const noteLogs = res.data.noteLogs.filter(item => item.customer_id === section.id);
//                     const meetLogs = res.data.meetLogs.filter(item => item.customer_id === section.id);
//                     const uploadDocuments1 = res.data.uploadDocuments.filter(item => item.customer_id === section.id);
//                     const bookingStouffville = res.data.booking.filter(item => item.customer_id === section.id);
//                     const bookingScarborough = res.data.bookingScarborough.filter(item => item.customer_id === section.id);
//                     const booking = [...bookingStouffville, ...bookingScarborough];

//                     const allLogs = [...inquiry, ...emailLogs, ...noteLogs, ...meetLogs, ...uploadDocuments1, ...booking];
                    
//                     let latestActivity = null;
//                     if (allLogs.length > 0) {
//                         allLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
//                         latestActivity = allLogs[0];
//                     }
              
//                     return { ...section, inquiry, emailLogs, noteLogs, meetLogs, uploadDocuments1,booking, latestActivity };
//                 });
//                 const customerWithMostRecentActivity = mergedArrayCustomer.reduce((mostRecent, customer) => {
//                     if (customer.latestActivity && (!mostRecent.latestActivity || new Date(customer.latestActivity.created_at) > new Date(mostRecent.latestActivity.created_at))) {
//                         return customer;
//                     }
//                     return mostRecent;
//                 }, { latestActivity: null });
                
//                 if (customerWithMostRecentActivity.latestActivity) {
//                   getCustomerById(customerWithMostRecentActivity.id);
//                   navigate(`/leads-details/${customerWithMostRecentActivity.id}`);
//                 } 
            

//             } else {
//                 setIsLoading(false);
//                 throw new Error('Failed to fetch customer list');
//             }
//         })
//         .catch(error => {
//             setIsLoading(false);
//             throw new Error('Error loading customer data:', error);
            
//         });
      
// }


    function getEmailTemplateList()
    {    
      axios.get(`${API_URL}/view-email-template`).then(res=>{
          if(res.data.status === 200){
              setEmail_template(res.data.emailTemplate);       
          }     
          else if(res.data.status === 404){
            setEmail_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getSmsTemplateList()
    {    
      axios.get(`${API_URL}/view-sms-template`).then(res=>{
          if(res.data.status === 200){
              setSms_template(res.data.smsTemplate);       
          }     
          else if(res.data.status === 404){
            setSms_template([]);
             // setMessage(res.data.message);     
              }             
       }); 
       
       
    }

    function getNoteTemplateList()
    {    
      axios.get(`${API_URL}/view-note-template`).then(res=>{
          if(res.data.status === 200){
              setNote_template(res.data.noteTemplate);       
          }     
          else if(res.data.status === 404){
            setNote_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getTeamList()
    {    
     
    axios.get(`${API_URL}/view-team-members`).then(res=>{
          if(res.data.status === 200){
              setTeam(res.data.team);       
          }     
          else if(res.data.status === 404){
            setTeam([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getUploadTemplateList()
    {    
      axios.get(`${API_URL}/view-upload-template`).then(res=>{
          if(res.data.status === 200){
              setUpload_template(res.data.uploadTemplate);       
          }     
          else if(res.data.status === 404){
            setUpload_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getBookingList()
    {    
     
    axios.get(`${API_URL}/view-booking`).then(res=>{
          if(res.data.status === 200){
              setBooking(res.data.booking); 
             
          }     
          else if(res.data.status === 404){
            setBooking([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getScarboroughBookingList()
    {    
     
    axios.get(`${API_URL}/view-booking-scarborough`).then(res=>{
          if(res.data.status === 200){
              setBookingScarborough(res.data.bookingScarborough); 
          }     
          else if(res.data.status === 404){
            setBookingScarborough([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

    function getPinTemplateList()
    {    
      axios.get(`${API_URL}/view-pin-template`).then(res=>{
          if(res.data.status === 200){
              setPin_template(res.data.pinTemplate);       
          }     
          else if(res.data.status === 404){
            setPin_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }
   

    // const formatBookedTime = (bookedTime) => {
    //     try {
    //       const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
    //       if (Array.isArray(timeSlots) && timeSlots.length > 0) {
    //         const startTime = timeSlots[0].split(' - ')[0];
    //         const endTime = timeSlots[timeSlots.length - 1].split(' - ')[1];
    //         // Convert to moment objects and format them
    //         const formattedStartTime = moment(startTime, 'hh:mm A').format('h:mm A');
    //         const formattedEndTime = moment(endTime, 'hh:mm A').format('h:mm A');
    //         return `${formattedStartTime} - ${formattedEndTime}`;
    //       }
    //       return 'No time slots available';
    //     } catch (error) {
    //       console.error("Error parsing booked time:", error);
    //       return 'Invalid time format';
    //     }
    //   };


    const formatBookedTime = (bookedTime) => {
        try {
          const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
          if (Array.isArray(timeSlots) && timeSlots.length > 0) {
            let mergedSlots = [];
            let currentRangeStart = null;
            let currentRangeEnd = null;
      
            timeSlots.forEach((slot, index) => {
              const [startTime, endTime] = slot.split(' - ');
              const currentStartTime = moment(startTime, 'hh:mm A');
              const currentEndTime = moment(endTime, 'hh:mm A');
      
              if (!currentRangeStart) {
                // First time slot, initialize the range
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              } else if (currentRangeEnd.isSame(currentStartTime)) {
                // If the current slot starts when the last one ended, extend the range
                currentRangeEnd = currentEndTime;
              } else {
                // If the current slot does not continue from the last one, close the current range and start a new one
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              }
      
              // If it's the last slot, push the current range
              if (index === timeSlots.length - 1) {
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
              }
            });
      
            return mergedSlots.join(', '); // Join the merged slots with a comma
          }
          return 'No time slots available';
        } catch (error) {
          console.error("Error parsing booked time:", error);
          return 'Invalid time format';
        }
      };



    function getCustomerById(id){

        setIsLoading(true);
        axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
            if(res.data.status === 200){
                const bookedSlot = res.data.bookedSlot;
                setCustomerById(res.data.bookedSlot); 
                setPayment_amount((bookedSlot.grand_total - bookedSlot.amount_paid).toFixed(2));

      const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);

      setCustomerById({
          ...bookedSlot,
          booked_time_formatted: formattedBookedTime,
          booking_time: bookedSlot.booked_time,
          consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : [],
          kids_form_details: bookedSlot.kids_form_details ? JSON.parse(bookedSlot.kids_form_details) : []
      
        });


         console.log(bookedSlot );
          setSub_total(bookedSlot.subtotal);
          setGrand_total(bookedSlot.grand_total);
          setBooking_time(JSON.parse(bookedSlot.booked_time));
           setAdults_count(bookedSlot.no_of_adults);   
           setKids_count(bookedSlot.no_of_children);   
          setCustomerBookedHistory(res.data.customerBookedHistory);
          setEmailLogById(res.data.emailLogs);
          setSmsLogById(res.data.smsLogs);
          setNoteLogById(res.data.sortedLogs);
          setPaymentHistory(res.data.payments);
        //   if(res.data.transactions)
        //   {
            setTransactions(res.data.transactions);
        //   }
       
        //  console.log(res.data.sortedLogs)

            }
            else if(res.data.status === 404){
                //setMessage(res.data.message);
             
                //setIsLoading(false);
                }
          
                let defaultTab = 'info';
        
                //          if (inquiry.includes(latestActivity)) {
                //             defaultTab = 'inquiry';
                //          } else if (emailLogs.includes(latestActivity)) {
                //              defaultTab = 'email';
                //          } else if (smsLogs.includes(latestActivity)) {
                //              defaultTab = 'sms';
                //          } else if (noteLogs.includes(latestActivity)) {
                //              defaultTab = 'note';
                //          } else if (meetLogs.includes(latestActivity)) {
                //              defaultTab = 'meet';
                //          } else if (uploadDocuments.includes(latestActivity)) {
                //              defaultTab = 'upload';
                //          } else if (booking.includes(latestActivity)) {
                //             defaultTab = 'booking';
                //         }
            
              setActiveButton(defaultTab);
      
            //   console.log(activeButton);
            setIsLoading(false);
         });


      
        }

function handleChange(event) {
    const { name, value } = event.target;

    // Update the state based on the input field
    setCustomerById(prevState => ({
        ...prevState,
        [name]: value
    }));
}

        useEffect(() => {
            // Optional: Perform actions when `activeButton` changes
            setActiveButton(activeButton);
         
        }, [activeButton]);

    // function getCustomerById(id){

      
    //     resetVehicleAnalyse();
    //       axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
    //         const { customer, inquiry, emailLogs, smsLogs, noteLogs, meetLogs, uploadDocuments, booking } = res.data;

    //          setCustomerById(res.data.customer);
    //          setEmail(res.data.customer[0].email);
    //          setFirst_name(res.data.customer[0].first_name);
    //          setPhone_no(res.data.customer[0].phone_no);
    //          let assign_to = [];
    //          if(res.data.customer[0].assign_to) {
    //         try {
    //             assign_to = JSON.parse(res.data.customer[0].assign_to);
        
    //             // console.log(assign_to);
    //         } catch (error) {
    //             console.error("Error parsing assign_to:", error);
    //         }
    //        }
    //          setTeam_select(assign_to);
    //          setInquiryById(res.data.inquiry);
    //          setEmailLogById(res.data.emailLogs);
    //          setSmsLogById(res.data.smsLogs);
    //          setNoteLogById(res.data.noteLogs);
    //          setMeetLogById(res.data.meetLogs);
    //          setUploadLogById(res.data.uploadDocuments);
    //         // setBookingById(res.data.booking);
    //         setBookingById([...res.data.booking, ...res.data.bookingScarborough]);
    //         console.log([...res.data.booking, ...res.data.bookingScarborough]);

    //          const allLogs = [...inquiry, ...emailLogs, ...smsLogs, ...noteLogs, ...meetLogs, ...uploadDocuments, ...booking];
    //          if (allLogs.length > 0) {
    //          allLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    //          const latestActivity = allLogs[0];
     
    //          let defaultTab = '';
    //          if (inquiry.includes(latestActivity)) {
    //             defaultTab = 'inquiry';
    //          } else if (emailLogs.includes(latestActivity)) {
    //              defaultTab = 'email';
    //          } else if (smsLogs.includes(latestActivity)) {
    //              defaultTab = 'sms';
    //          } else if (noteLogs.includes(latestActivity)) {
    //              defaultTab = 'note';
    //          } else if (meetLogs.includes(latestActivity)) {
    //              defaultTab = 'meet';
    //          } else if (uploadDocuments.includes(latestActivity)) {
    //              defaultTab = 'upload';
    //          } else if (booking.includes(latestActivity)) {
    //             defaultTab = 'booking';
    //         }

    //          setActiveButton(defaultTab);
    //     setIsSearch(false);
    //     setSettingsVisible(defaultTab === 'settings');
    //     setInqueryVisible(defaultTab === 'inquiry');
    //     setInqVisible(defaultTab === 'inq');
    //     setCallVisible(defaultTab === 'call');
    //     setEmailVisible(defaultTab === 'email');
    //     setTextVisible(defaultTab === 'sms');
    //     setPinVisible(defaultTab === 'pin');
    //     setMeetVisible(defaultTab === 'meet');
    //     setNoteVisible(defaultTab === 'note');
    //     setUploadVisible(defaultTab === 'upload');
    //     setBookingVisible(defaultTab === 'booking');
    //         }
    //         else {
    //             // If no activity logs are present, default to the inquiry tab
    //             setActiveButton(' ');
    //             setIsSearch(false);
    //             setInqueryVisible(true);
    //             setSettingsVisible(false);
    //             setInqVisible(false);
    //             setCallVisible(false);
    //             setEmailVisible(false);
    //             setTextVisible(false);
    //             setPinVisible(false);
    //             setMeetVisible(false);
    //             setNoteVisible(false);
    //             setUploadVisible(false);
    //             setBookingVisible(false);
    //         }
    //     setIsUploadImageLoading(true);
    //     setIsPhotoLoading(true);
    //        setIsLoading(false);
    //       resetBooking();
    //         });          
      
    //     }

    function getEmaillogbyId(id){
        axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
            const bookedSlot = res.data.bookedSlot;
       
      const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);

      setCustomerById({
          ...bookedSlot,
          booked_time_formatted: formattedBookedTime,
          consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : [],
          kids_form_details: bookedSlot.kids_form_details ? JSON.parse(bookedSlot.kids_form_details) : []
      
        });
            //setEmail(res.data.customer[0].email);
            // setInquiryById(res.data.inquiry);
            setEmailLogById(res.data.emailLogs);
            // setIsSearch(false);
            // setInqueryVisible(false);
            // setSettingsVisible(false);
            // setInqVisible(false);
            setInfoVisible(false);
            setHistoryVisible(false);
            setCallVisible(false);
            setEmailVisible(true);
            setTextVisible(false);
            // setPinVisible(false);
            // setMeetVisible(false);
            // setNoteVisible(false);
            // setUploadVisible(false);
        });  
 
    }
    

    function getSmslogbyId(id){

        // axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{

            axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
                const bookedSlot = res.data.bookedSlot;
           
          const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);
    
          setCustomerById({
              ...bookedSlot,
              booked_time_formatted: formattedBookedTime,
              consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : [],
              kids_form_details: bookedSlot.kids_form_details ? JSON.parse(bookedSlot.kids_form_details) : []
          
            });


           // setCustomerById(res.data.customer);
            setSmsLogById(res.data.smsLogs);
            // setIsSearch(false);
            // setInqueryVisible(false);
            // setInqVisible(false);
            // setSettingsVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(true);
            // setPinVisible(false);
            // setMeetVisible(false);
            setNoteVisible(false);
            // setUploadVisible(false);

        });  

    }

    function getMeetlogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setMeetLogById(res.data.meetLogs);
            setIsSearch(false);
            setInqueryVisible(false);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(true);
            setNoteVisible(false);
            setUploadVisible(false);
        });  
 
    }
     
    function getNotelogbyId(id){
        axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
                const bookedSlot = res.data.bookedSlot;
           
          const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);
    
          setCustomerById({
              ...bookedSlot,
              booked_time_formatted: formattedBookedTime,
              consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : [],
              kids_form_details: bookedSlot.kids_form_details ? JSON.parse(bookedSlot.kids_form_details) : []
          
            });
            // setCustomerById(res.data.customer);
            setNoteLogById(res.data.sortedLogs);
            // console.log(res.data.sortedLogs)
            // setIsSearch(false);
            // setInqueryVisible(false);
            // setSettingsVisible(false);
            // setInqVisible(false);
            setInfoVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            // setPinVisible(false);
            // setMeetVisible(false);
            setNoteVisible(true);
            // setUploadVisible(false);
        });  
 
    }

    function getUploadlogbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setUploadLogById(res.data.uploadDocuments);
            setIsSearch(false);
            setInqueryVisible(false);
            setInqVisible(false);
            setSettingsVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(true);
        });  
 
    }
        


    function getBookingbyId(id){
        axios.get(`${API_URL}/view-customer-by-id/`+id).then(res=>{
            setCustomerById(res.data.customer);
            setBookingById(res.data.booking);
            setIsSearch(false);
            setInqueryVisible(false);
            setSettingsVisible(false);
            setInqVisible(false);
            setCallVisible(false);
            setEmailVisible(false);
            setTextVisible(false);
            setPinVisible(false);
            setMeetVisible(false);
            setNoteVisible(false);
            setUploadVisible(false);
            setBookingVisible(true);
        });  
 
    }


    function getInventoryList(){
 
        axios.get(`${API_URL}/view-inventory`).then(res=>{
            if(res.data.status === 200){
              const inventory = res.data.inventorySection;
                setInventory(res.data.inventorySection);
                //setInventory(res.data.localInventory);
                

                const mergedArray =    res.data.inventorySection.map(section => {
                    const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                    const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                    const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
                    // const count = (inventoryImages.filepath_compressed.split(',').length) + (coverImage.filepath.split(',').length)
                    return { ...section, coverImage, price };    
                 
                  })

                  setMergedArray(res.data.inventorySection.map(section => {
                    const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                    const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                    const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
                    // const count = (inventoryImages.filepath_compressed.split(',').length) + (coverImage.filepath.split(',').length)
                 
                    return { ...section, coverImage, price, inventoryImages };    
                 
                  }))   

                const selectedInventory = inventory.filter((row) => row.global_inventory === "false");
                const uniqueMake = [...new Set(selectedInventory.map(row => row.brand.toLowerCase()))]
                .map(make => make.charAt(0).toUpperCase() + make.slice(1));
                setMakeOptions(uniqueMake ? uniqueMake : []);

                const filteredInventory = mergedArray.filter((item) => item.global_inventory === "false");
                setFilteredInventory(filteredInventory);
               
 
            }
            else if(res.data.status === 404){
              //  setMessage(res.data.message);
                }
                
           
         });
           
        }

    
    const handleInventoryTypeChange  = (selectedOptions) => {
        setSelectedInventoryType(selectedOptions.value);
        if (selectedOptions.value === 'all') {
            const uniqueMake = [...new Set(inventory.map(row => row.brand.toLowerCase()))]
            .map(make => make.charAt(0).toUpperCase() + make.slice(1));       
            setDisableMakeOptions(false); 
            setModelOptions([]);
            setMakeOptions(uniqueMake ? uniqueMake : []);
            setSelectedMakes([]);
            setSelectedModels([]);

          } else {
            const selectedInventory = inventory.filter((row) => row.global_inventory === selectedOptions.value);

            const uniqueMake = [...new Set(selectedInventory.map(row => row.brand.toLowerCase()))]
      .map(make => make.charAt(0).toUpperCase() + make.slice(1));
      
         
           setDisableMakeOptions(false);
           setModelOptions([]);
             setMakeOptions(uniqueMake ? uniqueMake : []);
             setSelectedMakes([]);
             setSelectedModels([]);
         
          }
        
};
   

const handleMakeChange = (selectedOptions) => {
    const selectedMakeValues = selectedOptions.map(option => option.value);
    setSelectedMakes(selectedMakeValues);
    const filteredModels = filteredInventory.filter(row => selectedMakeValues.includes(row.brand))
      .map(row => row.model_name.toLowerCase());
    const uniqueModels = [...new Set(filteredModels)];
    const formattedUniqueModels = uniqueModels.map(model => model.charAt(0).toUpperCase() + model.slice(1));
    setModelOptions(formattedUniqueModels);


//   // Filter body styles based on selected make
//   const filteredBodyStyles = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.body_type.toLowerCase());
//   const uniqueBodyStyles = [...new Set(filteredBodyStyles)];
//   setBodyStyleOptions(uniqueBodyStyles);

//   // Filter engines based on selected make
//   const filteredEngines = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.engine_type.toLowerCase());
//   const uniqueEngines = [...new Set(filteredEngines)];
//   setEngineOptions(uniqueEngines);

//   // Filter exterior colors based on selected make
//   const filteredExteriorColors = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.exterior_color.toLowerCase());
//   const uniqueExteriorColors = [...new Set(filteredExteriorColors)];
//   setExteriorColorOptions(uniqueExteriorColors);

//   // Filter transmissions based on selected make
//   const filteredTransmissions = filteredInventory
//     .filter(row => selectedMakeValues.includes(row.brand))
//     .map(row => row.transmission.toLowerCase());
//   const uniqueTransmissions = [...new Set(filteredTransmissions)];
//   setTransmissionOptions(uniqueTransmissions);

//   const filteredDriveType = filteredInventory
//   .filter(row => selectedMakeValues.includes(row.brand))
//   .map(row => row.drive_type.toLowerCase());
// const uniqueDriveType = [...new Set(filteredDriveType)];
// setDriveTypeOptions(uniqueDriveType);


// const filteredDoors = filteredInventory
// .filter(row => selectedMakeValues.includes(row.brand))
// .map(row => row.door.toLowerCase());
// const uniqueDoors = [...new Set(filteredDoors)];
// setDoorsOptions(uniqueDoors);

// const filteredStockNo = filteredInventory
// .filter(row => selectedMakeValues.includes(row.brand))
// .map(row => row.stock_no);
// const uniqueStockNo = [...new Set(filteredStockNo)];
// setStockNoOptions(uniqueStockNo);

    
  };

  const handleModelChange = (selectedOptions) => {
    const selectedModelValues = selectedOptions.map(option => option.value);
    setSelectedModels(selectedModelValues);
  
  };

  const handleBodyStyleChange = (selectedOptions) => {
    const selectedBodyStyleValues = selectedOptions.map(option => option.value);
    setSelectedBodyStyles(selectedBodyStyleValues);
  

  };


  const handleEngineChange = (selectedOptions) => {
    const selectedEngineValues = selectedOptions.map(option => option.value);
    setSelectedEngines(selectedEngineValues);
  };

  const handleExteriorColorChange = (selectedOptions) => {
    const selectedExteriorColorValues = selectedOptions.map(option => option.value);
    setSelectedExteriorColors(selectedExteriorColorValues);
   
  };

  const handleTransmissionChange = (selectedOptions) => {
    const selectedTransmissionValues = selectedOptions.map(option => option.value);
    setSelectedTransmissions(selectedTransmissionValues);
  };

  const handleDriveTypeChange = (selectedOptions) => {
    const selectedDriveTypeValues = selectedOptions.map(option => option.value);
    setSelectedDriveType(selectedDriveTypeValues);
  };

  const handleDoorChange = (selectedOptions) => {
    const selectedDoorValues = selectedOptions.map(option => option.value);
    setSelectedDoors(selectedDoorValues);
  };

  const handleStockNoChange = (selectedOptions) => {
    const selectedStockNo = selectedOptions.map(option => option.value);
    setSelectedStockNo(selectedStockNo);
  };


  const search = (event) => {
    event.preventDefault();
    setLoading(true);

    let hasError = false;

    // Validate the make field
    if (selectedMakes.length === 0) {
        setMakeError("The make field is required");
        hasError = true;
      
    } else {
        setMakeError("");
    }

    // Validate the model field
    // if (selectedModels.length === 0) {
    //     setModelError("The model field is required");
    //     hasError = true;
    // } else {
    //     setModelError("");
    // }

    // If there are errors, stop further execution
    if (hasError) {
        setLoading(false);
        return;
    }


    setFilteredResults([]);
    setIsSearch(true);
    setInqueryVisible(false);

    //   if (selectedInventoryType === "") {
    //     document.getElementById("errorMessage").style.display = "block";
    //   }
    //   else{
    //     document.getElementById("errorMessage").style.display = "none";
    //   }
   
 // console.log(mergedArray);
  const filteredInventory = mergedArray.filter((item) => {
        return (
          (selectedInventoryType === 'all' || item.global_inventory === selectedInventoryType) &&
          (!selectedMakes.length || selectedMakes.includes(item.brand) || 
          (selectedMakes.includes(item.brand) && selectedModels.includes(item.model_name)) )&&
          (!minPrice || item.price.sale_price >= minPrice) &&
          (!maxPrice || item.price.sale_price <= maxPrice) &&
          (!minKilometer || item.odometer >= minKilometer) &&
          (!maxKilometer || item.odometer <= maxKilometer)
        );
      })
      
    setFilteredInventory(filteredInventory);
    //console.log(filteredInventory);
     
    var myModalEl = document.getElementById('exampleModalToggle');
    myModalEl.classList.remove('show');

    // Remove the modal-backdrop element
    var backdropEl = document.getElementsByClassName('modal-backdrop')[0];
    if (backdropEl) {
        backdropEl.remove();
    }
    
    // Reset the modal state
    myModalEl.style.display = 'none';
    myModalEl.setAttribute('aria-hidden', 'true');
    myModalEl.setAttribute('aria-modal', 'false');
    myModalEl.removeAttribute('role');
    myModalEl.removeAttribute('aria-labelledby');
    myModalEl.removeAttribute('tabindex');
    
    setLoading(false);
  }


  useEffect(() => {
   // const count = filteredResult.length;
   //setRecordCount(count);
 }, [filteredInventory]);  
 

 function resetVehicleAnalyse(){
    setSelectedMakes([]);
setSelectedModels([]);
    setMinPrice("");
    setMaxPrice("");
setMinKilometer("");
setMaxKilometer("");
setMakeError("");

 } const handleSelectAllTeam = (event) => {
    if (event.target.checked) {
      const allTeamIds = team.map(member => member.id.toString()); // Convert all ids to strings for comparison
      setTeam_select(allTeamIds);
    } else {
      setTeam_select([]);
    }
  };
 

 const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const allItemIds = filteredInventory.map((item) => item.id);
    if (checked) {
      setSelectedRows(allItemIds);
   //console.log(allItemIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (e, id) => {
      const checked = e.target.checked;
      let updatedSelectedRows;
    
      if (checked) {
        updatedSelectedRows = [...selectedRows, id];
      } else {
        updatedSelectedRows = selectedRows.filter((row) => row !== id);
      }
    
      setSelectedRows(updatedSelectedRows);
  
      const allRowsSelected = filteredInventory.every((item) => updatedSelectedRows.includes(item.id));
  
      selectAllCheckboxRef.current.checked = allRowsSelected; // Assuming you have a ref to the "Select All" checkbox
    };
  

  const handleGenerate = () => {
   
//  console.log(customerById[0].phone_no)
     if(selectedRows.length){
        setLoading(true);
        const queryParams = selectedRows.join(",");
        const makesQueryParam = selectedMakes && selectedMakes.length > 0 ? `selectedMakes=${selectedMakes.join(",")}` : "";
        const modelsQueryParam = selectedModels && selectedModels.length > 0 ? `selectedModels=${selectedModels.join(",")}` : "";
        const queryString = [queryParams, makesQueryParam, modelsQueryParam].filter(queryParam => queryParam !== "").join("&");
        window.location.href = `/vehicle-list?selectedRows=${queryString}&email=${customerById[0].email}&phoneNo=${encodeURIComponent(customerById[0].phone_no)}`;

       //navigate(`/vehicle-list?selectedRows=${queryString}&email=${customerById[0].email}&phoneNo=${encodeURIComponent(customerById[0].phone_no)}`);

    }
     else
     alert("Please select a vehicle");

   
};

async function saveInquiry(event)
  {
    event.preventDefault();
    setLoading(true);
   
    const formData = new FormData();

    formData.append('id', id)
    formData.append('vehicle_name', vehicle_name)
    formData.append('selectedMakes', selectedMakes)
    formData.append('selectedModels', selectedModels)
    formData.append('minPrice', minPrice)
    formData.append('maxPrice', maxPrice)
    formData.append('minKilometer', minKilometer)
    formData.append('maxKilometer', maxKilometer)
    formData.append('selectedBodyStyles', selectedBodyStyles)
    formData.append('selectedEngines', selectedEngines)
    formData.append('selectedExteriorColors', selectedExteriorColors)
    formData.append('selectedTransmissions', selectedTransmissions)
    formData.append('selectedDriveType', selectedDriveType)
    formData.append('selectedDoors', selectedDoors)
    formData.append('selectedStockNo', selectedStockNo)
    formData.append('description', description)
 
   await axios.post(`${API_URL}/save-inquiry`, formData).then(res =>{
         
    Swal.fire({
      icon:"success",
      text:res.data.message
    }).then(function(){    
        setLoading(false);     
         resetData();
         setErrorList([]);
         getCustomerById(id);
         
  });
  }).catch(function(error) {
    setLoading(false);   
    // if(error.response.status===422){         
   setErrorList(error.response.data.validate_err);
    // }
    // else{
      Swal.fire({
        text:error.response.data.message,
        icon:"error"
      })
    // }
  })
}

const resetData = () => {
   setVehicle_name("");
   setSelectedMakes([]);
   setSelectedModels([]);
   setMinPrice("");
   setMaxPrice("");
   setMinKilometer("");
   setMaxKilometer("");
   setSelectedBodyStyles([]);
   setSelectedEngines([]);
   setSelectedExteriorColors([]);
   setSelectedTransmissions([]);
   setSelectedDriveType([]);
   setSelectedDoors([]);
   setDescription("");
   
    };

    const resetEmail = () => {
        setTemplate([]);
        setSubject("");
        setMessage("");
        setImages([]);
        setVideos([]);
        setPreviews([]);
        setPreviewsVideo([]);
        setSuccessMessage("");
        setLoading(false);
         };

         const resetMeeting = () => {
            setTitle("");
            setDate("");
            setTime("");
             };
    


             const resetBooking = () => {
                setGame_location("");
                setGame_type("");
                setBooking_date("");
                setBooking_time("");
                setKids_count(0);
                setAdults_count(0);
                setTimeSlotById((prev) => ({ ...prev, price: 0 }));
                 };




             const resetNote = () => {
                setNote_template_select("");
                setNote_subject("");
                setNote_message("");
                setIsEmail(false);  
                 };

                 const resetPayment = () => {
                    setPayment_method("");
            
                     };

                 const resetUpload = () => {
                    setUpload_template_select("");
                    setUpload_subject("");
                    setUpload_message("");
                    setImages([]);
                    setPreviews([]);
                     };

         const getFileType = (url) => {
            const extension = url.split('.').pop().toLowerCase();
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    case 'webp':
                    return 'image';
                case 'pdf':
                    return 'pdf';
                case 'mp4':
                case 'avi':
                case 'mov':
                    return 'video';
                // Add more file types as needed
                default:
                    return 'unknown';
            }
        };
   
    const handleTemplateChange  = (event) => {
        setTemplate(event.target.value);
        if(event.target.value){
            const template_details = email_template.find(item=> item.template_name === event.target.value);
            setTemplate_details(template_details);
            setSubject(template_details.subject);
            setMessage(template_details.message);
            if (template_details.image_path) {

                const filepathArray = template_details.image_path.split(',');
                const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
                setPreviews(imageUrlArray);
    
                const initialPreviewsPromises = imageUrlArray.map(async filepath => {
                    const filename = filepath.substring(filepath.lastIndexOf('/') + 1); // Extract filename from URL
                    const fileType = filename.split('.').pop().toLowerCase(); // Extract file extension and convert to lowercase
                
                    if (fileType === 'pdf') {
                        // For PDF files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: 'application/pdf' });
                        } catch (error) {
                            console.error('Error fetching PDF file:', error);
                            return null;
                        }
                    } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `video/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching video:', error);
                            return null;
                        }
                    } else {
                        // For image files
                        try {
                            const response = await fetch(filepath);
                            const blob = await response.blob();
                            return new File([blob], filename, { type: `image/${fileType}` });
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                });
                
                Promise.all(initialPreviewsPromises)
                    .then(initialPreviews => {
                        // Filter out null values (in case of error)
                        const filteredPreviews = initialPreviews.filter(preview => preview !== null);
                        setImages(filteredPreviews);
                      //  console.log(filteredPreviews)
                    })
                    .catch(error => {
                        console.error('Error creating initial previews:', error);
                        setImages([]); // Set empty array in case of error
                    });
                
                const updatedPreviews = imageUrlArray.map(url => {
                    const fileType = getFileType(url);
                    return { type: fileType, url: url };
                });
                setPreviews(updatedPreviews);
            } 
            // if (template_details.video_path) {
            //     const filepathArray = template_details.video_path.split(',');
            //     const videoUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
            //     setPreviewsVideo(videoUrlArray);
    
            //     // Set videos based on preview videos
            //     const videoFiles = videoUrlArray.map(async url => {
            //         const res = await fetch(url);
            //         const blob = await res.blob();
    
            //         // Extract filename from URL
            //         const filename = url.substring(url.lastIndexOf('/') + 1);
    
            //         return new File([blob], filename, { type: "video/mp4" });
            //     });
            //     Promise.all(videoFiles).then(videos => {
            //         setVideos(videos);
            //     });
            // } else {
            //     setPreviewsVideo([]);
            //     setVideos([]);
            // }
        }
        else
        {   setTemplate(""); 
            setTemplate_details([]);
            setSubject("");
            setMessage("");
            setPreviews([]);
            setImages([]);
        }
        };

        const openSearchPopup = (item) => {
            //setSelectedInquiry(item);
         $('#exampleModalToggle').modal('show');
          };

        const sendEmail = (e) => {
            e.preventDefault();
            setLoading(true);
            // let emailArray;
            // emailArray = email.split(",");

            let emailArray;
            emailArray = customerById.email.split(",");
          
                // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
            // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
          
            let message = template_details?.message ?? ''; 

            const consentFormUrl = `${API_BASE_URL}/consent-form-page/${customerById.booking_ref_id}`;
           
             message = convertPlainTextToHTML(message)
            .replace('[customer_name]', `${customerById.firstname} ${customerById.lastname}`)
            .replace('[event_date]', moment(customerById.booked_date).format('D MMMM YYYY'))
            .replace('[event_time]', customerById.booked_time_formatted)  // Joining the times array into a string
            .replace('[location]', customerById.location_name)
            .replace('[number_of_players]', customerById.total_players)
            .replace('[package_name]', customerById.game_type_name || `${customerById.package_name} - ${customerById.game_package_name}`)
            .replace('[consent_form_link]', `<a href="${consentFormUrl}">Click here to complete the consent form</a>`)
            .replace('[qr_code]', `<img src="${LARAVEL_API_URL}/uploads/qrImages/${customerById.booking_ref_id}.png" alt="QR Code for Consent Form" />`);


            const formData = new FormData();
            // formData.append("customer_id", id);
            formData.append("booking_id", id);
            formData.append("to", emailArray);
            formData.append("subject", subject);
            formData.append("message", message);
            // if(template_details.message){
            //     formData.append("message", formattedMessage);
            // }
            // else{
            //     formData.append("message", message);
            // }
           
          
            images.forEach((image, index) => {
              formData.append(`image[${index}]`, image);
            });

           
          
            // videos.forEach((video, index) => {
            //   formData.append(`video[${index}]`, video);
            // });
          
            axios.post(`${API_URL}/send-email-attachment`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                
              }
              )
              .then(() => {
                
               // console.log("Email sent successfully");
               showToast("Email sent successfully!"); 
               
                // setSuccessMessage("Email sent successfully!");
                setLoading(false);
                setTemplate_details([]);
                resetEmail();
                getEmaillogbyId(id);
                setEmailErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setEmailErrorList(error.response.data.validate_err);
                //console.error("Error sending email:", error.response.data);
                Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })

              });
          };

          function convertPlainTextToHTML(text) {
            return text
                .replace(/\n/g, '<br>')  // Replace line breaks with <br>
                .replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length));  // Replace multiple spaces with &nbsp;
        }

        

          const sendSMS = async () => {
            setLoading(true);

              try {
        const response = await axios.post(`${API_URL}/send-sms`, {
        booking_id: id,
        phone_no: customerById.phone,
        message: text_message,
        send_as_template: isTemplate,
     
    });

      if (response.data.status === "success") {
        // Update message history, or show a success message
              showToast("SMS sent successfully!"); 
              setText_template_details([]);
              resetSms();
              getSmslogbyId(id);
              setErrorList([]);
              setLoading(false);
              console.log(response);
            
      }
     
    } catch (error) {
        setLoading(false);
        setErrorList({ message: "Failed to send message!" });
      console.error("Error sending message", error);

           Swal.fire({
                text: error.response.data.message,  // Use the extracted or default error message
                icon: "error"
            });
    }

          
        //     const formData = new FormData();
        //     // formData.append("customer_id", id);
        //     formData.append("booking_id", id);
        //     formData.append("phone_no", customerById.phone);
        //     formData.append("message", text_message);


        //       await  axios.post(`${API_URL}/send-sms`, formData) .then(() => {
        //     //   console.log('SMS sent successfully:', response.data);
        //       showToast("SMS sent successfully!"); 
        //       setText_template_details([]);
        //       resetSms();
        //       getSmslogbyId(id);
        //        setErrorList([]);
        //       setLoading(false);
            
        //     })
        //     .catch((error) => {
        //         setLoading(false);
        //       //console.error('Failed to send SMS:', error);      
        //         // Check if error.response and error.response.data exist
        //         const errorMessage = error.response && error.response.data && error.response.data.message
        //         ? error.response.data.message
        //         : "Failed to send message!"; // Default error message

        //     setErrorList({ message: errorMessage });

        //     Swal.fire({
        //         text: errorMessage,  // Use the extracted or default error message
        //         icon: "error"
        //     });
        
           
        
        //     //   showToastFail("Failed to send SMS");
        //     });
           };


          const makeCall = async () => {
            setLoading(true);
            try {
              
                const response = await axios.post(`${API_URL}/make-call`, {
                    recipient_number: '+94763125620' // Replace with recipient number
                });
              
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };

        const MeetingFormatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };
        
        const MeetingFormatTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutes} ${ampm}`;
        };

        const sendMeetingEmail = (e) => {
            e.preventDefault();
            setLoading(true);
                 
            // Construct the message body
            const messageBody = `
            <div class="header">
    <img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" />
   </div>

    <p><h2>Your appointment for ${title} has been confirmed, ${first_name}!<h2> </p>
    <p><strong>Date:</strong> ${formattedDateString}</p>
    <p><strong>Time:</strong> ${time}</p>
    <p><strong>Location:</strong> 415 The West Plaza, Ste. 110 Toronto, Ontario A9C 5J1</p><br>

 
<p>Regards,<br>Attodesk team,<br>Phone No: +1 (647) 555-5678</p>
 
            `;
        
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("to", email);
            formData.append("title", title);
            formData.append("date", date);
            formData.append("time", time);

            formData.append("subject", "Appointment Confirmation");
            formData.append("message", messageBody); 
     
        
            axios.post(`${API_URL}/save-meeting`, formData)
            .then(() => {
                showToast("Email sent successfully!"); 
                setLoading(false);
                // setTemplate_details([]);
                resetMeeting();
                getMeetlogbyId(id);
                setMeetingErrorList([]);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                 setMeetingErrorList(error.response.data.validate_err);
            });
        };


        async function updateBooking(event)
       {
        event.preventDefault();
        setLoading(true); 

         const data = customerById;
  console.log(data);
   
            await axios.post(`${API_URL}/update-booked-slots/${customerById.id}`, data).then(res =>{
         
                Swal.fire({
               icon:"success",
               text:res.data.message
             }).then(function(){
                handleCloseModal();
                getCustomerById(id);
              //   setErrorList([]);
              //   navigate('/view-customers');
            
             
           });
           }).catch(function(error) {
              // if(error.response.status===422){         
              setErrorList(error.response.data.validate_err);
              // }
          //    else{
               Swal.fire({
              
                 text:error.response.data.message,
                 icon:"error"
               })
               setLoading(false); 
          //    }
           })

           setLoading(false); 
           }

        const bookGame = (e) => {
            e.preventDefault();
            setLoading(true);
           



        
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("game_title", game_title);
            formData.append("game_description", game_description);
            formData.append("game_location", game_location);
            formData.append("game_type", game_type);
            formData.append("booking_date", booking_date);
            formData.append("booking_time", booking_time);
            formData.append("kids_count", kids_count);
            formData.append("adults_count", adults_count);
            formData.append("total_price", timeSlotById.price);

           if(game_location === 'stouffville'){
            
            axios.post(`${API_URL}/save-booking`, formData)
            .then(() => {
                showToast("Booked successfully!"); 
                setLoading(false);
                // setTemplate_details([]);
                resetBooking();
                getBookingbyId(id);
                setBookingErrorList([]);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                 setBookingErrorList(error.response.data.validate_err);
          Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })
            });
           }
        else if(game_location === 'scarborough'){
            axios.post(`${API_URL}/save-booking-scarborough`, formData)
            .then(() => {
                showToast("Booked successfully!"); 
                setLoading(false);
                // setTemplate_details([]);
                resetBooking();
                getBookingbyId(id);
                setBookingErrorList([]);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                 setBookingErrorList(error.response.data.validate_err);
          Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })
            });
        }
            
        };


        const savePayments = (e) => {
            e.preventDefault();
            setPaymentLoading(true);

            const formData = new FormData();
            formData.append("booking_id", id);
            formData.append("location_id", customerById.location_id);
            formData.append("payment_amount", payment_amount);
            formData.append("payment_method", payment_method);

                    axios.post(`${API_URL}/store-cash-payments`, formData)
        
        
                      .then((response) => {
                        if (response.data.status === 200) {
                            showToast("Payment successful!");
                            setPaymentLoading(false);
                            resetPayment();
                            getCustomerById(id);
                            setPaymentErrorList([]);
                            handleClosePaymentModal();
                        } else {
                            // Handle non-success status codes
                            const details = response.data.details || 'No additional details available';
                            showToast(`Payment failed: ${response.data.message || 'Unknown error'}`);
                            setPaymentErrorList([details]);
                            setPaymentLoading(false);
                        }
                    })
                    .catch((error) => {
                        setPaymentLoading(false);
            
                        // Check if error.response exists and contains data
                        const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
            
                        // Show an alert with the error message
                        Swal.fire({
                            text: errorMessage,
                            icon: 'error',
                        });
            
                        // Optionally set error details for further use
                        setPaymentErrorList([errorMessage]);
                    });
        
                
        

    
            // axios.post(`${API_URL}/process-payments`, formData)

            // const paymentData = {
            //     booking_id : id,
            //     location_id : customerById.location_id,
            //     payment_amount: payment_amount, // Amount in the smallest unit (e.g., cents, so $10.00 = 1000)
            //     currency: "CAD", // Currency code (e.g., CAD for Canadian Dollars)
            //     payment_method: payment_method, // Payment method type
            //     card: {
            //         number: "4030000010001234", // Visa test card number
            //         expiry_month: "12", // Expiry month (e.g., '12')
            //         expiry_year: "24", // Expiry year (e.g., '2024')
            //         cvd: "123", // CVV
            //         name: "John Doe", // Cardholder's name
             
            //     }
            // };

            // axios.post('https://api.na.bambora.com/v1/payments', paymentData, {
            //     headers: {
            //         'Authorization': `Passcode ${btoa('383612682:932F9C59A1194244bB31D8A9CF5Bcda2')}`, // Base64-encoded credentials
            //         'Content-Type': 'application/json'
            //     }
            // })
            // .then(response => {
                
            //     setPaymentLoading(false);
            //     showToast("Payment successful!");
            //     handleClosePaymentModal();
            //     console.log('Payment successful:', response.data);
            // })
            // .catch(error => {
            //     setPaymentLoading(false);
            //     const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
            //     showToast(`Payment failed: ${errorMessage}`);
            //     console.error('Payment error:', error.response?.data || error.message);
            // });

        //     if (payment_method === 'Cash') {
        //     axios.post(`${API_URL}/store-cash-payments`, paymentData)


        //       .then((response) => {
        //         if (response.data.status === 200) {
        //             showToast("Payment successful!");
        //             setPaymentLoading(false);
        //             resetPayment();
        //             getCustomerById(id);
        //             setPaymentErrorList([]);
        //             handleClosePaymentModal();
        //             console.log(response)
        //         } else {
        //             // Handle non-success status codes
        //             const details = response.data.details || 'No additional details available';
        //             showToast(`Payment failed: ${response.data.message || 'Unknown error'}`);
        //             setPaymentErrorList([details]);
        //             setPaymentLoading(false);
        //         }
        //     })
        //     .catch((error) => {
        //         setPaymentLoading(false);
    
        //         // Check if error.response exists and contains data
        //         const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
    
        //         // Show an alert with the error message
        //         Swal.fire({
        //             text: errorMessage,
        //             icon: 'error',
        //         });
    
        //         // Optionally set error details for further use
        //         setPaymentErrorList([errorMessage]);
        //     });

        // }

        // if (payment_method === 'Card') {

        //     axios.post(`${API_URL}/store-card-payments`, paymentData)


        //     .then((response) => {
        //       console.log(response);
        //       if (response.data.status === 200) {
        //           showToast("Payment successful!");
        //           console.log("Transaction Details:", response.data.transaction);
        //           setPaymentLoading(false);
        //           resetPayment();
        //           getCustomerById(id);
        //           setPaymentErrorList([]);
        //           handleClosePaymentModal();
        //       } else {
        //           // Handle non-success status codes
        //           const details = response.data.details || 'No additional details available';
        //           showToast(`Payment failed: ${response.data.message || 'Unknown error'}`);
        //           setPaymentErrorList([details]);
        //           setPaymentLoading(false);
        //       }
        //   })
        //   .catch((error) => {
        //       setPaymentLoading(false);
  
        //       // Check if error.response exists and contains data
        //       const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
  
        //       // Show an alert with the error message
        //       Swal.fire({
        //           text: errorMessage,
        //           icon: 'error',
        //       });
  
        //       // Optionally set error details for further use
        //       setPaymentErrorList([errorMessage]);
        //   });

        // }





    };



        const saveNote = (e) => {
            e.preventDefault();
            setLoading(true);

            const formData = new FormData();
            formData.append("booking_id", id);
            formData.append("note_subject", note_subject);
            formData.append("note_message", note_message);  

            axios.post(`${API_URL}/save-note`, formData)
              .then(() => {
               
               showToast("Note saved successfully!"); 
                setLoading(false);
             //   setNote_template_details([]);
                resetNote();
                getNotelogbyId(id);
                setNoteErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setNoteErrorList(error.response.data.validate_err);
              //  console.error("Error sending email:", error.response.data);
                Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })
              });
          };



        const sendNoteEmail = (e) => {
            e.preventDefault();
            setLoading(true);
        
                // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
            // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
            
            const formData = new FormData();
            // formData.append("customer_id", id);
            formData.append("booking_id", id);
            formData.append("to", customerById.email);
            formData.append("note_subject", note_subject);
            formData.append("note_message", note_message.replace(/\n/g, '<br>'));
         
     
            axios.post(`${API_URL}/send-note-email`, formData)
              .then(() => {
               
               showToast("Email sent successfully!"); 
                setLoading(false);
                setNote_template_details([]);
                resetNote();
                getNotelogbyId(id);
                setNoteErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setNoteErrorList(error.response.data.validate_err);
              //  console.error("Error sending email:", error.response.data);
                // Swal.fire({
                //     text:error.response.data.message,
                //     icon:"error"
                //   })
              });
          };

          const uploadDocuments = (e) => {
            e.preventDefault();
            setLoading(true);
          
            const formData = new FormData();
            formData.append("customer_id", id);
            formData.append("upload_subject", upload_subject);
            formData.append("upload_message", upload_message.replace(/\n/g, '<br>'));
           
            images.forEach((image, index) => {
              formData.append(`image[${index}]`, image);
            });
          
            axios.post(`${API_URL}/upload-documents-by-id`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                
              }
              )
              .then(() => {
              
               showToast("Document uploaded!"); 
               
                setLoading(false);
                setUpload_template_details([]);
                resetUpload();
                getUploadlogbyId(id);
                setUploadErrorList([]);
               
              })
              .catch((error) => {
                setLoading(false);
                setUploadErrorList(error.response.data.validate_err);
                //console.error("Error sending email:", error.response.data);
                Swal.fire({
                    text:error.response.data.message,
                    icon:"error"
                  })

              });
          };
             
          


          function onSelectImages(event){
            const selectedImages = Array.from(event.target.files);

                const validImages = selectedImages.filter(image => image.size <= 15 * 1024 * 1024); // 15MB 
                if (validImages.length !== selectedImages.length) {
                  Swal.fire({
                    text: "Image file size should be less than or equal to 15MB.",
                    icon: "error"
                  });
                }       
                setImages([...images, ...validImages]);       
            
                //  console.log([...images, ...validImages]);
               // const filePreviews = validImages.map((image) => URL.createObjectURL(image));
        
                const filePreviews = validImages.map(file => {
                if (file.type === 'application/pdf') {
                    return { type: 'pdf', name: file.name , url: URL.createObjectURL(file)};
                } else if (file.type === 'video/mp4') {
                  return { type: 'video', name: file.name, url: URL.createObjectURL(file) };
                 } else {
                    return { type: 'image', name: file.name ,url: URL.createObjectURL(file) };
                }
            });
        
                setPreviews([...previews, ...filePreviews]);
                

            
          }
    
          function onSelectVideos(event){
    
            const selectedVideos = Array.from(event.target.files);
    
            // Check the file size for each selected video
            const validVideos = selectedVideos.filter(video => video.size <= 10 * 1024 * 1024); // 25MB
          
            if (validVideos.length !== selectedVideos.length) {
              // Show the validation error message
              Swal.fire({
                text: "Video file size should be less than or equal to 10MB.",
                icon: "error"
              });
            }
          
            setVideos(validVideos);
          
            const videoPreviews = validVideos.map(video => URL.createObjectURL(video));
            setPreviewsVideo(videoPreviews);
          }
    
          
          function deleteImage(index){
            const updatedFiles = [...images];
          updatedFiles.splice(index, 1);
          setImages(updatedFiles);
          setPreviews(previews.filter((_, i) => i !== index));
         
         }
    

        
          
          
        //  function deleteVideo(index){
        //     const updatedVideos = [...videos];
        //     updatedVideos.splice(index, 1);
        //   setVideos(updatedVideos);
        //   setPreviewsVideo(previewsVideo.filter((_, i) => i !== index));
         
        //  }

        const handleImageClick =  (index) => {
            Swal.fire({
                imageUrl: previews[index].url,
                imageAlt: `Preview ${index}`,
                showCloseButton: true,
                showConfirmButton: false,
                imageWidth: "100%",
                imageHeight: 'auto',
            });
          }
        
          const handlePdfClick = (index) => {
            const pdfUrl = previews[index].url;
            window.open(pdfUrl, '_blank');
        }
        
        const handleVideoClick = (index) => {
          const videoUrl = previews[index].url;
          
          Swal.fire({
            html: `<div style="overflow: hidden;">
                      <video width="100%" height="100%" controls style="margin-top: 15px;"> <!-- Adjust margin-right to accommodate scrollbar -->
                          <source src="${videoUrl}" type="video/mp4">
                          Your browser does not support the video tag.
                      </video>
                  </div>`,
            showCloseButton: true,
            showConfirmButton: false,
            scrollbarPadding: false 
          });
        };


        
        const resetSms = () => {
            setText_template([]);
            setText_message("");
            setLoading(false);
             };

             const handleTextTemplateChange  = (event) => {
                setText_template(event.target.value);
                if(event.target.value){
                    const text_template_details = sms_template.find(item=> item.template_name === event.target.value);
                    setText_template_details(text_template_details);
                    setText_message(text_template_details.message);
                 
                }
                else
                {   setText_template(""); 
                    setText_template_details([]);
                    setText_message("");
                }
                };

                const handleNoteTemplateChange  = (event) => {
                    setNote_template_select(event.target.value);
                    if(event.target.value){
                        const note_template_details = note_template.find(item=> item.note_title === event.target.value);
                        setNote_template_details(note_template_details);
                        setNote_subject(note_template_details.subject);
                        setNote_message(note_template_details.note_message);
                     
                    }
                    else
                    {   setNote_template_select(""); 
                        setNote_template_details([]);
                        setNote_subject("");
                        setNote_message("");
                    }
                    };


                    // const handleTeamChange  = (selectedOptions) => {
                    //         if (selectedOptions && selectedOptions.length > 0) {
                    //           // Check if "All" option is selected
                    //           const isAllSelected = selectedOptions.some(option => option.value === 'all');
                          
                    //           if (isAllSelected) {
                    //             // If "All" is selected, select all team members
                    //             const allTeamIds = team.map(item => item.id);
                    //             setTeam_select(allTeamIds);
                    //           } else {
                    //             // If individual team members are selected, update the selected team members
                    //             const selectedTeamMemberIds = selectedOptions.map(option => option.value);
                    //             setTeam_select(selectedTeamMemberIds);
                    //           }
                    //         } else {
                    //           // If no options are selected, clear the selected team members
                    //           setTeam_select([]);
                    //         }
                    //       };
                  
                          const handleTeamChange = (event) => {
                        var updatedList = [...team_select];
                  
                      if (event.target.checked) {
              updatedList = [...team_select, event.target.value];
      
            } else {
              updatedList.splice(team_select.indexOf(event.target.value), 1);
            }
            setTeam_select(updatedList);

          };    
                     
                  
                  
                        const handleUploadTemplateChange  = (event) => {
                        setUpload_template_select(event.target.value);
                        if(event.target.value){
                            const upload_template_details = upload_template.find(item=> item.upload_title === event.target.value);
                            setUpload_template_details(upload_template_details);
                            setUpload_subject(upload_template_details.subject);
                            setUpload_message(upload_template_details.upload_message);
                         
                        }


                        else
                        {   setUpload_template_select(""); 
                            setUpload_template_details([]);
                            setUpload_subject("");
                            setUpload_message("");
                        }
                        };


                    const handlePinTemplateChange  = (event) => {
                        setPin_template_select(event.target.value);
                        if(event.target.value){
                            const pin_template_details = pin_template.find(item=> item.pin_title === event.target.value);
                            setPin_template_details(pin_template_details);
                            setPin_message(pin_template_details.pin_message);
                         
                        }
                        else
                        {   setPin_template_select(""); 
                            setPin_template_details([]);
                            setPin_message("");
                        }
                        };


                        const handleSubmit = async (e) => {
                            e.preventDefault();
                            setLoading(true);
                           
                    
                                await axios.post(`${API_URL}/save-meet-template`, {
                                    title,
                                    start_datetime: startDatetime,
                                    end_datetime: endDatetime
                                }).then(res =>{
                               
                                    Swal.fire({
                                        icon:"success",
                                        text:res.data.message
                                      }).then(function(){ 
                                        setLoading(false);        
                                        resetMeetingData();
                                        setErrorList([]); 
                                        // fetchEvents();
                                    });
                                    }).catch(function(error) {
                                        setLoading(false);
                                        setErrorList(error.response.data.validate_err);
                                        Swal.fire({
                                          text:error.response.data.message,
                                          icon:"error"
                                        })
                                    })
                    
                            }
                    
                    
                        const resetMeetingData = () => {
                            setTitle('');
                            setStartDatetime('');
                            setEndDatetime('');
                        };


                   
                        const deleteAdultConsent = (e, index) => {
                            e.preventDefault();
                        
                            const buttonClicked = window.confirm("Are you sure you want to delete the adult consent form?");
                            if (buttonClicked) {
                                // Make sure the index is valid
                                if (index < 0 || index >= customerById.consent_form_details.length) {
                                    console.error("Invalid index:", index);
                                    return;
                                }
        //                  
                                const updatedConsentFormDetails = customerById.consent_form_details.map((detail) => {
                                    
                         
                                    if (detail === index ) {
                                        // console.log("Updating item:", detail);
                                        return { ...detail, is_deleted: 1 };
                                    }
                                    return detail;
                                });
                              
                        
                                // Make the API call to update the consent form
                                axios.put(`${API_URL}/delete-adult-consent`, {
                                    booking_ref_id: customerById.booking_ref_id,
                                    consent_form_details: updatedConsentFormDetails
                                }).then(res => {
                                    Swal.fire({
                                        icon: "success",
                                        text: res.data.message
                                    }).then(() => {
                                        getCustomerById(id); // Refresh data
                                    });
                                }).catch(error => {
                                    Swal.fire({
                                        icon: "error",
                                        text: "An error occurred while updating the consent form."
                                    });
                                });
                            } else {
                                getCustomerById(id); // Optionally refresh data if canceled
                            }
                        };
                        
                        const deleteKidConsent = (e, index) => {
                            e.preventDefault();
                        
                            const buttonClicked = window.confirm("Are you sure you want to delete the adult consent form?");
                            if (buttonClicked) {
                                // Make sure the index is valid
                                if (index < 0 || index >= customerById.kids_form_details.length) {
                                    console.error("Invalid index:", index);
                                    return;
                                }
        //                  
                                const updatedKidsConsentFormDetails = customerById.kids_form_details.map((detail) => {
                                    
                         
                                    if (detail === index ) {
                                        // console.log("Updating item:", detail);
                                        return { ...detail, is_deleted: 1 };
                                    }
                                    return detail;
                                });
                              
                        
                                // Make the API call to update the consent form
                                axios.put(`${API_URL}/delete-kid-consent`, {
                                    booking_ref_id: customerById.booking_ref_id,
                                    kids_form_details: updatedKidsConsentFormDetails
                                }).then(res => {
                                    Swal.fire({
                                        icon: "success",
                                        text: res.data.message
                                    }).then(() => {
                                        getCustomerById(id); // Refresh data
                                    });
                                }).catch(error => {
                                    Swal.fire({
                                        icon: "error",
                                        text: "An error occurred while updating the consent form."
                                    });
                                });
                            } else {
                                getCustomerById(id); // Optionally refresh data if canceled
                            }
                        };
                     
                        

                        const fetchTimeSlots = async () => {
                            setTimeSlotLoading(true);
                            try {
                             
                                const response = await axios.get(`${API_URL}/fetch-time-slots`, {
                                    params: {
                                        location_id: customerById.location_id,
                                        game_id: customerById.game_id,
                                        game_type_id: customerById.game_type_id,
                                        selected_date: customerById.booked_date,
                                        //created_by: userContext.userDetails.id,
                                    }
                                });
                                //   setTimeSlotDetails(prevState => ({
                                //     ...prevState,
                                //     [selectedGameType]: response.data // Assuming the response is an array with one object for the selected game type
                                //   }));
                    
                    
                                setTimeSlotDetails(response.data);
                              
                                const timeSlot = response.data;
                    
                                const { start_time, end_time, time_slot_interval } = timeSlot;
                                const timeSlots = calculateTimeSlots1(start_time, end_time, parseInt(time_slot_interval, 10));
                                const formattedTimeSlots = formatTimeSlots(timeSlots);
                    
                    
                                //   console.log('Generated Time Slots:', timeSlots);
                                const comparisonResponse = await axios.post(`${API_URL}/compare-time-slots`, {
                                    location_id: customerById.location_id,
                                    game_id: customerById.game_id,
                                    game_type_id: customerById.game_type_id,
                                    selected_date: customerById.booked_date,
                                    generated_time_slots: formattedTimeSlots,
                                    no_of_adults: adults_count,
                                    no_of_children: kids_count,
                                });
                    
                                const { disabledTimeSlots, availableSpaces } = comparisonResponse.data;
                    
                                setTimeSlotDetails(timeSlot);
                                setBookingAvailableTimeSlots(formattedTimeSlots);
                                setDisabledTimeSlots(disabledTimeSlots);
                                // console.log(disabledTimeSlots)
                                setAvailableSpaces(availableSpaces);
                    
                                await new Promise(resolve => setTimeout(resolve, 300));
                    
                               //  console.log('Fetched Data:', comparisonResponse.data);
                    
                            } catch (error) {
                                console.error('Error fetching data:', error);
                    
                            }
                            setTimeSlotLoading(false);
                        }

                        const fetchTimeSlotsBirthday = async () => {
                            setTimeSlotLoading(true);
                            try {
                                const response = await axios.get(`${API_URL}/fetch-time-slots-birthday`, {
                                    params: {
                                        location_id: customerById.location_id,
                                        game_id: customerById.game_id,
                                        game_package_id: customerById.game_package_id,
                                        selected_date: customerById.booked_date,
                                        // created_by: userContext.userDetails.id,
                                    }
                                });
                               
                    
                    
                                setTimeSlotDetails(response.data);
                                const timeSlot = response.data;
                    
                                const { start_time, end_time, time_slot_interval } = timeSlot;
                                const timeSlots = calculateTimeSlots1(start_time, end_time, parseInt(time_slot_interval, 10));
                                const formattedTimeSlots = formatTimeSlots(timeSlots);
                    
                        
                                const comparisonResponse = await axios.post(`${API_URL}/compare-time-slots-birthday`, {
                                    location_id: customerById.location_id,
                                    game_id: customerById.game_id,
                                    game_package_id: customerById.game_package_id,
                                    // game_type_id: selectedGameType,
                                    selected_date: customerById.booked_date,
                                    generated_time_slots: formattedTimeSlots,
                                    no_of_adults: adults_count,
                                    no_of_children: kids_count,
                                });
                    
                                const { disabledTimeSlots, availableSpaces } = comparisonResponse.data;
                    
                                 
                                
                                setTimeSlotDetails(timeSlot);
                                setBookingAvailableTimeSlots(formattedTimeSlots);
                                setDisabledTimeSlots(disabledTimeSlots);
                                setAvailableSpaces(availableSpaces);
                    
                                await new Promise(resolve => setTimeout(resolve, 300));
                    
                                // console.log('Fetched Data:', comparisonResponse.data);
                    
                            } catch (error) {
                                console.error('Error fetching data:', error);
                    
                            }
                            setTimeSlotLoading(false);
                        }







                    
  // Function to format time slots into ranges
  const formatTimeSlots = (timeSlots) => {
    const formattedSlots = [];

    for (let i = 0; i < timeSlots.length - 1; i++) {
        const startTime = timeSlots[i];
        const endTime = timeSlots[i + 1];

        // Convert to 12-hour format
        const startTime12h = convertTo12HourFormat1(startTime);
        const endTime12h = convertTo12HourFormat1(endTime);

        // Format the range
        const formattedRange = `${startTime12h} - ${endTime12h}`;
        formattedSlots.push(formattedRange);
    }

    return formattedSlots;
};

// Function to convert 24-hour time to 12-hour format
const convertTo12HourFormat1 = (time24h) => {
    const [hours, minutes] = time24h.split(':');
    let period = 'AM';
    let hours12 = parseInt(hours, 10);

    if (hours12 === 0) {
        hours12 = 12;
    } else if (hours12 === 12) {
        period = 'PM';
    } else if (hours12 > 12) {
        hours12 -= 12;
        period = 'PM';
    }

    return `${hours12.toString().padStart(2, '0')}:${minutes} ${period}`;
};

const fetchGameTypesArray = async () => {
    try {
        const response = await axios.get(`${API_URL}/view-game-type`);

        setGame_types_array(response.data.gameType);
      
       // setIsLoading(false);
 
    } catch (error) {
        //setIsLoading(false);
        console.error("Error fetching games:", error);

    }
   
};

const calculateTimeSlots1 = (startTime, endTime, interval) => {
    const timeSlots = [];
    const [startHour, startMinute] = parseTime(startTime);
    const [endHour, endMinute] = parseTime(endTime);

    let current = new Date();
    current.setHours(startHour, startMinute, 0, 0);

    const end = new Date();
    end.setHours(endHour, endMinute, 0, 0);

    while (current <= end) {
        const timeSlot = current.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
        timeSlots.push(timeSlot);
        current.setMinutes(current.getMinutes() + interval);
    }

    return timeSlots;
};


 const parseTime = (timeString) => {
        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        const adjustedHours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
        return [adjustedHours, minutes];
    };

    const handleTimeSlotChange1 = (event) => {
        const selectedTimeSlot = event.target.value;
        const selectedSlotIndex = bookingAvailableTimeSlots.indexOf(selectedTimeSlot);

        // if (customerById.package_name === 'Birthday') {
            
        //         const userGameCount = parseInt(game_count, 10); // Get the number of games selected by the user
        //         const totalSlotsToSelect = userGameCount + 2; // Add 2 free games

        //         // Get the next `game_count` consecutive slots
        //         const nextSlots = bookingAvailableTimeSlots.slice(selectedSlotIndex, selectedSlotIndex + totalSlotsToSelect);
                
        //         setBooking_time(() => {
        //             // Clear all previous selections and set only the new consecutive slots
        //             return nextSlots;
        //         });

        //     }
        //     else{

                
                setBooking_time((prevBookingTime) => {
                    const isSelected = prevBookingTime.includes(selectedTimeSlot);
                    const updatedBookingTime = isSelected 
                        ? prevBookingTime.filter((timeSlot) => timeSlot !== selectedTimeSlot) // Remove if already selected
                        : [...prevBookingTime, selectedTimeSlot]; // Add new time slot
                
                    const parseTimeSlot = (timeSlot) => {
                        const [startTime, endTime] = timeSlot.split(' - ');
                        return {
                            start: moment(startTime, 'hh:mm A'),
                            end: moment(endTime, 'hh:mm A'),
                            original: timeSlot
                        };
                    };
                
                    // Sort the updated time slots
                    const sortedBookingTime = updatedBookingTime
                        .map(parseTimeSlot)
                        .sort((a, b) => a.start - b.start) // Sort by start time
                        .map(item => item.original);
                
                   // console.log('Sorted Booking Time:', sortedBookingTime); 


                    setCustomerById((prevCustomerById) => ({
                        ...prevCustomerById,
                        booked_time: JSON.stringify(sortedBookingTime),
                        no_of_slots: sortedBookingTime.length, // Store booking_time as a JSON string
                    }));
                    

                   
                    return sortedBookingTime;
                });


                

    // };


    }







    return(
<div>
   
    <SideNav/>
      <TopBar/>
      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


      <div class="right_panel position contact_section vh-100" id="right_panel">
        <div class="contact_section_wrapper">
            <div class="d-lg-flex">
            
                <a id="contact_inbox" class="a_id_contact_inbox position-relative"></a>
              
                                                
                                
    <>
    <div class="border border-top-0 border-bottom-0 user_panel bg-white" style={{width:'60%'}}>
   
    {/* <div class="row">
    <div class="col-4">
    <div className="booking-box" style={{height:'300px'}} >
        <div class="profile_photo_wrapper vm_bg_cover vm_bg_center vm_bg_norepeat w-100 d-flex align-items-center flex-column ">
         
         </div>
        <h2 class="text-center vm_font_bold pt-2 mt-2">{customerById.firstname} {customerById.lastname} - {customerById.booking_ref_id}</h2>
        <div class="d-flex justify-content-center">
            <a href="tel:${item.phone_no}">
                <div style={{cursor:"pointer"}} class="d-flex align-items-center pe-3"  >
                    <i class="bi bi-telephone me-2"></i>
                    <p>{customerById.phone}</p>
                </div>
           </a>
        
                <div style={{cursor:"pointer"}} class="d-flex align-items-center"  onClick={() => toggleDiv('email')}>
                    <i class="bi bi-envelope me-2"></i>
                    <p>{customerById.email}</p>
                </div>
           
        </div>
        </div>
    </div>
    <div class="col-8">
    <div className="booking-box" >
            <div className="booking-info">
                <p><i class="bi bi-pin-map text-success"></i>Location</p>
                <h6>{customerById.location_name}</h6>
            </div>
            <div className="booking-info">
                <p><i class="bi bi-controller pe-1 text-success"></i>Game Package</p>
                <h6>{customerById.game_type_name ? customerById.game_type_name: customerById.package_name - customerById.game_package_name}</h6>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-calendar3 me-2 text-success" ></i>Booked date</p>
                <h6>{moment(customerById.booked_date).format('D MMMM YYYY')}</h6>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-clock-fill me-2 text-success" ></i>Booked Time</p>
                <h6>{customerById.booked_time}</h6>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-person text-success"></i>Adults</p>
                <h6>{customerById.no_of_adults}</h6>
            </div>

            <div className="booking-info">
                <p><i className="bi bi-person text-success"></i>Kids</p>
                <h6>{customerById.no_of_children}</h6>
            </div>
            </div>

    </div>
    </div> */}
    
   

  
        <div class="profile_photo_wrapper vm_bg_cover vm_bg_center vm_bg_norepeat w-100 d-flex align-items-center flex-column ">
         
         </div>
        <h2 class="text-center vm_font_bold pt-2 mt-2">{customerById.firstname} {customerById.lastname} - {customerById.booking_ref_id} -  <i className="bi bi-person-fill" style={{ fontSize: '1rem' }}>{customerById.total_players}</i>
    </h2>
        <div class="d-flex justify-content-center">
            <a href="tel:${item.phone_no}">
                <div style={{cursor:"pointer"}} class="d-flex align-items-center pe-3"  >
                    <i class="bi bi-telephone me-2"></i>
                    <p>{customerById.phone}</p>
                </div>
           </a>
        
                <div style={{cursor:"pointer"}} class="d-flex align-items-center"  onClick={() => toggleDiv('email')}>
                    <i class="bi bi-envelope me-2"></i>
                    <p>{customerById.email}</p>
                </div>
           
        </div>
      
   
    {/* <div class="col-12">
    <div className="booking-box" >
            <div className="booking-info">
                <p><i class="bi bi-pin-map text-success"></i>Location</p>
                <p className=" vm_font_bold">{customerById.location_name}</p>
            </div>
            <div className="booking-info">
                <p><i class="bi bi-controller pe-1 text-success"></i>Game Package</p>
                <p className=" vm_font_bold"> {customerById.game_type_name 
    ? customerById.game_type_name 
    : `${customerById.package_name} - ${customerById.game_package_name}`
  }</p>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-calendar3 me-2 text-success" ></i>Booked date</p>
                <p className=" vm_font_bold">{moment(customerById.booked_date).format('D MMMM YYYY')}</p>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-clock-fill me-2 text-success" ></i>Booked Time</p>
                <p className=" vm_font_bold">{customerById.booked_time}</p>
            </div>
            <div className="booking-info">
                <p><i className="bi bi-person text-success"></i>Total Players</p>
                <div style={{  display: 'flex', gap:'20px'}}>
                <p className=" vm_font_bold">Adults - {customerById.no_of_adults}</p>
                <p className=" vm_font_bold">kids - {customerById.no_of_children}</p>
                </div>
            </div>

           
            </div>

    </div> */}
 

  
        


           
   

        <div class="option d-flex justify-content-center flex-wrap pt-2">
                   
     

<a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('info')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'info' ? 'selected' : ''}`}>
                <i class="bi bi-info-circle text-white"></i>
                </div>
                <p class="text-center">Info</p>
            </a>
            
            {/* <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('call')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'call' ? 'selected' : ''}`}>
                    <i class="bi bi-telephone-fill text-white"></i>
                </div>
                <p class="text-center">Call</p>
            </a> */}
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('email')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'email' ? 'selected' : ''}`}>
                    <i class="bi bi-envelope-fill text-white"></i>
                </div>
                <p class="text-center w-100">Email</p>
            </a>
            {/* <a   href={`https://wa.me/1${customerById.phone}?text=Hello%2C%20I%20would%20like%20to%20get%20more%20information!`} target="self" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('whatsapp')}>
    <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'whatsapp' ? 'selected' : ''}`}>
    <i class="bi bi-chat-dots-fill text-white"></i>
    </div>
    <p class="text-center w-100">Text</p>
</a> */}
            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('text')} >
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'text' ? 'selected' : ''}`}>
                    <i class="bi bi-chat-dots-fill text-white"></i>
                </div>
                <p class="text-center w-100">Text</p>
            </a>

            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('note')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'note' ? 'selected' : ''}`}>
                    <i class="bi bi-stickies-fill text-white"></i>
                </div>
                <p class="text-center w-100">Note</p>
            </a>

            <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('history')} >
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'history' ? 'selected' : ''}`}>
                    <i class="bi bi-clock-history text-white"></i>
                </div>
                <p class="text-center w-100">History</p>
            </a>
            {/* <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('pin')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'pin' ? 'selected' : ''}`}>
                    <i class="bi bi-geo-fill text-white"></i>
                </div>
                <p class="text-center w-100">Pin</p>
            </a> */}
            {/* <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center"  onClick={() => toggleDiv('meet')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'meet' ? 'selected' : ''}`}>
                    <i class="bi bi-calendar-check text-white"></i>
                </div>
                <p class="text-center w-100">Meet</p>
            </a> */}
           
            {/* <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('upload')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'upload' ? 'selected' : ''}`}>
                    <i class="bi bi-cloud-arrow-up-fill text-white"></i>
                </div>
                <p class="text-center w-100">Upload</p>
            </a> */}

            {/* <a href="#" class="ps-2 d-flex justify-content-center flex-column align-items-center" onClick={() => toggleDiv('booking')}>
                <div class={`item d-flex justify-content-center align-items-center ${activeButton === 'booking' ? 'selected' : ''}`}>
                    <i class="bi bi-cloud-arrow-up-fill text-white"></i>
                </div>
                <p class="text-center w-100">Booking</p>
            </a> */}
        </div>

<div>

</div>
{settingsVisible && <div id="settingsDiv" class="px-3 mb-5 bg-light mt-3 pb-4 pt-3">
  
      <div className="row">
        <div className="col-md-7">
          <h4 className="pb-2 mb-2 vm_font_semi_bold">Assign to</h4>
        </div>
        <div className="col-md-5 form-group">
          <div className="d-flex input_wrapper">
            <div className="icon_wrapper">
              <i className="bi bi-search icon"></i>
            </div>
            <input className="form-control" name="teamMember" id="teamMember"
             placeholder="Team Member" type="text"  value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}} />
          </div>
        </div>
      </div>
      <div className="row py-3 border-top">
        <div className="col-md-12">
          <div className="form-group-checkbox d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input
                style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                className="checkbox"
                type="checkbox"
                onChange={handleSelectAllTeam}
                checked={team_select.length === team.length && team.length > 0} // Check if all members are selected
              />
              <label htmlFor="selectAll" className="ml-2">Select All</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-3">
      {filteredTeam.length === 0 ? 
                

                <div class="row" style={{margin: "0px -550px 0px 0px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
      {filteredTeam.map((item, index) => (
          <div className="col-md-4 pb-2" key={index}>
            <div className="form-group-checkbox d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <input style={{boxShadow: "2px 2px #059618",transition: "all .5s ease"}}
                  className="checkbox"
                  value={item.id}
                  type="checkbox"
                  name="team_select"
                  id={`team_select${index}`}
                  checked={team_select.includes(item.id.toString())}
                //   checked={team_select.includes(item.id)}
                  onChange={ handleTeamChange}
                />
                <label
                  htmlFor={`team_select${index}`}
                  className={team_select.includes(item.id.toString()) ? 'checked' : ''}
                >
                  {item.first_name} {item.last_name}
                </label>
              </div>
            </div>
          </div>
          
        ))}
          <div class="d-flex justify-content-end pt-3">
                            <button onClick={saveAssign}  class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Saving...":"Save"} </button>
                        </div>
      </div>


    </div>}

      {inqVisible && <div id="inqDiv" class="px-3 mb-5 bg-light mt-3 pb-4 pt-3">
        
     
                        <h3 class="vm_font_bold pb-3">Vehicle Inquery</h3>
                        <div class="form-group">
                            <label for="vehicle_name">Vehicle Name</label>
                            <input type="text" class="form-control" name="vehicle_name" value={vehicle_name} id="vehicle_name" placeholder="Ex: 2015 Chevrolet Equinox"
                            onChange={(event) =>{ setVehicle_name(event.target.value);}}/>
                             {vehicle_name.length === 0 && (
    <span class="text-danger">{error_list.vehicle_name}</span>
                             )}
                        </div>
                    
                        <div class="d-md-flex gx-5">
                        <div class="form-group col pe-md-2">
                        <label >Make</label>
                        <Select 
        value={selectedMakes.map(make => ({ value: make, label: make}))}
        options={makeOptions.map(make => ({ value: make, label: make }))}
        isMulti
        placeholder="Select Make"
        onChange={handleMakeChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      />
       {selectedMakes.length === 0 && (
        <span class="text-danger">{error_list.selectedMakes}</span>
    )}
    
      </div>
     
      <div class="form-group col ps-md-2">
      <label >Model</label>
                                    <Select 
        value={selectedModels.map(model => ({ value: model, label: model}))}
        options={modelOptions.map(model => ({ value: model, label: model }))}
        isMulti
        placeholder="Select Model"
        onChange={handleModelChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
        required  
      />
        {selectedModels.length === 0 && (
        <span class="text-danger">{error_list.selectedModels}</span>
    )}
      </div>
    

      </div>

                        
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                                <label for="minPrice">Min Price</label>
                                <input type="number" class="form-control"  name="minPrice" id="minPrice"  value={minPrice} onChange={(e) => setMinPrice(e.target.value)} min="0"/>
                            </div>
                            <div class="form-group col ps-md-2">
                                <label for="maxPrice">Max Price</label>
                                <input type="number" class="form-control "  name="max_price" id="max_price"  value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} min="0"/>
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                                <label for="minPrice">Min KM</label>
                                <input  type="number" class="form-control "  name="minKilometer" id="minKilometer"
            value={minKilometer} onChange={(e) => setMinKilometer(e.target.value)} min="0"/>
                            </div>
                            <div class="form-group col ps-md-2">
                                <label for="maxPrice">Max KM</label>
                                <input  type="number" class="form-control "  name="maxKilometer" id="maxKilometer"
            value={maxKilometer} onChange={(e) => setMaxKilometer(e.target.value)} min="0"/>
                            </div>
                        </div>


                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Body Style</label>
  <Select 
    value={selectedBodyStyles.map(style => ({ value: style, label: style}))}
    options={bodyStyleOptions.map(style => ({ value: style, label: style }))}
    isMulti
    placeholder="Select Body Style"
    onChange={handleBodyStyleChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col ps-md-2">
                            <label >Engine</label>
  <Select 
    value={selectedEngines.map(engine => ({ value: engine, label: engine}))}
    options={engineOptions.map(engine => ({ value: engine, label: engine }))}
    isMulti
    placeholder="Select Engine"
    onChange={handleEngineChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Exterior Color</label>
  <Select 
    value={selectedExteriorColors.map(color => ({ value: color, label: color}))}
    options={exteriorColorOptions.map(color => ({ value: color, label: color }))}
    isMulti
    placeholder="Select Exterior Color"
    onChange={handleExteriorColorChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col ps-md-2">
                            <label >Transmission</label>
  <Select 
    value={selectedTransmissions.map(transmission => ({ value: transmission, label: transmission}))}
    options={transmissionOptions.map(transmission => ({ value: transmission, label: transmission }))}
    isMulti
    placeholder="Select Transmission"
    onChange={handleTransmissionChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div>
                        <div class="d-md-flex gx-5">
                            <div class="form-group col pe-md-2">
                            <label >Drive Trains</label>
  <Select 
    value={selectedDriveType.map(driveType => ({ value: driveType, label: driveType}))}
    options={driveTypeOptions.map(driveType => ({ value: driveType, label: driveType }))}
    isMulti
    placeholder="Select Drive Train"
    onChange={handleDriveTypeChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col pe-md-2">
                            <label >Doors</label>
  <Select 
    value={selectedDoors.map(doors => ({ value: doors, label: doors}))}
    options={doorsOptions.map(doors => ({ value: doors, label: doors }))}
    isMulti
    placeholder="Select No of door"
    onChange={handleDoorChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                            <div class="form-group col pe-md-2">
                            <label for="stock_no">Stock No</label>
                            <Select 
    value={selectedStockNo.map(stockNo => ({ value: stockNo, label: stockNo}))}
    options={stockNoOptions.map(stockNo => ({ value: stockNo, label: stockNo }))}
    isMulti
    placeholder="Select Stock No"
    onChange={handleStockNoChange}
    noOptionsMessage={() => 'No options available'}
  />
                            </div>
                        </div>
                        <div class="form-group col">
                        <label for="description">Description</label>
                            <textarea class="form-control" name="description" id="description" value={description} cols="" rows="3"
                            onChange={(event) =>{ setDescription(event.target.value);}}/>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            <button onClick={saveInquiry} class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Submitting...":"Submit Inquery"} </button>
                        </div>
                  

           
        
        </div>}
      {callVisible && <div id="callDiv" style={{height:"100vh"}}>
      {/* <a href="tel:+94763125620">

        <button  class="btn btn-sm btn_secondary text-white">{loading?"Calling...":"Call"}</button>
        </a> */}
      <h3 class="vm_font_bold ps-3 pt-3">Call History</h3>

<div class="activity_wrapper">
    <div class="row pt-3">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_red px-2 py-1 text-center">03 Feb</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_red p-2">
            <p class="vm_font_bold">Call | Business LIne</p>
            <p>Outgoing | Wed at 07:45am</p>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_blue px-2 py-1 text-center">02 Feb</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_blue p-2">
            <p class="vm_font_bold">Call | Business LIne</p>
            <p>Outgoing | Wed at 07:45am</p>
        </div>
    </div>
</div>
        
        
        </div>}
      {emailVisible && <div id="emailDiv" >
        
      <div class="px-3 pb-3 pt-2 bg-light mt-3">
                        <h3 class="vm_font_semi_bold border-bottom mb-3 pb-2">
                            <i class="bi bi-pencil"></i> Compose Email
                        </h3>
                        <div class="form-group row pb-2">
                            <div class="col-md-2 d-flex pt-2">
                                <label for="toemail">To email</label>
                            </div>
                            <div class="col-md-10">
                                <input class="form-control col" type="text" name="toemail" id="toemail" value={customerById.email} placeholder="Enter to Email"/>
                            </div>
                        </div>
                       
 <div class="form-group row pb-2">
                            <div class="col-md-2 d-flex pt-2">
                                <label for="toemail">Subject</label>
                            </div>
                            <div class="col-md-10">
                                <input class="form-control col" type="text" name="subject" id="subject" value={subject} onChange={(event) =>{ setSubject(event.target.value); }}
                                 placeholder="Enter Subject"/>
                                <span class="text-danger">{email_error_list.subject}</span> 
                            </div>
                        </div>

                        <div class="form-group pb-2">
                            <label for="toemail">Message</label>
                            <textarea class="mh-100 form-control" name="message" id="message" cols="100%" value={message} placeholder="Enter Message" rows="4"
                            onChange={(event) =>{ setMessage(event.target.value); }}></textarea>
                             <span class="text-danger">{email_error_list.message}</span> 
                            
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center" style={{ marginLeft:"4px"}}>
                                <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image h2"></i></label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>


 

                             
                                <select class="form-select ms-3 form-select-sm" name="template" id="template" value={template} onChange={handleTemplateChange}>
                                    <option value="">Select Template</option>
                                    {email_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>

                            </div>



                            {successMessage && <p class="text-left" style={{color:"green"}}>{successMessage}</p>}
                            <div>

                                <button onClick={sendEmail} class="btn btn-sm btn_secondary text-white"><i class="bi bi-send"> </i>{loading?"Sending...":"Send Message"} </button>
                            </div>

                            
                        </div>
                        <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )}
                                                </div>
                                            ))}
                                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Email History</h3>
                    <div class="content">
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-8 form-group" >
                 
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-4 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                              </div>
                    {filteredEmailHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(filteredEmailHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_red px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_red p-2">
                                <p class="vm_font_bold">Subject | {item.subject}</p>
                               
                                <p>  
                                <a
                            className="btn btn-link p-0"
                            style={{ fontSize: '0.85rem' }}
                            onClick={() => handleToggle(item.id)} // Replace item.id with unique identifier for each email
                            aria-controls={`collapse-text-${item.id}`}
                            aria-expanded={openEmailId === item.id}
                        >
                            {openEmailId === item.id ? 'Show Less' : 'Show More'}
                        </a>
                    </p>
                    <Collapse in={openEmailId === item.id}>
                        <div id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Collapse>
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                           
                        </div>                    
                    </div>
                    )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                        {filteredEmailHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredEmailHistory.length)} of ${emailLogById.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredEmailHistory.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>

                    </div>
                
        
        </div>}
      {textVisible && <div id="textDiv">
        
      <div class="py-4 mt-4 border-top bg-light">
                        <div class="form-group pb-4 px-3">
                            <label for="toemail">Text Message</label>
                            <textarea class="mh-100 form-control" name="text_message" id="text_message" cols="100%" rows="3" value={text_message}  placeholder="Enter Text Message"
                            onChange={(event) =>{ setText_message(event.target.value); }}></textarea>
                            <span  class="text-danger">{error_list.text_message}</span>
                        </div>
                        <div class="d-md-flex">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="text_template" id="text_template" value={text_template} onChange={handleTextTemplateChange}>
                                    <option value="">Select Template</option>
                                    {sms_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button  onClick={sendSMS} class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send SMS'}</button>
                            </div>
                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Text History</h3>
                    <div class="content">
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-9 form-group" >
                 
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>

{/* <div class="col-md-3 form-group">
    <div class="d-flex input_wrapper">
       <div> <label>Sort by <select  value={sortOrder}   onChange={(e) => setSortOrder(e.target.value)} >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
            </select>
        </label>
        </div>
    </div>
</div> */}
                              </div>
                    {sortedSmsHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(sortedSmsHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_blue px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_blue p-2">
                                <p class="vm_font_bold">Message | {item.message}</p>
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                        </div>
                      
                    </div>
                        )))}
                        <div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                                                {sortedSmsHistory.length > 0 && `Showing 1 to ${Math.min(perPage, sortedSmsHistory.length)} of ${smsLogById.length} entries`}
                                                            </div><div class="col-md-8" style={{ float: "right" }}>
                        
                                                                <ReactPaginate style={{ float: "right" }}
                                                                    previousLabel={"< Prev"}
                                                                    nextLabel={"Next >"}
                                                                    breakLabel={'...'}
                                                                    pageCount={Math.ceil(sortedSmsHistory.length / perPage)}
                                                                    onPageChange={handlePageChange}
                                                                    containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                                                    disabledClassName={"disabled"}
                                                                    breakClassName={['page-item']}
                                                                    breakLinkClassName={'page-link'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={['active']} />
                        
                        
                                                            </div>
                        
                                            </div>
               
        
        
        </div>}
      {pinVisible && <div id="pinDiv">
      
      <div class="py-4 mt-4 border-top bg-light">
                        <div class="form-group pb-4 px-3">
                            <label for="toemail">Enter Pin Message</label>
                            <textarea class="mh-100 form-control" name="pin_message" id="pin_message" cols="100%" value={pin_message}
                            onChange={(event) =>{ setPin_message(event.target.value); }} rows="3"></textarea>
                             <span  class="text-danger">{error_list.pin_message}</span>
                        </div>
                        <div class="d-md-flex justify-content-start">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="pin_template_select" id="pin_template_select" value={pin_template_select} onChange={handlePinTemplateChange}>
                                    <option value="">Select Pin Template</option>
                                    {pin_template.map((item) => (
                                    <option value={item.pin_title}>{item.pin_title}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send Message'}</button>
                            </div>
                        </div>
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Pin History</h3>

                    <div class="activity_wrapper pb-5 mb-5">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_red px-2 py-1 text-center">03 Feb</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_red p-2">
                                <p class="vm_font_bold">Call | Business LIne</p>
                                <p>Outgoing | Wed at 07:45am</p>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_blue px-2 py-1 text-center">02 Feb</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_blue p-2">
                                <p class="vm_font_bold">Call | Business LIne</p>
                                <p>Outgoing | Wed at 07:45am</p>
                            </div>
                        </div>
                    </div>
                
      </div>}
      {meetVisible && <div id="meetDiv">
      <div class="py-4 mt-4 border-top bg-light">

      <div className="content px-3">
                         
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                                <label htmlFor="meeting_title">Enter Title</label>
                                            <input type="text" className="form-control" id="meeting_title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Ex: Meeting..." />
                                            <span class="text-danger">{meetingErrorList.title}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group py-2">
                                            <label htmlFor="date">Select Date</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                                {/* <input type="datetime-local" className="form-control" id="start_datetime" value={startDatetime} onChange={(e) => setStartDatetime(e.target.value)} /> */}
                                         
          <input type="date" id="date" value={date} className="form-control" onChange={handleDateChange} required />
     

                                            </div>
                                            <span class="text-danger">{meetingErrorList.date}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                    <div className="form-group py-2">
          <label htmlFor="time">Select Time:</label>
          <select id="time" value={time} className="form-control" onChange={(e) => setTime(e.target.value)} required>
            <option value="">Select Time</option>
            {availableTimeSlots.map((timeSlot, index) => (
              <option key={index} value={timeSlot}>{timeSlot}</option>
            ))}
          </select>
          <span class="text-danger">{meetingErrorList.time}</span>
          </div>
        </div>




                                  
                                </div>

                                <div class="d-flex justify-content-end ms-auto py-3">
                                        <button onClick={sendMeetingEmail} class="btn btn-sm btn_secondary text-white "><i class="bi bi-send"></i>  {loading ? "Sending..." : "Send Message"} </button>
                                </div>
                         
                                </div>
        
      </div> 

      <h3 class="vm_font_bold ps-3 pt-2">Meet History</h3>


<div class="content">
<div class="row pt-3" style={{padding:"10px"}}>
<div class="col-md-8 form-group" >

              <div class="d-flex input_wrapper" >
              <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                  <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                </select>  entries</label>
                </div>
              </div>
          </div>

          <div class="col-md-4 form-group">
              <div class="d-flex input_wrapper">
              
                  <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                  value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
              </div>
          </div>
          </div>


<div class="activity_wrapper pb-5 mb-5">


{filteredMeetHistory.length==0?
<p style={{ textAlign: "center" }}>No records found</p>
:
(filteredMeetHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
<div class="activity_wrapper pb-3 mb-1">
    <div class="row pt-1">
        <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div class="border-bottom col"></div>
            <div class="vm_bg_light_green px-2 py-1 text-center">{formatDate(item.created_at)}</div>
            <div class="border-bottom col"></div>
        </div>
        <div class="col-md-8 col-8 vm_bg_light_green p-2">
            <p class="vm_font_bold">{item.subject} on {formatMeetDate(item.date)} at {formatMeetTime(item.time)} </p>
            <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
        </div>
       
    </div>                    
</div>
)))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                    {filteredMeetHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredMeetHistory.length)} of ${meetLogById.length} entries`}
                </div><div class="col-md-8" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredMeetHistory.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div>





</div>

</div>


      </div>
    
      
      }



      {noteVisible && <div id="noteDiv">
        
        
      <div class="py-4 mt-4 border-top bg-light">

      <div class="form-group pb-4 px-3">
                            <label for="note_subject">Subject</label>
                            <input class="mh-100 form-control" name="note_subject" id="note_subject" cols="100%" value={note_subject}
                            onChange={(event) =>{ setNote_subject(event.target.value); }} placeholder="Enter Subject"/>
                             <span  class="text-danger">{noteErrorList.note_subject}</span>
                        </div>

                        <div class="form-group pb-4 px-3">
                            <label for="note_message">Note Message</label>
                            <textarea class="mh-100 form-control" name="note_message" id="note_message" cols="100%" value={note_message}
                            onChange={(event) =>{ setNote_message(event.target.value); }} rows="3" placeholder="Enter Note Message"></textarea>
                             <span  class="text-danger">{noteErrorList.note_message}</span>
                        </div>

                        <div className="form-group px-3">
                            <label className="d-flex align-items-center">
                                <div
                                    onClick={handleEmailToggle}
                                    className={`toggle-button ${isEmail ? 'on' : 'off'}`}
                                    style={{
                                        width: '30px',
                                        height: '15px',
                                        backgroundColor: isEmail ? '#4caf50' : '#ccc',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '13px',
                                            height: '13px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            top: '1px',
                                            left: isEmail ? '16px' : '1px',
                                            transition: 'left 0.2s',
                                        }}
                                    ></div>
                                </div>
                                <span className="ms-2">Send as Email</span>
                            </label>
                        </div>
      {isEmail ? (
                        <div class="d-md-flex">
                            <div>
                            <select class="form-select ms-3 form-select-sm" name="note_template_select" id="note_template_select" value={note_template_select} onChange={handleNoteTemplateChange}>
                                    <option value="">Select Note Template</option>
                                    {note_template.map((item) => (
                                    <option value={item.note_title}>{item.note_title}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="ms-auto me-3">
                                <button onClick={sendNoteEmail} class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send Note'}</button>
                            </div>
                        </div>
                   ) :(
                <div className="d-md-flex justify-content-end me-3">

                                <button onClick={saveNote} class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Saving...':' Save Note'}</button>
                            </div>
                   )
                }
                  
                  
                    </div>

                    <h3 class="vm_font_bold ps-3 pt-2">Note History</h3>


                    <div class="content">
                    <div class="row pt-3" style={{padding:"10px"}}>
                    <div class="col-md-8 form-group" >
                 
                                  <div class="d-flex input_wrapper" >
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-4 form-group">
                                  <div class="d-flex input_wrapper">
                                  
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                              </div>


                    <div class="activity_wrapper pb-5 mb-5">
    

                    {filteredNoteHistory.length==0?
                <p style={{ textAlign: "center" }}>No records found</p>
                :
(filteredNoteHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                    <div class="activity_wrapper pb-3 mb-1">
                        <div class="row pt-1">
                            <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                                <div class="border-bottom col"></div>
                                <div class="vm_bg_light_yellow px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                                <div class="border-bottom col"></div>
                            </div>
                            <div class="col-md-8 col-8 vm_bg_light_yellow p-2">
                                <p class="vm_font_bold">
                               
                                    Subject | {item.subject}
                                    {item.to ? <span style={{ backgroundColor: "#059618", fontSize: "12px", borderRadius: "50%", marginLeft: "10px" }} class="badge badge-success">E</span> : null}</p>
                                    <p>  
                                <a
                            className="btn btn-link p-0"
                            style={{ fontSize: '0.85rem' }}
                            onClick={() => handleToggleNote(item.id)} // Replace item.id with unique identifier for each email
                            aria-controls={`collapse-text-${item.id}`}
                            aria-expanded={openEmailId === item.id}
                        >
                            {openNoteId === item.id ? 'Show Less' : 'Show More'}
                        </a>
                    </p>
                    <Collapse in={openNoteId === item.id}>
                        <div id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Collapse>
                               
                                <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                            </div>
                           
                        </div>                    
                    </div>
                    )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                        {filteredNoteHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredNoteHistory.length)} of ${noteLogById.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredNoteHistory.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>

                  
                


</div>

                    </div>
                
        
       </div>}
      {uploadVisible && <div id="uploadDiv">
        
      <div class="py-4 mt-4 border-top bg-light">

      <div class="form-group col-3 pb-4 px-0.5">
      <select class="form-select ms-3 form-select-sm" name="upload_template_select" id="upload_template_select" value={upload_template_select} onChange={handleUploadTemplateChange}>
                              <option value="">Select Category</option>
                              {upload_template.map((item) => (
                              <option value={item.upload_title}>{item.upload_title}</option>
                              ))}
                          </select>
</div>
<div class="form-group pb-4 px-3">
                      <label for="upload_subject">Subject</label>
                      <input class="mh-100 form-control" name="upload_subject" id="upload_subject" cols="100%" value={upload_subject}
                      onChange={(event) =>{ setUpload_subject(event.target.value); }} placeholder="Enter Subject"/>
                       <span  class="text-danger">{uploadErrorList.upload_subject}</span>
                  </div>

                  <div class="form-group pb-3 px-3">
                      <label for="note_message">Upload Message</label>
                      <textarea class="mh-100 form-control" name="upload_message" id="upload_message" cols="100%" value={upload_message}
                      onChange={(event) =>{ setUpload_message(event.target.value); }} rows="3" placeholder="Enter Upload Message"></textarea>
                       <span  class="text-danger">{uploadErrorList.upload_message}</span>
                  </div>
           
                      <div class="d-flex pb-3 px-3 align-items-center justify-content-between">
                            <div  >
                                {/* <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image" style={{fontSize: '2rem'}} ></i></label> */}
                                <label class="vm_cursor_pointer border border-1 d-flex align-items-center px-2" for="image" style={{width:"max-content"}}>
                                    <i class="bi bi-upload  h3 me-2"></i>
                                    Upload Files
                                </label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>
                     
 <div>
                            </div>


                      </div>


                      <div class="ms-auto me-3">
                          <button onClick={uploadDocuments}  class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Uploading...':' Upload'}</button>
                      </div>


                      </div>
                      <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {/* {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )} */}
                                                </div>
                                            ))}
                                        </div>
                        

                 
              </div>

            <h3 class="vm_font_bold ps-3 pt-2">Uploaded Documents</h3>
            <ul className="nav nav-tabs" id="messageTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'documents' ? 'active' : ''}`} id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents"  aria-selected={activeTab === 'documents' ? 'true' : 'false'} onClick={() => setActiveTab('documents')}>Documents</button>
          </li>
            
            
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'timeline' ? 'active' : ''}`} id="timeline-tab" data-bs-toggle="tab" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline"   aria-selected={activeTab === 'timeline' ? 'true' : 'false'} onClick={() => setActiveTab('timeline')}>Timeline</button>
          </li>
            
        </ul>     

        <div className="tab-content" id="messageTabsContent">
          <div className={`tab-pane fade ${activeTab === 'documents' ? 'show active' : ''}`} id="documents" role="tabpanel" aria-labelledby="documents-tab">
         

          <div class="row pt-4 d-flex justify-content-center px-4">
                        <h4 class="text-center vm_font_bold pb-2 text-uppercase">Images</h4>
                        
                        {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (              
                     <>{item.document_path ? (
            item.document_path.split(',').map((path, index) => (
               
                isImage(path.trim()) && (
             
                    <div key={index} class="col-md-4 col-6 pb-3 position-relative">
                      {(isUploadImageLoading && item.document_path) ? <img class="w-100" onLoad={handleUploadImageLoad} src={placeholderImage} />
                  :  <img class="w-100" onClick={() => uploadImageClick(`${LARAVEL_API_URL}/${path.trim()}`)}  src={path?`${LARAVEL_API_URL}/${path.trim()}`:placeholderImage} />
                }
                </div>
                  
                )              
            ))
        ) : 'No records found'}</>
)))}

                       
                    </div>
         
          <div class="row pt-4 d-flex justify-content-center px-4 pb-3">
                        <h4 class="text-center vm_font_bold pb-2 text-uppercase">Documents</h4>
                       
                        {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (              
                      <p class="vm_font_bold">{item.document_path ? (
            item.document_path.split(',').map((path, index) => (
               
                isDocument(path.trim()) && (
                <span key={index}>
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2">   
                      
                   {/* <label for="checkbox1" class="d-flex align-items-center ps-2"> */}
                  <i class="bi bi-file-earmark-pdf-fill fs-5"></i>
                  <a  class="ps-2" href={`${LARAVEL_API_URL}/${path.trim()}`} target="_blank">{` ${path.trim().split('/').pop()}`}</a>
                  {/* <p class="ps-2">Document Name 1</p> */}
              {/* </label> */}
                
                </div>
                    </span>
                )              
            ))
        ) : 'No records found'}</p>
)))}  
                        
  
                       
                    </div>
</div>


          <div className={`tab-pane fade ${activeTab === 'timeline' ? 'show active' : ''}`} id="timeline" role="tabpanel" aria-labelledby="timeline-tab">

          <div class="content">
              <div class="row pt-3" style={{padding:"10px"}}>
              <div class="col-md-8 form-group" >
           
                            <div class="d-flex input_wrapper" >
                            <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                              </select>  entries</label>
                              </div>
                            </div>
                        </div>

                        <div class="col-md-4 form-group">
                            <div class="d-flex input_wrapper">
                            
                                <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                            </div>
                        </div>
                        </div>


              <div class="activity_wrapper pb-5 mb-5">


              {filteredUploadHistory.length==0?
          <p style={{ textAlign: "center" }}>No records found</p>
          :
(filteredUploadHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
              <div class="activity_wrapper pb-3 mb-1">
                  <div class="row pt-1">
                      <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
                          <div class="border-bottom col"></div>
                          <div class="vm_bg_light_pink px-2 py-1 text-center">{formatDate(item.created_at)}</div>
                          <div class="border-bottom col"></div>
                      </div>
                      
                      <div class="col-md-8 col-8 vm_bg_light_pink p-2">
                      <p class="vm_font_bold"> {item.subject} - {item.document_path ? (
            item.document_path.split(',').map((path, index) => (
                <span key={index}>
                    {` ${path.trim().split('/').pop()}`}
                    {/* <a style={{ color: 'gray', textDecoration: 'underline' }}>
                        {item.document_path.split(',').length > 1 ?   `View document ${index + 1}` : 'View document'}
                    </a> */}
                    {index !== item.document_path.split(',').length - 1 && ', '}
                </span>
            ))
        ) : 'No records found'}</p>
                          <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
                      </div>
                     
                  </div>                    
              </div>
              )))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
                                  {filteredUploadHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredUploadHistory.length)} of ${uploadLogById.length} entries`}
                              </div><div class="col-md-8" style={{ float: "right" }}>

                                  <ReactPaginate style={{ float: "right" }}
                                      previousLabel={"< Prev"}
                                      nextLabel={"Next >"}
                                      breakLabel={'...'}
                                      pageCount={Math.ceil(filteredUploadHistory.length / perPage)}
                                      onPageChange={handlePageChange}
                                      containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                      disabledClassName={"disabled"}
                                      breakClassName={['page-item']}
                                      breakLinkClassName={'page-link'}
                                      pageClassName={'page-item'}
                                      pageLinkClassName={'page-link'}
                                      previousClassName={'page-item'}
                                      previousLinkClassName={'page-link'}
                                      nextClassName={'page-item'}
                                      nextLinkClassName={'page-link'}
                                      activeClassName={['active']} />


                              </div>

</div>

              </div>


</div>
</div>
        
        
        
        </div>}

        {bookingVisible && <div id="bookingDiv">

            <div class="py-4 mt-4 border-top bg-light">

<div className="content px-3">
                   
                          <div className="row">
                          <div class="col-md-12">
                          <div class="col-md-4">
                                <div class="form-group">
                                    <label for="game_location">Select Location</label>
                                    <select class="form-select" name="game_location" id="game_location" value={game_location} onChange={handleGameLocation}>
                                        <option value="">Select Location</option>
                                        <option value="stouffville">Stouffville</option>
                                        <option value="scarborough">Scarborough</option>
                                    </select>
                                    <span class="text-danger">{error_list.game_location}</span>
                                </div>
                            </div>
                            </div>

                              <div className="col-md-4">
                                  <div className="form-group">
                                          <label htmlFor="game_title">Game Title</label>
                                      <input type="text" className="form-control" id="game_title" value={game_title} onChange={(e) => setGame_title(e.target.value)} placeholder="Enter game title" />
                                      <span class="text-danger">{bookingErrorList.game_title}</span>
                                  </div>
                              </div>

                              <div className="col-md-4">
                                  <div className="form-group">
                                          <label htmlFor="game_description">Game Description</label>
                                      <input type="text" className="form-control" id="game_description" value={game_description} onChange={(e) => setGame_description(e.target.value)} placeholder="Enter game description" />
                                      <span class="text-danger">{bookingErrorList.game_description}</span>
                                  </div>
                              </div>

                            

                              <div class="col-md-4">
                                <div class="form-group">
                                    <label for="game_type">Select Game Type</label>
                                    <select class="form-select" name="game_type" id="game_type" value={game_type} onChange={handleGameType}>
                                        <option value="">Select Game Type</option>
                                        <option value="Public">Public</option>
                                        <option value="Private">Private</option>
                                    </select>
                                    <span class="text-danger">{error_list.game_type}</span>
                                </div>
                            </div>

                              
                              <div className="col-md-3">
                                  <div className="form-group py-2">
                                      <label htmlFor="date">Select Date</label>
                                      <div class="d-flex input_wrapper">
                                          {/* <div class="icon_wrapper">
                                              <i class="bi bi-calendar2-plus icon"></i>
                                          </div> */}
                                   
                        <input type="date" id="booking_date" value={booking_date} className="form-control" onChange={handleBookingDateChange} required />


                                      </div>
                                      <span class="text-danger">{bookingErrorList.booking_date}</span>
                                  </div>
                              </div>


                              <div className="col-md-3">
                              <div className="form-group py-2">
    <label htmlFor="booking_time">Select Time:</label>
    <select id="booking_time" value={booking_time} className="form-control" onChange={handleTimeSlotChange } required>
      <option value="">Select Time</option>
      {bookingAvailableTimeSlots.map((timeSlot, index) => (
        <option key={index} value={timeSlot}>  {convertTo12HourFormat(timeSlot)}</option>
      ))}
    </select>
    <span class="text-danger">{bookingErrorList.booking_time}</span>
    </div>
  </div>

  
<div className="col-md-2">
<div className="form-group py-2">
          <label htmlFor="kids_count">Kids</label>
          <input
            type="number"
            id="kids_count"
            name="kids_count"
            value={kids_count}
            className="form-control"
            onChange={(e) => {setKids_count(e.target.value);}}
            min="0"
            required
          />
        </div>
</div>   <div className="col-md-2">
<div className="form-group py-2">
          <label htmlFor="kids_count">Adults</label>
          <input
            type="number"
            id="adults_count"
            name="adults_count"
            value={adults_count}
            className="form-control"
            onChange={(e) => {setAdults_count(e.target.value);}}
            required
               min="0"
          />
        </div>
</div>   


<div className="col-md-2">
                              <div className="form-group py-2">
                              <label htmlFor="price">Price</label>
                              <input type="number" class="form-control" name="price" id="price" value={timeSlotById.price} placeholder="Price" readOnly
                                    min="0"/>
                                            <span class="text-danger">{bookingErrorList.price}</span>

</div></div>
{booking_time && (
<div className="col-md-10">
<div className="form-group py-2 pt-6">
<label htmlFor="available_space">Available Space : {available_space}</label>

</div>
</div>
)}

<div class="d-flex justify-content-end ms-auto py-3">


        <button onClick={bookGame} class="btn btn-sm btn_secondary text-white "><i class="bi bi-send"></i>  {loading ? "Booking..." : "Book"} </button>
</div>
                          </div>
                          

                        
                   
                          </div>
  
</div> 

<h3 class="vm_font_bold ps-3 pt-2">Booking History</h3>


<div class="content">
<div class="row pt-3" style={{padding:"10px"}}>
<div class="col-md-8 form-group" >

        <div class="d-flex input_wrapper" >
        <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
            <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
          </select>  entries</label>
          </div>
        </div>
    </div>

    <div class="col-md-4 form-group">
        <div class="d-flex input_wrapper">
        
            <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
            value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
        </div>
    </div>
    </div>


<div class="activity_wrapper pb-5 mb-5">


{filteredBookingHistory.length==0?
<p style={{ textAlign: "center" }}>No records found</p>
:
(filteredBookingHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
<div class="activity_wrapper pb-3 mb-1">
<div class="row pt-1">
  <div class="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
      <div class="border-bottom col"></div>
      <div class="vm_bg_light_green px-2 py-1 text-center">{formatDate(item.created_at)}</div>
      <div class="border-bottom col"></div>
  </div>
  <div class="col-md-8 col-8 vm_bg_light_green p-2">
      <p class="vm_font_bold">Booking confirmed for {item.game_location} on {formatBookingDate(item.booking_date)} at {formatBookingTime(item.booking_time)}, kids- {item.kids_count}, Adults- {item.adults_count} </p>
      <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
  </div>
 
</div>                    
</div>
)))}
<div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 10px" }}>
              {filteredBookingHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredBookingHistory.length)} of ${bookingById.length} entries`}
          </div><div class="col-md-8" style={{ float: "right" }}>

              <ReactPaginate style={{ float: "right" }}
                  previousLabel={"< Prev"}
                  nextLabel={"Next >"}
                  breakLabel={'...'}
                  pageCount={Math.ceil(filteredBookingHistory.length / perPage)}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                  disabledClassName={"disabled"}
                  breakClassName={['page-item']}
                  breakLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={['active']} />


          </div>





</div>

</div>



            </div>}


{isSearch && 

<div>
<a id="result"></a>
                    <div class="bg-light px-3 pt-3 mt-3">
                   
                        <h4 class="pb-3 text-center"> <label class="checkbox-inline" style={{float:"left"}}>
    <input type="checkbox"  ref={selectAllCheckboxRef}  onChange={handleSelectAll} /> Select All
  </label>
                        Search Results - {filteredInventory.length}</h4>
                       
                        {filteredInventory.map(function fn(item) {

return (
    
    <>
    <div key={item.id}>
                        <div class="search_result">
                            <div>
                                <input class="d-none" type="checkbox" name="vehicle_check" onChange={(e) => handleRowSelect(e, item.id)} id={`v${item.id}`} value={`v${item.id}`}  checked={selectedRows.includes(item.id)} />
                                <label class="shadow-lg mb-3 w-100 d-flex" for={`v${item.id}`}>
                                    <div class="position-relative p-3">
                                        {selectedRows.includes(item.id) ? <div class="vehicle_checked_wrapper w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center" style={{background:"rgba(5,150,24,.4)"}}>
                                            <i class="bi bi-check-circle text-white display-3"></i>
                                        </div>:null}
                                      
                           
                                        <div class="row pb-md-3">
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.co+m/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0">
                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2">  {item.vehicle_name} {item.trim} {item.drive_type}</h3>
                                            <div class="d-md-flex justify-content-between">
                                                <p class="lh-1"><i class="bi bi-tags fs-6"></i> <span class="vm_font_semi_bold vm_text_color_secondary fs-6"><span class="currency">CAD</span>{item.price.sale_price.toLocaleString()}</span> + Tax & Lic</p>
                                                <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer me-1"></i> Milage</span> : {item.odometer.toLocaleString()} Km</p>
                                            </div>
                                            <p class="text-muted pt-1">7.99% for 84 Months</p>
                                            <div class="row mt-2 border-top pb-2">
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                                    <div class="col p-1 ps-3">{item.body_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                                    <div class="col p-1 ps-3">{item.engine_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                                    <div class="col p-1 ps-3">{item.exterior_color}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                                    <div class="col p-1 ps-3">{item.transmission}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                                    <div class="col p-1 ps-3">{item.drive_type}</div>
                                                </div>
                                                <div class="d-flex border-bottom">
                                                    <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                                    <div class="col p-1 ps-3">{item.stock_no}</div>
                                                </div>
                                            </div>
                                            <Link to={`/inventory-details/${item.id}`} target="_blank">
                                                <button class="btn btn-sm btn_secondary">View More</button>
                                                </Link>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            </div>
                            </div>


                            </>
);
})}
                            <div class="d-flex justify-content-center align-items-center pt-2 pb-5 mb-5 send_option_wrapper">
                         
                            <button onClick={handleGenerate} class="btn btn-sm btn_secondary text-white" ><i class="bi bi-send"></i> {loading?"Generating...":"Generate"}</button>
                        </div>

                        </div>
                      
</div>
}

{infoVisible && 
            <>
               {/* <div class="border vm_bg_secondary p-2 d-flex justify-content-between align-items-center shadow-lg vm_cursor_pointer mx-3 my-3" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
                <h5 class="text-white text-uppercase vm_font_bold ps-2">Booking Information</h5>
              
            </div> */}

<div class="d-flex mt-2 pt-2 px-3 border-top bg-light">
        <div className="vm_content_box_1 mt-2 ">
      
        <div class="title">
            <h2>Booking Details</h2>
            <button style={{ color: "white", backgroundColor: "transparent", border: "none", padding: "0" }} onClick={handleEditBooking}>
        <i className="bi bi-pencil-square vm_bg_secondary text-white pt-1 pb-1 px-2 py-2"></i>
      </button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking - {moment(customerById.booked_date).format('D MMMM YYYY')}  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="col-md-12">
        <div className="form-box py-4 px-3 bg-light border  shadow-sm mb-4">
        <div className="col-md-12">
        <div className='row'>


                                                    {/* <div className="col-md-4">
                                                        <div className="form-group ">
                                                        <label htmlFor="date"> Date</label>
                                                        <div class="d-flex input_wrapper">
                                                                        <div class="icon_wrapper">
                                                                            <i class="bi bi-calendar2-plus icon"></i>
                                                                        </div>
                                                        <input type="date" id="booking_date" value={customerById.booked_date} className="form-control"  readOnly />
                                                        </div>
                                                        <span class="text-danger">{bookingErrorList.booked_date}</span>
                                                    </div>  
                                                        </div>   */}

                                                         <div className="col-md-3">
                                                        <div className="form-group ">
                                                            <label htmlFor="kids_count">Kids</label>
                                                            <input
                                                                type="number"
                                                                id="kids_count"
                                                                name="kids_count"
                                                                value={kids_count}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setKids_count(value); // Update individual kids_count state
                                                    
                                                                    // Update customerById state with the new kids_count value
                                                                    setCustomerById(prevCustomer => ({
                                                                        ...prevCustomer,
                                                                        no_of_children: value,
                                                                    }));
                                                                }}
                                                                min="0"
                                                                required
                                                            />
                                                        </div>
                                                    </div>   <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="adults_count">Adults</label>
                                                            <input
                                                                type="number"
                                                                id="adults_count"
                                                                name="adults_count"
                                                                value={adults_count}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setAdults_count(value); // Update individual kids_count state
                                                    
                                                                    // Update customerById state with the new kids_count value
                                                                    setCustomerById(prevCustomer => ({
                                                                        ...prevCustomer,
                                                                        no_of_adults: value,
                                                                    }));
                                                                }}
                                                                required
                                                                min="0"
                                                            />
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="grand_total">Cost</label>
                                                            <input type="number" className="form-control" id="grand_total" value={sub_total}  readOnly/>

                                                        </div>
                                                    </div>  

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="grand_total">Grand Total</label>
                                                            <input type="number" className="form-control" id="grand_total" value={grand_total} readOnly/>
                                                            <span class="text-danger">{bookingErrorList.grand_total}</span>
                                                        </div>
                                                    </div>
                                                     
                                            <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="booking_time">Select Time:</label>
                                                            <div id="booking_time" className="form-control py-3">
                                                           
                                                            {timeSlotLoading ? (
                                                                    <div>Loading...</div>
                                                                ) : (
                                                                    bookingAvailableTimeSlots
                                                                    .map((timeSlot, index) => {
                                                                        const remainingSpace = availableSpaces[timeSlot] || 0;
                                                                         const game_type = game_types_array.find(item => item.id.toString() === customerById.game_type_id);
                                                                         const isBooked = customerById.booking_time.includes(timeSlot);
                                                                         let isDisabled = Array.isArray(disabledTimeSlots) && disabledTimeSlots.includes(timeSlot) && !isBooked;
                                                                

                                                                          // Check if the time slot is in customerById.booki

                                                                      
                                                                     
                                                                             // Handle specific logic for "Private" game type
                                                                        if (game_type && game_type.game_type_name.toLowerCase().includes('private')) {
                                                                            // Disable slots that do not have exactly max players available
                                                                            if ( remainingSpace !== game_type.max_players_in_a_slot) {
                                                                                isDisabled = true;
                                                                            }
                                                                        }
                                                                     
                                                                     
                                                                      
                                                                  

                                                                        // Handle specific logic for "Birthday Package"
                                                                        // if (customerById.package_name === 'Birthday' ) {
                                                                            
                                                                        //     const userGameCount = parseInt(customerById.no_of_slots, 10); // Replace with the actual number of games selected by the user

                                                                          
                                                                        //         if ( userGameCount <= bookingAvailableTimeSlots.length) {
                                                                        //         const isConsecutiveAvailable = bookingAvailableTimeSlots.slice(index, index + userGameCount).every(slot => availableSpaces[slot] === game_packages[0].max_players_in_a_slot);
                                                                          
                                                                        //         if (!isConsecutiveAvailable) {
                                                                        //             isDisabled = true;
                                                                        //         }
                                                                        //     } 
                                                                        //     else {
                                                                        //         isDisabled = true;
                                                                        //     }

                                                                        // }                                                        
                                                                     

                                                                        if (booking_time.includes(timeSlot) ) {
                                                                            isDisabled = false;
                                                                        }

                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className="form-group-checkbox d-flex align-items-center justify-content-between"
                                                                                style={isDisabled ? { color: '#a9a9a9', opacity: 0.6, pointerEvents: 'none' } : {}}
                                                                            >
                                                                        
                                                                                <div className="d-flex align-items-center">
                                                                                    <div>
                                                                                        <input
                                                                                            style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                                                                                            type="checkbox"
                                                                                            className="checkbox"
                                                                                            id={`timeslot_${index}`}
                                                                                            value={timeSlot}
                                                                                            checked={booking_time.includes(timeSlot)}
                                                                                            onChange={handleTimeSlotChange1}
                                                                                            disabled={isDisabled}
                                                                                            
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`timeslot_${index}`}
                                                                                            style={{ marginLeft: "5px" }}
                                                                                        >
                                                                                            <span style={isDisabled ? { textDecoration: 'line-through' } : {}}>
                                                                                                {timeSlot}
                                                                                            </span>
                                                                                            <span style={{ color: 'green', marginLeft: '30px' }}>
                                                                                                {remainingSpace} left
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                
                                                                )}
                                                               
                                                            </div>
                                                            <span className="text-danger">{bookingErrorList.booked_time}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>

         
        </Modal.Body>
        <Modal.Footer>
        <div class="d-md-flex align-items-center justify-content-end">
                                    <div>
                                        <button  class="btn btn-sm btn_secondary text-white" onClick={updateBooking}><i class="bi bi-save me-2"></i> 
                                         {loading ?'Updating' : 'Update'}
                                        </button>
                                    </div>
                                </div>
        </Modal.Footer>
      </Modal>
            
        </div>
        
<div className="content py-0 bg-white px-3 mb-3 w-100" >
            
          

<div className="row">
<div className="col-md-12">
<div className="row border-top pb-3">
                {[
                    // { label: 'Booking Id', value: customerById.booking_ref_id },
                    { label: 'Game Name', value: customerById.game_name },
                    { label: 'Location', value: customerById.location_name },
                    { label: 'Game Package', value: customerById.game_type_name ? customerById.game_type_name : `${customerById.package_name} - ${customerById.game_package_name}` },
                    { label: 'Booked Date', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-calendar3 me-2 text-success" ></i>
                            <span>{moment(customerById.booked_date).format('D MMMM YYYY')}</span>
                        </div>
                    ) },
                    { label: 'Booked Time', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-clock-fill me-2 text-success" ></i>
                            <span>{customerById.booked_time_formatted}</span>
                           
                        </div>
                    ) },
                    { label: 'Adult Count', value: customerById.no_of_adults },
                    { label: 'Kids Count', value: customerById.no_of_children },
                   
                    {  label: 'Consent Form Status',
                        value: (
                          filteredConsentFormDetails && 
                          filteredConsentFormDetails.length === customerById.no_of_adults &&
                          filteredKidsFormDetails && 
                          filteredKidsFormDetails.reduce((count, form) => count + form.children.length, 0) === customerById.no_of_children ? (
                            <p style={{ color: 'green' }}>Completed</p>
                          ) : (
                            <p style={{ color: 'red' }}>Incomplete</p>
                          )
                        ), },
                      
                    ...(customerById.package_name 
                        ? [
                          
                            { label: 'Birthday Kid Name', value: customerById.birthday_kid_name },
                            ...(customerById.food_details 
                                ? [{ label: 'Food details', value: (customerById.food_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                : []),
                                ...(customerById.drink_details 
                                    ? [{ label: 'Drink details', value: (customerById.drink_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                    : []),
                           

                        ]
                        : []),

            //             { label: 'Total Amount', value: customerById.grand_total ? customerById.grand_total.toFixed(2) : '0.00'},
            //             {
            //                 label: 'Amount Paid',
            //                 value: paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0).toFixed(2)
            //             },
            //             {
            //                 label: 'Balance Amount',
            //                 value: (
            //                     <div className="d-flex justify-content-between align-items-center">
            //                       <span>{(customerById.grand_total - paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0)).toFixed(2)}</span>
            //                       {(customerById.grand_total - paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0)) > 0 && (
            //     <button className="btn btn-sm btn_secondary" onClick={handleShowPaymentModal}>Pay</button>
            // )}
                                
                            
                                
            //                     </div>
            //                   ),
            //               },

            //               {
            //                 label: 'Payment History',
            //                 value: (
            //                     <div className="mt-0">
            //                         {/* <h6>Payment History</h6> */}
            //                         {paymentHistory.length > 0 ? (
            //                             <table class="display dataTable dtr-inline" style={{ width: "100%" }}>
            //                                 <thead>
            //                                     <tr>
            //                                         <th>Date</th>
            //                                         <th>Amount</th>
            //                                         <th>Method</th>
            //                                     </tr>
            //                                 </thead>
            //                                 <tbody>
            //                                     {paymentHistory.map((payment, index) => (
            //                                         <tr key={index}>
            //                                            <td>{moment(payment.created_at).format('D MMM YYYY, h:mm A')}</td>
            //                                             <td>${payment.payment_amount}</td>
            //                                             <td>{payment.payment_method}</td>
            //                                         </tr>
            //                                     ))}
            //                                 </tbody>
            //                             </table>
            //                         ) : (
            //                             <p>No payments made yet.</p>
            //                         )}
            //                     </div>
            //                 ),
            //             },

                ].map((detail, index) => (
                    <div className="d-flex border-bottom py-2" key={index}>
                        <div className="col-5 p-1 border-end vm_font_bold text-secondary">{detail.label}</div>
                        <div className="col-7 p-1 ps-3">{detail.value}</div>
                    </div>
                ))}
            </div>


          
</div>



<Modal show={showPaymentModal} onHide={handleClosePaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Make a Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
<div class="row">
       
<div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="payment_amount">Payment Amount</label>
                                <input
                                    type="number" className="form-control" name="payment_amount" id="payment_amount" value={payment_amount}
                                    onChange={handlePaymentAmountChange} min="0" readOnly
                                />
                                <span className="text-danger">{paymentErrorList.payment_amount}</span>
                            </div>
                        </div>

        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="payment_method">Payment Method</label>
                                <select  class="form-select" id="payment_method" name="payment_method" value={payment_method} 
                                 onChange={handlePaymentMethodChange }>
         <option value="">Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="Credit">Credit </option>
              <option value="Debit">Debit </option>
              <option value="E-transfer">E-transfer</option>
         
        </select>
                                <span className="text-danger">{paymentErrorList.payment_method}</span>
                            </div>
                        </div>

                      
                        </div>
        </Modal.Body>
        <Modal.Footer>
         
          <button class="btn btn-sm btn_secondary" onClick={savePayments}>
           {paymentLoading? 'Processing...':'Confirm Payment'} 
          </button>
        </Modal.Footer>
      </Modal>


</div>






</div>

            
        </div>
    </div>



    <div class="d-flex mt-2 pt-2 px-3 ">
        <div className="vm_content_box_1 mt-2 ">
      
        <div class="title">
            <h2>Payment Details</h2>
        </div>
<div className="content py-0 bg-white px-3 mb-3 w-100" >
            
          

<div className="row">
<div className="col-md-12">
<div className="row border-top pb-3">
                {[

{ label: 'Total Amount', value: customerById.grand_total ? customerById.grand_total.toFixed(2) : '0.00'},
{
    label: 'Amount Paid',
    value: paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0).toFixed(2)
},
{
    label: 'Balance Amount',
    value: (
        <div className="d-flex justify-content-between align-items-center">
          <span>{(customerById.grand_total - paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0)).toFixed(2)}</span>
          {(customerById.grand_total - paymentHistory.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0)) > 0 && (
<button className="btn btn-sm btn_secondary" onClick={handleShowPaymentModal}>Pay</button>
)}
        
    
        
        </div>
      ),
  },

  {
    label: 'Payment History',
    value: (
        <div className="mt-0">
            {/* <h6>Payment History</h6> */}
            {paymentHistory.length > 0 ? (
                <table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Method</th>
                            <th>Mode</th>
                  
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistory.map((payment, index) => (
                            <tr key={index}>
                               <td >{moment(payment.created_at).format('D MMM YYYY, h:mm A')}</td>
                                <td>${payment.payment_amount}</td>
                                <td>{payment.payment_method}</td>
                                <td>{payment.payment_mode.toLowerCase()}</td>
                               
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No payments made yet.</p>
            )}
        </div>
    ),
},


{
    label: 'Transaction History',
    value: (
        <div className="mt-0">
            {/* <h6>Payment History</h6> */}
            {transactions.length > 0 ? (
                <table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>TXID</th>
                            <th>Card </th>
                            <th>Amount</th>
                            <th>Status</th>
                  
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((item, index) => (
                            <tr key={index}>
                                <td style={{width:'80px'}}><p>{moment(item.tran_date).format('D MMM YYYY')}</p><p> {moment(item.tran_time, 'HH:mm:ss').format('h:mm:ss A')}</p></td>
                                <td>{item.transaction_id}</td>
                                <td>{item.tran_card_type} Ending with {item.card_last_four} </td>
                                <td>${item.tran_amount.toFixed(2)}</td>
                                <td>{item.message_text}</td>
                               
                               
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No online transactions made yet.</p>
            )}
        </div>
    ),
},
].map((detail, index) => (
    <div className="d-flex border-bottom py-2" key={index}>
        <div className="col-5 p-1 border-end vm_font_bold text-secondary">{detail.label}</div>
        <div className="col-7 p-1 ps-3">{detail.value}</div>
    </div>
))}
           


</div>
</div>
</div>

</div>
</div>
</div>


            <div class="mt-2 pt-2 px-3 pb-3 ">
            <div class="vm_content_box_1 mt-2">
        <div class="title">
            <h2>Consent Form Details</h2>
           
                       <a
        href={'/consent-form-page/' + customerById.booking_ref_id}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-sm btn_secondary"
        style={{ color: 'white', textDecoration: 'none' }}
    >
        Fill Out Consent Forms
    </a>
                      
                                    
                
            </div>


            <div className="d-flex justify-content-between align-items-center position-relative">
           <div style={{padding:'20px'}}>
       
           </div>
          
            <div className="status-container">
            <span className="status-text">Adults: {filteredConsentFormDetails && filteredConsentFormDetails.length}/{customerById.no_of_adults}</span>
            </div>

   
  </div>
        <div class="content">
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                           <th>S.No</th>
                                            <th>Name</th>
                                            <th>DOB</th>
                                            <th>Submitted on</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredConsentFormDetails && filteredConsentFormDetails.length === 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredConsentFormDetails && filteredConsentFormDetails.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>
                                      <td>{(currentPage * perPage) + index + 1}</td>
                                                <td><p>{item.name}</p> </td>
                                                <td><p> {moment(item.dob).format('D MMMM YYYY')}</p> </td>
                                                <td><p> {moment(item.created_at).format('MMM D, YYYY, h:mm A')}</p> </td>
                                                <td>


<button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                          onClick={(e) => deleteAdultConsent(e, item)}  ><i class="bi bi-trash3"></i> </button>
</td>
                                           
                                            </tr>


                                        ))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
    </tfoot>
                                  
                                </table>
                               
                                    </>
                      

        </div>
       
       
        <div className="d-flex justify-content-between align-items-center position-relative">
           <div style={{padding:'20px'}}>
       
           </div>
        <div className="status-container">
    <span className="status-text">Kids: {filteredKidsFormDetails && filteredKidsFormDetails.reduce((count, form) => count + form.children.length,0)}/{customerById.no_of_children}</span>
  </div>

  </div>
        <div class="content">
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                         
                                            {/* <th>Guardian Name</th> */}
                                            <th>Guardian Name</th>
                                            <th>Child details</th>
                                            <th>Submitted on</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredKidsFormDetails && filteredKidsFormDetails.length === 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredKidsFormDetails && filteredKidsFormDetails.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>
                                              
                                                <td>{item.guardian_name} </td>
                                                <td>
              {item.children.map((child, idx) => (
                <div key={idx} style={{ marginBottom: "8px" }}>
                  <strong>Name:</strong> {child.name}
                  <br />
                  <strong>DOB:</strong> {moment(child.dob).format('D MMMM YYYY')}
                </div>
              ))}
            </td>
            <td><p> {moment(item.created_at).format('MMM D, YYYY, h:mm A')}</p> </td>
            <td >


<button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                          onClick={(e) => deleteKidConsent(e, item)}  ><i class="bi bi-trash3"></i> </button>
</td>
                                            </tr>


                                        ))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                               
                                </table>
                                    </>
                      

        </div>
      
      












{/* 
            <ul className="nav nav-tabs" id="messageTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'adults' ? 'active' : ''}`} id="adults-tab" data-bs-toggle="tab" data-bs-target="#adults" type="button" role="tab" aria-controls="adults"  aria-selected={activeTab === 'adults' ? 'true' : 'false'} onClick={() => setActiveTab('adults')}>Adults</button>
          </li>
            
            
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'kids' ? 'active' : ''}`} id="kids-tab" data-bs-toggle="tab" data-bs-target="#kids" type="button" role="tab" aria-controls="kids"   aria-selected={activeTab === 'kids' ? 'true' : 'false'} onClick={() => setActiveTab('kids')}>Kids</button>
          </li>
            
        </ul>

        <div className="tab-content" id="messageTabsContent">
        <div className={`tab-pane fade ${activeTab === 'adults' ? 'show active' : ''}`} id="adults" role="tabpanel" aria-labelledby="adults-tab"> 
        <div className="status-container">
    <span className="status-text">Adults: {filteredConsentFormDetails && filteredConsentFormDetails.length}/{customerById.no_of_adults}</span>
  </div>
        <div class="content">
            
        <div class="row pt-3">
        
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                           <th>S.No</th>
                                            <th>Name</th>
                                            <th>DOB</th>
                                            <th>Submitted on</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredConsentFormDetails && filteredConsentFormDetails.length === 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredConsentFormDetails && filteredConsentFormDetails.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>
                                      <td>{(currentPage * perPage) + index + 1}</td>
                                                <td><p>{item.name}</p> </td>
                                                <td><p> {moment(item.dob).format('D MMMM YYYY')}</p> </td>
                                                <td><p> {moment(item.created_at).format('MMM D, YYYY, h:mm A')}</p> </td>
                                                <td>


<button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                          onClick={(e) => deleteAdultConsent(e, item)}  ><i class="bi bi-trash3"></i> </button>
</td>
                                           
                                            </tr>


                                        ))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
    </tfoot>
                                
                                </table>
                                <div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                {filteredConsentFormDetails && filteredConsentFormDetails.length > 0 && `Showing 1 to ${Math.min(perPage, filteredConsentFormDetails.length)} of ${filteredConsentFormDetails.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredConsentFormDetails && filteredConsentFormDetails.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                                    </>
                      

        </div>
        </div>
        </div> */}

        {/* <div className="tab-content" id="messageTabsContent">
        <div className={`tab-pane fade ${activeTab === 'kids' ? 'show active' : ''}`} id="kids" role="tabpanel" aria-labelledby="kids-tab">
        <div className="status-container">
    <span className="status-text">Kids: {filteredKidsFormDetails && filteredKidsFormDetails.reduce((count, form) => count + form.children.length,0)}/{customerById.no_of_children}</span>
  </div>
        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                         
                                           
                                            <th>Guardian Name</th>
                                            <th>Child details</th>
                                            <th>Submitted on</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredKidsFormDetails && filteredKidsFormDetails.length === 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -500px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredKidsFormDetails && filteredKidsFormDetails.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>
                                              
                                                <td>{item.guardian_name} </td>
                                                <td>
              {item.children.map((child, idx) => (
                <div key={idx} style={{ marginBottom: "8px" }}>
                  <strong>Name:</strong> {child.name}
                  <br />
                  <strong>DOB:</strong> {moment(child.dob).format('D MMMM YYYY')}
                </div>
              ))}
            </td>
            <td><p> {moment(item.created_at).format('MMM D, YYYY, h:mm A')}</p> </td>
            <td >


<button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                          onClick={(e) => deleteKidConsent(e, item)}  ><i class="bi bi-trash3"></i> </button>
</td>
                                            </tr>


                                        ))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                        <tr>
                                        <th>S.No</th>
                                        <th>Child Name</th>
                                            <th>Child DOB</th>
                                            <th>Submitted on</th>
                                        </tr>
                                    </tfoot> */}
                                {/* </table>
                                <div class="col-md-4" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                {filteredKidsFormDetails && filteredKidsFormDetails.length > 0 && `Showing 1 to ${Math.min(perPage, filteredKidsFormDetails.length)} of ${filteredKidsFormDetails.length} entries`}
                                    </div><div class="col-md-8" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredKidsFormDetails && filteredKidsFormDetails.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                                    </>
                      

        </div>
        </div>
        </div> */} 
        </div>


        </div>
                </>

}
           
{historyVisible && <div style={{height:'80vh'}} >



        <div class="main_title" >
            <h1><i class="bi bi-book"></i> Booking History</h1>
        </div>

        <div class="content_wrapper pt-0">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>Location</th>
                                    <th>Game</th>
                                    <th>Booked By</th>
                                    <th>Booked On</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredBookedHistory.length === 0 ? 
                

                <div class="row" style={{margin: "10px -600px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                            {filteredBookedHistory.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                           
                                <>
                               
                                   <tr >
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.game_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.first_name} {item.last_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td>
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary" onClick={() => getCustomerById(item.id)}  style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>





                                      
                               </div>
                                    </td>
                                </tr></>
))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                         
                        </table>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredBookedHistory.length > 0 && `Showing 1 to ${Math.min(perPage, filteredBookedHistory.length)} of ${filteredBookedHistory.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredBookedHistory.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
      
        </div>


}
                    

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title vm_font_bold text-uppercase" id="exampleModalLabel">Suggested Listing By Attodesk</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="search_result">
                                        {filteredInventory.filter(section => selectedRows.includes(section.id)).map(function fn(item) {

return (
    <>  
    <div key={item.id}>
                                            <div>
                                           

                                                <label class="shadow-lg mb-3 w-100 d-flex">
                                                    <div class="position-relative p-3">
                                                        {/* <div class="vehicle_checked_wrapper w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center">
                                                            <i class="bi bi-check--circle text-white display-3"></i>
                                                        </div> */}
                                                        <div class="row pb-md-3">
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0 pb-3 pb-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                            <div class="col-md-2 col-4 d-flex align-items-start pe-md-0">
                                                                <img class="w-100" src="https://parkers-images.bauersecure.com/wp-images/17053/930x620/00-parkers-best-new-cars-coming-2023-2024-polestar-4-lead-alt.jpg" alt=""/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2">  {item.vehicle_name} {item.trim} {item.drive_type}</h3>
                                                            <div class="d-md-flex justify-content-between">
                                                                <p class="lh-1"><i class="bi bi-tags fs-6"></i>
                                                                    <span class="vm_font_semi_bold vm_text_color_secondary fs-6">
                                                                        <span class="currency">CAD</span>{item.price.sale_price.toLocaleString()}
                                                                    </span> + Tax & Lic
                                                                </p>
                                                                <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer me-1"></i> Milage</span> : {item.odometer.toLocaleString()} Km</p>
                                                            </div>
                                                            <p class="text-muted pt-1">7.99% for 84 Months</p>
                                                            <div class="row mt-2 border-top pb-2">
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                                                    <div class="col p-1 ps-3">{item.body_type}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                                                    <div class="col p-1 ps-3">{item.engine_type}</div>
                                                                </div>
                                                                <div class="6d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                                                    <div class="col p-1 ps-3">{item.exterior_color}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                                                    <div class="col p-1 ps-3">{item.transmission}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                                                    <div class="col p-1 ps-3">{item.drive_type}</div>
                                                                </div>
                                                                <div class="d-flex border-bottom">
                                                                    <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                                                    <div class="col p-1 ps-3">{item.stock_no}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            </div>
                        </>
);
})}
                                        </div>




                                    </div>
                                    <div class="modal-footer send_option_wrapper" >
                                    <div class="option d-flex">
            <input class="d-none" type="checkbox" id="email_send"/>
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="email_send" >
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                Email
            </label>
            <input class="d-none" type="checkbox" id="sms_send"/>
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="sms_send">
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                SMS
            </label>
        </div>
                                      
                                        <button class="btn btn-sm btn_secondary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="bi bi-send"></i> Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>       




        <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Analyse Vehicle</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                       
                            <div class="row">
                            <div class="form-group col-md-6 pb-3">
                             
                                    <label class="pb-0 mb-0 mb-2" for="vehicle_make">Make</label>
                                    <Select 
        value={selectedMakes.map(make => ({ value: make, label: make}))}
        options={makeOptions.map(make => ({ value: make, label: make }))}
        isMulti
        placeholder="Select Make"
        onChange={handleMakeChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      />           {makeError && <span class="text-danger">{makeError}</span>} 

                                </div>
                    
                                
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0 mb-2" for="vehicle_make">Model</label>
                                    <Select
        value={selectedModels.map(model => ({ value: model, label: model}))}
        options={modelOptions.map(model => ({ value: model, label: model }))}
        isMulti
        placeholder="Select Model"
        onChange={handleModelChange}
        // menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options available'}
      /> 
       {/* {modelError && <span class="text-danger">{modelError}</span>}  */}
                                </div>
                               
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="min_price">Min Price</label>
                                    <input type="number" class="form-control mt-2"  name="minPrice" id="minPrice"  value={minPrice} onChange={(e) => setMinPrice(e.target.value)} min="0"/>
                                </div>
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="max_price">Max Price</label>
                                    <input type="number" class="form-control mt-2"  name="max_price" id="max_price"  value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} min="0" />
                                </div>

                                <div class="form-group col-md-6 pb-3">                    
                                    <label class="pb-0 mb-0" for="start_km">Min KM</label>
                                    <input  type="number" class="form-control mt-2"  name="minKilometer" id="minKilometer"
            value={minKilometer} onChange={(e) => setMinKilometer(e.target.value)} min="0"/>
                                    
                                </div>
                                <div class="form-group col-md-6 pb-3">
                                    <label class="pb-0 mb-0" for="to_year">Max KM</label>
                                    <input type="number"  class="form-control mt-2"  name="maxKilometer" id="maxKilometer" 
            value={maxKilometer} onChange={(e) => setMaxKilometer(e.target.value)} min="0"/>
                                </div>
                               
                            </div>
                       
                    </div>
                    <div class="modal-footer">
                       
                            <button class="btn btn-primary" 
                             onClick={search}>{loading ? "Searching..." : "Search"}</button>
                     
                    </div>
                </div>
            </div>
        </div>



        
    </div></>
            


                <a id="contact_history" class="a_id_contact_history position-relative"></a>
                <div class="activity bg-white" style={{width:'40%'}}>
                    
<div class="d-flex justify-content-between">
    <div class="main_title bg-white border-bottom w-100" >
        <h1><i class="bi bi-card-checklist"></i>Activity History</h1>
        <div class="dropdown">
            <i class="bi bi-three-dots-vertical vm_cursor_pointer me-0" data-bs-toggle="dropdown" aria-expanded="false"></i>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
        </div>
    </div>
</div>
<div className="activity_wrapper">
  {inquiryById.length === 0 &&
  emailLogById.length === 0 &&
  smsLogById.length === 0 &&
  noteLogById.length === 0 &&
  meetLogById.length === 0 &&
  uploadLogById.length === 0 ? (
    <p style={{ textAlign: "center", padding: "20px" }}>No Activity found</p>
  ) : (
    [...inquiryById, ...emailLogById, ...smsLogById, ...noteLogById, ...meetLogById, ...uploadLogById,
      ...customerById.consent_form_details,
      ...customerById.kids_form_details
    ]
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((item, index) => (
        <div className="row pt-3" key={`activity-${index}`}>
          <div className="col-md-4 col-4 d-flex justify-content-center align-items-center pe-md-0">
            <div className="border-bottom col"></div>
            <div className={`${
              emailLogById.includes(item) ? 'vm_bg_light_red' :
              inquiryById.includes(item) ? 'vm_bg_light_orange' :
              smsLogById.includes(item) ? 'vm_bg_light_blue' :
              meetLogById.includes(item) ? 'vm_bg_light_green' :
              uploadLogById.includes(item) ? 'vm_bg_light_pink' :
              customerById.consent_form_details.includes(item) ? 'vm_bg_light_green' :
              customerById.kids_form_details.includes(item) ? 'vm_bg_light_green' :
              'vm_bg_light_yellow'
            } px-2 py-1 text-center`}>
              {formatDate(item.created_at)}
            </div>
            <div className="border-bottom col"></div>
          </div>

          <div className={`col-md-8 col-8 ${
            emailLogById.includes(item) ? 'vm_bg_light_red' :
            inquiryById.includes(item) ? 'vm_bg_light_orange' :
            smsLogById.includes(item) ? 'vm_bg_light_blue' :
            meetLogById.includes(item) ? 'vm_bg_light_green' :
            uploadLogById.includes(item) ? 'vm_bg_light_pink' :
            customerById.consent_form_details.includes(item) || customerById.kids_form_details.includes(item) ? 'vm_bg_light_green' :
            'vm_bg_light_yellow'
          } p-2`}>
            {emailLogById.includes(item) && (
              <>
                <span className="vm_font_bold">Email sent</span>
                <div className="col-md-12 col-9 vm_bg_light_red">
                  <p className="vm_font_bold">Subject | {item.subject}</p>
                  <p>
                    <a
                      className="btn btn-link p-0"
                      style={{ fontSize: '0.85rem' }}
                      onClick={() => handleToggleEmailActivity(item.id)}
                      aria-controls={`collapse-text-${item.id}`}
                      aria-expanded={openActivityEmailId === item.id}
                    >
                      {openActivityEmailId === item.id ? 'Show Less' : 'Show More'}
                    </a>
                  </p>
                  <Collapse in={openActivityEmailId === item.id}>
                    <div id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Collapse>
                </div>
              </>
            )}
            {inquiryById.includes(item) && <span>Inquiry submitted</span>}
            {smsLogById.includes(item) && 
             <><span className="vm_font_bold">Sms sent</span><p >
                          <a
                              className="btn btn-link p-0"
                              style={{ fontSize: '0.85rem' }}
                              onClick={() => handleToggleSmsActivity(item.id)}
                              aria-controls={`collapse-text-${item.id}`}
                              aria-expanded={openActivitySmsId === item.id}
                          >
                              {openActivitySmsId === item.id ? 'Show Less' : 'Show More'}
                          </a>
                      </p>
                      <Collapse  in={openActivitySmsId === item.id}>
                    <div style={{paddingBottom:'5px'}} id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Collapse>
                      </>
            
            }

            {meetLogById.includes(item) && <span>Appointment email sent</span>}
            {uploadLogById.includes(item) && <span>Document uploaded</span>}
            {customerById.consent_form_details.includes(item) && (
              customerById.consent_form_details
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .filter(detail => detail === item)
                .map((detail, index) => (
                  <div key={index}>
                     <span className="vm_font_bold">
                     {Number(detail.is_deleted) === 1 ? 'Adult consent form deleted' : 'Adult consent form submitted'}
        </span>
        <p>Name: {detail.name} | Date of Birth: {moment(detail.dob).format('D MMMM YYYY')}</p>
                  </div>
                ))
            )}
            {customerById.kids_form_details.includes(item) && (
              customerById.kids_form_details
               .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .filter(detail => detail === item)
                .map((detail, index) => (
                  <div key={index}>
                    <span className="vm_font_bold">
                    {Number(detail.is_deleted) === 1 ? 'Kids consent form deleted' : 'Kids consent form submitted'}
                    </span>
                    <p>Guardian Name: {detail.guardian_name}</p>
                    {detail.children.map((child, childIndex) => (
                      <div key={childIndex}>
                        <p>Child Name: {child.name} | Date of Birth: {moment(child.dob).format('D MMMM YYYY')}</p>
                      </div>
                    ))}
                  </div>
                ))
            )}
            {noteLogById.includes(item) && item.to && (
              <>
                <span className="vm_font_bold">Note email sent</span>
                <div className="col-md-12 col-9 vm_bg_light_yellow">
                  <p className="vm_font_bold">Subject | {item.subject}</p>
                  <p>
                    <a
                      className="btn btn-link p-0"
                      style={{ fontSize: '0.85rem' }}
                      onClick={() => handleToggleNoteActivity(item.id)}
                      aria-controls={`collapse-text-${item.id}`}
                      aria-expanded={openActivityNoteId === item.id}
                    >
                      {openActivityNoteId === item.id ? 'Show Less' : 'Show More'}
                    </a>
                  </p>
                  <Collapse in={openActivityNoteId === item.id}>
                    <div id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Collapse>
                </div>
              </>
            )}
            {noteLogById.includes(item) && !item.to && (
              <>
                <span className="vm_font_bold">Note Saved</span>
                <div className="col-md-12 col-9 vm_bg_light_yellow">
                  <p className="vm_font_bold">Subject | {item.subject}</p>
                  <p>
                    <a
                      className="btn btn-link p-0"
                      style={{ fontSize: '0.85rem' }}
                      onClick={() => handleToggleNoteActivity(item.id)}
                      aria-controls={`collapse-text-${item.id}`}
                      aria-expanded={openActivityNoteId === item.id}
                    >
                      {openActivityNoteId === item.id ? 'Show Less' : 'Show More'}
                    </a>
                  </p>
                  <Collapse in={openActivityNoteId === item.id}>
                    <div id={`collapse-text-${item.id}`} dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Collapse>
                </div>
              </>
            )}
            
            <p>{getDayName(item.created_at)} at {formatTime(item.created_at)}</p>
          </div>
        </div>
      ))
  )}
  <div className="py-5"></div>
  <div className="py-3"></div>
</div>
        </div>
    </div>



    

              
               
</div>
          
           
</div>
</div>

          
           

    );
}
export default LeadsDetails;