

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import UserContext from './UserContext';
import moment from 'moment';
import {Link, useParams, useNavigate} from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap'; 
import QRCode from 'qrcode';

function ViewBooking() {

    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;

    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [timeSlotLoading, setTimeSlotLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const [kids_count, setKids_count] = useState(0);
    const [adults_count, setAdults_count] = useState(0);
    const [deposit_amount, setDeposit_amount] = useState(0);
    const [min_game, setMin_game] = useState(0);
    const [deposit_details, setDeposit_details] = useState('');
    const [grand_total, setGrand_total] = useState(0);
    const [amount_paid, setAmount_paid] = useState(0);
    const [minPlayers, setMinPlayers] = useState(0);
    const [maxPlayers, setMaxPlayers] = useState(0);
    const [hst, setHst] = useState(0);
    const [basic_price, setBasic_price] = useState(0);
    const [game_title, setGame_title] = useState('');
    const [game_description, setGame_description] = useState('');
    const [available_space, setAvailable_space] = useState('');
    const [bookedTimes, setBookedTimes] = useState([]);
    const [game_location, setGame_location] = useState('stouffville');
    const [game_type, setGame_type] = useState('');
    const [groupedBookedTimes, setGroupedBookedTimes] = useState({});
    const [bookingErrorList, setBookingErrorList] = useState([]);
    const [totalPlayersErrorList, setTotalPlayersErrorList] = useState([]);
    const [booking_date, setBooking_date] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [booking_time, setBooking_time] = useState('');
    const [cart_details, setCart_details] = useState('');
    const [timeSlotById, setTimeSlotById] = useState([]);
    const [gameTypeById, setGameTypeById] = useState([]);
    const [bookingAvailableTimeSlots, setBookingAvailableTimeSlots] = useState([]);
    const [disabledTimeSlots, setDisabledTimeSlots] = useState([]);
    const userContext = useContext(UserContext);
    const [team, setTeam] = useState([]);

    const [games, setGames] = useState([]);
    const [game_types, setGame_types] = useState([]);
    const [game_packages, setGame_packages] = useState([]);
    const [game_types_array, setGame_types_array] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedGameType, setSelectedGameType] = useState('');
    const [selectedGameLocation, setSelectedGameLocation] = useState('');

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [note, setNote] = useState("");
    const [locations, setLocations] = useState([]);
    const [game_available, setGame_available] = useState([]);
    const [timeSlotDetails, setTimeSlotDetails] = useState([]);
    const [availableSpaces, setAvailableSpaces] = useState({});
    const [error, setError] = useState('');
    const [bookingDetails, setBookingDetails] = useState({});
    const [view, setView] = useState('form');
    const [selectedPackageType, setSelectedPackageType] = useState("");
    const [selectedBirthdayPackage, setSelectedBirthdayPackage] = useState('');
    const [birthdayKid, setBirthdayKid] = useState("");
    const [selectedFoods, setSelectedFoods] = useState('');
    const [selectedDrinks, setSelectedDrinks] = useState('');
    const [birthdayPrice, setBirthdayPrice] = useState([]);
    const [game_count, setGame_count] = useState([]);
    const [foodOptions, setFoodOptions] = useState([]);
    const [drinkOptions, setDrinkOptions] = useState([]);
    const [birthday_packages, setBirthday_packages] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [email_error_list, setEmailErrorList] = useState({
        to: '',
        subject: '',
        message: ''
    });
    const [emailData, setEmailData] = useState({
        to: '',
        subject: '',
        message: ''
    });

    const [email_template, setEmail_template] = useState([]);
    const [template, setTemplate] = useState("");
    const [template_details, setTemplate_details] = useState([]);
    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [payment_method, setPayment_method] = useState('');
    const [fullyBookedDates, setFullyBookedDates] = useState({});


    const handlePaymentMethodChange = (e) => {
        setPayment_method(e.target.value);
      };
    // const locationIds = userContext.userDetails.location_id
    // ? userContext.userDetails.location_id.split(',').map(id => parseInt(id.trim(), 10))
    // : [];
    
    // // Filter locations to include only those with IDs in locationIds
    // const filteredLocations = locations.filter(location => locationIds.includes(location.id));

const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

const filteredEvents = events.filter(event => event.start >= today);

    const showToast = (message) => {
        Swal.fire({
            icon: 'success',
            title: message,
            color: "white",
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
                popup: 'sweetalert-popup',
                title: 'sweetalert-title',
                icon: 'sweetalert-icon',
            },
            background: '#333',
            showCloseButton: true,
            padding: '4px',
        });
    };

    function assignColorsToLocations(filteredLocations) {
        const predefinedColors = ["#FF5733", "#006400", "#3357FF"]; // Your predefined colors
        const locationColors = {};
    
        filteredLocations.forEach((location, index) => {
            locationColors[location.location_name.trim()] = predefinedColors[index % predefinedColors.length];
        });
    
        return locationColors;
    }

    
    const locationColors = assignColorsToLocations(filteredLocations);

    useEffect(() => {
        if (userContext.username) {
            getEmailTemplateList();
           // fetchEventTimeSlots();
            getLocationByUser();
        }
    }, [userContext.username]); 

    useEffect(() => {
        // Check if email_template is loaded and has the required default template
        const defaultTemplate = email_template.find(item => item.template_name === 'Booking Confirmation');
        if (defaultTemplate) {
            setTemplate('Booking Confirmation');
            setTemplate_details(defaultTemplate);
    
            setEmailData({
                ...emailData,
                subject: defaultTemplate.subject,
                message: defaultTemplate.message
            });
        } else {
            // Reset if default template is not found
            setTemplate("");
            setTemplate_details({});
            setEmailData({ subject: "", message: "", to: "" });
        }
    }, [email_template]);
    
    async function getLocationByUser() {
        setIsLoading(true);
        try {
            const res = await axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`);
            if (res.data.status === 200) {
                const filtered = res.data.locations;
                setFilteredLocations(filtered);
    
                if (filtered.length > 0) {
                    const defaultLocation = filtered[0].id;
                    setSelectedGameLocation(defaultLocation);
                      fetchGameAvailable(defaultLocation);
                }
            } else if (res.data.status === 404) {
                // Handle 404 status if needed
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching locations:", error);
        } 
    
    }


    useEffect(() => {
  
        if (selectedGameType ) {
            //setIsLoading(true);
            fetchGameTypesArray();
            //setIsLoading(false);
        }
    }, [selectedGameType]);


    const validateTotalPlayers = () => {
        const totalPlayers = Number(kids_count) + Number(adults_count);
      

        if (totalPlayers < minPlayers || totalPlayers > maxPlayers) {
            setBookingErrorList(`The total number of players must be between ${minPlayers} and ${maxPlayers}.`);
       console.log(`The total number of players must be between ${minPlayers} and ${maxPlayers}.`);
        } else if (selectedBirthdayPackage && totalPlayers > birthday_packages.max_players_in_a_slot) {
            console.log(`The total number of players for a birthday package must be 1 to ${birthday_packages.max_players_in_a_slot}.`);
            setBookingErrorList(`The total number of players for a birthday package must be 1 to ${birthday_packages.max_players_in_a_slot}.`);
        } else {
            setBookingErrorList(''); // No error
        }
    };



    useEffect(() => {
        // const fetchData = async () => {
        //     setIsLoading(true);
        //     await fetchEvents();
        //     setIsLoading(false);
        // };
  
        if (selectedGameLocation && selectedGame) {
            setIsLoading(true);
            fetchEvents();
         
            //setIsLoading(false);
        }
    }, [selectedGameLocation, selectedGame]);


    const handleViewBookings = (bookingId) => {
        navigate('/booking-details/'+bookingId);      
      };


    const fetchLocations = async () => {
        try {

            const response = await axios.get(`${API_URL}/view-game-location`);
            setLocations(response.data.gameLocation || []);
        } catch (error) {
            console.error("Error fetching locations:", error);
        }
      //  setIsLoading(false);
    };

    function onSelectImages(event){
        const selectedImages = Array.from(event.target.files);

            const validImages = selectedImages.filter(image => image.size <= 15 * 1024 * 1024); // 15MB 
            if (validImages.length !== selectedImages.length) {
              Swal.fire({
                text: "Image file size should be less than or equal to 15MB.",
                icon: "error"
              });
            }       
            setImages([...images, ...validImages]);       
        
           //  console.log([...images, ...validImages]);
           // const filePreviews = validImages.map((image) => URL.createObjectURL(image));
    
            const filePreviews = validImages.map(file => {
            if (file.type === 'application/pdf') {
                return { type: 'pdf', name: file.name , url: URL.createObjectURL(file)};
            } else if (file.type === 'video/mp4') {
              return { type: 'video', name: file.name, url: URL.createObjectURL(file) };
             } else {
                return { type: 'image', name: file.name ,url: URL.createObjectURL(file) };
            }
        });
    
            setPreviews([...previews, ...filePreviews]);
            

        
      }

    const fetchOptionsForBirthdayPackage = async (selectedBirthdayPackageId) => {
        try {
       
          const response = await axios.get(`${API_URL}/view-game-package-by-selectedBirthdayId/${selectedBirthdayPackageId}`);
          setBirthday_packages(response.data.gamePackages[0]);

         const foodDetails = response.data.gamePackages[0].food_details || '';
      const drinkDetails = response.data.gamePackages[0].drink_details || '';

      setFoodOptions(foodDetails.split(',').map(item => item.trim()).filter(item => item));
      setDrinkOptions(drinkDetails.split(',').map(item => item.trim()).filter(item => item));
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      };


    const getGameTypeList = async (selectedGameType) => {
        try {
            const res = await axios.get(`${API_URL}/view-time-slot-by-game-type/${selectedGameType}`);
            if (res.data.status === 200) {
                const timeSlot = res.data.gameType[0];
                setGameTypeById(timeSlot);


            } else if (res.data.status === 404) {
                setBookingAvailableTimeSlots([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching game type:', error);
        }
    };

    const getTimeSlotList = async (selectedGameType) => {
        try {
            const res = await axios.get(`${API_URL}/view-time-slot-by-game-type/${selectedGameType}`);
            if (res.data.status === 200) {
                const timeSlot = res.data.gameType[0];
                setGameTypeById(timeSlot);


                const { start_time, end_time, time_slot_interval, max_players_in_a_slot } = timeSlot;
                const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_slot_interval, 10));
                const formattedTimeSlots = formatTimeSlots(timeSlots); // Format time slots

                setBookingAvailableTimeSlots(formattedTimeSlots);


                // const availableTimes = generateBookingTimeSlots(bookedTimes, formattedTimeSlots);
                // setBookingAvailableTimeSlots(availableTimes);
                // setAvailable_space(reserved_slot);
                // console.log('space',availableTimes)


            } else if (res.data.status === 404) {
                setBookingAvailableTimeSlots([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching time slots:', error);
        }
    };

    const handleTemplateChange  = (event) => {
        setTemplate(event.target.value);
        if(event.target.value){
            const template_details = email_template.find(item=> item.template_name === event.target.value);
            setTemplate_details(template_details);

        //     const currentPageUrl = window.location.href;

        //  // let updatedSubject = template_details.subject.replace(/\[model\]/g, vehicleListName ? vehicleListName : '')
          let updatedSubject = template_details.subject
        //   .replace(/\[model\]/g, vehicleListName && (selectedMakes.split(',').length == 1 || selectedModels.split(',').length == 1 )? vehicleListName : '')
          let updatedMessage = template_details.message
        //      // .replace(/\[model\]/g, vehicleListName  ? vehicleListName : '')
        //       .replace(/\[model\]/g, vehicleListName && (selectedMakes.split(',').length == 1 || selectedModels.split(',').length == 1 )? vehicleListName : '')
        //       .replace(/\[link\]/g, currentPageUrl + '?email=true')
        
              setSubject(updatedSubject);
              setMessage(updatedMessage);


              setEmailData({
                ...emailData,
                subject: updatedSubject,
                message: updatedMessage
            });

            // if (template_details.image_path) {

            //     const filepathArray = template_details.image_path.split(',');
            //     const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
            //     setPreviews(imageUrlArray);
    
            //     const initialPreviewsPromises = imageUrlArray.map(async filepath => {
            //         const filename = filepath.substring(filepath.lastIndexOf('/') + 1); // Extract filename from URL
            //         const fileType = filename.split('.').pop().toLowerCase(); // Extract file extension and convert to lowercase
                
            //         if (fileType === 'pdf') {
            //             // For PDF files
            //             try {
            //                 const response = await fetch(filepath);
            //                 const blob = await response.blob();
            //                 return new File([blob], filename, { type: 'application/pdf' });
            //             } catch (error) {
            //                 console.error('Error fetching PDF file:', error);
            //                 return null;
            //             }
            //         } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
            //             try {
            //                 const response = await fetch(filepath);
            //                 const blob = await response.blob();
            //                 return new File([blob], filename, { type: `video/${fileType}` });
            //             } catch (error) {
            //                 console.error('Error fetching video:', error);
            //                 return null;
            //             }
            //         } else {
            //             // For image files
            //             try {
            //                 const response = await fetch(filepath);
            //                 const blob = await response.blob();
            //                 return new File([blob], filename, { type: `image/${fileType}` });
            //             } catch (error) {
            //                 console.error('Error fetching image:', error);
            //                 return null;
            //             }
            //         }
            //     });
                
            //     Promise.all(initialPreviewsPromises)
            //         .then(initialPreviews => {
            //             // Filter out null values (in case of error)
            //             const filteredPreviews = initialPreviews.filter(preview => preview !== null);
            //             setImages(filteredPreviews);
            //         })
            //         .catch(error => {
            //            // console.error('Error creating initial previews:', error);
            //             setImages([]); // Set empty array in case of error
            //         });
                
            //     const updatedPreviews = imageUrlArray.map(url => {
            //         const fileType = getFileType(url);
            //         return { type: fileType, url: url };
            //     });
            //     setPreviews(updatedPreviews);
            // } 
        
        }
        else
        {   setTemplate(""); 
            setTemplate_details([]);
            setSubject("");
            setMessage("");
            // setPreviews([]);
            // setImages([]);
        }
        };
    

        function getEmailTemplateList()
        {    
          axios.get(`${API_URL}/view-email-template`).then(res=>{
              if(res.data.status === 200){
                  setEmail_template(res.data.emailTemplate);       
              }     
              else if(res.data.status === 404){
                setEmail_template([]);
                 // setMessage(res.data.message);     
                  }             
           });    
        }

    // const fetchData = async (selectedGameType) => {

    //     try {
    //       const response = await axios.get(`${API_URL}/consolidated-data`, {
    //         params: {
    //           location_id: selectedGameLocation,
    //           game_id: selectedGame,
    //           game_type_id: selectedGameType,
    //           created_by:userContext.userDetails.id,
    //         }
    //       });
    //       setTimeSlotDetails(prevState => ({
    //         ...prevState,
    //         [gameTypeId]: response.data // Assuming the response is an array with one object for the selected game type
    //       }));

    //       console.log('fetchData', response.data);
    //     } catch (error) {       
    //       console.error('Error fetching data:', error);
    //     }
    //     setIsLoading(false);
    //   };


    const fetchTimeSlots = async () => {
        setTimeSlotLoading(true);
        try {
         
            const response = await axios.get(`${API_URL}/fetch-time-slots`, {
                params: {
                    location_id: selectedGameLocation,
                    game_id: selectedGame,
                    game_type_id: selectedGameType,
                    selected_date: selectedDate,
                    created_by: userContext.userDetails.id,
                }
            });
            //   setTimeSlotDetails(prevState => ({
            //     ...prevState,
            //     [selectedGameType]: response.data // Assuming the response is an array with one object for the selected game type
            //   }));


            setTimeSlotDetails(response.data);
          
            const timeSlot = response.data;

            const { start_time, end_time, time_slot_interval } = timeSlot;
            const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_slot_interval, 10));
            const formattedTimeSlots = formatTimeSlots(timeSlots);


            //   console.log('Generated Time Slots:', timeSlots);
            const comparisonResponse = await axios.post(`${API_URL}/compare-time-slots`, {
                location_id: selectedGameLocation,
                game_id: selectedGame,
                game_type_id: selectedGameType,
                selected_date: selectedDate,
                generated_time_slots: formattedTimeSlots,
                no_of_adults: adults_count,
                no_of_children: kids_count,
            });

            const { disabledTimeSlots, availableSpaces } = comparisonResponse.data;

            setTimeSlotDetails(timeSlot);
            setBookingAvailableTimeSlots(formattedTimeSlots);
            setDisabledTimeSlots(disabledTimeSlots);
            // console.log(disabledTimeSlots)
            setAvailableSpaces(availableSpaces);

            await new Promise(resolve => setTimeout(resolve, 300));

           //  console.log('Fetched Data:', comparisonResponse.data);

        } catch (error) {
            console.error('Error fetching data:', error);

        }
        setTimeSlotLoading(false);
    }


    const fetchEventTimeSlots = async () => {
       // setTimeSlotLoading(true);
        try {
         
            const response = await axios.get(`${API_URL}/fetch-time-slots-events`, {
                params: {
                    location_id: selectedGameLocation,
                    game_id: selectedGame,
                    // game_type_id: selectedGameType,
                    // selected_date: selectedDate,
                    created_by: userContext.userDetails.id,
                }
            });
            //   setTimeSlotDetails(prevState => ({
            //     ...prevState,
            //     [selectedGameType]: response.data // Assuming the response is an array with one object for the selected game type
            //   }));


           // setTimeSlotDetails(response.data);
           console.log(response.data,selectedGameLocation,selectedGame);
          
             const timeSlot = response.data;

             const { start_time, end_time, time_slot_interval } = timeSlot;
             const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_slot_interval, 10));
             const formattedTimeSlots = formatTimeSlots(timeSlots);


               console.log('Generated Time Slots:', timeSlots);
 
            const comparisonResponse = await axios.post(`${API_URL}/compare-time-slots-events`, {
                location_id: selectedGameLocation,
                game_id: selectedGame,
                // game_type_id: selectedGameType,
                // selected_date: selectedDate,
                generated_time_slots: formattedTimeSlots,
                // no_of_adults: adults_count,
                // no_of_children: kids_count,
            });

            // const { disabledTimeSlots, availableSpaces } = comparisonResponse.data;

           // setTimeSlotDetails(timeSlot);
          //  setBookingAvailableTimeSlots(formattedTimeSlots);
            //setDisabledTimeSlots(disabledTimeSlots);
            // console.log(comparisonResponse.data)
           // setAvailableSpaces(availableSpaces);

           // await new Promise(resolve => setTimeout(resolve, 300));

            console.log('Fetched Data:', comparisonResponse.data);

        } catch (error) {
            console.error('Error fetching data:', error);

        }
        //setTimeSlotLoading(false);
    }




    const fetchTimeSlotsBirthday = async () => {
        setTimeSlotLoading(true);
        try {
            const response = await axios.get(`${API_URL}/fetch-time-slots-birthday`, {
                params: {
                    location_id: selectedGameLocation,
                    game_id: selectedGame,
                    game_package_id: selectedBirthdayPackage,
                    selected_date: selectedDate,
                    created_by: userContext.userDetails.id,
                }
            });
           


            setTimeSlotDetails(response.data);
            const timeSlot = response.data;

            const { start_time, end_time, time_slot_interval } = timeSlot;
            const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_slot_interval, 10));
            const formattedTimeSlots = formatTimeSlots(timeSlots);

    
            const comparisonResponse = await axios.post(`${API_URL}/compare-time-slots-birthday`, {
                location_id: selectedGameLocation,
                game_id: selectedGame,
                game_package_id: selectedBirthdayPackage,
                // game_type_id: selectedGameType,
                selected_date: selectedDate,
                generated_time_slots: formattedTimeSlots,
                no_of_adults: adults_count,
                no_of_children: kids_count,
            });

            const { disabledTimeSlots, availableSpaces } = comparisonResponse.data;

             
            
            setTimeSlotDetails(timeSlot);
            setBookingAvailableTimeSlots(formattedTimeSlots);
            setDisabledTimeSlots(disabledTimeSlots);
            setAvailableSpaces(availableSpaces);

            await new Promise(resolve => setTimeout(resolve, 300));

            // console.log('Fetched Data:', comparisonResponse.data);

        } catch (error) {
            console.error('Error fetching data:', error);

        }
        setTimeSlotLoading(false);
    }




    function fetchGameAvailable(locationId) {
       // setIsLoading(true); // Start loader for game fetching
        axios.get(`${API_URL}/view-available-game-by-locationId/${locationId}`)
            .then(res => {
                if (res.data.games) {
                    setGame_available(res.data.games);
                    if (res.data.games.length > 0) {
                        setSelectedGame(res.data.games[0].id);
                    } else {
                        setSelectedGame('');
                    }
                } else {
                    setGame_available([]);
                }
            })
            .catch(error => {
                console.error("Error fetching games:", error);
            })
           
    }

    const getTimeSlotList1 = async (bookedTimes) => {
        try {
            const res = await axios.get(`${API_URL}/view-time-slot`);
            if (res.data.status === 200) {
                const timeSlot = res.data.timeslot[0];
                setTimeSlotById(timeSlot);


                const { start_time, end_time, time_interval, reserved_slot } = timeSlot;
                const timeSlots = calculateTimeSlots(start_time, end_time, parseInt(time_interval, 10));
                const formattedTimeSlots = formatTimeSlots(timeSlots); // Format time slots

                setBookingAvailableTimeSlots(formattedTimeSlots);

                // const initialAvailableSpace = {};
                // formattedTimeSlots.forEach(slot => {
                //     initialAvailableSpace[slot] = reserved_slot - (groupedBookedTimes[slot] || 0);
                // });
                // setAvailable_space(initialAvailableSpace);



                const availableTimes = generateBookingTimeSlots(bookedTimes, formattedTimeSlots);
                setBookingAvailableTimeSlots(availableTimes);
                // setAvailable_space(reserved_slot);
               


            } else if (res.data.status === 404) {
                setBookingAvailableTimeSlots([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching time slots:', error);
        }
    };



    useEffect(() => {
     
    setBooking_time([]);
    if(selectedGameType && (adults_count + kids_count)  > 1){
   
  const totalPlayers = Number(kids_count) + Number(adults_count);
  if (totalPlayers < minPlayers || totalPlayers > maxPlayers) {
      setTotalPlayersErrorList(`The total number of players must be between ${minPlayers} and ${maxPlayers}.`);
      setBookingAvailableTimeSlots([]);
  } 
 
     else {
        fetchTimeSlots();
        setTotalPlayersErrorList('');

     }
}       

if(selectedBirthdayPackage && game_count > 1 &&  (adults_count + kids_count)  > 1)
{
    // const filteredPackage = game_packages.filter(item => item.id === Number(selectedBirthdayPackage));
    //         const max_players_in_a_slot = filteredPackage[0].max_players_in_a_slot;

    const totalPlayers = Number(kids_count) + Number(adults_count);
    if (selectedBirthdayPackage && totalPlayers > birthday_packages.max_players_in_a_slot) {
        setTotalPlayersErrorList(`The total number of players for a birthday package must be between 1 and ${birthday_packages.max_players_in_a_slot}.`);
    } 
    
    else {
        setTotalPlayersErrorList('');
        fetchTimeSlotsBirthday();
     }
}

    }, [adults_count, kids_count, game_count]);




    // Function to format time slots into ranges
    const formatTimeSlots = (timeSlots) => {
        const formattedSlots = [];

        for (let i = 0; i < timeSlots.length - 1; i++) {
            const startTime = timeSlots[i];
            const endTime = timeSlots[i + 1];

            // Convert to 12-hour format
            const startTime12h = convertTo12HourFormat(startTime);
            const endTime12h = convertTo12HourFormat(endTime);

            // Format the range
            const formattedRange = `${startTime12h} - ${endTime12h}`;
            formattedSlots.push(formattedRange);
        }

        return formattedSlots;
    };

    // Function to convert 24-hour time to 12-hour format
    const convertTo12HourFormat = (time24h) => {
        const [hours, minutes] = time24h.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours, 10);

        if (hours12 === 0) {
            hours12 = 12;
        } else if (hours12 === 12) {
            period = 'PM';
        } else if (hours12 > 12) {
            hours12 -= 12;
            period = 'PM';
        }

        return `${hours12.toString().padStart(2, '0')}:${minutes} ${period}`;
    };


    const fetchGames = async () => {
        try {
            const response = await axios.get(`${API_URL}/view-game`);
            setGames(response.data.game);
        } catch (error) {
            console.error("Error fetching games:", error);
        }
    };


    const fetchGameTypesArray = async () => {
        try {
            const response = await axios.get(`${API_URL}/view-game-type`);

            setGame_types_array(response.data.gameType);
           // setIsLoading(false);
     
        } catch (error) {
            //setIsLoading(false);
            console.error("Error fetching games:", error);

        }
       
    };


    const fetchGameTypes = async (selectedGame, selectedGameLocation) => {

        try {
            const response = await axios.get(`${API_URL}/view-game-type-by-gameId/${selectedGame}/${selectedGameLocation}`);
            if (response.data.gameType) {
                setGame_types(response.data.gameType);
            }
            else {
                setGame_types([]);
            }



        } catch (error) {
            console.error("Error fetching games:", error);

        }
    };

    useEffect(() => {
        if (selectedPackageType === 'birthday_package') {
            fetchGamePackages(1);
        } 
    }, [selectedPackageType]);

    const fetchGamePackages = async (selectedPackageType) => {

        try {
            const response = await axios.get(`${API_URL}/view-game-package-by-package/${selectedGameLocation}/${selectedGame}/${selectedPackageType}`);
     
            if (response.data.gamePackages) {
                setGame_packages(response.data.gamePackages);
       
            }
            else {
                setGame_packages([]);
            }
        } catch (error) {
            console.error("Error fetching package:", error);

        }
    };




    const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
    };



    useEffect(() => {

        if (selectedGame && selectedGameLocation) {
            fetchGameTypes(selectedGame, selectedGameLocation);
        }

    }, [selectedGame, selectedGameType, selectedGameLocation]);

   

    const handleGameTypeChange = (e) => {
        setAdults_count(0);
        setKids_count(0);
        setTimeSlotDetails({});
        setGrand_total(0);
        setAmount_paid(0);
        setDeposit_amount(0);
        setBirthdayPrice({});
        setBooking_time([]);
        setBookingAvailableTimeSlots([]);
        setTotalPlayersErrorList('');
        const selectedGameTypeId = e.target.value;
        setSelectedGameType(selectedGameTypeId);
     
    };




    const handleBirthdayPackageChange = (e) => {
        const selectedBirthdayPackageId = e.target.value;
        setSelectedBirthdayPackage(selectedBirthdayPackageId);
        fetchOptionsForBirthdayPackage(selectedBirthdayPackageId);
        setFoodOptions([]);
        setDrinkOptions([]);
    };

    useEffect(() => {
     setSelectedFoods('');
     setSelectedDrinks('');
     setGame_count(0);
    }, [selectedBirthdayPackage]);

    const handleGameAvailableLocationChange = (e) => {
        const selectedGameLocationId = e.target.value;
        setSelectedGameLocation(selectedGameLocationId);
        fetchGameAvailable(selectedGameLocationId);
    };




    // useEffect(() => {
    //     if (!isLoading && booking_time && gameTypeById) {
    //         let totalPrice = 0;
    //         if (booking_time.length === 1) {
    //             totalPrice = gameTypeById.kids_first_price * kids_count + 
    //             gameTypeById.adults_first_price * adults_count;
    //         } else if (booking_time.length === 2) {
    //             totalPrice = (gameTypeById.kids_first_price * kids_count + 
    //                           gameTypeById.adults_first_price * adults_count) +
    //                          (gameTypeById.kids_second_price * kids_count + 
    //                             gameTypeById.adults_second_price * adults_count);
    //         } else if (booking_time.length >= 3) {
    //             const additionalSlots = booking_time.length - 2;
    //             totalPrice = (gameTypeById.kids_first_price * kids_count + 
    //                 gameTypeById.adults_first_price * adults_count) +
    //                          (gameTypeById.kids_second_price * kids_count + 
    //                             gameTypeById.adults_second_price * adults_count) +
    //                          (additionalSlots * (gameTypeById.kids_third_price * kids_count + 
    //                             gameTypeById.adults_third_price * adults_count));
    //         }


    //         setGameTypeById((prev) => ({ ...prev, price: totalPrice }));

    //         const totalPeople = parseInt(kids_count, 10) + parseInt(adults_count, 10);
    //         const bookedPeople = groupedBookedTimes[booking_time] || 0;
    //         const remainingSpace = gameTypeById.max_players_in_a_slot - bookedPeople;
    //         setAvailable_space(Math.max(0, remainingSpace - totalPeople));
    //         if (totalPeople <= 30 && Math.max(0, remainingSpace - totalPeople) === 0) {
    //             // alert('Cannot exceed 30 people and available space is 0.');
    //             // You can reset counts or take other actions as needed
    //             // setKids_count(0);
    //             // setAdults_count(0);
    //         }
    //     }
    // }, [kids_count, adults_count, isLoading, booking_time]);

    useEffect(() => {
        if (selectedPackageType  ) {
            // Reset counts to 0
            
            setAdults_count(0);
            setKids_count(0);
            setTimeSlotDetails({});
            setGrand_total(0);
            setDeposit_amount(0);
            setAmount_paid(0);
            setBirthdayPrice({});
            setBooking_time([]);
            setTimeSlotDetails((prev) => ({ ...prev, price: 0 }));
            setBirthdayPrice((prev) => ({ ...prev, price: 0 }));
            setBookingAvailableTimeSlots([]);
            setTotalPlayersErrorList('');
            // Trigger recalculation
         
            // if (selectedGameType) {
            //     recalculateCosts();
            // } else if (selectedBirthdayPackage) {
            //     recalculateBirthdayCosts();
            // }
        }
    }, [selectedPackageType]);

    
    const generateCartDetails = (times, priceForSlot) => {
        const details = {};
        times.forEach(time => {
            details[time] = {
                "time_slot": time,
                "price_for_slot": priceForSlot.toFixed(2), // Format to two decimal places
                "total_players": Number(adults_count) + Number(kids_count), // This should be dynamic based on your requirements
                "row_total": (priceForSlot * (Number(adults_count) + Number(kids_count))).toFixed(2) // Example calculation
            };
        });
        return details;
    };

    
    useEffect(() => {
        recalculateCosts();
    }, [kids_count, adults_count, selectedGameType, booking_time]);
    
    useEffect(() => {
        recalculateBirthdayCosts();
    }, [kids_count, adults_count, selectedBirthdayPackage, booking_time]);


    
    const recalculateCosts = () => {
        if (!isLoading && booking_time && selectedGameType) {


            const filteredGameType = game_types.filter(item => item.id === Number(selectedGameType));
            const adultsFirstPrice = filteredGameType[0].adults_first_price;
            const adultsSecondPrice = filteredGameType[0].adults_second_price;
            const adultsThirdPrice = filteredGameType[0].adults_third_price;
            const depositMethod = filteredGameType[0].deposit_method;
            const depositAmountPerPerson = parseFloat(filteredGameType[0].deposit_amount_per_person).toFixed(2);
            const depositPercentage = parseFloat(filteredGameType[0].deposit_percentage).toFixed(2);
            const minPlayers = filteredGameType[0].min_players;
            const maxPlayers = filteredGameType[0].max_players;
          
          setMinPlayers(minPlayers);
          setMaxPlayers(maxPlayers);

            let totalPrice = 0;
            let slotDetails = {};
          const totalPlayers = Number(kids_count) + Number(adults_count);
         
            if (booking_time.length === 1) {
                totalPrice = adultsFirstPrice * kids_count +
                adultsFirstPrice * adults_count;
             
                slotDetails[booking_time[0]] = {
                    time_slot: booking_time[0],
                    price_for_slot: adultsFirstPrice.toFixed(2),
                    total_players: totalPlayers,
                    row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
                };


            } else if (booking_time.length === 2) {
                totalPrice = (adultsFirstPrice * kids_count +
                    adultsFirstPrice * adults_count) +
                    (adultsSecondPrice * kids_count +
                        adultsSecondPrice* adults_count);

                        
            slotDetails[booking_time[0]] = {
                time_slot: booking_time[0],
                price_for_slot: adultsFirstPrice.toFixed(2),
                total_players: totalPlayers,
                row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
            };

            slotDetails[booking_time[1]] = {
                time_slot: booking_time[1],
                price_for_slot: adultsSecondPrice.toFixed(2),
                total_players: totalPlayers,
                row_total: (adultsSecondPrice * totalPlayers).toFixed(2)
            };
                    
            } else if (booking_time.length >= 3) {
                const additionalSlots = booking_time.length - 2;
                totalPrice = (adultsFirstPrice * kids_count +
                    adultsFirstPrice * adults_count) +
                    (adultsSecondPrice * kids_count +
                        adultsSecondPrice * adults_count) +
                    (additionalSlots * (adultsThirdPrice * kids_count +
                        adultsThirdPrice * adults_count));


                        slotDetails[booking_time[0]] = {
                            time_slot: booking_time[0],
                            price_for_slot: adultsFirstPrice.toFixed(2),
                            total_players: totalPlayers,
                            row_total: (adultsFirstPrice * totalPlayers).toFixed(2)
                        };
            
                        slotDetails[booking_time[1]] = {
                            time_slot: booking_time[1],
                            price_for_slot: adultsSecondPrice.toFixed(2),
                            total_players: totalPlayers,
                            row_total: (adultsSecondPrice * totalPlayers).toFixed(2)
                        };
            
                        for (let i = 2; i < booking_time.length; i++) {
                            const priceForSlot = adultsThirdPrice;
                            const rowTotal = priceForSlot * totalPlayers;
            
                            slotDetails[booking_time[i]] = {
                                time_slot: booking_time[i],
                                price_for_slot: priceForSlot.toFixed(2),
                                total_players: totalPlayers,
                                row_total: rowTotal.toFixed(2)
                            };
                        }
                    }
            
                    setCart_details(slotDetails);
      

            setTimeSlotDetails((prev) => ({ ...prev, price: totalPrice }));


            const hst = (totalPrice * 13) / 100;
             setHst(hst);

            // Calculate grand total
            const grandTotal = totalPrice + hst;
            const formattedGrandTotal = parseFloat(grandTotal.toFixed(2));

            setGrand_total(formattedGrandTotal);


              // Calculate the deposit amount
        let depositAmount = 0;
        if (depositMethod === 1) { // Amount per person
            depositAmount = depositAmountPerPerson * (Number(kids_count) + Number(adults_count))* booking_time.length ;
        } else if (depositMethod === 2) { // Percentage
            depositAmount = (totalPrice * depositPercentage) / 100;
        }
        setDeposit_amount(depositAmount);
        setAmount_paid(depositAmount);

       


        let depositDetails = '';
        if(depositMethod === 1)
        {
          depositDetails = 'Deposit amount per person : ' +depositAmountPerPerson;
          setDeposit_details(depositDetails);
        
        }
        else if (depositMethod === 2)
        {
           depositDetails = 'Deposit amount percentage : ' +depositPercentage;
           setDeposit_details(depositDetails);
    
        }

        // const cartDetails =  generateCartDetails(booking_time,priceForSlot);
        // setCart_details(cartDetails);
        // console.log(cartDetails);

        }
        

    }


    const recalculateBirthdayCosts = () => {
    
        if(!isLoading && booking_time  && selectedBirthdayPackage)
        {
       
            const filteredPackage = game_packages.filter(item => item.id === Number(selectedBirthdayPackage));
            const birthdayPrice = filteredPackage[0].price_per_game;
            const basicPrice= filteredPackage[0].basic_price;
            const depositAmt= filteredPackage[0].deposit_amount;

            let totalPrice = basicPrice;
            let slotDetails = {};
            const totalPlayers =  Number(kids_count) + Number(adults_count);

            const totalPlayersExcludingBirthdayKid = totalPlayers > 1 ? totalPlayers - 1 : 0; // Ensure the total doesn't go below 0
           
            const totalSlots = booking_time.length;
            const freeGames = 2; // Number of free games
    
            // Calculate the number of paid slots
            const paidSlots = totalSlots > freeGames ? totalSlots - freeGames : 0;
            const totalPriceForPaidSlots = birthdayPrice * totalPlayersExcludingBirthdayKid * paidSlots;
    
            totalPrice += totalPriceForPaidSlots; // Add the calculated price
    
            // Populate slot details
            for (let i = 0; i < totalSlots; i++) {
                const timeSlot = booking_time[i];
                slotDetails[timeSlot] = {
                    time_slot: timeSlot,
                    price_for_slot: i < freeGames ? 0 : birthdayPrice.toFixed(2),
                    total_players: totalPlayersExcludingBirthdayKid,
                    row_total: i < freeGames ? '0.00' : (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
                };
            }


            // if (booking_time.length === 1) {

            //     //totalPrice += birthdayPrice * kids_count + birthdayPrice * adults_count;

            //     totalPrice += birthdayPrice * totalPlayersExcludingBirthdayKid;
               

            //     slotDetails[booking_time[0]] = {
            //         time_slot: booking_time[0],
            //         price_for_slot: birthdayPrice.toFixed(2),
            //         total_players: totalPlayersExcludingBirthdayKid,
            //         row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //     };


            // } else if (booking_time.length === 2) {
            //     // totalPrice += (birthdayPrice * kids_count + birthdayPrice * adults_count) +
            //     //               (birthdayPrice * kids_count + birthdayPrice * adults_count);
           
            //     totalPrice += birthdayPrice * totalPlayersExcludingBirthdayKid * 2;

            //                   slotDetails[booking_time[0]] = {
            //                     time_slot: booking_time[0],
            //                     price_for_slot: birthdayPrice.toFixed(2),
            //                     total_players: totalPlayersExcludingBirthdayKid,
            //                     row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //                 };
                
            //                 slotDetails[booking_time[1]] = {
            //                     time_slot: booking_time[1],
            //                     price_for_slot: birthdayPrice.toFixed(2),
            //                     total_players: totalPlayersExcludingBirthdayKid,
            //                     row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //                 };
           
            //                 } else if (booking_time.length >= 3) {
            //     const additionalSlots = booking_time.length - 2;
            //     // totalPrice += (birthdayPrice * kids_count + birthdayPrice * adults_count) +
            //     //               (birthdayPrice * kids_count + birthdayPrice * adults_count) +
            //     //               (additionalSlots * (birthdayPrice * kids_count + birthdayPrice * adults_count));
            
            //      totalPrice += birthdayPrice * totalPlayersExcludingBirthdayKid * (2 + additionalSlots);
            
            //                   slotDetails[booking_time[0]] = {
            //                     time_slot: booking_time[0],
            //                     price_for_slot: birthdayPrice.toFixed(2),
            //                     total_players: totalPlayersExcludingBirthdayKid,
            //                     row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //                 };
                
            //                 slotDetails[booking_time[1]] = {
            //                     time_slot: booking_time[1],
            //                     price_for_slot: birthdayPrice.toFixed(2),
            //                     total_players: totalPlayersExcludingBirthdayKid,
            //                     row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //                 };
                
            //                 for (let i = 2; i < booking_time.length; i++) {
            //                     slotDetails[booking_time[i]] = {
            //                         time_slot: booking_time[i],
            //                         price_for_slot: birthdayPrice.toFixed(2),
            //                         total_players: totalPlayersExcludingBirthdayKid,
            //                         row_total: (birthdayPrice * totalPlayersExcludingBirthdayKid).toFixed(2)
            //                     };
            //                 }
            //             }
                
                    
                        setCart_details(slotDetails);
            
            
            

            setBirthdayPrice((prev) => ({ ...prev, price: totalPrice }));

        const hst = (totalPrice * 13) / 100;
        setHst(hst);

        const grandTotal = totalPrice + hst;
        const formattedGrandTotal = parseFloat(grandTotal.toFixed(2));


        setGrand_total(formattedGrandTotal);
        setDeposit_amount(depositAmt);
 

        setAmount_paid(depositAmt);
     
        setBasic_price(basicPrice);

            if(selectedBirthdayPackage && birthday_packages.min_games)  
        { 
            setMin_game(birthday_packages.min_games)
        }

        let depositDetails = '';
          depositDetails = 'Package basic price : ' +depositAmt;
          setDeposit_details(depositDetails);

        //   const cartDetails =  generateCartDetails(booking_time, priceForSlot);
        //   setCart_details(cartDetails);

        }
    }

   





    function fetchEvents() {
         
       

        axios.get(`${API_URL}/view-booked-slots/${selectedGameLocation}/${selectedGame}`).then(res => {
  
            if (res.data.status === 200) {

                const fullyBookedDates = {};


                const formattedEvents = res.data.bookedSlots.map(slot => {
                    const bookingTimes = Array.isArray(slot.booked_time) ? slot.booked_time : JSON.parse(slot.booked_time);
                    // const startTime = new Date(`${slot.booked_date} ${bookingTimes[0].split(' - ')[0]}`);
                    // const endTime = new Date(`${slot.booked_date} ${bookingTimes[bookingTimes.length - 1].split(' - ')[1]}`);

                    const startTime = moment(`${slot.booked_date} ${bookingTimes[0].split(' - ')[0]}`, 'YYYY-MM-DD h:mm A');
                    const endTime = moment(`${slot.booked_date} ${bookingTimes[bookingTimes.length - 1].split(' - ')[1]}`, 'YYYY-MM-DD h:mm A');

                    const formattedBookedTime = formatBookedTime(slot.booked_time);

                    // Format times
                    const startFormatted = startTime.format('h:mm A');
                    const endFormatted = endTime.format('h:mm A');

                    // const typeOrPackageName = slot.game_type_name ? slot.game_type_name : slot.package_name;
                    
                    const locationShort = slot.location_name.substring(0, 3);
                    const typeOrPackageName = slot.game_type_name ? slot.game_type_name : slot.package_name;
                    const typeOrPackageShort = typeOrPackageName.substring(0, 3);



                    const className = typeOrPackageName === 'Birthday' ? 'birthday-event' : '';
                    
                    const eventColor = locationColors[slot.location_name] || '#000000'; 
                    const formattedEvent = {
                        // title: `${slot.location_name} - ${typeOrPackageName} - Adults: ${slot.no_of_adults}, Kids: ${slot.no_of_children}`,
                        title: `${formattedBookedTime} - ${locationShort} - ${typeOrPackageShort} A-${slot.no_of_adults} K-${slot.no_of_children}`,
                        start: startTime.toISOString(),
                        end: endTime.toISOString(),
                        description: `${slot.game_name} - ${typeOrPackageName} at ${slot.location_name}`,
                        className: className,
                        backgroundColor: eventColor,  // Set the event background color
                        borderColor: eventColor,  
                        textColor: '#FFFFFF', 
                        extendedProps: {
                            booked_date: slot.booked_date,
                            location_name: slot.location_name,
                            game_type_name: slot.game_type_name,
                            package_name: slot.package_name,
                            game_name: slot.game_name,
                            booked_time: bookingTimes.join(', '),
                            no_of_adults: slot.no_of_adults,
                            no_of_children: slot.no_of_children,
                            firstname: slot.firstname, // Add customer first name
                            lastname: slot.lastname,  // Add customer last name
                            email: slot.email,        // Add customer email
                            phone: slot.phone,
                            start_time: startFormatted, // Start time
                            end_time: endFormatted,
                            booked_times: formattedBookedTime,
                            birthday_kid_name: slot.birthday_kid_name,
                            food_details: slot.food_details,
                            drink_details: slot.drink_details,
                            amount_paid: slot.amount_paid,
                           game_package_name: slot.game_package_name,
                           booking_id: slot.booking_id,

                        }
                    };
                    // const dateKey = slot.booked_date;
                    // fullyBookedDates[dateKey] = fullyBookedDates[dateKey] || [];
                    // fullyBookedDates[dateKey].push(formattedBookedTime);
    
                    return formattedEvent;
                });
    
                // Check if all slots are booked for each date
                // for (const dateKey in fullyBookedDates) {
                //     const totalSlotsForDay = calculateTimeSlots('9:00 AM', '5:00 PM', 30); // Example: Generate slots from 9:00 AM to 5:00 PM with 30 min interval
                //     const bookedSlotsForDay = fullyBookedDates[dateKey];

                    // console.log(totalSlotsForDay);
    
                    // If all time slots are booked, mark the date as fully booked
                    // if (bookedSlotsForDay.length === totalSlotsForDay.length) {
                    //     fullyBookedDates[dateKey] = true; // Mark as fully booked
                    // }
                // }
    
                setEvents(formattedEvents);
                // setFullyBookedDates(fullyBookedDates);
                setIsLoading(false);
            }
            else if (res.data.status === 404) {
                setEvents([]);
                setIsLoading(false);
            }
  
           
        });

    }



    const handleEventClick = ({ event }) => {
        if (event.extendedProps) {
            setBookingDetails(event.extendedProps);
          //  console.log(event.extendedProps);
            setView('details');
        } else {
           // console.log('No details found in event extendedProps');
        }
    };


    const calculateTimeSlots = (startTime, endTime, interval) => {
        const timeSlots = [];
        const [startHour, startMinute] = parseTime(startTime);
        const [endHour, endMinute] = parseTime(endTime);

        let current = new Date();
        current.setHours(startHour, startMinute, 0, 0);

        const end = new Date();
        end.setHours(endHour, endMinute, 0, 0);

        while (current <= end) {
            const timeSlot = current.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
            timeSlots.push(timeSlot);
            current.setMinutes(current.getMinutes() + interval);
        }

        return timeSlots;
    };


    const parseTime = (timeString) => {
        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        const adjustedHours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
        return [adjustedHours, minutes];
    };

   

    const generateBookingTimeSlots = (bookedTimes, allTimeSlots) => {
        const availableTimeSlots = allTimeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
        return availableTimeSlots;
    };



    const handleBookingDateChange = (selectedDate) => {
        let errors = {};
        if (!selectedGameLocation) {
            errors.location_id = "Please select a game location.";
        }

        if (!selectedGame) {
            errors.game_id = "Please select a game.";
        }


        if (Object.keys(errors).length > 0) {
            setErrorList(errors);
            return;
        }

        setIsLoading(true);
        resetBooking();
        if (selectedGameLocation && selectedGame) {
            setBooking_date(selectedDate);
            setView('form');
        }
        setIsLoading(false);

    };

   


    const handleTimeSlotChange = (event) => {
        const selectedTimeSlot = event.target.value;
        const selectedSlotIndex = bookingAvailableTimeSlots.indexOf(selectedTimeSlot);

        if (selectedPackageType === 'birthday_package') {
            
                const userGameCount = parseInt(game_count, 10); // Get the number of games selected by the user
                const totalSlotsToSelect = userGameCount + 2; // Add 2 free games

                // Get the next `game_count` consecutive slots
                const nextSlots = bookingAvailableTimeSlots.slice(selectedSlotIndex, selectedSlotIndex + totalSlotsToSelect);
                
                setBooking_time(() => {
                    // Clear all previous selections and set only the new consecutive slots
                    return nextSlots;
                });


                // Automatically select the next consecutive slots
                // setBooking_time((prevBookingTime) => {
                //     let newBookingTime = [...prevBookingTime];
        
                //     if (prevBookingTime.includes(selectedTimeSlot)) {
                //         // If the time slot is already selected, unselect it and the next consecutive slots
                //         newBookingTime = newBookingTime.filter(slot => !nextSlots.includes(slot));
                //     } else {
                //         // If the time slot is not selected, select it and the next consecutive slots
                //         newBookingTime = [...newBookingTime, ...nextSlots];
                //     }
        
                //     return newBookingTime;
                // });
            }
            else{

                
        setBooking_time((prevBookingTime) =>
            prevBookingTime.includes(selectedTimeSlot)
                ? prevBookingTime.filter((timeSlot) => timeSlot !== selectedTimeSlot)
                : [...prevBookingTime, selectedTimeSlot]
        );

            }

    };



    const getNextTimeSlot = (index) => {
        return bookingAvailableTimeSlots[index + 1];
    };


    const handleGameType = (event) => {
        setGame_type(event.target.value);
    };


    const handleGameLocation = (event) => {
        setIsLoading(true);
        setGame_location(event.target.value);
        if (event.target.value == 'stouffville') {
            axios.get(`${API_URL}/view-booking`).then(res => {
                if (res.data.status === 200) {
                    const formattedEvents = res.data.booking.map(booking => ({
                        title: booking.game_location,
                        start: booking.booking_date + 'T' + booking.booking_time // Combine date and time into a single string
                    }));
                    setEvents(formattedEvents);

                }
                else if (res.data.status === 404) {
                    setEvents([]);
                }
                setIsLoading(false);
            });
        }
        else if (event.target.value == 'scarborough') {
            axios.get(`${API_URL}/view-booking-scarborough`).then(res => {
                if (res.data.status === 200) {
                    const formattedEvents = res.data.bookingScarborough.map(bookingScarborough => ({
                        title: bookingScarborough.game_location,
                        start: bookingScarborough.booking_date + 'T' + bookingScarborough.booking_time // Combine date and time into a single string
                    }));
                    setEvents(formattedEvents);

                }
                else if (res.data.status === 404) {
                    setEvents([]);
                }
                setIsLoading(false);
            });
        }
    };

    function renderEventContent(eventInfo) {
        const { event } = eventInfo;
        
        const backgroundColor = event.backgroundColor || '#03a9f3'; // Default color
    
        return (
            <div
                style={{
                    backgroundColor,
                    color: event.textColor || '#ffffff',
                    padding: '6px',
                    borderRadius: '5px',
                    textAlign: 'left',
                    width: '100%',
                    boxSizing: 'border-box',
                    overflowWrap: 'break-word', 
                    whiteSpace: 'normal', 
                    
                    
                }}
            >
                {event.title}
            </div>
        );
    }
    const handleDateChange = (e) => {

        const selectedDate = e.target.value;
        setDate(selectedDate);
        axios.get(`${API_URL}/booked-appointments`, { params: { selectedDate } })
            .then(response => {
                const bookedTimes = response.data.map(time => {
                    const [hour, minute] = time.split(':');
                    const hour12 = (parseInt(hour) % 12) || 12;
                    const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
                    return `${hour12}:${minute} ${ampm}`;
                });

                const availableTimes = generateTimeSlots(bookedTimes);
                // console.log(bookedTimes);
            })
            .catch(error => {
                console.error('Error fetching available date-time pairs:', error);
            });
    };

    const generateTimeSlots = (bookedTimes) => {
        // Generate time slots from 9 AM to 4 PM
        const startTime = 9;
        const endTime = 16;
        const timeSlots = [];

        for (let hour = startTime; hour <= endTime; hour++) {
            const formattedHour = hour % 12 || 12;
            const ampm = hour < 12 ? 'AM' : 'PM';
            const timeSlot = `${formattedHour}:00 ${ampm}`;
            timeSlots.push(timeSlot);
        }


        // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

        const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
        setAvailableTimeSlot(availableTimeSlots);
        return availableTimeSlots;
    };





    const resetData = () => {
        setTitle('');
        setStartDatetime('');
        setEndDatetime('');
    };


    async function bookGame(e) {
        e.preventDefault();
        setLoading(true);
    
        try {
            const formData = new FormData();
            formData.append("location_id", selectedGameLocation);
            formData.append("game_id", selectedGame);
            formData.append("game_type_id", selectedGameType);
            formData.append("booked_date", booking_date);
            formData.append("booked_time", JSON.stringify(booking_time));
            formData.append("booked_by", userContext.userDetails.id);
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
            formData.append("phone", phone);
            formData.append("email", email);
            formData.append("no_of_adults", adults_count);
            formData.append("no_of_children", kids_count);
            formData.append("hst", hst);
            formData.append("grand_total", grand_total);
            formData.append("deposit_amount", deposit_amount);
            formData.append("deposit_details", deposit_details);
            formData.append("no_of_slots", booking_time.length);
            formData.append("cart_details", JSON.stringify(cart_details));
            formData.append("amount_paid", amount_paid);
            formData.append("selectedPackageType", selectedPackageType);
            formData.append("selectedGameType", selectedGameType);
            formData.append("selectedBirthdayPackage", selectedBirthdayPackage);
            formData.append("min_players", minPlayers);
            formData.append("max_players", maxPlayers);
            formData.append("total_players", Number(adults_count) + Number(kids_count));
            formData.append("payment_method", payment_method);
  

            if (selectedBirthdayPackage) {
                formData.append("is_package", 1);
                formData.append("main_package_id", 1);
                formData.append("sub_package_id", selectedBirthdayPackage);
                formData.append("package_price", basic_price);
                formData.append("game_package_id", selectedBirthdayPackage);
                formData.append("birthday_kid_name", birthdayKid);
                formData.append("food_details", selectedFoods);
                formData.append("drink_details", selectedDrinks);
                formData.append("game_count", game_count);
                formData.append("min_game", min_game);
                formData.append("max_players_in_a_slot", birthday_packages.max_players_in_a_slot);
                formData.append("cost", birthdayPrice.price);
                formData.append("subtotal", birthdayPrice.price);
            } else {
                formData.append("cost", timeSlotDetails.price);
                formData.append("subtotal", timeSlotDetails.price);
            }
    
            // API call to save booking
            const bookingResponse = await axios.post(`${API_URL}/save-booked-slots`, formData);
    
            // Prepare email data after successful booking
            const emailData = await prepareEmailData(bookingResponse.data.booking);
    

            // API call to send email
            const emailResponse = await axios.post(`${API_URL}/send-email`, emailData);

        
    
            Swal.fire({
                icon: "success",
                // text: emailResponse.data.message
                text: 'Booked Successfully! Confirmation Email sent to ' + emailData.to
            });
    
            resetBooking();
            fetchEvents();
            setBookingErrorList([]);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            setBookingErrorList(error.response?.data?.validate_err || []);
            Swal.fire({
                text: error.response?.data?.message || 'Something went wrong!',
                icon: "error"
            });
            console.error('Full error:', error);

        } finally {
            setLoading(false);
        }
    }
    
    // const formatBookedTime = (bookedTime) => {
    //     try {
    //       const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
    //       if (Array.isArray(timeSlots) && timeSlots.length > 0) {
    //         const startTime = timeSlots[0].split(' - ')[0];
    //         const endTime = timeSlots[timeSlots.length - 1].split(' - ')[1];
    //         // Convert to moment objects and format them
    //         const formattedStartTime = moment(startTime, 'hh:mm A').format('h:mm A');
    //         const formattedEndTime = moment(endTime, 'hh:mm A').format('h:mm A');
    //         return `${formattedStartTime} - ${formattedEndTime}`;
    //       }
    //       return 'No time slots available';
    //     } catch (error) {
    //       console.error("Error parsing booked time:", error);
    //       return 'Invalid time format';
    //     }
    //   };



    const formatBookedTime = (bookedTime) => {
        try {
          const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
          if (Array.isArray(timeSlots) && timeSlots.length > 0) {
            let mergedSlots = [];
            let currentRangeStart = null;
            let currentRangeEnd = null;
      
            timeSlots.forEach((slot, index) => {
              const [startTime, endTime] = slot.split(' - ');
              const currentStartTime = moment(startTime, 'hh:mm A');
              const currentEndTime = moment(endTime, 'hh:mm A');
      
              if (!currentRangeStart) {
                // First time slot, initialize the range
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              } else if (currentRangeEnd.isSame(currentStartTime)) {
                // If the current slot starts when the last one ended, extend the range
                currentRangeEnd = currentEndTime;
              } else {
                // If the current slot does not continue from the last one, close the current range and start a new one
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              }
      
              // If it's the last slot, push the current range
              if (index === timeSlots.length - 1) {
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
              }
            });
      
            return mergedSlots.join(', '); // Join the merged slots with a comma
          }
          return 'No time slots available';
        } catch (error) {
          console.error("Error parsing booked time:", error);
          return 'Invalid time format';
        }
      };



    function convertPlainTextToHTML(text) {
        return text
            .replace(/\n/g, '<br>')  // Replace line breaks with <br>
            .replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length));  // Replace multiple spaces with &nbsp;
    }

    async function prepareEmailData(bookingDetails) {
        
        const consentFormUrl = `${API_BASE_URL}/consent-form-page/${bookingDetails.booking_ref_id}`;
        const formattedBookedTime = formatBookedTime(bookingDetails.booked_time);

        const qrCodeFilename = `${bookingDetails.booking_ref_id}.png`; // Use the same filename for consistency

        // Generate and upload QR code image
        const qrCodeImageUrl  = await generateQRCodeImage(consentFormUrl, qrCodeFilename);
    

        // Await the QR code data URL
        // let qrCodeDataUrl;
        // try {
        //     qrCodeDataUrl = await generateQRCodeDataUrl(consentFormUrl);
        //     console.log(qrCodeDataUrl);
        // } catch (error) {
        //     qrCodeDataUrl = null; // Handle the error as needed
        // }
    
        // Convert the plain text template to HTML
        const message = convertPlainTextToHTML(emailData.message)
            .replace('[customer_name]', `${bookingDetails.firstname} ${bookingDetails.lastname}`)
            .replace('[event_date]', moment(bookingDetails.booked_date).format('D MMMM YYYY'))
            .replace('[event_time]', formattedBookedTime)  // Joining the times array into a string
            .replace('[location]', bookingDetails.location_name)
            .replace('[number_of_players]', bookingDetails.total_players)
            .replace('[package_name]', bookingDetails.game_type_name || `${bookingDetails.package_name} - ${bookingDetails.game_package_name}`)
            .replace('[consent_form_link]', `<a href="${consentFormUrl}">Click here to complete the consent form</a>`)
            .replace('[qr_code]', qrCodeImageUrl ? `<img src="${qrCodeImageUrl}" alt="QR Code for Consent Form" />` : 'QR code not available');
    

        return {
            booking_id: bookingDetails.booking_id,
            to: bookingDetails.email,
            subject: emailData.subject,
            message // This is now HTML formatted
        };
    }
    
    function generateQRCodeDataUrl(url) {
        // Return a promise that resolves with the QR code Data URL
     
        return QRCode.toDataURL(url, { width: 150 });
    }

    async function generateQRCodeImage(url, filename,size = 150) {
        try {
            // Generate QR Code as Data URL
            const dataUrl = await QRCode.toDataURL(url,  { width: size, height: size });
    
            // Convert Data URL to Blob
            const blob = await (await fetch(dataUrl)).blob();
            
            // Upload Blob to your server and get the URL
            const imageUrl = await uploadQRCodeImage(blob, filename);
            
           
            return imageUrl;
        } catch (error) {
            console.error('Error generating QR code image:', error);
            return null;
        }
    }
    
    async function uploadQRCodeImage(blob, filename) {
        const formData = new FormData();
        formData.append('file', blob, filename);
    
        try {
            const response = await axios.post(`${API_URL}/upload-qr-code`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.status !== 200) {
                throw new Error('Failed to upload QR code image');
            }
    
            const result = response.data; 
           
            return result.imageUrl;         
        } catch (error) {
            console.error('Error uploading QR code image:', error);
            return null;
        }
    }




    const handleCloseEmailModal = () => setShowEmailModal(false);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = {        
          ...emailData,
      };
      // Implement email sending functionality here
    //   console.log('Email sent with data:', formData);
    
        axios.post(`${API_URL}/send-email`, formData)
        .then(() => {
    
         setLoading(false);
         showToast("Email sent successfully!");
         handleCloseEmailModal();
       //  setEmailErrorList({ to: '', subject: '', message: '' });
      //    resetEmail();
         
        })
        .catch((error) => {
          setLoading(false);
         // setEmailErrorList(error.response.data.validate_err);
          
         console.error('Error sending email:', error.response.data.message);
        });





  };

  




    const resetBooking = () => {
        setBooking_date("");
        setFirstname('');
        setLastname('');
        setPhone('');
        setEmail('');
        setBooking_time([]);
        setKids_count(0);
        setAdults_count(0);
        setTimeSlotDetails((prev) => ({ ...prev, price: 0 }));
        setBirthdayPrice((prev) => ({ ...prev, price: 0 }));
        setNote('');
        setSelectedGameType('');
        setSelectedPackageType('');
        setSelectedBirthdayPackage('');
        setBirthdayKid('');
        setSelectedFoods('');
        setSelectedDrinks('');
        setBirthdayKid('');
        setBookingAvailableTimeSlots([]);
        setGame_count('');
        setGrand_total(0);
        setDeposit_amount(0);
        setAmount_paid(0);
        setBookingErrorList([]);
        setPayment_method("");
        setTotalPlayersErrorList('');
    };




    const handleDateClick = (arg) => {
        const today = new Date().toISOString().split('T')[0]; // Current date
        if (arg.dateStr < today) {
            return; // Ignore clicks on past dates
        }
        resetBooking();
        setSelectedDate(arg.dateStr);
        handleBookingDateChange(arg.dateStr);

    };

    const handleFoodOptionChange = (e) => {
        const value = e.target.value;
      //  let updatedLocations = selectedLocations.split(',').map(Number); // Ensure it's an array
        let updatedFoods = [...selectedFoods];
        if (e.target.checked) {
            updatedFoods = [...updatedFoods, value];
        } else {
            updatedFoods = updatedFoods.filter((id) => id !== value);
        }
      
        setSelectedFoods(updatedFoods); // Convert back to a comma-separated string
      };

      const handleDrinkOptionChange = (e) => {
        const value = e.target.value;
      //  let updatedLocations = selectedLocations.split(',').map(Number); // Ensure it's an array
        let updatedDrinks = [...selectedDrinks];
        if (e.target.checked) {
            updatedDrinks = [...updatedDrinks, value];
        } else {
            updatedDrinks = updatedDrinks.filter((id) => id !== value);
        }
      
        setSelectedDrinks(updatedDrinks); // Convert back to a comma-separated string
      };


    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading ?
                <div className="loader-container" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "9999" }}>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div> : null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Calender</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Booking details</h2>
                        </div>
                        <div className='row px-4'>


                            <div class="content col-md-2">
                                <div class="form-group pt-3 pb-2">

                                    <select class="form-select" id="selectedGameLocation" name="selectedGameLocation" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                                        <option value="">Select game location</option>
                                        {filteredLocations.map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.location_name}
                                            </option>
                                        ))}
                                    </select>   {!selectedGameLocation && (
                                        <span class="text-danger">{error_list.location_id}</span>)}
                                </div>
                            </div>


                            <div class="content col-md-2 ">
                                <div class="form-group pt-3 pb-2">

                                    <select className="form-select me-2" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
                                        <option value="">Select a game</option>
                                        {game_available.map((game) => (
                                            <option key={game.id} value={game.id}>
                                                {game.name}
                                            </option>
                                        ))}
                                    </select> {!selectedGame && (
                                        <span class="text-danger">{error_list.game_id}</span>)}
                                </div>
                            </div>





                        </div>
                        <div className='row'>


                            <div className='col'>
                                <div className="content pt-3 pb-5 mb-5" id="calender" style={{ boxShadow: "0px 0px 21px 2px rgba(0, 0, 0, 0.18)", backgroundColor: "white", marginTop: "20px", width: '100%' }}  >


                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        events={filteredEvents}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                        themeSystem="bootstrap-premium"
                                        height="auto"
                                        contentHeight="auto"
                                        eventColor="#03a9f3" // Set event color
                                        eventTextColor="#ffffff" // Set event text color
                                        dateClick={handleDateClick}
                                        eventContent={renderEventContent} 
                                        dayCellDidMount={(info) => {
                                            const today = new Date().toISOString().split('T')[0]; // Current date
                                            const cellDate = moment(info.date).format('YYYY-MM-DD');
                                            if (info.date < today) {
                                                info.el.classList.add('fc-day-past');
                                            }
                                            // if (fullyBookedDates[cellDate]) {  // Check if the date exists in fullyBookedDates
                                            //     info.el.classList.add('fc-day-disabled'); // Add class for fully booked dates
                                            //     info.el.style.pointerEvents = 'none'; // Disable clicking on fully booked dates
                                            // }
                                        }}
                                        eventClick={handleEventClick}
                                    />

                                </div>
                            </div>

                            {view === 'form' && (
                                booking_date && (
                                    <div className='col-md-4'>

                                        <div class="py-3 mt-4 border bg-white">
                                            <h3 class="vm_font_semi_bold border-bottom mb-3 px-3 pb-2">
                                                Booking Form
                                            </h3>



                                            <div className="container">
    <div className="col">
        {/* Box 1 */}
        <div className="col-md-12">
            <div className="form-box py-4 px-3 bg-light border  shadow-sm mb-4">
                {/* <h5 className="box-title mb-4">Personal Information</h5> */}
                
                <div className="col-md-12">

{/* <div className="col-md-12">
    <button className="btn btn-primary" onClick={() => { setShowEmailModal(true); console.log('clicked', showEmailModal); }}>
        Send Email
    </button>


    <Modal show={showEmailModal} onHide={handleCloseEmailModal}>
    <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="alert alert-success text-center">
    <i className="bi bi-check-circle h1"></i>  Successfully Booked! Send a confirmation email to the customer.
        </div>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="emailTo" className="form-group mt-3 ">
                <label>To</label>
                <Form.Control 
                    type="email" 
                    name="to" 
                    value={emailData.to} 
                    onChange={handleInputChange} 
                    readOnly
                />
                <span className="text-danger">{email_error_list.to}</span>
            </Form.Group>
            <Form.Group controlId="emailSubject"  className="form-group mt-3 ">
                <Form.Label>Subject</Form.Label>
                <Form.Control 
                    type="text" 
                    name="subject" 
                    value={emailData.subject} 
                    onChange={handleInputChange} 
                />
                <span className="text-danger">{email_error_list.subject}</span>
            </Form.Group>
            <Form.Group controlId="emailMessage"  className="form-group mt-3 ">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={8} 
                    name="message" 
                    value={emailData.message} 
                    onChange={handleInputChange} 
                />
                <span className="text-danger">{email_error_list.message}</span>
            </Form.Group>

            <div class="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center" style={{ padding:'10px 10px 30px 10px' }}>
                <label className="vm_cursor_pointer" htmlFor="image">
                    <i className="bi bi-card-image h2"></i>
                </label>
                <input 
                    className="d-none" 
                    type="file" 
                    name="image" 
                    id="image" 
                    multiple 
                    onChange={onSelectImages} 
                />
                <select 
                    className="form-select ms-3 form-select-sm" 
                    name="template" 
                    id="template" 
                    value={template} 
                    onChange={handleTemplateChange}
                >
                    <option value="">Select Template</option>
                    {email_template.map((item) => (
                        <option value={item.template_name} key={item.template_name}>
                            {item.template_name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                <button className="btn btn-sm btn_secondary text-white ms-auto" type="submit">
                    Send Email
                </button>
            </div>
            </div>
        </Form>
    </Modal.Body>
</Modal>
</div> */}

              
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group ">
                                                                    <label htmlFor="date"> Date</label>
                                                                    <div class="d-flex input_wrapper">
                                                                        <div class="icon_wrapper">
                                                                            <i class="bi bi-calendar2-plus icon"></i>
                                                                        </div>

                                                                        <input type="date" id="booking_date" value={booking_date} className="form-control" onChange={(e) => handleBookingDateChange(e.target.value)} readOnly />


                                                                    </div>
                                                                    <span class="text-danger">{bookingErrorList.booked_date}</span>
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="selectedPackageType">Select Package Type</label>
                                                                    <select
                                                                        className="form-select"
                                                                        id="selectedPackageType"
                                                                        name="selectedPackageType"
                                                                        value={selectedPackageType}
                                                                        onChange={(e) => {setSelectedPackageType(e.target.value); setSelectedGameType('');setSelectedBirthdayPackage('');if(selectedPackageType === 'birthday_package')
                                                                            {fetchGamePackages(1)}}}
                                                                        
                                                                    >
                                                                        <option value="">Select Package Type</option>
                                                                        <option value="laser_tag_package">Laser Tag package</option>
                                                                        <option value="birthday_package">Birthday Package</option>
                                                                    </select>
                                                                    <span className="text-danger">{bookingErrorList.selectedPackageType}</span>
                                                                </div>
                                                            </div>




                                                            {selectedPackageType === 'laser_tag_package' && (
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label htmlFor="game_type"> Select Game Type</label>
                                                                        <select class="form-select" id="game_type" name="game_type" value={selectedGameType} onChange={handleGameTypeChange}>
                                                                            <option value="">Select game type</option>
                                                                            {game_types.map((game_type) => (
                                                                                <option key={game_type.id} value={game_type.id}>
                                                                                    {game_type.game_type_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <span class="text-danger">{bookingErrorList.game_type_id}</span>
                                                                    </div>
                                                                </div>
                                                            )}


                                                            {selectedPackageType === 'birthday_package' &&  (
                                                                <><div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label htmlFor="birthday_package"> Select Birthday Package</label>
                                                                        <select class="form-select" id="birthday_package" name="birthday_package" value={selectedBirthdayPackage} onChange={handleBirthdayPackageChange}>
                                                                            <option value="">Select Birthday package</option>
                                                                            {game_packages.map((game_package) => (
                                                                                <option key={game_package.id} value={game_package.id}>
                                                                                    {game_package.sub_package_name}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                        <span class="text-danger">{bookingErrorList.game_package_id}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                <div className="col-md-6">
            <div className="form-group">
                <label htmlFor="birthday_kid">Birthday Kid Name</label>
                <div class="mt-3">
                <input type="text" id="birthday_kid" className="form-control" value={birthdayKid} onChange={(e) => setBirthdayKid(e.target.value)} />
                <span className="text-danger">{bookingErrorList.birthday_kid}</span>
                </div>
            </div>
        </div>
      
        {selectedBirthdayPackage && birthday_packages.min_games && (
    <div className="col-md-6">
        <div className="form-group">
            <label htmlFor="game_count">
                No of Games
                {/* <span
                    style={{ marginLeft: '5px', color:'gray', cursor: 'pointer' }}
                    title={`Minimum ${birthday_packages.min_games} games should be selected`}
                >
                   <i class="bi bi-question-circle-fill"></i>
                </span> */}
            </label>
            {birthday_packages.min_games && (
            <div className="mb-1 text-muted" style={{ fontSize: '0.9em', marginTop: '-5px' }}>
                *Select minimum {birthday_packages.min_games} games.
            </div>
        )}
            <input
                type="number"
                id="game_count"
                className="form-control"
                value={game_count}
                onChange={(e) => setGame_count(e.target.value)}
                min="0"
            />
            <span className="text-danger">{bookingErrorList.game_count}</span>
        </div>
    </div>
)}
     {/* { selectedBirthdayPackage && (birthday_packages.sub_package_name !== 'Bronze') && ( */}
         { selectedBirthdayPackage && (birthday_packages.food_quantity > 0) && (
        <><div className="col-md-6">
               <div className="form-group">
                                                                                            <label htmlFor="food_details">Food details
                                                                                                {/* <span
                                                                                                    style={{ marginLeft: '5px', color: 'gray', cursor: 'pointer' }}
                                                                                                    title={`Please select ${birthday_packages.food_quantity} food options.`}
                                                                                                >
                                                                                                    <i class="bi bi-question-circle-fill"></i>
                                                                                                </span> */}


                                                                                            </label>
                                                                                            {birthday_packages.food_quantity && (
                                                                                                    <div className="mb-1 text-muted" style={{ fontSize: '0.9em', marginTop: '-5px' }}>
                                                                                                        *Select {birthday_packages.food_quantity} food options.
                                                                                                    </div>
                                                                                                )}
                                                                                            <div class="px-3 mb-1 bg-white mt-1 pb-1 pt-1">
                                                                                                <div class="row ">
                                                                                                    {foodOptions.map((food, index) => (
                                                                                                        <div className="col-md-9" key={index}>
                                                                                                            <div className="form-group-checkbox d-flex align-items-center justify-content-between">
                                                                                                                <div className="d-flex align-items-center">

                                                                                                                    <input
                                                                                                                        style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                                                                                                                        type="checkbox"
                                                                                                                        value={food}
                                                                                                                        onChange={handleFoodOptionChange}
                                                                                                                        disabled={selectedFoods.length >= birthday_packages.food_quantity && !selectedFoods.includes(food)} />
                                                                                                                    <label style={{ marginTop: '9px' }}>{food}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    ))}   <span className="text-danger">{bookingErrorList.selectedFoods}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div></>)}


                                                                                {selectedBirthdayPackage && (birthday_packages.drink_quantity > 0) && (
                                                                                    <>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="food_details">Drink details

                                                                                                    {/* <span
                                                                                                        style={{ marginLeft: '5px', color: 'gray', cursor: 'pointer' }}
                                                                                                        title={`Please select ${birthday_packages.drink_quantity} drink options.`}
                                                                                                    >
                                                                                                        <i class="bi bi-question-circle-fill"></i>
                                                                                                    </span> */}
                                                                                                </label>
                                                                                                {birthday_packages.drink_quantity && (
                                                                                                      <div className="mb-1 text-muted" style={{ fontSize: '0.9em', marginTop: '-5px' }}>
                                                                                                        *Select {birthday_packages.drink_quantity} drink options.
                                                                                                    </div>
                                                                                                )}
                                                                                                <div class="px-3 mb-1 bg-white mt-1 pb-1 pt-1">

                                                                                                    <div class="row ">
                                                                                                        {drinkOptions.map((drink, index) => (
                                                                                                            <div className="col-md-9" key={index}>
                                                                                                                <div className="form-group-checkbox d-flex align-items-center justify-content-between">
                                                                                                                    <div className="d-flex align-items-center">

                                                                                                                        <input
                                                                                                                            style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                                                                                                                            type="checkbox"
                                                                                                                            value={drink}
                                                                                                                            onChange={handleDrinkOptionChange}
                                                                                                                            disabled={selectedDrinks.length >= birthday_packages.drink_quantity && !selectedDrinks.includes(drink)} />
                                                                                                                        <label style={{ marginTop: '9px' }}>{drink}</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        ))}     <span className="text-danger">{bookingErrorList.selectedDrinks}</span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div></>
)}
       
        </div> </>


                                                            )}

                                                        </div>


                                                    </div>



            </div>
        </div>

        {/* Box 2 */}
        <div className="col-md-12">
            <div className="form-box py-4 px-3 bg-light border shadow-sm">
                {/* <h5 className="box-title mb-4">Contact Details</h5> */}
                <div className="row">
                <div className="col-md-6">
             
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">First Name</label>
                                                            <input type="text" className="form-control" id="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder="Enter First Name" />
                                                            <span class="text-danger">{bookingErrorList.firstname}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Last Name</label>
                                                            <input type="text" className="form-control" id="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder="Enter Last Name" />
                                                            <span class="text-danger">{bookingErrorList.lastname}</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 form-group">
                                                        <label for="phone">Phone Number</label>
                                                        <input type="text" class="form-control" name="phone" id="phone" placeholder="Enter Phone Number" value={phone}
                                                            onChange={(event) => { setPhone(event.target.value); }} />
                                                        <span class="text-danger">{bookingErrorList.phone}</span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label for="email">Email</label>
                                                        <input type="text" class="form-control" name="email" id="email" value={email} placeholder="Enter Email Address"
                                                            onChange={(event) => { setEmail(event.target.value); }} />

                                                        <span class="text-danger">{bookingErrorList.email}</span>
                                                  
                                                    </div>

                                                    {selectedGameType && (
            <div className="mb-1 text-muted" style={{ fontSize: '0.9em', marginTop: '-5px' }}>
                *The total no of players must be between {minPlayers} and {maxPlayers}.
            </div>
        )}
          {selectedBirthdayPackage  && (
            <div className="mb-1 text-muted" style={{ fontSize: '0.9em', marginTop: '-5px' }}>
                *The total no of players must be between 1 and {birthday_packages.max_players_in_a_slot }.
            </div>
        )}

                                                    <div className="col-md-4">
                                                        <div className="form-group py-2">
                                                            <label htmlFor="kids_count">Kids</label>
                                                            <input
                                                                type="number"
                                                                id="kids_count"
                                                                name="kids_count"
                                                                value={kids_count}
                                                                className="form-control"
                                                                onChange={(e) => { setKids_count(e.target.value); }}
                                                                min="0"
                                                                required
                                                            />
                                                        </div>
                                                    </div>   <div className="col-md-4">
                                                        <div className="form-group py-2">
                                                            <label htmlFor="kids_count">Adults</label>
                                                            <input
                                                                type="number"
                                                                id="adults_count"
                                                                name="adults_count"
                                                                value={adults_count}
                                                                className="form-control"
                                                                onChange={(e) => { setAdults_count(e.target.value); }}
                                                                required
                                                                min="0"
                                                            />
                                                        </div>

                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="form-group py-2">
                                                            <label htmlFor="price">Cost</label>
                                                            <input type="number" class="form-control" name="price" id="price" value={selectedBirthdayPackage?birthdayPrice.price:timeSlotDetails.price} placeholder="Cost" readOnly
                                                                min="0" />
                                                            <span class="text-danger">{bookingErrorList.price}</span>

                                                        </div>
                                                      
                                                      

                                                    </div>
                                                 
                                                    <span class="text-danger">{totalPlayersErrorList}</span>
                                                    {/* <span class="text-danger">{bookingErrorList.total_players}</span> */}
                                                 
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="booking_time">Select Time:</label>
                                                            <div id="booking_time" className="form-control py-3">

                                                                        
                                                                {timeSlotLoading ? (
                                                                    <div>Loading...</div>
                                                                ) : (


                                                                    bookingAvailableTimeSlots
                                                                    .map((timeSlot, index) => {
                                                                        const remainingSpace = availableSpaces[timeSlot] || 0;
                                                                         const game_type = game_types_array.find(item => item.id.toString() === selectedGameType);
                                                                        let isDisabled = Array.isArray(disabledTimeSlots) && disabledTimeSlots.includes(timeSlot);
                                                                
                                                                       // Handle specific logic for "Private" game type
                                                                        if (game_type && game_type.game_type_name.toLowerCase().includes('private')) {
                                                                            // Disable slots that do not have exactly max players available
                                                                            if (remainingSpace !== game_type.max_players_in_a_slot) {
                                                                                isDisabled = true;
                                                                            }
                                                                        }

                                                                        //  // Handle specific logic for "Public" game type
                                                                        // if (game_type && game_type.game_type_name.toLowerCase().includes('public')) {
                                                                        //     // Disable slots that are already booked for "Private" game types
                                                                        //     const isBookedForPrivate = disabledTimeSlots.includes(timeSlot);
                                                                        //     if (isBookedForPrivate) {
                                                                        //         isDisabled = true;
                                                                        //     }
                                                                        // }

                                                                        // Handle specific logic for "Birthday Package"
                                                                        if (selectedPackageType === 'birthday_package') {
                                                                            
                                                                            const userGameCount = parseInt(game_count, 10); // Replace with the actual number of games selected by the user

                                                                          
                                                                                if ( userGameCount <= bookingAvailableTimeSlots.length) {
                                                                                const isConsecutiveAvailable = bookingAvailableTimeSlots.slice(index, index + userGameCount).every(slot => availableSpaces[slot] === game_packages[0].max_players_in_a_slot);
                                                                          
                                                                                if (!isConsecutiveAvailable) {
                                                                                    isDisabled = true;
                                                                                }
                                                                            } 
                                                                            else {
                                                                                isDisabled = true;
                                                                            }

                                                                        }                                                        
                                                                
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className="form-group-checkbox d-flex align-items-center justify-content-between"
                                                                                style={isDisabled ? { color: '#a9a9a9', opacity: 0.6, pointerEvents: 'none' } : {}}
                                                                            >
                                                                                <div className="d-flex align-items-center">
                                                                                    <div>
                                                                                        <input
                                                                                            style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
                                                                                            type="checkbox"
                                                                                            className="checkbox"
                                                                                            id={`timeslot_${index}`}
                                                                                            value={timeSlot}
                                                                                            checked={booking_time.includes(timeSlot)}
                                                                                            onChange={handleTimeSlotChange}
                                                                                            disabled={isDisabled}
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`timeslot_${index}`}
                                                                                            style={{ marginLeft: "5px" }}
                                                                                        >
                                                                                            <span style={isDisabled ? { textDecoration: 'line-through' } : {}}>
                                                                                                {timeSlot}
                                                                                            </span>
                                                                                            <span style={{ color: 'green', marginLeft: '30px' }}>
                                                                                                {remainingSpace} left
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                













                                                                    
                                                                    
                                                                    // bookingAvailableTimeSlots.
                                                                    // filter((timeSlot, index, array)  => {
                                                                    //     const remainingSpace = availableSpaces[timeSlot] || 0;
                                                                    //     const game_type = game_types_array.find(item=> item.id.toString() === selectedGameType);
                                                                       
                                                                    //     // Only filter time slots if the selected game type is "Private"
                                                                    //     if (selectedPackageType === 'birthday_package') {
                                                                      
                                                                    //         const userGameCount = parseInt(game_count, 10); // Replace with the actual number of games selected by the user
                                                                    
                                                                    //         // Check for consecutive slots based on user count
                                                                    //         if (index + userGameCount <= array.length) {
                                                                    //             return array.slice(index, index + userGameCount).every(slot => availableSpaces[slot] === game_packages[0].max_players_in_a_slot);
                                                                    //         }
                                                                    //         // return remainingSpace === 30;
                                                                    //     }
                                                                    //     if (game_type) {
                                                                          
                                                                    //     if (game_type.game_type_name.toLowerCase().includes('private')) {
                                                                    //         // console.log(game_type)
                                                                    //         return remainingSpace === game_type.max_players_in_a_slot ; // Filter for slots with exactly 30 spaces left
                                                                    //     }
                                                                        
                                                                    // }
                                                                    //     return true;
                                                                    //      // Show all slots if not Private
                                                                    // }).
                                                                    // map((timeSlot, index) => {
                                                                    //     const isDisabled = Array.isArray(disabledTimeSlots) && disabledTimeSlots.includes(timeSlot);
                                                                    //     const remainingSpace = availableSpaces[timeSlot] || 0;
                                                                    //     return (
                                                                    //         <div
                                                                    //             key={index}
                                                                    //             className="form-group-checkbox d-flex align-items-center justify-content-between"
                                                                    //             style={isDisabled ? { color: '#a9a9a9', opacity: 0.6, pointerEvents: 'none' } : {}}
                                                                    //         >
                                                                    //             <div className="d-flex align-items-center">
                                                                    //                 <div>
                                                                    //                     <input
                                                                    //                         style={
                                                                    //                             { boxShadow: "2px 2px #059618", transition: "all .5s ease" }
                                                                    //                         }
                                                                    //                         type="checkbox"
                                                                    //                         className="checkbox"
                                                                    //                         id={`timeslot_${index}`}
                                                                    //                         value={timeSlot}
                                                                    //                         checked={booking_time.includes(timeSlot)}
                                                                    //                         onChange={handleTimeSlotChange}
                                                                    //                         disabled={isDisabled}
                                                                    //                     />
                                                                    //                     <label
                                                                    //                         htmlFor={`timeslot_${index}`}
                                                                    //                         style={{ marginLeft: "5px" }}
                                                                    //                     >
                                                                    //                         <span style={isDisabled ? { textDecoration: 'line-through' } : {}}>
                                                                    //                             {timeSlot}
                                                                    //                         </span>
                                                                    //                         <span style={{ color: 'green', marginLeft: '30px' }}>
                                                                    //                             {remainingSpace} left
                                                                    //                         </span>
                                                                    //                     </label>
                                                                    //                 </div>
                                                                    //             </div>
                                                                    //         </div>
                                                                    //     );
                                                                    // })
                                                                )}
                                                               
                                                            </div>
                                                            <span className="text-danger">{bookingErrorList.booked_time}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="grand_total">Grand Total</label>
                                                            <input type="number" className="form-control" id="grand_total" value={grand_total} onChange={(e) => setGrand_total(e.target.value)} readOnly/>
                                                            <span class="text-danger">{bookingErrorList.grand_total}</span>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="deposit_amount">Deposit Amountt</label>
                                                            <input type="number" className="form-control" id="deposit_amount" value={deposit_amount} onChange={(e) => setDeposit_amount(e.target.value)} readOnly/>
                                                            <span class="text-danger">{bookingErrorList.deposit_amount}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="amount_paid">Amount Paid</label>
                                                            <input type="number" className="form-control" id="amount_paid" value={amount_paid} onChange={(e) => setAmount_paid(e.target.value)} />
                                                            <span class="text-danger">{bookingErrorList.amount_paid}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                    <div className="form-group">
                                <label htmlFor="payment_method">Payment Method</label>
                                <select  class="form-select" id="payment_method" name="payment_method" value={payment_method} 
                                 onChange={handlePaymentMethodChange }>
         <option value="">Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="Credit">Credit </option>
              <option value="Debit">Debit </option>
              <option value="E-transfer">E-transfer</option>
         
        </select>
                                <span className="text-danger">{bookingErrorList.payment_method}</span>
                            </div>
                                                    </div>


                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="note">Note</label>
                                                            <input type="text" className="form-control" id="note" value={note} onChange={(e) => setNote(e.target.value)} placeholder="Enter Note" />
                                                            <span class="text-danger">{bookingErrorList.note}</span>
                                                        </div>
                                                    </div>
</div>


               
            </div>
        </div>
    </div>
</div>





                                            <div className="content px-3">
                                               
                                                   

                                    




                                                    <div class="d-flex justify-content-end ms-auto py-3">


                                                        <button onClick={bookGame} class="btn btn-sm btn_secondary text-white "><i class="bi bi-send"></i>  {loading ? "Booking..." : "Book"} </button>
                                                    </div>   
                                            </div>

                                        </div>
                                    </div>
                                )
                            )}



{view === 'details' && (
    <div className='col-md-4'>
        <div className="py-3 mt-4 border-top bg-light rounded shadow-sm">
            <h3 className="vm_font_semi_bold border-bottom mb-3 px-3 pb-2 ">
                Booking Details
            </h3>
<div className="content py-0 bg-white px-3 py-3 shadow-lg mb-3 w-100" >
            <div className="text-center">
                <h2 className="vm_font_bold mt-2">{bookingDetails.firstname} {bookingDetails.lastname}</h2>
                <div className="d-flex justify-content-center mb-3">
                    <a style={{ cursor: "pointer" }} className="d-flex align-items-center pe-3 text-decoration-none text-dark">
                        <i className="bi bi-telephone-fill me-2 text-success"></i>
                        <p className="mb-0">{bookingDetails.phone}</p>
                    </a>

                    <a style={{ cursor: "pointer" }} className="d-flex align-items-center text-decoration-none text-dark">
                        <i className="bi bi-envelope-fill me-2 text-success"></i>
                        <p className="mb-0">{bookingDetails.email}</p>
                    </a>
                </div>
            </div>

            <div className="row border-top pb-3">
                {[
                    { label: 'Game Name', value: bookingDetails.game_name },
                    { label: 'Location', value: bookingDetails.location_name },
                    { label: 'Game Package', value: bookingDetails.game_type_name ? bookingDetails.game_type_name : `${bookingDetails.package_name} - ${bookingDetails.game_package_name}` },
                    { label: 'Booked Date', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-calendar3 me-2 text-success" ></i>
                            <span>{moment(bookingDetails.booked_date).format('D MMMM YYYY')}</span>
                        </div>
                    ) },
                    { label: 'Booked Time', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-clock-fill me-2 text-success" ></i>
                            <span>{bookingDetails.booked_times}</span>
                        </div>
                    ) },
                    { label: 'Adult Count', value: bookingDetails.no_of_adults },
                    { label: 'Kids Count', value: bookingDetails.no_of_children },
                    { label: 'Amount paid', value: bookingDetails.amount_paid },
                    
                    ...(bookingDetails.package_name 
                        ? [
                          
                            { label: 'Birthday Kid Name', value: bookingDetails.birthday_kid_name },
                            ...(bookingDetails.food_details 
                                ? [{ label: 'Food details', value: (bookingDetails.food_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                : []),
                                ...(bookingDetails.drink_details 
                                    ? [{ label: 'Drink details', value: (bookingDetails.drink_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                    : []),
                           

                        ]
                        : [])

                    
                    
                    // { label: 'Birthday Kid Name', value: bookingDetails.birthday_kid_name },
                    // { label: 'Food details', value: bookingDetails.food_details },
                    // { label: 'Drink details', value: bookingDetails.drink_details },

                ].map((detail, index) => (
                    <div className="d-flex border-bottom py-2" key={index}>
                        <div className="col-5 p-1 border-end vm_font_bold text-secondary">{detail.label}</div>
                        <div className="col-7 p-1 ps-3">{detail.value}</div>
                    </div>
                ))}
          
   <div class="d-flex justify-content-center pt-3 ">
<button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(bookingDetails.booking_id)}  >
View More Details</button>
</div>

            </div>
        </div>
    </div>
    </div>
)}

 </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewBooking;
