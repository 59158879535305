import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserContext from './UserContext';
import MultipleConsentForms from './MultipleConsentForms';
import moment from 'moment';

function ConsentFormPage(){

      const API_URL = process.env.REACT_APP_API_URL;
      const { id } = useParams();
      const [name, setName] = useState("");
      const [isLoading, setIsLoading] = useState(true);
      const [loading, setLoading] = useState(false);
      const userContext = useContext(UserContext);
      const [showComponents, setShowComponents] = useState(false);
      const [error_list, setErrorList] = useState([]);
      const [customerById, setCustomerById] = useState([]);
      const navigate = useNavigate();


      useEffect(() => {
         setIsLoading(true);
         getCustomerByBookingId(id);
       
    }, []);

    

    function getCustomerByBookingId(id){
 
        axios.get(`${API_URL}/view-booked-slots-by-booking-id/`+id).then(res=>{
            if(res.data.status === 200){
                const bookedSlot = res.data.bookedSlot;
                setCustomerById(res.data.bookedSlot);
                //console.log( res.data.bookedSlot);
               const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);
      
      // Update state with formatted data
      setCustomerById({
        ...bookedSlot,
        booked_time: formattedBookedTime
      });
            }
            else if(res.data.status === 404){
                //setMessage(res.data.message);
                console.log(res.data.status);
                //setIsLoading(false);
                }
            setIsLoading(false);
         });
        
        }

        const formatBookedTime = (bookedTime) => {
            try {
              const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
              if (Array.isArray(timeSlots) && timeSlots.length > 0) {
                const startTime = timeSlots[0].split(' - ')[0];
                const endTime = timeSlots[timeSlots.length - 1].split(' - ')[1];
                // Convert to moment objects and format them
                const formattedStartTime = moment(startTime, 'hh:mm A').format('h:mm A');
                const formattedEndTime = moment(endTime, 'hh:mm A').format('h:mm A');
                return `${formattedStartTime} - ${formattedEndTime}`;
              }
              return 'No time slots available';
            } catch (error) {
              console.error("Error parsing booked time:", error);
              return 'Invalid time format';
            }
          };

          const handleAllFormsSubmitted = () => {
            navigate(`/thank-you-completed/${id}`, {
              state: { allFormsCompleted: true },
            });

           // setShowConsentForms(false);
           // alert('All consent forms have been submitted successfully!');
          };


   

    return(
        <div>
          
          {showComponents && (
 <><SideNav /><TopBar /></>
      )}
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


   <div class="right_panel position contact_section vh-100" id="right_panel" style={{padding:'0px'}} >

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Consent Form</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Fill Consent Forms for Participants</h2>
        </div>
        <div class="content pt-3">  
                    
        <div className="px-2">
                        {(customerById.no_of_adults > 0 || customerById.no_of_children > 0 ) && ( 
                              <MultipleConsentForms
                                bookingId={customerById.booking_ref_id}
                                participantCount={customerById.no_of_adults}
                                noOfKids={customerById.no_of_children }
                                onAllFormsSubmitted={handleAllFormsSubmitted}
                              />
                         )} 
                          </div>
            
        </div>
    </div>

   
</div>

</div>



        </div>
    )
}

export default ConsentFormPage;