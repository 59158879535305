import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link, useLocation  } from 'react-router-dom';
import UserContext from './UserContext';
import backgroundImage from '../img/lasercombat_background2.jpeg';
import axios from 'axios';


function ThankYouPage() {

  const API_URL = process.env.REACT_APP_API_URL;
  const { bookingId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const userContext = useContext(UserContext);
  const [customerById, setCustomerById] = useState([]);
  // const location = useLocation();
  // const { allFormsCompleted } = location.state || {};
  const [allFormsCompleted, setAllFormsCompleted] = useState(false);




  


  // Inline styles
  const loaderContainerStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  const spinnerBorderStyle = {
    width: '3rem',
    height: '3rem',
    borderWidth: '0.4em',
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, sans-serif',
  };

  const contentContainerStyle = {
    background: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    // width:'700px',
  };

  const mainTitleStyle = {
    color: '#4CAF50', // Green color
    marginBottom: '1rem',
    
  };

  const contentWrapperStyle = {
    color: '#333',
    marginBottom: '0.5rem',
       height:'130px',
  };

  const linkStyle = {
    color: '#4CAF50',
    textDecoration: 'none',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };


  
  function getCustomerByBookingId(id){
 
    axios.get(`${API_URL}/view-booked-slots-by-id/`+id).then(res=>{
        if(res.data.status === 200){
            const bookedSlot = res.data.bookedSlot;
            setCustomerById(res.data.bookedSlot);
            // setEmailData(prevData => ({ ...prevData, to: res.data.bookedSlot.email }));
         

           // console.log( res.data.bookedSlot);
          //  const formattedBookedTime = formatBookedTime(bookedSlot.booked_time);
  
  // setCustomerById({
  //   ...bookedSlot,
  //   booked_time: formattedBookedTime,
  //   consent_form_details: bookedSlot.consent_form_details ? JSON.parse(bookedSlot.consent_form_details) : []
  // });
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            console.log(res.data.status);
            //setIsLoading(false);
            }
        setIsLoading(false);
     });
    
    }
 

  // Render nothing if data is not available yet
 
  return (



    <div style={backgroundStyle}>
      {isLoading && (
        <div style={loaderContainerStyle}>
          <div style={spinnerBorderStyle} className="spinner-border" role="status"></div>
        </div>
      )}

      <div style={contentContainerStyle}>
        <div style={{ ...mainTitleStyle, fontSize: '2rem' }}>
        <i class="bi bi-check-circle"><h2 style={{fontSize: '2rem' }}>Thank You!</h2></i>
        </div>
        <div style={contentWrapperStyle}>
       <h2>Consent Form Submitted Successfully.</h2> 
         
        
          <p>To submit consent forms for other participants, please use the link below:</p>
          <Link to={`/consent-form-page/${bookingId}`} class="btn btn-sm btn_secondary " >Submit Consent Forms for Other Participants</Link>
      
         
          <p>If you need any further assistance, feel free to <a href="https://lasercombat.ca/contact/" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = linkHoverStyle.textDecoration} onMouseOut={(e) => e.target.style.textDecoration = linkStyle.textDecoration}>contact us</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
